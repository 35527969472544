import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import utils from 'utils';

export function init() {
	Sentry.init({
		dsn: 'https://87d0ed9ec8a1400b85741b8c818f8dcf@o21951.ingest.sentry.io/5258357',
		release: process.env.REACT_APP_GIT_SHA,
		environment: _getSentryEnvironment(),
	});
}

function _getSentryEnvironment() {
	switch (utils.getEnvironment()) {
		case 'PRD':
			return 'production';
		case 'STG':
		default:
			return 'development';
	}
}

function captureException(expection: Error) {
	Sentry.captureException(expection);
}

function identifyUser({ email, username, id }: { email: string; username: string; id: string }) {
	return Sentry.setUser({ email, username, id });
}

export default { init, ErrorBoundary, captureException, identifyUser };
