import CardStatus from 'components/cards/CardStatus/CardStatus';
import LastFour from 'components/cards/LastFour/LastFour';
import DescriptionItem from 'components/DescriptionItem/DescriptionItem';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import style from '../../CardDetails.module.scss';
import Section from '../Section/Section';
import SectionTitle from '../SectionTitle/SectionTitle';

interface IOverviewSectionProps {
	card: IAptoCardSummaryResponse['card'];
}

export default function OverviewSection(props: IOverviewSectionProps) {
	return (
		<Section>
			<SectionTitle title="Overview" />
			<DescriptionItem title="Card Number">
				<LastFour value={props.card.lastFour} />
			</DescriptionItem>
			<DescriptionItem title="Card Type">
				<Paragraph size="small" className={style['CardDetails__cardType']}>
					{props.card.cardType.toLowerCase()}
				</Paragraph>
			</DescriptionItem>
			<DescriptionItem title="Status" className={style['CardDetails__cardStatus']}>
				<CardStatus value={props.card.status} />
			</DescriptionItem>
			<DescriptionItem title="Card ID">
				<Paragraph size="small">{props.card.id}</Paragraph>
			</DescriptionItem>
		</Section>
	);
}
