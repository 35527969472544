import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import GridColumns from '../GridColumns';
import style from './Item.module.scss';

export interface IItemProps {
  /**
   * A CSS classname to add additional styles.
   */
  className?: string;

  /**
   * Number of columns of the Grid the Item will occupy.
   */
  colWidth: GridColumns;

  /**
   * Number of columns of the Grid the Item will occupy for bigger screen sizes.
   */
  colWidthLg?: GridColumns;

  /**
   * Restrict the Item to a maxiumum width.
   */
  maxWidth?: string;
}

export default function Item(props: PropsWithChildren<IItemProps>) {
  const cnColumnWidth = style[`Item--${props.colWidth || '1'}`];
  const cnColWidthLg = props.colWidthLg ? style[`Item-lg--${props.colWidthLg}`] : null;

  return (
    <div style={{ maxWidth: props.maxWidth }} className={classnames(cnColumnWidth, cnColWidthLg, props.className)}>
      {props.children}
    </div>
  );
}
