import classnames from 'classnames';
import CopyText from 'components/CopyText/CopyText';
import DateFormatter from 'components/formatters/DateFormatter/DateFormatter';
import React, { PropsWithChildren, useState } from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ICoreApiKey from 'types/ICoreApiKey';
import style from './CredentialItem.module.scss';

export interface ICredentialProps {
	credential: ICoreApiKey;
	onRemoveClick: (id: string) => void;
	index?: number;
}

export default function CredentialItem({ credential, onRemoveClick, index }: PropsWithChildren<ICredentialProps>) {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<View className={classnames(style.CredentialItem, { [style['CredentialItem--isExpanded']]: isExpanded })}>
			<View className={style.CredentialItem__header} onClick={() => setIsExpanded(!isExpanded)}>
				<Grid cols="3" isNested className={style.CredentialItem__grid}>
					<Item colWidth="1">
						<Paragraph size="small" color="contentPrimary">
							{credential.description || `Core API Key ${index}`}
						</Paragraph>
						<Paragraph size="small" color="contentTertiary">
							<DateFormatter date={credential.created} />
						</Paragraph>
					</Item>
					<Item colWidth="2">
						<Paragraph size="small" color="contentPrimary" textAlign="right">
							<CopyText text={credential.apiClientId}>
								<span className={style.CredentialItem__longText}>{credential.apiClientId}</span>
							</CopyText>
						</Paragraph>
					</Item>
				</Grid>
			</View>

			{isExpanded ? (
				<View className={style.CredentialItem__body}>
					<Grid cols="12" isNested>
						<Item colWidth="6" colWidthLg="5">
							<Label size="small" color="contentPrimary" fontWeight="600">
								Public Key
							</Label>
							<Paragraph margin="4px 0 8px" size="small" color="contentPrimary">
								<span className={style.CredentialItem__longText}>{credential.apiClientId}</span>
							</Paragraph>
						</Item>

						<Item colWidth="6" colWidthLg="3">
							<Label size="small" color="contentPrimary" fontWeight="600">
								Name
							</Label>
							<Paragraph margin="4px 0 8px" size="small" color="contentPrimary">
								{credential.description}
							</Paragraph>
						</Item>

						<Item colWidth="6" colWidthLg="2">
							<Label size="small" color="contentPrimary" fontWeight="600">
								Last updated
							</Label>
							<Paragraph margin="4px 0 8px" size="small" color="contentPrimary">
								<DateFormatter date={credential.updated} />
							</Paragraph>
						</Item>

						<Item colWidth="6" colWidthLg="2">
							<Label size="small" color="contentPrimary" fontWeight="600">
								Created
							</Label>
							<Paragraph margin="4px 0 8px" size="small" color="contentPrimary">
								<DateFormatter date={credential.created} />
							</Paragraph>
						</Item>
					</Grid>
				</View>
			) : null}

			{isExpanded ? (
				<View className={style.CredentialItem__footer}>
					<Pill level="secondary" size="medium" onClick={() => onRemoveClick(credential.keyId)}>
						<Label size="medium" color="negative">
							Delete
						</Label>
					</Pill>
				</View>
			) : null}
		</View>
	);
}
