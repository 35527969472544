import { DASHBOARD_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router';

export default function useSuccessfulApplication() {
	const { isLoggedIn, setEnvironment } = useSession();
	const navigate = useNavigate();

	function handleGoToProduction() {
		setEnvironment('production');
		navigate(DASHBOARD_ROUTE, { replace: true });
		// TODO: Reassure sessionStorage remains synced STARGATE-1082
		window.location.reload();
	}

	return {
		handleGoToProduction,
		isLoggedIn,
	};
}
