import { ACTIVATE_ACCOUNT_LEGAL_ROUTE } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'styleguide/Modal/Modal';
import ModalSetPublicKey from './components/ModalSetPublicKey/ModalSetPublicKey';

export interface IUsePublicKeyStepSuccessArgs {
	publicKey: string;
	applicationId: string;
	name: string;
}

export default function usePublicKeyStepSuccess(args: IUsePublicKeyStepSuccessArgs) {
	const { applicationId, publicKey, name } = args;
	const navigate = useNavigate();

	function handleConfirm() {
		navigate(ACTIVATE_ACCOUNT_LEGAL_ROUTE, { replace: true });
	}

	function showModalSetPublicKey() {
		Modal.show(
			<ModalSetPublicKey
				onSuccess={_handleOnSuccess}
				applicationId={applicationId}
				currentPublicKey={publicKey}
				currentName={name}
			/>,
			{
				canBeClosedOnClickOut: false,
				ariaTitle: 'Set Public Key',
			}
		);
	}

	function _handleOnSuccess() {
		Modal.hide();
		toast.success('Public key changes saved.');
	}

	return {
		publicKey,
		handleConfirm,
		showModalSetPublicKey,
	};
}
