import { useFeature } from '@optimizely/react-sdk';
import Company from 'components/Company/Company';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import {
	SETTINGS_ACCOUNTS_ROUTE,
	SETTINGS_BUSINESS_ROUTE,
	SETTINGS_DOCUMENTS_ROUTE,
	SETTINGS_TEAM_ROUTE,
	SETTINGS_TRANSACTIONS_ROUTE,
} from 'constants/routes';
import useSession from 'hooks/useSession';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import TabItem from 'styleguide/Tabs/TabItem/TabItem';
import TabsList from 'styleguide/Tabs/TabsList/TabsList';
import AccountsSection from './sections/Accounts/Accounts.section';
import BusinessSection from './sections/Business/Business.section';
import DocumentsSection from './sections/Documents/Documents.section';
import PaymentsSection from './sections/Payments/Payments.section';
import TeamSection from './sections/Team/Team.section';
import style from './SettingsPage.module.scss';

export default function SettingsRootPage() {
	const [isBusinessFFEnabled] = useFeature('SETTINGS_BUSINESS');
	const { companyName } = useSession();

	return (
		<React.Fragment>
			<PaddingContainer>
				<Company name={companyName} isTitle />
				<div className={style.TabsNav}>
					<TabsList>
						<TabItem name="Accounts" to={SETTINGS_ACCOUNTS_ROUTE} />
						{isBusinessFFEnabled ? <TabItem name="Business" to={SETTINGS_BUSINESS_ROUTE} /> : null}
						<TabItem name="Transactions" to={SETTINGS_TRANSACTIONS_ROUTE} />
						<TabItem name="Documents" to={SETTINGS_DOCUMENTS_ROUTE} />
						<TabItem name="Members" to={SETTINGS_TEAM_ROUTE} />
					</TabsList>
				</div>
			</PaddingContainer>
			<Routes>
				<Route index element={<Navigate replace to={SETTINGS_ACCOUNTS_ROUTE} />} />
				<Route path="accounts" element={<AccountsSection />} />
				<Route path="business" element={<BusinessSection />} />
				<Route path="documents" element={<DocumentsSection />} />
				<Route path="transactions" element={<PaymentsSection />} />
				<Route path="team" element={<TeamSection />} />
			</Routes>
		</React.Fragment>
	);
}
