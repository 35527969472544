import React from 'react';
import View from 'styleguide/View/View';
import IDeveloperAccount from 'types/IDeveloperAccount';
import style from '../TeamsList.module.scss';
import TeamsListItem from './TeamsListItem/TeamsListItem';

export interface TeamsListSuccessProps {
	switchTeam: (teamId: string) => void;
	teams: IDeveloperAccount[];
}

export default function TeamsListSuccess(props: TeamsListSuccessProps) {
	return props.teams.length ? (
		<View className={style.TeamsList}>
			{props.teams.map((team: IDeveloperAccount) => (
				<TeamsListItem key={team.developerAccountId} team={team} switchTeam={props.switchTeam} />
			))}
		</View>
	) : null;
}
