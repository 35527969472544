import WithQuery from 'utils/WithQuery';
import CardsPageFailed from './components/CardsPageFailed';
import CardsPageLoading from './components/CardsPageLoading';
import CardsPageSuccess from './components/CardsPageSuccess/CardsPageSuccess';
import useCards from './useCards';

export default WithQuery({
	Component: CardsPageSuccess,
	Error: CardsPageFailed,
	Loader: CardsPageLoading,
	useHook: useCards,
});
