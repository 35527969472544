import apiclientService from 'api-client/apiclient.service';
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import useSession from 'hooks/useSession';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import pendoService from 'services/pendo.service';
import sentryService from 'services/sentry.service';
import sessionService from 'services/session.service';
import validationService from 'services/validationService/validation.service';
import AptoServerError from 'utils/AptoServerError';
import useForm from 'utils/useForm';

export default function useTwoFactorAuth() {
	const { initSession } = useSession();
	const navigate = useNavigate();
	const params = useQueryParams();
	const attemptID = params.get('attemptID') as string; // TODO: Fail if no attemptID

	const REDIRECT_TIME_MS = 2000;

	const [serverError, setServerError] = useState('');

	const { isSubmitting, isButtonEnabled, bind, submitForm, setFieldValue } = useForm({
		initialValues: { code: '' },
		onSubmit: function (values) {
			setServerError('');
			return apiclientService.auth
				.verify2FACode(attemptID, values.code)
				.then((res) => {
					initSession(res);
					sentryService.identifyUser({
						email: res.session.userData.email,
						id: res.session.userId,
						username: `${res.session.userData.firstName} ${res.session.userData.lastName}`,
					});
					pendoService.identifyUser({
						email: res.session.userData.email,
						companyName: res.developerAccount.companyName,
						environment: sessionService.getEnvironment(),
					});
					navigate(DASHBOARD_ROUTE);
				})
				.catch((error: AptoServerError) => {
					// TODO: Use i18n and logic based on error codes
					if (error.code === 91201) {
						setFieldValue('code', '');
						return setServerError('Incorrect code. Please try again.');
					}
					if (error.code === 91202) {
						setTimeout(() => {
							navigate(LOGIN_ROUTE);
						}, REDIRECT_TIME_MS);
						return setServerError('Too many attempts. Please restart your log in.');
					}
					return setServerError(error.message);
				});
		},
		validate: validationService.createValidator({
			code: [validationService.required],
		}),
	});

	return {
		bind,
		serverError,
		isSubmitting,
		isButtonDisabled: !isButtonEnabled,
		isNotificationHidden: serverError === '',
		submitForm,
	};
}
