import Notification from 'components/Notification/Notification';
import React from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ResendSMS from './components/ResendSMS/ResendSMS';
import useVerifyPhoneNumberStepWithApplicationId from './useVerifyPhoneNumberStepWithApplicationId';
import style from './VerifyPhoneNumberStepWithApplicationId.module.scss';

export default function VerifyPhoneNumberStepWithApplicationId() {
	const { serverError, phoneNumber, resendSMS, hasSMSBeenResent, submitForm, isLoading, bind, isButtonDisabled } =
		useVerifyPhoneNumberStepWithApplicationId();

	return (
		<View className={style.VerifyPhoneNumber}>
			<form onSubmit={submitForm} data-testid="VerifyPhoneNumberStep" className={style.Card}>
				<View className={style.Card__body}>
					<Notification
						isHidden={!serverError}
						type="negative"
						title="Error"
						description={serverError}
						margin="26px 0"
					/>
					<Heading size="small" tag="h1" margin="26px 0 12px">
						What's the code?
					</Heading>
					{phoneNumber ? (
						<Paragraph size="small" margin="12px 0 24px">
							Enter the code sent to {phoneNumber}
						</Paragraph>
					) : null}
					<Input type="text" label="Code" {...bind('code')} />
				</View>
				<View className={style.Card__footer}>
					<View>
						<Paragraph size="small" color="contentTertiary">
							Problems receiving the code?
						</Paragraph>
						<ResendSMS onButtonClick={resendSMS} hasSMSBeenResent={hasSMSBeenResent} />
					</View>
					<Pill
						type="submit"
						level="primary"
						size="medium"
						loading={isLoading}
						disabled={isButtonDisabled}
						data-testid="btn-verify"
					>
						Verify
					</Pill>
				</View>
			</form>
		</View>
	);
}
