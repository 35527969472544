function getNumberOfViews() {
	const numberOfViews = Number(localStorage.getItem('tutorialViews'));
	return numberOfViews && !isNaN(numberOfViews) ? numberOfViews : 0;
}

function incrementNumberOfViews() {
	const numberOfViews = getNumberOfViews();
	const newViewTotal = (numberOfViews + 1).toString();
	return localStorage.setItem('tutorialViews', newViewTotal);
}

export default {
	getNumberOfViews,
	incrementNumberOfViews,
};
