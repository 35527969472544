import ContentHeader from 'components/ContentHeader/ContentHeader';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';

interface ITransactionsHeaderProps {
	total: number;
	onAddClick?: () => void;
}

export default function TransactionsHeader(props: ITransactionsHeaderProps) {
	return (
		<ContentHeader title="Transactions" total={props.total}>
			{props.onAddClick ? (
				<Pill level="primary" size="small" onClick={props.onAddClick}>
					Add
				</Pill>
			) : null}
		</ContentHeader>
	);
}
