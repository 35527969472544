import AptoCard from 'components/AptoCard/AptoCard';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import View from 'styleguide/View/View';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import style from './AptoCardSection.module.scss';

interface IAptoCardSectionProps {
	card: IAptoCardSummaryResponse['card'];
	user: IAptoCardSummaryResponse['user'];
	isProduction: boolean;
	onLoadFundsClicked: (id: string) => void;
}

export default function AptoCardSection(props: IAptoCardSectionProps) {
	return (
		<View className={style.AptoCardSection}>
			<View className={style['AptoCardSection__container--first']}>
				<Pill
					type="button"
					level="primary"
					size="small"
					onClick={props.onLoadFundsClicked}
					disabled={props.card.status !== 'ACTIVATED'}
				>
					Load funds
				</Pill>
			</View>
			<View className={style['AptoCardSection__container--second']}>
				<View className={style.AptoCardSection__card}>
					<AptoCard
						side="front"
						logo={props.card.logoUrl}
						designKey={props.card.designKey}
						firstName={props.user.name.firstName}
						lastName={props.user.name.lastName}
						lastFour={props.card.lastFour}
						network={props.isProduction ? props.card.network : undefined}
					/>
				</View>
			</View>
		</View>
	);
}
