import Notification from 'components/Notification/Notification';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalDeleteSignedPayloadsKey.module.scss';
import useModalDeleteSignedPayloadsKey from './useModalDeleteSignedPayloadsKey';

export interface IModalDeleteSignedPayloadsKeyProps {
	id: string;
	onCancelClick: () => void;
	onSuccessfulDelete: () => void;
}

export default function ModalDeleteSignedPayloadsKey(props: IModalDeleteSignedPayloadsKeyProps) {
	const { handleDelete, isLoading, serverError } = useModalDeleteSignedPayloadsKey({
		id: props.id,
		onSuccessfulDelete: props.onSuccessfulDelete,
	});
	return (
		<View className={style.ModalDeleteSignedPayloadsKey}>
			<Notification isHidden={!serverError} type="negative" margin="0 0 20px" title="Error" description={serverError} />
			<Heading size="small" tag="h1" margin="0 0 12px 0">
				Delete Signed Payloads Key?
			</Heading>
			<Paragraph size="small" color="contentPrimary" className={style.ModalDeleteSignedPayloadsKey__body}>
				Are you sure you want to delete your Signed Payloads Key?
			</Paragraph>
			<View className={style.ModalDeleteSignedPayloadsKey__footer}>
				<Pill onClick={props.onCancelClick} size="medium" level="secondary">
					Cancel
				</Pill>
				<Pill
					level="primary"
					size="medium"
					className={style.ModalDeleteSignedPayloadsKey__cta}
					loading={isLoading}
					onClick={handleDelete}
					data-testid="btn-delete"
				>
					Delete Key
				</Pill>
			</View>
		</View>
	);
}
