import React from 'react';
import View from 'styleguide/View/View';
import style from './ChevronPagination.module.scss';

export interface IChevronPaginationProps {
  /**
   * This renders the pagination as if it was placed in
   * a full width bar, aligned to the right
   */
  isInBlock?: boolean;
  isNextVisible: boolean;
  isPrevVisible: boolean;
  onNextClicked: () => void;
  onPrevClicked: () => void;
}

export default function ChevronPagination(props: IChevronPaginationProps) {
  return (
    <View className={props.isInBlock ? style['ChevronPagination--block'] : style.ChevronPagination}>
      <button
        disabled={!props.isPrevVisible}
        onClick={props.onPrevClicked}
        title="Previous"
        className={style['ChevronPagination__button--prev']}
      ></button>
      <button
        disabled={!props.isNextVisible}
        onClick={props.onNextClicked}
        title="Next"
        className={style['ChevronPagination__button--next']}
      ></button>
    </View>
  );
}
