import Initials from 'components/Initials/Initials';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import ICardholder from 'types/cardholders/ICardholder';
import style from './HeaderSection.module.scss';

interface IHeaderSectionProps {
	isCardholder: boolean;
	isLoadFundsDisabled: boolean;
	isProduction: boolean;
	isDeleteLoading: boolean;
	onDeleteClicked: (id: string) => void;
	onLoadFundsClicked: (id: string) => void;
	user: ICardholder;
}

export default function HeaderSection(props: IHeaderSectionProps) {
	return (
		<article className={style.Header}>
			<View className={style.Header__left}>
				<Initials className={style.Header__initials} firstName={props.user.firstName} lastName={props.user.lastName} />
				<View>
					<Heading size="x-small" tag="h1" margin="0 0 4px">
						{props.user.firstName} {props.user.lastName}
					</Heading>
					<Label size="small">{props.user.email}</Label>
				</View>
			</View>

			<View className={style.Header__right}>
				<Pill
					type="button"
					level="primary"
					size="small"
					className={style['Header__loadFunds']}
					onClick={props.onLoadFundsClicked}
					disabled={props.isLoadFundsDisabled || props.isDeleteLoading}
				>
					Load funds
				</Pill>
				{!props.isProduction && props.isCardholder ? (
					<Pill
						type="button"
						level="secondary"
						size="small"
						onClick={() => props.onDeleteClicked(props.user.id)}
						loading={props.isDeleteLoading}
						data-testid="btn-delete"
					>
						Delete
					</Pill>
				) : null}
			</View>
		</article>
	);
}
