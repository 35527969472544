import Notification from 'components/Notification/Notification';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from '../ModalAccountActivation.module.scss';

interface IModalResumeAccountActivation {
	handleResume: () => void;
	handleRestart: () => void;
	isLoading: boolean;
	serverError: string;
}

export default function ModalResumeAccountActivation(props: IModalResumeAccountActivation) {
	return (
		<View className={style.ModalAccountActivation}>
			<Notification
				isHidden={!props.serverError}
				type="negative"
				margin="0 0 20px"
				title="Error"
				description={props.serverError}
			/>
			<Heading size="small" tag="h1" margin=" 0 0 12px">
				Resume production application
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin=" 0 0 24px">
				Click resume to start where you left off! Note: if you made a mistake or want to modify information, you should
				start over.
			</Paragraph>
			<View className={style.ModalAccountActivation__footer}>
				<Pill
					level="secondary"
					size="medium"
					loading={props.isLoading}
					onClick={props.handleRestart}
					data-testid="restart-application-button"
				>
					Start over
				</Pill>
				<Pill
					level="primary"
					size="medium"
					className={style.ModalAccountActivation__cta}
					onClick={props.handleResume}
					data-testid="resume-application-button"
				>
					Resume
				</Pill>
			</View>
		</View>
	);
}
