import IPaymentMethod from 'types/IPaymentMethod';

export default function usePaymentMethodsSectionSuccess() {
	function checkRemoveEnabled(paymentSource: IPaymentMethod) {
		return !(paymentSource.preferenceTypes.includes('funding') || paymentSource.preferenceTypes.includes('billing'));
	}

	return {
		checkRemoveEnabled,
	};
}
