import ContentHeader from 'components/ContentHeader/ContentHeader';
import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import View from 'styleguide/View/View';

interface ITransactionsPageFailedProps {
	serverError: string;
}

export default function TransactionsPageFailed(props: ITransactionsPageFailedProps) {
	return (
		<View data-testid="page-transactions">
			<ContentHeader title="Transactions" />
			<PaddingContainer>
				<Notification type="negative" margin="2rem 0" title="Error" description={props.serverError} />
			</PaddingContainer>
		</View>
	);
}
