import classnames from 'classnames';
import { PUSH_FUNDS_CONFIRM_ROUTE, PUSH_FUNDS_LOAD_ROUTE, PUSH_FUNDS_REVIEW_ROUTE } from 'constants/routes';
import React from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import Label from 'styleguide/Type/Label/Label';
import style from './PushFundsSidebar.module.scss';

export default function PushFundsSidebar() {
	const location = useLocation();
	const isLoadRouteMatch = useMatch(PUSH_FUNDS_LOAD_ROUTE);
	const isReviewRouteMatch = useMatch(PUSH_FUNDS_REVIEW_ROUTE);

	// Last step has no sidebar
	if (location.pathname === PUSH_FUNDS_CONFIRM_ROUTE) {
		return null;
	}

	return (
		<nav className={style.PushFundsSidebar}>
			<Label
				size="medium"
				className={classnames(style.PushFundsSidebar__link, {
					[`${style['PushFundsSidebar__link--isActive']}`]: isLoadRouteMatch,
				})}
			>
				Load user's card
			</Label>
			<Label
				size="medium"
				className={classnames(style.PushFundsSidebar__link, {
					[`${style['PushFundsSidebar__link--isActive']}`]: isReviewRouteMatch,
				})}
			>
				Review
			</Label>
		</nav>
	);
}
