import Address, { IAddress, IAddressProps } from 'components/Address/Address';
import Tooltip from 'components/Tooltip/Tooltip';
import Item from 'styleguide/Item/Item';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import addIcon from './images/add.svg';
import infoIcon from './images/info.svg';
import removeIcon from './images/remove.svg';
import style from './RegistrationAddressSection.module.scss';

interface IRegistrationAddressSectionProps<T extends Record<keyof IAddress, T[keyof T]>> extends IAddressProps<T> {
	onAddRegistrationAddressClick: () => void;
	onRemoveRegistrationAddressClick: () => void;
	isVisible: boolean;
}

export default function RegistrationAddressSection<T extends Record<keyof IAddress, T[keyof T]>>(
	props: IRegistrationAddressSectionProps<T>
) {
	return (
		<>
			{props.isVisible ? (
				<>
					<Item padding="10px 0" hasBorderBottom>
						<button
							type="button"
							aria-expanded="true"
							aria-controls="registration-address"
							onClick={props.onRemoveRegistrationAddressClick}
							className={style.RegistrationAddressSection__button}
						>
							<img src={removeIcon} alt="" />
							<Paragraph size="medium" color="contentPrimary" margin="0 4px 0 20px">
								Remove registration address
							</Paragraph>
							<Tooltip message="A registration address acts as the official address and point of contact for your company. If you use a registered agent for your company, they will provide you with a registration address.">
								<img src={infoIcon} alt="" />
							</Tooltip>
						</button>
					</Item>
					<Paragraph size="medium" margin="24px 0">
						Enter the registration address of your company.
					</Paragraph>
					<View id="registration-address" data-testid="registration-address">
						<Address bind={props.bind} ids={props.ids} labels={props.labels} />
					</View>
				</>
			) : (
				<>
					<Item padding="10px 0" hasBorderBottom>
						<button
							type="button"
							aria-expanded="false"
							aria-controls="registration-address"
							onClick={props.onAddRegistrationAddressClick}
							className={style.RegistrationAddressSection__button}
						>
							<img src={addIcon} alt="" />
							<Paragraph size="medium" color="contentPrimary" margin="0 4px 0 20px">
								Add registration address
							</Paragraph>
							<Tooltip message="A registration address acts as the official address and point of contact for your company. If you use a registered agent for your company, they will provide you with a registration address.">
								<img src={infoIcon} alt="" />
							</Tooltip>
						</button>
					</Item>
					<View id="registration-address" data-testid="registration-address"></View>
				</>
			)}
		</>
	);
}
