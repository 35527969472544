import { CARDS_ROUTE, PUSH_FUNDS_LOAD_ROUTE, TRANSACTIONS_ROUTE, USERS_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useRemoveUserMutation } from 'queries/users.queries';
import { useNavigate } from 'react-router';
import IAptoCard from 'types/aptoCards/IAptoCard';

interface IUseUserPageSuccessArgs {
	cards: IAptoCard[];
	id: string;
}

export default function useUserPageSuccess(args: IUseUserPageSuccessArgs) {
	const navigate = useNavigate();
	const removeUserMutation = useRemoveUserMutation();
	const { isProduction } = useSession();

	function goToLoadFunds() {
		navigate(PUSH_FUNDS_LOAD_ROUTE.replace(':cardholderId', args.id));
	}

	function onTransactionClicked(transactionId: string) {
		navigate(`${TRANSACTIONS_ROUTE}/${transactionId}`);
	}

	function onCardClicked(cardId: string) {
		navigate(`${CARDS_ROUTE}/${cardId}`);
	}

	async function onDeleteClicked() {
		return removeUserMutation.mutate(args.id, {
			onSuccess: () => {
				// TODO: We should keep the search params in case we delete a user from the second page and onwards
				// What happens if the user deletes a user that starts/ends the pagination page?
				// What happens if the user goes to the user details directly via an URL?
				navigate(USERS_ROUTE);
			},
		});
	}

	return {
		goToLoadFunds,
		onCardClicked,
		onDeleteClicked,
		onTransactionClicked,
		hasActivatedCards: args.cards.some((card) => card.status === 'ACTIVATED'),
		isDeleting: removeUserMutation.isLoading,
		isProduction,
		serverError: removeUserMutation.error?.message || '',
	};
}
