import IAptoCard from 'types/aptoCards/IAptoCard';

function parseSummaryCards({ cards }: { cards: any[] }): IAptoCard[] {
	return cards.map(parseSummaryCard);
}

function parseSummaryCard({ card }: { card: any }): IAptoCard {
	return {
		designKey: card.design_key,
		id: card.id,
		lastFour: card.last_four,
		nameOnCard: card.name_on_card,
		network: card.network,
		status: card.status,
	};
}

export default {
	parseSummaryCard,
	parseSummaryCards,
};
