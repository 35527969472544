import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalRequestSent.module.scss';

export interface IModalRequestSentProps {
	onDoneClick: () => void;
}

export default function ModalRequestSent(props: IModalRequestSentProps) {
	return (
		<View className={style.ModalRequestSent}>
			<Heading size="small" color="contentPrimary" tag="h1" margin="0 0 12px 0">
				Request sent
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin="0 0 24px 0">
				Thank you. A member of our team will contact you within 3 days to begin the account closure process.
			</Paragraph>

			<View className={style.ModalRequestSent__footer}>
				<Pill level="primary" size="medium" onClick={props.onDoneClick} data-testid="modal-deactivate-btn">
					Done
				</Pill>
			</View>
		</View>
	);
}
