import DateFormatter from 'components/formatters/DateFormatter/DateFormatter';
import React, { PropsWithChildren, useState } from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import IWebhook from 'types/IWebhook';
import style from './Webhook.module.scss';

export interface IWebhookProps {
	webhook: IWebhook;
	isDeleting: boolean;
	onRemoveClick: (id: string) => void;
	onEditClick: (id: string) => void;
}

export default function Webhook(props: PropsWithChildren<IWebhookProps>) {
	const [expanded, setExpanded] = useState(false);

	if (expanded) {
		return (
			<View className={style['Webhook--isExpanded']}>
				<button className={style.Webhook__header} onClick={() => setExpanded(false)}>
					<View>
						<Text weight={400} type="small" color="black">
							{props.webhook.url}
						</Text>
						<Text margin="6px 0 0 0" type="small" color="muted">
							<DateFormatter date={props.webhook.createdAt} />
						</Text>
					</View>
					<View>
						<Text type="small" color="black" weight={600}>
							{props.webhook.isActive ? 'On' : 'Off'}
						</Text>
					</View>
				</button>

				<View className={style.Webhook__body}>
					<Grid cols="12" isNested>
						<Item colWidth="6" colWidthLg="3">
							<Text weight={600} type="small" color="black">
								Endpoint URL
							</Text>
							<Text margin="6px 0" type="small">
								<span className={style.Webhook__url}>{props.webhook.url}</span>
							</Text>

							<Text margin="16px 0" type="small">
								{props.webhook.description}
							</Text>
						</Item>

						<Item colWidth="6" colWidthLg="3">
							<Text weight={600} type="small" color="black">
								Events
							</Text>
							{props.webhook.eventTypes.map((eventType) => {
								return (
									<Text key={eventType} margin="6px 0" type="small">
										{eventType}
									</Text>
								);
							})}
						</Item>

						<Item colWidth="6" colWidthLg="3">
							<Text weight={600} type="small" color="black">
								Last updated
							</Text>
							<Text margin="6px 0 16px 0" type="small">
								<DateFormatter date={props.webhook.updatedAt} />
							</Text>

							<Text weight={600} type="small" color="black">
								Created
							</Text>
							<Text margin="6px 0" type="small">
								<DateFormatter date={props.webhook.createdAt} />
							</Text>
						</Item>

						<Item colWidth="6" colWidthLg="3">
							<Text weight={600} type="small" color="black">
								Status
							</Text>
							<Text margin="6px 0" type="small">
								{props.webhook.isActive ? 'Enabled' : 'Disabled'}
							</Text>
						</Item>
					</Grid>
				</View>

				<View className={style.Webhook__footer}>
					<Pill
						level="secondary"
						size="medium"
						onClick={() => props.onRemoveClick(props.webhook.id)}
						loading={props.isDeleting}
					>
						<Text color="negative" type="small">
							Delete
						</Text>
					</Pill>
					{/* <Button onClick={() => onEditClick(webhook.id)} size="small"> Edit </Button> */}
				</View>
			</View>
		);
	}

	return (
		<View className={style.Webhook}>
			<button className={style.Webhook__header} onClick={() => setExpanded(!expanded)}>
				<View>
					<Text weight={400} type="small" color="black">
						{props.webhook.url}
					</Text>
					<Text margin="6px 0 0 0" type="small" color="muted">
						<DateFormatter date={props.webhook.createdAt} />
					</Text>
				</View>
				<View>
					<Text type="small" color="black" weight={600}>
						{props.webhook.isActive ? 'On' : 'Off'}
					</Text>
				</View>
			</button>
		</View>
	);
}
