import { ICurrency } from 'constants/currencies';
import IAptoCard from 'types/aptoCards/IAptoCard';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import WithQuery from 'utils/WithQuery';
import LoadStepSuccess from './components/LoadStepSuccess/LoadStepSuccess';
import useLoadStep from './useLoadStep';

export interface IHandleNextClickArgs {
	amount: string;
	cardId: string;
	currency: ICurrency;
	designKey: IAptoCardDesignKey;
	lastFour: string;
	network: IAptoCardNetwork;
}

export interface ILoadStepProps {
	amount?: string;
	cardholderId: string;
	cardId?: string;
	currency?: ICurrency;
	handleNextClick: (args: IHandleNextClickArgs) => void;
	isAptoCardSelectDisabled: boolean;
}

export interface IHookReturnedProps extends ILoadStepProps {
	cards: IAptoCard[];
}

export default WithQuery<ILoadStepProps, IHookReturnedProps>({
	Component: LoadStepSuccess,
	useHook: useLoadStep,
});
