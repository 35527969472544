import WithQuery from 'utils/WithQuery';
import ModalAddApiKeysLoading from './components/ModalAddApiKeysLoading';
import ModalApiKeysSuccess from './components/ModalApiKeysSuccess';
import useModalAddApiKeys from './useModalAddApiKeys';

export default WithQuery({
	Component: ModalApiKeysSuccess,
	Loader: ModalAddApiKeysLoading,
	useHook: useModalAddApiKeys,
	shouldHandleErrorsOnSuccess: true,
	shouldHandleIdleOnSuccess: true,
});
