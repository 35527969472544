import Notification from 'components/Notification/Notification';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalDeleteApiKey.module.scss';
import useModalDeleteApiKey from './useModalDeleteApiKey';

export interface IModalDeleteApiKeyProps {
	apiKeyId: string;
	onCancelClick: () => void;
	onApiKeyRemoved: () => void;
}

export default function ModalDeleteApiKey(props: IModalDeleteApiKeyProps) {
	const { handleRemoveApiKey, isLoading, serverError } = useModalDeleteApiKey(props);
	return (
		<View className={style.ModalDeleteApiKey}>
			<Notification isHidden={!serverError} type="negative" margin="0 0 20px" title="Error" description={serverError} />
			<form>
				<Heading size="small" tag="h1" margin="0 0 12px 0">
					Delete Core API Key
				</Heading>
				<Paragraph size="small" color="contentPrimary" className={style.ModalDeleteApiKey__body}>
					Are you sure you want to remove this Core API Key?
				</Paragraph>
				<View className={style.ModalDeleteApiKey__footer}>
					<Pill onClick={props.onCancelClick} size="medium" level="secondary">
						Cancel
					</Pill>
					<Pill
						level="secondary"
						size="medium"
						className={style.ModalDeleteApiKey__cta}
						loading={isLoading}
						onClick={handleRemoveApiKey}
						data-testid="btn-delete"
					>
						Delete
					</Pill>
				</View>
			</form>
		</View>
	);
}
