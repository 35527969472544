import { useListAgreementsQuery } from 'queries/agreements';
import IGetAgreementsResponse from 'types/agreements/IAgreements';

export interface IUseDocumentsSectionReturnedProps {
	agreements: IGetAgreementsResponse;
	errorMessage: string;
}

export default function useDocumentsSection() {
	const queryListAgreements = useListAgreementsQuery();

	return {
		props: {
			agreements: queryListAgreements.data as IGetAgreementsResponse,
			errorMessage: queryListAgreements.error?.message || '',
		},
		status: queryListAgreements.status,
	};
}
