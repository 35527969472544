import DescriptionItem from 'components/DescriptionItem/DescriptionItem';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import Section from '../Section/Section';
import SectionTitle from '../SectionTitle/SectionTitle';
import Address from './components/Address/Address';

interface IDetailsSectionProps {
	card: IAptoCardSummaryResponse['card'];
}

export default function DetailsSection(props: IDetailsSectionProps) {
	return (
		<Section>
			<SectionTitle title="Details" borderTop />
			<DescriptionItem title="Created Date">
				<Paragraph size="small">
					{props.card.createdAt
						? dateTimeFormatService.format(props.card.createdAt, { isTimeAgo: true })
						: 'Not created'}
				</Paragraph>
			</DescriptionItem>
			<DescriptionItem title="Activated Date">
				<Paragraph size="small">
					{props.card.activatedAt
						? dateTimeFormatService.format(props.card.activatedAt, { isTimeAgo: true })
						: 'Not active'}
				</Paragraph>
			</DescriptionItem>
			<DescriptionItem title="Shipped Date">
				<Paragraph size="small">
					{props.card.shippedAt
						? dateTimeFormatService.format(props.card.shippedAt, { isTimeAgo: true })
						: 'Not shipped'}
				</Paragraph>
			</DescriptionItem>
			<DescriptionItem title="Last Activity">
				<Paragraph size="small">
					{props.card.lastActivityAt
						? dateTimeFormatService.format(props.card.lastActivityAt, { isTimeAgo: true })
						: 'Not used'}
				</Paragraph>
			</DescriptionItem>
			<DescriptionItem title="Billing Address">
				<Address
					country={props.card.billingAddress.country}
					locality={props.card.billingAddress.locality}
					postalCode={props.card.billingAddress.postalCode}
					region={props.card.billingAddress.region}
					streetOne={props.card.billingAddress.streetOne}
					streetTwo={props.card.billingAddress.streetTwo}
				/>
			</DescriptionItem>
		</Section>
	);
}
