import LastFour from 'components/cards/LastFour/LastFour';
import { ICurrency } from 'constants/currencies';
import React from 'react';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import style from './ConfirmationStep.module.scss';

export type IPushFundsStatus = 'processed' | 'failed';
export interface IConfirmationStepProps {
	amount: string;
	createdAt?: Date;
	currency: ICurrency;
	lastFour: string;
	network: IAptoCardNetwork;
	onDoneClick: () => void;
	status: IPushFundsStatus;
	designKey: IAptoCardDesignKey;
}

export default function ConfirmationStep(props: IConfirmationStepProps) {
	return (
		<View className={style.ConfirmationStep} data-testid="confirmation-step">
			<View className={style.Card}>
				<Label size="medium" fontWeight="600" className={style.Card__title}>
					{_getComfirmationTitle(props)}
				</Label>

				<View className={style.Card__row}>
					<Label size="small" color="contentPrimary">
						Status
					</Label>
					<Label size="small" color="contentPrimary">
						{props.status === 'processed' ? 'Completed' : props.status}
					</Label>
				</View>
				{props.createdAt ? (
					<View className={style.Card__row}>
						<Label size="small" color="contentPrimary">
							Time
						</Label>
						<Label size="small" color="contentPrimary">
							{dateTimeFormatService.format(props.createdAt, { includeHourAndMinute: true })}
						</Label>
					</View>
				) : null}
				<View className={style.Card__row}>
					<Paragraph size="small" color="contentPrimary">
						To
					</Paragraph>
					<LastFour
						designKey={props.designKey}
						value={props.lastFour}
						network={props.network}
						iconSize="small"
						typeOfCard="apto"
					/>
				</View>
				<Pill level="primary" size="medium" onClick={props.onDoneClick} className={style.Card__cta}>
					Done
				</Pill>
			</View>
		</View>
	);
}

function _getComfirmationTitle(props: IConfirmationStepProps) {
	switch (props.status) {
		case 'failed':
			return `Your $${props.amount} load failed`;
		case 'processed':
		default:
			return `You loaded $${props.amount}`;
	}
}
