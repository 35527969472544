import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import { PropsWithChildren } from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import View from 'styleguide/View/View';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import style from './CardDetails.module.scss';
import AptoCardSection from './components/AptoCardSection/AptoCard.section';
import DetailsSection from './components/DetailsSection/Details.section';
import OverviewSection from './components/OverviewSection/Overview.section';
import TransactionsSection from './components/TransactionsSection/Transactions.section';
import useCardDetailsSuccess from './useCardDetailsSuccess';

type ICardDetailsSuccessProps = IAptoCardSummaryResponse;

export default function CardDetailsSuccess(props: PropsWithChildren<ICardDetailsSuccessProps>) {
	const { isProduction, onLoadFundsClicked, onTransactionClicked } = useCardDetailsSuccess({
		cardId: props.card.id,
		userId: props.user.id,
	});

	return (
		<View data-testid="page-card">
			<PaddingContainer>
				<Grid cols="12" colsLg="12" isNested className={style.CardDetails__grid}>
					<Item colWidth="12" colWidthLg="6" className={style[`CardDetails__gridItem--first`]}>
						<AptoCardSection
							card={props.card}
							user={props.user}
							isProduction={isProduction}
							onLoadFundsClicked={onLoadFundsClicked}
						/>
						<OverviewSection card={props.card} />
						<DetailsSection card={props.card} />
					</Item>
					<Item colWidth="12" colWidthLg="6" className={style[`CardDetails__gridItem--second`]}>
						<TransactionsSection transactions={props.transactions} onTransactionClicked={onTransactionClicked} />
					</Item>
				</Grid>
			</PaddingContainer>
		</View>
	);
}
