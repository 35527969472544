import apiFetch from 'api-client/utils/fetch';
import getVaultBaseUrl from 'api-client/utils/getVaultBaseUrl';
import creditCardService from 'services/creditCard.service';
import ICreditCard from 'types/financial/ICreditCard';
import IPaymentMethod, { IPaymentMethodPreferenceType } from 'types/IPaymentMethod';
import IEnvironment from 'types/session/IEnvironment';
import paymentSourcesParser from './paymentSources.parser';

const PAYMENT_SOURCES_ENDPOINT = '/developer/settings/payment_sources';

interface IReadPaymentSourcesArgs {
	environment: IEnvironment;
}

export interface IReadPaymentSourcesResponse {
	paymentSources: IPaymentMethod[];
	hasMore: boolean;
	total: number;
}

function read(args: IReadPaymentSourcesArgs): Promise<IReadPaymentSourcesResponse> {
	const requestBody = undefined;
	return apiFetch(`${PAYMENT_SOURCES_ENDPOINT}?environment=${args.environment}`, 'GET', requestBody, {
		baseURL: getVaultBaseUrl(),
	}).then(paymentSourcesParser.parsePaymentSources);
}

interface IGetPaymentSourceArgs {
	id: string;
	environment: IEnvironment;
}

function get(args: IGetPaymentSourceArgs): Promise<IPaymentMethod> {
	const requestBody = undefined;
	return apiFetch(`${PAYMENT_SOURCES_ENDPOINT}/${args.id}?environment=${args.environment}`, 'GET', requestBody, {
		baseURL: getVaultBaseUrl(),
	}).then(paymentSourcesParser.parsePaymentSource);
}

export interface IEditPaymentSourceArgs {
	description: string;
	environment: IEnvironment;
	id: string;
	preferenceTypes: IPaymentMethodPreferenceType[];
}

export type IEditPaymentSourceResponse = IPaymentMethod;

function put({
	description,
	environment,
	id,
	preferenceTypes,
}: IEditPaymentSourceArgs): Promise<IEditPaymentSourceResponse> {
	const requestBody = { preference_types: preferenceTypes, description };
	return apiFetch(`${PAYMENT_SOURCES_ENDPOINT}/${id}?environment=${environment}`, 'PUT', requestBody, {
		baseURL: getVaultBaseUrl(),
	}).then(paymentSourcesParser.parsePaymentSource);
}

export interface IAddPaymentSourceArgs {
	card: ICreditCard;
	description: string;
	environment: IEnvironment;
}

function addCard(args: IAddPaymentSourceArgs): Promise<IPaymentMethod> {
	const { card, description } = args;
	return apiFetch(
		`${PAYMENT_SOURCES_ENDPOINT}?environment=${args.environment}`,
		'POST',
		{
			type: 'card',
			description: description,
			card: {
				pan: card.pan,
				cvv: card.cvv,
				exp_date: creditCardService.formatExpirationDate(card.exp),
				last_four: _getLastFour(card.pan),
				postal_code: card.postalCode,
			},
		},
		{
			baseURL: getVaultBaseUrl(),
		}
	).then(paymentSourcesParser.parsePaymentSource);
}

export interface IRemovePaymentSourceArgs {
	id: string;
	environment: IEnvironment;
}

function remove(args: IRemovePaymentSourceArgs) {
	const requestBody = undefined;
	return apiFetch(`${PAYMENT_SOURCES_ENDPOINT}/${args.id}?environment=${args.environment}`, 'DELETE', requestBody, {
		baseURL: getVaultBaseUrl(),
	});
}

function _getLastFour(pan: string) {
	return pan.slice(-4);
}

export default {
	addCard,
	get,
	put,
	read,
	remove,
};
