import useSession from 'hooks/useSession';
import { QK_TEAM_MEMBERS } from 'queries/teamMember';
import { useQueryClient } from 'react-query';
import Modal from 'styleguide/Modal/Modal';
import ModalDeleteMember from './components/ModalDeleteMember/ModalDeleteMember';

export default function useAcceptedInvitesSuccess() {
	const { userId } = useSession();
	const queryClient = useQueryClient();

	function handleSuccessfulRemove() {
		queryClient.refetchQueries(QK_TEAM_MEMBERS);
		Modal.hide();
	}

	function showModalDeleteMember(memberId: string) {
		Modal.show(
			<ModalDeleteMember onCancelClick={Modal.hide} onSuccessfulRemove={handleSuccessfulRemove} memberId={memberId} />,
			{
				canBeClosedOnClickOut: false,
				ariaTitle: 'Remove team member',
			}
		);
	}

	function isRemoveButtonDisabled(memberId: string): boolean {
		return userId === memberId;
	}

	return {
		showModalDeleteMember,
		isRemoveButtonDisabled,
	};
}
