import WithQuery from 'utils/WithQuery';
import TransactionsHistoryError from './components/TransactionsHistoryError';
import TransactionsHistoryLoading from './components/TransactionsHistoryLoading';
import TransactionsHistorySuccess from './components/TransactionsHistorySuccess';
import useTransactionsHistory from './useTransactionsHistory';

export default WithQuery({
	useHook: useTransactionsHistory,
	Component: TransactionsHistorySuccess,
	Error: TransactionsHistoryError,
	Loader: TransactionsHistoryLoading,
});
