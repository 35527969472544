import apiclientService from 'api-client/apiclient.service';
import { SYNC_2FA_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import IError from 'types/network/IError';
import AptoServerError from 'utils/AptoServerError';

export default function useConfirmEmail() {
	const params = useQueryParams();
	const code = params.get('code');
	const attemptID = params.get('attempt_id');
	const navigate = useNavigate();

	const [state, setState] = useState({
		isLoading: true,
		hasError: false,
		isConfirmed: false,
		emoji: '',
		title: '',
		message: '',
	});

	useEffect(() => {
		if (!code || !attemptID) {
			return setState((s) => ({
				isLoading: false,
				hasError: true,
				emoji: '🤔',
				title: 'Sorry, this link is invalid',
				message: '',
				isConfirmed: false,
			}));
		}

		apiclientService.auth
			.verifyEmail(code, attemptID)
			.then((res) => {
				const searchParams = new URLSearchParams({
					secret: res.totp.secret,
					attempt_id: attemptID,
					email: res.totp.email,
				});
				navigate({
					pathname: SYNC_2FA_ROUTE,
					search: `?${searchParams.toString()}`,
				});
			})
			.catch((err: AptoServerError) => {
				const [emoji, title, message] = getErrorDescription(err);
				setState((s) => ({
					isLoading: false,
					hasError: true,
					emoji: emoji,
					title: title,
					message: message,
					isConfirmed: false,
				}));
			});
	}, [code, attemptID, navigate]);

	return { state };
}

function getErrorDescription(err: IError) {
	if (!err.code || !(err.code in errorDescriptions)) {
		return ['😢', 'Oops! This is unexpected...', err.message];
	}
	return errorDescriptions[err.code];
}

// TODO: Extract this to a i18n service

const errorDescriptions: { [errorCode: number]: [string, string, string] } = {
	91100: ['😦', 'Sorry, this link is invalid', ''],
	91101: [
		'🤔',
		'Sorry, this link is no longer valid.',
		'The link you clicked has expired. A new verification link has been sent to your email address.',
	],
	91102: ['😦', 'Sorry, this link is invalid', 'The verification code is invalid.'],
	91104: ['🤔', 'Hmm... The link is no longer valid.', 'It looks like your email is already verified.'],
};
