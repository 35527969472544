import CardsSelect from 'components/CardsSelect/CardsSelect';
import CardsSelectItem from 'components/CardsSelect/components/CardsSelectItem';
import React from 'react';
import IAptoCard from 'types/aptoCards/IAptoCard';
import useAptoCardSelect from './useAptoCardSelect';

export interface IAptoCardSelectProps {
	aptoCards?: IAptoCard[];
	isDisabled?: boolean;
	selectedAptoCard?: IAptoCard;
	onAptoCardClick?: (aptoCardId: string) => void;
}

export default function AptoCardSelect(props: IAptoCardSelectProps) {
	const { isExpanded, setExpanded, handleAptoCardClick } = useAptoCardSelect(props);
	return (
		<CardsSelect
			isExpanded={isExpanded}
			isDisabled={props.isDisabled}
			selectedNode={
				props.selectedAptoCard ? (
					<CardsSelectItem
						key={props.selectedAptoCard.id}
						id={props.selectedAptoCard.id}
						ariaExpanded={isExpanded}
						ariaHaspopup="listbox"
						ariaLabel="Choose a card:"
						designKey={props.selectedAptoCard.designKey}
						isSelectedItem
						isDisabled={props.isDisabled}
						network={props.selectedAptoCard.network}
						onItemClick={() => setExpanded(!isExpanded)}
						typeOfCard="apto"
						value={props.selectedAptoCard.lastFour}
					/>
				) : (
					<CardsSelectItem
						ariaExpanded={isExpanded}
						ariaHaspopup="listbox"
						ariaLabel="Choose a card:"
						id="CardsSelectItem--empty"
						isDisabled={props.isDisabled}
						onItemClick={() => setExpanded(!isExpanded)}
					/>
				)
			}
			dropdownContent={
				props.aptoCards?.length
					? props.aptoCards.map((aptoCard: IAptoCard) => (
							<CardsSelectItem
								key={aptoCard.id}
								id={aptoCard.id}
								ariaSelected={aptoCard.id === props.selectedAptoCard?.id}
								designKey={aptoCard.designKey}
								network={aptoCard.network}
								onItemClick={() => handleAptoCardClick(aptoCard.id)}
								typeOfCard="apto"
								value={aptoCard.lastFour}
							/>
					  ))
					: null
			}
		></CardsSelect>
	);
}
