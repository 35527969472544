import apiFetch from 'api-client/utils/fetch';
import toCamelCase from 'api-client/utils/toCamelCase';
import IApplicationStatus from 'types/session/IApplicationStatus';

const ENDPOINT = '/developer/get_started_application';

export interface IGetPublicKeyArgs {
	applicationId: string;
}

export interface IGetPublicKeyResponse {
	publicKey: string;
	name: string;
}

async function getPublicKey(args: IGetPublicKeyArgs): Promise<IGetPublicKeyResponse> {
	return apiFetch(`${ENDPOINT}/${args.applicationId}/signed_payloads`, 'GET').then(toCamelCase);
}

interface IApplicationResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

export interface ISetPublicKeyResponse {
	application: IApplicationResponse;
}

export interface ISetPublicKeyArgs {
	applicationId: string;
	publicKey: string;
	name: string;
}

async function setPublicKey(args: ISetPublicKeyArgs): Promise<ISetPublicKeyResponse> {
	return apiFetch(`${ENDPOINT}/${args.applicationId}/signed_payloads`, 'PUT', {
		public_key: args.publicKey,
		name: args.name,
	}).then(toCamelCase);
}

export default {
	getPublicKey,
	setPublicKey,
};
