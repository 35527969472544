import { CREDENTIALS_ROUTE } from 'constants/routes';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Notification from 'styleguide/Notification/Notification';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import OverviewFundingAccountContainer from './components/OverviewFundingAccount/OverviewFundingAccount';
import style from './Overview.module.scss';
import useOverviewPage from './useOverview.page';

export default function OverViewPage() {
	const {
		firstName,
		isActivateAccountButtonVisible,
		isPendingReviewNotificationVisible,
		isProduction,
		isRejectedNotificationVisible,
		showModalAccountActivation,
	} = useOverviewPage();

	if (isProduction) {
		return (
			<View className={style.Overview}>
				<Grid cols="12" isNested>
					<Item colWidth="12" colWidthLg="8" maxWidth="620px">
						<Heading size="large" tag="h1" margin="0 0 12px 0">
							Hi {firstName}, welcome!
						</Heading>
						<Paragraph size="small" color="contentPrimary" margin="0 0 24px 0">
							You are in Apto's Production environment. The first step is to update the mobile API keys used in your
							SDKs so you can onboard cardholders and issue real cards. Please remember that the Mobile API key provided
							on the Dev Portal is different for each environment (Sandbox vs Production). You can find your Mobile API
							keys by navigating to the{' '}
							<Pill type="link" level="inline-link" size="small" to={CREDENTIALS_ROUTE}>
								Developers
							</Pill>{' '}
							section.
						</Paragraph>
						<Paragraph size="small" color="contentPrimary" margin="0 0 24px 0">
							Reminder - Some endpoints of the Core API are only for Enterprise customers, so you may find that PUT and
							POST calls to the Core API are restricted in production, unless previously approved. If you have more
							questions, don't hesitate to reach out to
							<Pill type="link" level="inline-link" size="small" external to="mailto:programsupport@aptopayments.com">
								programsupport@aptopayments.com
							</Pill>
							.
						</Paragraph>
						<Pill type="link" level="primary" size="medium" to={CREDENTIALS_ROUTE} className={style.Overview__cta}>
							Get Production API keys
						</Pill>
					</Item>
				</Grid>
				<OverviewFundingAccountContainer />
			</View>
		);
	}

	return (
		<View className={style.Overview}>
			<View>
				<Notification
					isHidden={!isPendingReviewNotificationVisible}
					margin="0 0 24px 0"
					type="positive"
					description="Your application has been received. We will notify you within 3 days whether it has been approved."
				/>
				<Notification
					isHidden={!isRejectedNotificationVisible}
					margin="0 0 24px 0"
					type="negative"
					description={
						<span>
							Your application to production has been rejected. Please restart your application process by clicking on
							{` `}
							<b>Switch to production</b> button below, or contact our support team.
						</span>
					}
				/>
				<Grid cols="12" isNested className={style.Overview__grid}>
					<Item colWidth="12" colWidthLg="6" maxWidth="520px">
						<Heading size="large" tag="h1" margin="0 0 12px 0">
							Hi {firstName}, welcome!
						</Heading>
						<Paragraph size="small" color="contentPrimary" margin="0 0 24px 0">
							This is our API-driven sandbox. Every tool and function is available for you to test how your card program
							will work. Don't forget to reference our Developer Guides and API Documentation if you have questions
							along the way. Once you're ready, switch to production to go live!
						</Paragraph>
						{isActivateAccountButtonVisible ? (
							<Pill
								type="button"
								level="primary"
								size="medium"
								onClick={showModalAccountActivation}
								className={style.Overview__cta}
							>
								Switch to production
							</Pill>
						) : null}
						<Pill type="link" level="secondary" size="medium" to={CREDENTIALS_ROUTE}>
							Get API keys
						</Pill>
					</Item>
					<Item colWidth="12" colWidthLg="5" className={style.GetStarted__gridItem}>
						<Label size="medium" fontWeight="600" margin="0 0 16px">
							Get started:
						</Label>
						<ol className={style.GetStarted__list}>
							<li>Generate API keys (Developers page)</li>
							<li>
								<span className={style.GetStarted__highlight}>NEW!</span> Enable signed payloads (Developers page)
							</li>
							<li>Create test cardholders with the Mobile API / demo app (Developers page)</li>
							<li>Create test transactions (Transactions page)</li>
							<li>Set up webhooks (Developers page)</li>
							<li>View sandbox activity (Dashboard)</li>
							<li>Go live: switch to production</li>
						</ol>
						<Pill
							type="link"
							level="inline-link"
							size="small"
							external
							to="https://docs.aptopayments.com/docs/overview/Getting%20Started/overview"
						>
							See documentation for details
						</Pill>
					</Item>
				</Grid>
			</View>

			<OverviewFundingAccountContainer />
		</View>
	);
}
