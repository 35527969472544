import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import useSession from 'hooks/useSession';
import { PropsWithChildren, useEffect } from 'react';
import getEnvironment from 'utils/getEnvironment';

const LOCAL_STORAGE_USER_ID = 'ADP_USER_PROFILE';
const optimizely = createInstance({
	sdkKey: _getSdkKey(),
	logLevel: _getLogLevel(),
});

export default function FFProvider(props: PropsWithChildren<Record<string, unknown>>) {
	const { email } = useSession();

	useEffect(() => {
		const id = email ? email : crypto.randomUUID();
		optimizely.setUser({
			id,
			attributes: { group: _getGroup() },
		});
	}, [email]);

	return <OptimizelyProvider optimizely={optimizely}>{props.children}</OptimizelyProvider>;
}

function _getSdkKey() {
	switch (getEnvironment()) {
		case 'PRD':
			return 'EjzimCaw6HtnxyAnyD6HC';
		case 'STG':
		default:
			return '3PxrJMKh7au5NvuygRFtb';
	}
}

// https://docs.developers.optimizely.com/full-stack/docs/customize-logger-reactnative#log-levels
function _getLogLevel() {
	switch (getEnvironment()) {
		case 'PRD':
			return 3;
		case 'STG':
		default:
			return 1;
	}
}

function _getGroup() {
	if (localStorage.getItem(LOCAL_STORAGE_USER_ID) === 'QA') {
		return 'QA';
	}
	return 'regular';
}
