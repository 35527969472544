import apiClientService from 'api-client/apiclient.service';
import { LOGIN_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import useSession from 'hooks/useSession';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

export default function useResetPassword() {
	const navigate = useNavigate();
	const params = useQueryParams();
	const { isLoggedIn } = useSession();
	const attemptId = isLoggedIn ? '' : (params.get('attempt_id') as string);
	const [isPasswordValidatorVisible, setIsPasswordValidatorVisible] = useState(false);
	const [serverError, setServerError] = useState(attemptId ? '' : 'Invalid url: missing attempt_id parameter');

	const {
		errors,
		values,
		setFieldError,
		handleChange,
		handleBlur,
		submitForm,
		isSubmitting,
		handleFocus,
		isButtonEnabled,
	} = useForm({
		initialValues: { newPassword: '', passwordConfirm: '' },
		onSubmit: function (values) {
			return apiClientService.auth
				.resetPassword(values.newPassword, attemptId)
				.then(() => {
					return navigate(LOGIN_ROUTE);
				})
				.catch((err) => {
					return setServerError(err.message);
				});
		},
		validate: validationService.createValidator({
			newPassword: [validationService.required, validationService.password],
			passwordConfirm: [
				validationService.required,
				validationService.password,
				(value, _, context) => {
					if (context.values.newPassword !== value) {
						return "Passwords don't match";
					}
				},
			],
		}),
	});

	return {
		submitForm,
		errors,
		handleBlur,
		handleChange,
		handleFocus,
		isButtonDisabled: !isButtonEnabled,
		isLoading: isSubmitting,
		isPasswordValidatorVisible,
		onPasswordBlur: (e: any) => {
			handleBlur(e);
			values.newPassword !== '' ? setIsPasswordValidatorVisible(true) : setIsPasswordValidatorVisible(false);
		},
		onPasswordFocus: (e: any) => {
			handleFocus(e);
			setIsPasswordValidatorVisible(true);
		},
		serverError,
		setInvalidPassword: (err: string) => setFieldError('password', err),
		values,
	};
}
