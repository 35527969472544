import apiClientService from 'api-client/apiclient.service';
import useSession from 'hooks/useSession';
import { useQuery, UseQueryOptions } from 'react-query';
import IError from 'types/network/IError';
import { ITeamMembersReadResponse } from './../api-client/teamMembers/teamMembers';

export const QK_TEAM_MEMBERS = 'TEAM_MEMBERS';

interface IUseListTeamMembersQueryArgs extends UseQueryOptions<ITeamMembersReadResponse, IError> {}

export function useListTeamMembersQuery(args?: IUseListTeamMembersQueryArgs) {
	const { environment } = useSession();

	return useQuery<ITeamMembersReadResponse, IError>({
		queryKey: [environment, QK_TEAM_MEMBERS, 'list'],
		queryFn: () => apiClientService.teamMembers.read(),
		...args,
	});
}
