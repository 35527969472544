import AmountInput from 'components/AmountInput/AmountInput';
import Link from 'components/Link/Link';
import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import { DASHBOARD_ROUTE } from 'constants/routes';
import { IFinancialAccountType, ILoadFundsFields } from 'pages/loadFunds/useLoadFunds';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import IPaymentMethod from 'types/IPaymentMethod';
import style from './LoadStep.module.scss';
import useLoadStep from './useLoadStep';

export interface ILoadStepProps {
	accountType: IFinancialAccountType;
	amount: string;
	paymentMethods: Array<IPaymentMethod>;
	selectedPaymentMethodId: string;
	onPaymentMethodClick: (paymentMethodId: string) => void;
	onSubmit: (values: ILoadFundsFields) => void;
	onCloseModal: () => void;
}

export default function LoadStep(props: ILoadStepProps) {
	const { bind, isButtonDisabled, showModalAddPaymentMethod, submitForm } = useLoadStep(props);

	return (
		<View className={style.LoadStep}>
			<form onSubmit={submitForm} style={{ padding: '28px 24px 40px' }}>
				<Label size="medium" fontWeight="600" margin="14px 0" className={style.LoadStep__text}>
					Amount
				</Label>
				<AmountInput {...bind('amount')} />
				<Label size="medium" fontWeight="600" margin="14px 0" className={style.LoadStep__text}>
					From
				</Label>
				<PaymentMethodSelect
					paymentMethods={props.paymentMethods}
					onPaymentMethodClick={props.onPaymentMethodClick}
					selectedPaymentId={props.selectedPaymentMethodId}
					onAddPaymentMethodClick={showModalAddPaymentMethod}
				/>
				<Pill
					level="primary"
					size="medium"
					data-testid="btn-next"
					type="submit"
					disabled={isButtonDisabled}
					className={style.LoadStep__cta}
				>
					Next
				</Pill>
				<View className={style.LoadStep__row}>
					<Link to={DASHBOARD_ROUTE}>
						<Label size="medium" fontWeight="600">
							Cancel
						</Label>
					</Link>
				</View>
			</form>
		</View>
	);
}
