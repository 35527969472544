import ContentHeader from 'components/ContentHeader/ContentHeader';
import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import View from 'styleguide/View/View';

interface IUsersPageFailedProps {
	serverError: string;
}

export default function UsersPageFailed(props: IUsersPageFailedProps) {
	return (
		<View data-testid="page-users">
			<ContentHeader title="Users" />
			<PaddingContainer>
				<Notification type="negative" margin="2rem 0" title="Error" description={props.serverError} />
			</PaddingContainer>
		</View>
	);
}
