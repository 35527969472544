import IPaymentMethod, { IPaymentMethodPreferenceType } from 'types/IPaymentMethod';

function getPreferredBillingMethod(paymentMethods: IPaymentMethod[]) {
	return _getPreferredMethod(paymentMethods, 'billing');
}

function getPreferredFundingMethod(paymentMethods: IPaymentMethod[]) {
	return _getPreferredMethod(paymentMethods, 'funding');
}

function _getPreferredMethod(paymentMethods: IPaymentMethod[], preference: IPaymentMethodPreferenceType) {
	return paymentMethods.find((method) => method.preferenceTypes.includes(preference));
}

export default {
	getPreferredBillingMethod,
	getPreferredFundingMethod,
};
