import { useFeature } from '@optimizely/react-sdk';
import { useListPaymentSourcesQuery } from 'queries/paymentSources';
import IPaymentMethod from 'types/IPaymentMethod';

export default function usePaymentMethodsSection() {
	const [isManagePaymentsEnabled] = useFeature('MANAGE_PAYMENT_METHODS');
	const queryPaymentSources = useListPaymentSourcesQuery();

	return {
		props: {
			paymentSources: queryPaymentSources.data as IPaymentMethod[],
			handleCloseModal: queryPaymentSources.refetch,
			isManagePaymentsEnabled,
			serverError: queryPaymentSources.error?.message || '',
		},
		status: queryPaymentSources.status,
	};
}
