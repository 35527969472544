import {
	IAcceptAgreementResponse,
	ICreateApplicationResponse,
	IGetCardDesignResponse,
	IGetCompanyInfoResponse,
	ISetBillingParams,
	ISetBillingResponse,
	ISetCardDesignAttrs,
	ISetCardDesignResponse,
	ISetCompanyInfoArgs,
	ISetCompanyInfoResponse,
} from 'api-client/activateAccount/activateAccount';
import {
	IGetCompanyAddressResponse,
	ISetCompanyAddressArgs,
	ISetCompanyAddressResponse,
} from 'api-client/activateAccount/companyAddress/companyAddress';
import {
	IAddOwnerArgs,
	IListOwnersResponse,
	IRemoveOwnerArgs,
	ISetOwnersResponse,
} from 'api-client/activateAccount/owners/owners';
import {
	IGetPublicKeyArgs,
	IGetPublicKeyResponse,
	ISetPublicKeyArgs,
	ISetPublicKeyResponse,
} from 'api-client/activateAccount/publicKeys/publicKeys';
import apiClientService from 'api-client/apiclient.service';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { IGetProgramAgreementResponse } from 'types/activateAccount/IGetProgramAgreementResponse';
import IOwner from 'types/activateAccount/IOwner';
import IError from 'types/network/IError';

export const QK_LIST_OWNERS = 'LIST_OWNERS';
export const QK_GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const QK_GET_COMPANY_ADDRESS = 'GET_COMPANY_ADDRESS';
export const QK_GET_CARD_DESIGN = 'GET_CARD_DESIGN';
export const QK_GET_AGREEMENT = 'GET_AGREEMENT';
export const QK_GET_PUBLIC_KEY = 'GET_PUBLIC_KEY';

interface IUseCreateApplicationMutationArgs extends UseMutationOptions<ICreateApplicationResponse, IError> {}

export function useCreateApplicationMutation(args?: IUseCreateApplicationMutationArgs) {
	return useMutation<ICreateApplicationResponse, IError>({
		mutationFn: apiClientService.activation.createApplication,
		onSuccess: (data, variables, context) => {
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}
interface IUseGetCompanyInfoQueryArgs extends UseQueryOptions<IGetCompanyInfoResponse, IError> {
	applicationId: string;
}

export function useGetCompanyInfoQuery({ applicationId, ...queryOptions }: IUseGetCompanyInfoQueryArgs) {
	return useQuery<IGetCompanyInfoResponse, IError>({
		queryKey: [applicationId, QK_GET_COMPANY_INFO],
		queryFn: () => apiClientService.activation.getCompanyInfo(applicationId),
		...queryOptions,
	});
}

interface IUseAddCompanyInfoMutationArgs
	extends UseMutationOptions<ISetCompanyInfoResponse, IError, ISetCompanyInfoArgs> {}

export function useAddCompanyInfoMutation(args?: IUseAddCompanyInfoMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<ISetCompanyInfoResponse, IError, ISetCompanyInfoArgs>({
		mutationFn: apiClientService.activation.setCompanyInfo,
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([variables.applicationId, QK_GET_COMPANY_INFO]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseListOwnersQueryArgs extends UseQueryOptions<IListOwnersResponse, IError> {
	applicationId: string;
}

export function useListOwnersQuery({ applicationId, ...queryOptions }: IUseListOwnersQueryArgs) {
	return useQuery<IListOwnersResponse, IError>({
		queryKey: [applicationId, QK_LIST_OWNERS],
		queryFn: () => apiClientService.activation.listOwners(applicationId),
		...queryOptions,
	});
}

interface IUseAddOwnerMutationArgs extends UseMutationOptions<IOwner, IError, IAddOwnerArgs> {}

export function useAddOwnerMutation(args?: IUseAddOwnerMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<IOwner, IError, IAddOwnerArgs>({
		mutationFn: apiClientService.activation.addOwner,
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([variables.applicationId, QK_LIST_OWNERS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseUpdateOwnerMutationArgs extends UseMutationOptions<IOwner, IError, IAddOwnerArgs> {}

export function useUpdateOwnerMutation(args?: IUseUpdateOwnerMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<IOwner, IError, IAddOwnerArgs>({
		mutationFn: apiClientService.activation.updateOwner,
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([variables.applicationId, QK_LIST_OWNERS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseRemoveOwnerMutationArgs extends UseMutationOptions<void, IError, IRemoveOwnerArgs> {}

export function useRemoveOwnerMutation(args?: IUseRemoveOwnerMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<void, IError, IRemoveOwnerArgs>({
		mutationFn: apiClientService.activation.removeOwner,
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([variables.applicationId, QK_LIST_OWNERS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseConfirmOwnersMutationArgs extends UseMutationOptions<ISetOwnersResponse, IError, string> {}

export function useConfirmOwnersMutation(args?: IUseConfirmOwnersMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<ISetOwnersResponse, IError, string>({
		mutationFn: apiClientService.activation.confirmOwners,
		onSuccess: (data, applicationId, context) => {
			queryClient.invalidateQueries([applicationId, QK_LIST_OWNERS]);
			args?.onSuccess?.(data, applicationId, context);
		},
		...args,
	});
}
interface IUseGetCompanyAddressQueryArgs extends UseQueryOptions<IGetCompanyAddressResponse, IError> {
	applicationId: string;
}

export function useGetCompanyAddressQuery({ applicationId, ...queryOptions }: IUseGetCompanyAddressQueryArgs) {
	return useQuery<IGetCompanyAddressResponse, IError>({
		queryKey: [applicationId, QK_GET_COMPANY_ADDRESS],
		queryFn: () => apiClientService.activation.getCompanyAddress({ applicationId }),
		...queryOptions,
	});
}

interface IUseSetCompanyAddressMutationArgs
	extends UseMutationOptions<ISetCompanyAddressResponse, IError, ISetCompanyAddressArgs> {}

export function useSetCompanyAddressMutation(args?: IUseSetCompanyAddressMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<ISetCompanyAddressResponse, IError, ISetCompanyAddressArgs>({
		mutationFn: apiClientService.activation.setCompanyAddress,
		onSuccess: (data: ISetCompanyAddressResponse, variables: ISetCompanyAddressArgs, context: unknown) => {
			queryClient.invalidateQueries([variables.applicationId, QK_GET_COMPANY_ADDRESS]);
			args?.onSuccess?.(data, variables, context);
		},
	});
}

interface IUseGetCardDesignQueryArgs extends UseQueryOptions<IGetCardDesignResponse, IError> {
	applicationId: string;
}

export function useGetCardDesignQuery({ applicationId, ...queryOptions }: IUseGetCardDesignQueryArgs) {
	return useQuery<IGetCardDesignResponse, IError>({
		queryKey: [applicationId, QK_GET_CARD_DESIGN],
		queryFn: () => apiClientService.activation.getCardDesign(applicationId),
		...queryOptions,
	});
}

interface IUseSetCardDesignMutationArgs
	extends UseMutationOptions<ISetCardDesignResponse, IError, ISetCardDesignAttrs> {}

export function useSetCardDesignMutation(args?: IUseSetCardDesignMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<ISetCardDesignResponse, IError, ISetCardDesignAttrs>({
		mutationFn: apiClientService.activation.setCardDesign,
		onSuccess: (data: ISetCardDesignResponse, variables: ISetCardDesignAttrs, context: unknown) => {
			queryClient.invalidateQueries([variables.applicationId, QK_GET_CARD_DESIGN]);
			args?.onSuccess?.(data, variables, context);
		},
	});
}

interface IUseGetLegalQueryArgs extends UseQueryOptions<IGetProgramAgreementResponse, IError> {
	applicationId: string;
}

export function useGetLegalQuery({ applicationId, ...queryOptions }: IUseGetLegalQueryArgs) {
	return useQuery<IGetProgramAgreementResponse, IError>({
		queryKey: [applicationId, QK_GET_AGREEMENT],
		queryFn: () => apiClientService.activation.getProgramAgreement(applicationId),
		...queryOptions,
	});
}

interface IUseConfirmLegalMutationArgs extends UseMutationOptions<IAcceptAgreementResponse, IError, string> {}

export function useConfirmLegalMutation(args?: IUseConfirmLegalMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<IAcceptAgreementResponse, IError, string>({
		mutationFn: apiClientService.activation.acceptAgreement,
		onSuccess: (data, applicationId, context) => {
			queryClient.invalidateQueries([applicationId, QK_GET_AGREEMENT]);
			args?.onSuccess?.(data, applicationId, context);
		},
		...args,
	});
}

interface ISetBillingMutationOptions extends UseMutationOptions<ISetBillingResponse, IError, ISetBillingParams> {}

export function useSetBillingMutation(args?: ISetBillingMutationOptions) {
	const queryClient = useQueryClient();

	return useMutation<ISetBillingResponse, IError, ISetBillingParams>({
		mutationFn: apiClientService.activation.setBilling,
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([variables.applicationId, QK_GET_AGREEMENT]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseGetPublicKeyQueryArgs extends UseQueryOptions<IGetPublicKeyResponse, IError>, IGetPublicKeyArgs {}

export function useGetPublicKeyQuery({ applicationId, ...queryOptions }: IUseGetPublicKeyQueryArgs) {
	return useQuery<IGetPublicKeyResponse, IError>({
		queryKey: [applicationId, QK_GET_PUBLIC_KEY],
		queryFn: () => apiClientService.activation.getPublicKey({ applicationId }),
		...queryOptions,
	});
}

export interface IUseSetPublicKeyMutationArgs
	extends UseMutationOptions<ISetPublicKeyResponse, IError, ISetPublicKeyArgs>,
		IGetPublicKeyArgs {}

export function useSetPublicKeyMutation({ applicationId, ...mutationOptions }: IUseSetPublicKeyMutationArgs) {
	const queryClient = useQueryClient();

	return useMutation<ISetPublicKeyResponse, IError, ISetPublicKeyArgs>({
		mutationFn: apiClientService.activation.setPublicKey,
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([applicationId, QK_GET_PUBLIC_KEY]);
			mutationOptions.onSuccess?.(data, variables, context);
		},
		...mutationOptions,
	});
}
