import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function OverviewFundingAccountError() {
	useEffect(() => {
		toast.error('Funding Balance information is currently unavailable. We are working on a fix.', {
			autoClose: false,
		});
	}, []);

	return null;
}
