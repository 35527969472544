import useApplicationId from 'hooks/useApplicationId';
import { QueryStatus } from 'react-query';

export default function useComplianceStepWithApplicationId() {
	// SafeCast: This is rendered inside a WithApplicationId component so we know this is a valid string
	const applicationId = useApplicationId() as string;

	//TODO GET storedValues and pass them to the StepSuccess

	return {
		props: {
			applicationId,
			storedValues: {
				moneyTransmitterLicense: undefined,
				accessibility: undefined,
			},
		},

		// TODO update this with the GET query status value
		status: 'success' as QueryStatus,
	};
}
