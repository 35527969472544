import { useSetPublicKeyMutation } from 'queries/activateAccount';
import validationService from 'services/validationService/validation.service';
import Modal from 'styleguide/Modal/Modal';
import useForm from 'utils/useForm';

interface ISetPublicKeyFields {
	publicKey: string;
	name: string;
}

interface IUseModalSetPublicKeyArgs {
	onSuccess: () => void;
	applicationId: string;
	currentPublicKey: string;
	currentName: string;
}

export default function useModalSetPublicKey(props: IUseModalSetPublicKeyArgs) {
	const { onSuccess, applicationId, currentPublicKey, currentName } = props;

	const setPublicKeyMutation = useSetPublicKeyMutation({ applicationId });

	const { bind, isSubmitting, isButtonEnabled, submitForm, values } = useForm<ISetPublicKeyFields>({
		initialValues: { publicKey: currentPublicKey, name: currentName },
		onSubmit: onSetPublicKey,
		validate: validationService.createValidator({
			publicKey: [validationService.required],
			name: [validationService.required],
		}),
	});

	async function onSetPublicKey() {
		await setPublicKeyMutation.mutateAsync(
			{ publicKey: values.publicKey, name: values.name, applicationId },
			{ onSuccess }
		);

		Modal.hide();
	}

	return {
		bind,
		hasPublicKey: Boolean(values.publicKey),
		isButtonEnabled,
		isButtonLoading: isSubmitting,
		onSubmit: submitForm,
		serverError: setPublicKeyMutation.error?.message || '',
	};
}
