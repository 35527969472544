import mocksService from 'mocks/mocks.service';
import dummy_accounts_get_response from 'mocks/serverResponses/accounts/dummy_accounts_get_response';
import dummy_funding_account_push_response from 'mocks/serverResponses/accounts/dummy_funding_account_push_response';
import dummy_list_developer_transactions_response from 'mocks/serverResponses/accounts/dummy_list_developer_transactions_response';
import { rest } from 'msw';

export default [
	rest.get('https://api.stg.aptopayments.com/v1/developer/settings/accounts', (req, res, ctx) => {
		return res(ctx.json(dummy_accounts_get_response), ctx.status(200));
	}),
	rest.get('https://api.stg.aptopayments.com/v1/developer/settings/transactions', (req, res, ctx) => {
		return res(ctx.json(dummy_list_developer_transactions_response), ctx.status(200));
	}),
	rest.post('https://api.stg.aptopayments.com/v1/developer/settings/accounts/funding_account/push', (req, res, ctx) => {
		return res(
			...mocksService.randomizeStatus([
				[ctx.json(dummy_funding_account_push_response), ctx.status(200)],
				[ctx.json({ code: 200105, message: 'Insufficient funds' }), ctx.status(400)],
			])
		);
	}),
];
