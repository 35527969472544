import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './PhoneNumber.module.scss';

export interface IPhoneNumberProps extends ICommonInputProps {
  type: 'tel';
  value: string;
  /** Country calling code https://en.wikipedia.org/wiki/List_of_country_calling_codes */
  dialCode?: string;
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function PhoneNumber(props: IPhoneNumberProps) {
  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(
        style.PhoneNumber,
        style[props.dialCode ? 'has-dial-code' : ''],
        style[cnContent],
        style[cnError],
        style[cnDisabled]
      )}
    >
      <input
        className={style['PhoneNumber__input--text']}
        disabled={props.disabled}
        autoComplete={props.autocomplete}
        pattern={props.pattern}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={{ paddingLeft: getInputPadding(props.dialCode) }}
      />
      <label className={style.PhoneNumber__label} htmlFor={props.id}>
        {' '}
        {props.label}{' '}
      </label>
      {props.dialCode ? <span className={style.PhoneNumber__dialCode}>+{props.dialCode}</span> : null}
      {props.error ? <span className={style.PhoneNumber__error}>{props.error}</span> : null}
    </div>
  );
}

/** Needed b/c dial code lengths vary */
function getInputPadding(dialCode?: string): React.CSSProperties['padding'] {
  if (!dialCode) {
    return;
  }

  const BASE_PADDING = 122;
  const dialCodePadding = dialCode.length * 4;
  const padding = BASE_PADDING + dialCodePadding;

  return `${padding}px`;
}
