import { useGetUserQuery } from 'queries/users.queries';
import IAptoCard from 'types/aptoCards/IAptoCard';
import { ILoadStepProps } from './LoadStep';

export default function useLoadStep(originalProps: ILoadStepProps) {
	// There's currently not a "cards" endpoint to return cards scoped to a user, hence we're using
	// the user summary endpoint here.
	const getUserQuery = useGetUserQuery({ id: originalProps.cardholderId });

	return {
		props: {
			// SafeCast: we're using <WithQuery />
			cards: (getUserQuery.data?.cards as IAptoCard[]) || [],
			...originalProps,
		},
		status: getUserQuery.status,
	};
}
