import { useFeature } from '@optimizely/react-sdk';
import Layout from 'components/Layout/Layout';
import { LOGIN_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import BillingRootPage from './billing/Billing.root.page';
import CardPage from './card/CardDetails.page';
import CardsPage from './cards/Cards.page';
import CreateWebhook from './createWebhook/CreateWebhook';
import DevelopersRootPage from './developers/Developers.root.page';
import OverViewPage from './overview/Overview.page';
import ProfilePage from './profile/Profile.page';
import SettingsRootPage from './settings/Settings.root.page';
import TransactionPage from './transaction/Transaction.page';
import TransactionsPage from './transactions/Transactions.page';
import UserRootPage from './user/User.page';
import UsersPage from './users/Users.page';

export default function DashboardRootPage() {
	const { isLoggedIn, firstName, lastName, logOut, companyName } = useSession();
	const [isBillingEnabled] = useFeature('BILLING');

	if (!isLoggedIn) {
		// TODO: Save route and redirect to saved route once logged in
		return <Navigate replace to={LOGIN_ROUTE} />;
	}

	return (
		<Layout companyName={companyName} firstName={firstName as string} lastName={lastName} onLogout={logOut}>
			<Routes>
				<Route index element={<OverViewPage />} />
				<Route path="overview" element={<OverViewPage />} />

				<Route path="cards" element={<CardsPage />} />
				<Route path="cards/:id" element={<CardPage />} />

				<Route path="transactions" element={<TransactionsPage />} />
				<Route path="transactions/:id" element={<TransactionPage />} />

				<Route path="users" element={<UsersPage />} />
				<Route path="users/:id/*" element={<UserRootPage />} />

				<Route path="developers/*" element={<DevelopersRootPage />} />
				<Route path="developers/webhooks/create" element={<CreateWebhook />} />

				{isBillingEnabled ? <Route path="billing/*" element={<BillingRootPage />} /> : null}

				<Route path="settings/*" element={<SettingsRootPage />} />

				<Route path="profile" element={<ProfilePage />} />
			</Routes>
		</Layout>
	);
}
