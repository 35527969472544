import React from 'react';
import CountryInput, { ICountryInputProps } from './components/CountryInput/CountryInput';
import DateInput, { IDateInputProps } from './components/DateInput/DateInput';
import ExpirationDate, { IExpirationDateProps } from './components/ExpirationDate/ExpirationDate';
import Password, { IPasswordProps } from './components/Password/Password';
import PercentageInput, { IPercentageInputProps } from './components/PercentageInput/PercentageInput';
import PhoneNumber, { IPhoneNumberProps } from './components/PhoneNumber/PhoneNumber';
import SelectInput, { ISelectInputProps } from './components/SelectInput/SelectInput';
import Switch, { ISwitchProps } from './components/Switch/Switch';
import TextAreaInput, { ITextAreaInputProps } from './components/TextAreaInput/TextAreaInput';
import TextInput, { ITextInputProps } from './components/TextInput/TextInput';

export interface ICommonInputProps {
  /** Determines the input subtype */
  type:
    | 'text'
    | 'number'
    | 'select'
    | 'list'
    | 'password'
    | 'email'
    | 'textarea'
    | 'date'
    | 'select'
    | 'exp-date'
    | 'country'
    | 'tel'
    | 'switch'
    | 'percentage';
  /** Html ID attribute used in the input element*/
  id: string;
  /** Html name attribute used in the input element*/
  name: string;
  /** When enabled the input won't allow any interaction */
  disabled?: boolean;
  /** When true the element will try to get the focus */
  autofocus?: boolean;
  /** Text displayed in the label element attached to the input */
  label?: string;
  /** HTML autocomplete field  */
  autocomplete?: string;
  /** Margin of the input */
  margin?: React.CSSProperties['margin'];
  /** When give set an invalid status and display the given value under the input */
  error?: string;
  /** When true the input will display an error if value is not given */
  required?: boolean;
  /** https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-pattern */
  pattern?: string;
  /** Text that appears in the form control when it has no value set */
  placeholder?: string;
  /** */
  readOnly?: boolean;
}

export type InputProps =
  | IExpirationDateProps
  | IPhoneNumberProps
  | ICountryInputProps
  | IDateInputProps
  | IPasswordProps
  | ISelectInputProps
  | ITextAreaInputProps
  | ITextInputProps
  | ISwitchProps
  | IPercentageInputProps;

export default function Input(props: InputProps) {
  switch (props.type) {
    case 'text':
    case 'number':
    case 'email':
      return <TextInput {...props} />;
    case 'textarea':
      return <TextAreaInput {...props} />;
    case 'date':
      return <DateInput {...props} />;
    case 'select':
      return <SelectInput {...props} />;
    case 'exp-date':
      return <ExpirationDate {...props} />;
    case 'country':
      return <CountryInput {...props} />;
    case 'tel':
      return <PhoneNumber {...props} />;
    case 'password':
      return <Password {...props} />;
    case 'switch':
      return <Switch {...props} />;
    case 'percentage':
      return <PercentageInput {...props} />;
    default:
      return null;
  }
}
