import { useFeature } from '@optimizely/react-sdk';
import { IGetCardDesignResponse } from 'api-client/activateAccount/activateAccount';
import AptoCard from 'components/AptoCard/AptoCard';
import Notification from 'components/Notification/Notification';
import React from 'react';
import CardFlipper from 'styleguide/CardFlipper/CardFlipper';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './CardDesignSuccess.module.scss';
import CardTypeRadioGroup from './components/CardTypeRadioGroup/CardTypeRadioGroup';
import ColorSelector from './components/ColorSelector/ColorSelector';
import FileUploader from './components/FileUploader/FileUploader';
import LogoPreview from './components/LogoPreview/LogoPreview';
import NetworkSelector from './components/NetworkSelector/NetworkSelector';
import useCardDesignSuccess from './useCardDesignSuccess';

export interface ICardDesignSuccessProps {
	storedValues: IGetCardDesignResponse;
	applicationId: string;
}

export default function CardDesignSuccess(props: ICardDesignSuccessProps) {
	const {
		fileRef,
		handleRemoveLogoClick,
		isButtonLoading,
		onFileChanged,
		onNextClick,
		selectCard,
		selectColor,
		selectNetwork,
		serverError,
		state,
	} = useCardDesignSuccess({ storedValues: props.storedValues, applicationId: props.applicationId });
	const [isVisaFFEnabled] = useFeature('VISA');

	return (
		<View className={style.CardDesign}>
			<Display size="x-small" tag="h1" margin="0 0 24px">
				Design your cards
			</Display>

			<Notification isHidden={!serverError} type="negative" margin="32px 0" title="Error" description={serverError} />

			<View className={style.Content}>
				<View className={style.Content__left} data-testid="card-flipper">
					<CardFlipper
						front={
							<AptoCard
								side="front"
								type={state.type}
								designKey={state.color}
								network={state.network}
								logo={state.logoBase64}
							/>
						}
						back={<AptoCard side="back" type={state.type} designKey={state.color} />}
					/>

					<View className={style['CardDesign__submit--tablet']}>
						<Pill type="button" level="primary" size="medium" onClick={onNextClick} loading={isButtonLoading}>
							Next
						</Pill>
					</View>
				</View>

				<View className={style.Content__right}>
					{isVisaFFEnabled ? (
						<>
							<Label size="large" margin="0 0 12px 0" fontWeight="600">
								Network
							</Label>
							<Paragraph size="small" margin="0 0 20px">
								Apto supports card network integrations with both Mastercard and Visa. We recommend you do your own
								research to decide which card network is best for your company long-term as it is not easy to switch
								later.
							</Paragraph>
							<View role="radiogroup" className={style.NetworkSelector__wrapper}>
								<NetworkSelector
									onClick={selectNetwork}
									network="MASTERCARD"
									isActive={state.network === 'MASTERCARD'}
								/>
								<NetworkSelector onClick={selectNetwork} network="VISA" isActive={state.network === 'VISA'} />
							</View>

							<View className={style.CardDesign__section}>
								<Label size="large" margin="0 0 12px 0" fontWeight="600">
									Type
								</Label>
								<CardTypeRadioGroup type={state.type} autoPrint={state.autoPrint} selectCard={selectCard} />
							</View>
						</>
					) : (
						<>
							<Label size="large" margin="0 0 12px 0" fontWeight="600">
								Type
							</Label>
							<CardTypeRadioGroup type={state.type} autoPrint={state.autoPrint} selectCard={selectCard} />
						</>
					)}

					<View className={style.CardDesign__section}>
						<Label size="large" margin="0 0 12px 0" fontWeight="600">
							Color
						</Label>
						<View role="radiogroup">
							<ColorSelector onClick={selectColor} name="White" color="white" isActive={state.color === 'white'} />
							<ColorSelector onClick={selectColor} name="Black" color="black" isActive={state.color === 'black'} />
							<ColorSelector onClick={selectColor} name="Grey" color="grey" isActive={state.color === 'grey'} />
							<ColorSelector onClick={selectColor} name="Green" color="green" isActive={state.color === 'green'} />
							<ColorSelector onClick={selectColor} name="Coral" color="coral" isActive={state.color === 'coral'} />
							<ColorSelector onClick={selectColor} name="Indigo" color="indigo" isActive={state.color === 'indigo'} />
							<ColorSelector onClick={selectColor} name="Blue" color="blue" isActive={state.color === 'blue'} />
						</View>
					</View>

					<View className={style.CardDesign__section}>
						<Label size="large" margin="0 0 12px 0" fontWeight="600">
							Logo
						</Label>
						<span className={style.Logo__text}>
							You can apply your company’s logo to the upper right corner of your physical cards (.PNG format only).
							Digital wallet card art will not display this logo.
						</span>
						<FileUploader ref={fileRef} value={state.logo} onChange={onFileChanged} selectedPath={state.selectedPath} />

						{state.logoBase64 ? (
							<LogoPreview handleRemoveLogoClick={handleRemoveLogoClick} logoBase64={state.logoBase64} />
						) : null}
					</View>
				</View>
			</View>
			<View className={style['CardDesign__submit--mobile']}>
				<Pill
					type="button"
					level="primary"
					size="medium"
					onClick={onNextClick}
					loading={isButtonLoading}
					data-testid="btn-next"
				>
					Next
				</Pill>
			</View>
		</View>
	);
}
