const NON_NUMERIC_REGEX = /\D/g;
const MAX_VALUE_LENGTH = 4;

export function formatEventTarget(value: string, previousValue: string) {
  value = _removeNonNumericalCharacters(value);
  value = _addLeadingZeroIfRequired(value);
  value = _restrictToMaxLength(value);
  value = _addSlashIfRequired(value, previousValue);
  return value;
}

function _removeNonNumericalCharacters(value: string) {
  return value.replace(NON_NUMERIC_REGEX, '');
}

/** If digit string begins with any number but 0 or 1, add a leading 0 */
function _addLeadingZeroIfRequired(value: string) {
  const firstChar = value[0];
  if (!firstChar || firstChar === '0' || firstChar === '1') {
    return value;
  }
  return `0${value}`;
}

/** Restrict string to max length */
function _restrictToMaxLength(value: string) {
  return value.slice(0, MAX_VALUE_LENGTH);
}

/**
 * Add a slash after the month (MM/YY) if enough digits exist and we're not deleting digits.
 * Deletion is tracked by observing the previousValue of the string from last onChange call.
 *
 */
function _addSlashIfRequired(value: string, previousValue: string) {
  if (value.length <= 1 || (value.length === 2 && previousValue.includes('/'))) {
    return value;
  }
  return `${value.slice(0, 2)}/${value.slice(2)}`;
}

export default { formatEventTarget };
