import apiClientService from 'api-client/apiclient.service';
import { ICurrency } from 'constants/currencies';
import { LOAD_FUNDS_CONFIRM_ROUTE, LOAD_FUNDS_REVIEW_ROUTE, SETTINGS_ACCOUNTS_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import useSession from 'hooks/useSession';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import paymentService from 'services/payments.service';
import IPaymentMethod from 'types/IPaymentMethod';
import IServerRequestState from 'types/network/IServerRequest';
import IEnvironment from 'types/session/IEnvironment';
import loadFundsService from './loadFunds.service';

export type IFinancialAccountType = 'Developer Funding Account' | 'Billing Account';
export interface ILoadFundsFields {
	amount: string;
}

interface ILoadFundsState extends IServerRequestState {
	amount: string;
	currency: ICurrency;
	paymentId: string;
	paymentMethods: Array<IPaymentMethod>;
	paymentStatus: 'processed' | 'pending' | 'cancelled' | 'failed';
	paymentTimestamp?: Date;
	selectedPaymentMethodId: string;
}

export default function useLoadFunds() {
	const { isLoggedIn, environment } = useSession();
	const navigate = useNavigate();
	const searchParams = useQueryParams();
	const accountType = loadFundsService.getIsBillingAccount(searchParams);
	const isLoadingBilling = accountType === 'Billing Account';
	const [state, setState] = useState<ILoadFundsState>({
		amount: '',
		currency: 'USD',
		paymentId: '',
		paymentMethods: [],
		paymentStatus: 'pending',
		paymentTimestamp: undefined,
		requestStatus: 'IDLE',
		selectedPaymentMethodId: '',
		serverError: '',
	});

	// TODO: environment is being updated and causes an infinite loop
	useEffect(() => {
		if (isLoggedIn) fetchPaymentMethods(environment);
	}, []); // eslint-disable-line

	/**
	 * On this step the selected payment method is set.
	 */
	function updateSelectedPaymentId(selectedPaymentId: string) {
		if (selectedPaymentId !== state.selectedPaymentMethodId) {
			setState({
				...state,
				selectedPaymentMethodId: selectedPaymentId,
			});
		}
	}

	/**
	 * On this step the selected amount is set.
	 */
	function onLoadStepSubmitted(values: ILoadFundsFields) {
		setState({
			...state,
			amount: values.amount,
			serverError: '',
		});
		return navigate(`${LOAD_FUNDS_REVIEW_ROUTE}?${searchParams.toString()}`, { replace: true });
	}

	function onReviewStepSubmitted() {
		setState((s) => ({ ...s, requestStatus: 'PENDING', serverError: '' }));
		const loadFundsMethod = accountType === 'Billing Account' ? 'loadBillingAccount' : 'loadFundingAccount';

		return apiClientService.accounts[loadFundsMethod]({
			amount: parseFloat(state.amount),
			currency: state.currency,
			environment,
			paymentSourceId: state.selectedPaymentMethodId,
		})
			.then((res) => {
				setState((s) => ({
					...s,
					requestStatus: 'SUCCESS',
					paymentId: res.id,
					paymentStatus: res.status,
					paymentTimestamp: res.createdAt,
					serverError: '',
				}));
				navigate(`${LOAD_FUNDS_CONFIRM_ROUTE}?${searchParams.toString()}`, { replace: true });
			})
			.catch((err) => {
				setState((s) => ({
					...s,
					requestStatus: 'FAILED',
					serverError: err.message,
				}));
			});
	}

	function closeLoadFunds() {
		navigate(SETTINGS_ACCOUNTS_ROUTE, { replace: true });
	}

	function fetchPaymentMethods(environment: IEnvironment) {
		setState((s) => ({ ...s, requestStatus: 'PENDING' }));

		apiClientService.paymentSources
			.read({ environment })
			.then((res) => {
				const selectedPaymentMethodId = isLoadingBilling
					? _getPreferredBillingMethod(res.paymentSources).id
					: _getPreferredFundingMethod(res.paymentSources).id;

				setState((s) => ({
					...s,
					paymentMethods: res.paymentSources,
					serverError: '',
					requestStatus: 'SUCCESS',
					selectedPaymentMethodId,
				}));
			})
			.catch((err) => {
				setState((s) => ({
					...s,
					serverError: err.message,
					requestStatus: 'FAILED',
				}));
			});
	}

	/**
	 * Return the currently selected or fallback to the favorite one
	 */
	function _getSelectedPaymentMethod() {
		const selectedPaymentMethod = state.paymentMethods.find((pm) => pm.id === state.selectedPaymentMethodId);

		if (selectedPaymentMethod) {
			return selectedPaymentMethod;
		}

		if (isLoadingBilling) {
			return (
				state.paymentMethods.find((paymentMethod) => paymentMethod.preferenceTypes.includes('billing')) ||
				state.paymentMethods[0]
			);
		}

		return (
			state.paymentMethods.find((paymentMethod) => paymentMethod.preferenceTypes.includes('funding')) ||
			state.paymentMethods[0]
		);
	}

	return {
		closeLoadFunds,
		handleCloseModal: () => fetchPaymentMethods(environment),
		accountType,
		isLoggedIn,
		networkIsLoading: state.requestStatus === 'PENDING',
		onLoadStepSubmitted,
		onReviewStepSubmitted,
		searchParams,
		selectedPaymentMethod: _getSelectedPaymentMethod(),
		state,
		updateSelectedPaymentId,
	};
}

function _getPreferredBillingMethod(paymentSources: IPaymentMethod[]) {
	return paymentService.getPreferredBillingMethod(paymentSources) || paymentSources[0];
}

function _getPreferredFundingMethod(paymentSources: IPaymentMethod[]) {
	return paymentService.getPreferredFundingMethod(paymentSources) || paymentSources[0];
}
