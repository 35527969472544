import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import style from './DescriptionItem.module.scss';

interface IDescriptionItemProps {
	title: string;
	className?: string;
	onClick?: () => void;
}

export default function DescriptionItem(props: PropsWithChildren<IDescriptionItemProps>) {
	return (
		<View className={classnames(style.DescriptionItem, props.className)} onClick={props.onClick}>
			<Label size="small" fontWeight="600">
				{props.title}
			</Label>
			{props.children}
		</View>
	);
}
