import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import Overview from './components/Overview/Overview';
import Sdks from './components/Sdks/Sdks';

export default function SdkSection() {
	return (
		<PaddingContainer>
			<Overview />
			<Sdks />
		</PaddingContainer>
	);
}
