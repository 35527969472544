import apiClientService from 'api-client/apiclient.service';
import { IReadTransactionsResponse, ISummaryTransactionsResponse } from 'api-client/transactions/transactions';
import { queryForItemsInThePreviousPage } from 'hooks/usePagination';
import useSession from 'hooks/useSession';
import { useQuery, UseQueryOptions } from 'react-query';
import IPaginationConfig from 'types/IPaginationConfig';
import IError from 'types/network/IError';

const QK_TRANSACTIONS = 'TRANSACTIONS';

interface IUseListTransactionsQueryArgs extends UseQueryOptions<IListTransactionsQueryResponse, IError> {
	paginationConfig: IPaginationConfig;
}

export interface IListTransactionsQueryResponse extends IReadTransactionsResponse {
	hasMoreBefore: boolean;
}

export function useListTransactionsQuery({ paginationConfig, ...args }: IUseListTransactionsQueryArgs) {
	const { environment } = useSession();

	return useQuery<IListTransactionsQueryResponse, IError>({
		queryKey: [environment, QK_TRANSACTIONS, 'list', paginationConfig],
		queryFn: async () => {
			const res = await apiClientService.transactions.read({
				paginationConfig,
				environment,
			});

			// We need this because the server doesn't reply with the hasMoreBefore pagination info :/
			const hasMoreBefore = await queryForItemsInThePreviousPage(
				res.transactions[0],
				(paginationConfig) =>
					apiClientService.transactions.read({
						paginationConfig: paginationConfig.paginationConfig,
						environment,
					}),
				'transactions'
			);

			return { ...res, hasMoreBefore };
		},
		...args,
	});
}

interface IUseGetTransactionQueryArgs extends UseQueryOptions<ISummaryTransactionsResponse, IError> {
	id: string;
}

export function useGetTransactionQuery({ id, ...args }: IUseGetTransactionQueryArgs) {
	const { environment } = useSession();
	return useQuery<ISummaryTransactionsResponse, IError>({
		queryKey: [environment, QK_TRANSACTIONS, 'details', id],
		queryFn: () =>
			apiClientService.transactions.getSummary({
				id,
				environment,
			}),
		...args,
	});
}
