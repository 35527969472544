// https://apto-api-specs.netlify.app/private/stargateapi#operation/get_program_agreement_timestamp
export default {
	apto_privacy_policy: 'dummy_apto_privacy_policy',
	apto_terms_of_service: 'dummy_apto_terms_of_service',
	bank_privacy_policy: 'dummy_bank_privacy_policy',
	bank_terms_agreement: 'dummy_bank_terms_agreement',
	card_program_agreement: 'dummy_card_program_agreement',
	instant_issuance_pricing: 'dummy_instant_issuance_pricing',
	timestamp: '2022-02-22T11:16:51+00:00',
};
