import classnames from 'classnames';
import React from 'react';
import View from 'styleguide/View/View';
import style from './CompanyLogo.module.scss';

export interface ICompanyLogoProps {
	companyName: string;
	size: 'large' | 'small';
}

export default function CompanyLogo(props: ICompanyLogoProps) {
	return (
		<View
			data-testid={`company-logo-${props.companyName.toLocaleLowerCase()}`}
			className={classnames(style.CompanyLogo, style[`CompanyLogo--${props.size}`])}
		>
			<span className={style.CompanyLogo__content}>{_getFirstCharacter(props.companyName)}</span>
		</View>
	);
}

function _getFirstCharacter(companyName: string): string {
	return companyName.charAt(0);
}
