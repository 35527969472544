const DECIMAL_REGEX = /\.+/g;
const LEADING_ZEROS_REGEX = /^0+/g;
const ONLY_DIGITS_REGEX = /[^0-9.]/g;

/**
 * Forces a numeric value to be a valid number with selected number of decimals maximum. By default two decimal places.
 * @param value
 */
export function format(value: string, decimalPlaces: number = 2): string {
	value = _removeNonDigitsCharacters(value);

	if (value === '0') {
		return value;
	}

	value = _removeLeadingZeros(value);

	if (!value.includes('.')) {
		return value;
	}

	if (value.charAt(0) === '.') {
		// Validator won't let a value less than zero pass, but this makes for a cleaner user experience
		value = _addLeadingZero(value);
	}

	const [integerDigits, decimalDigits] = _splitToIntegerAndDecimal(value, decimalPlaces);
	return `${integerDigits}.${decimalDigits}`;
}

function _removeNonDigitsCharacters(value: string): string {
	return value.replace(ONLY_DIGITS_REGEX, '');
}

function _removeLeadingZeros(value: string): string {
	return value.replace(LEADING_ZEROS_REGEX, '');
}

function _addLeadingZero(value: string): string {
	return '0' + value;
}

function _splitToIntegerAndDecimal(value: string, decimalPlaces: number): Array<string> {
	const decimalIndex = value.indexOf('.');
	const integerDigits = value.slice(0, decimalIndex);

	let decimalDigits = value.slice(decimalIndex + 1);
	decimalDigits = _removeExtraDecimalPoints(decimalDigits);
	decimalDigits = decimalDigits.slice(0, decimalPlaces);

	return [integerDigits, decimalDigits];
}

function _removeExtraDecimalPoints(decimalDigits: string): string {
	return decimalDigits.replace(DECIMAL_REGEX, '');
}

export default { format };
