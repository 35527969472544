import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import View from 'styleguide/View/View';
import IOwner from 'types/activateAccount/IOwner';
import Owner from './components/Owner/Owner';

export interface IOwnersProps {
	owners: Array<IOwner>;
	isLoading: boolean;
	onClick: (owner: IOwner) => void;
}

export default function Owners(props: IOwnersProps) {
	if (props.isLoading) {
		return (
			<View
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Spinner width="64px" height="64px" />
			</View>
		);
	}

	return (
		<View>
			{props.owners.map((owner: IOwner, i: number) => (
				<Owner key={i} owner={owner} onClick={props.onClick} />
			))}
		</View>
	);
}
