import CardStatus from 'components/cards/CardStatus/CardStatus';
import LastFour from 'components/cards/LastFour/LastFour';
import ContentHeader from 'components/ContentHeader/ContentHeader';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import ChevronPagination from 'styleguide/Pagination/ChevronPagination/ChevronPagination';
import Table from 'styleguide/Table/Table';
import View from 'styleguide/View/View';
import IAptoCard from 'types/aptoCards/IAptoCard';
import IAptoCardStatus from 'types/aptoCards/IAptoCardStatus';
import useCardsPageSuccess from './useCardsPageSuccess';

export interface ICardContentProps {
	cards: IAptoCard[];
	isPaginationNextVisible: boolean;
	isPaginationPrevVisible: boolean;
	isProduction: boolean;
	total: number;
}

export default function CardsPageSuccess(props: ICardContentProps) {
	const { goToNextPage, goToPrevPage, onRowClick } = useCardsPageSuccess({
		cards: props.cards,
	});

	const COLUMNS = [
		{
			key: 'lastFour',
			name: 'Card Number',
			renderer: (value: string, item: IAptoCard) => _lastFourRenderer(value, item, props.isProduction),
		},
		{ key: 'nameOnCard', name: 'Name on Card' },
		{ key: 'id', name: 'Card ID' },
		{ key: 'status', name: 'Status', renderer: _statusRenderer },
	];

	return (
		<View data-testid="page-cards" style={{ position: 'relative', minHeight: 'calc(100vh - 80px)' }}>
			<ContentHeader title="Cards" total={props.total} />
			<Table
				minWidth="800px"
				data={props.cards}
				columns={COLUMNS}
				onRowClick={onRowClick}
				emptyTableMessage="You have no cards."
			/>
			<PaddingContainer>
				<ChevronPagination
					isInBlock
					isNextVisible={props.isPaginationNextVisible}
					isPrevVisible={props.isPaginationPrevVisible}
					onNextClicked={goToNextPage}
					onPrevClicked={goToPrevPage}
				/>
			</PaddingContainer>
		</View>
	);
}

function _lastFourRenderer(value: string, item: IAptoCard, isProduction: boolean) {
	return (
		<LastFour
			value={value}
			designKey={item.designKey}
			network={item.network}
			iconSize="small"
			typeOfCard={isProduction ? 'apto' : 'placeholder'}
		/>
	);
}

function _statusRenderer(value: IAptoCardStatus) {
	return <CardStatus value={value} />;
}
