import React, { PropsWithChildren, useState } from 'react';
import sessionService, { ISession } from 'services/session.service';

export interface ISessionContext {
	session: ISession | null;
	setSession: React.Dispatch<React.SetStateAction<ISession | null>>;
}

export const SessionContext = React.createContext<ISessionContext | null>(null);

export default function SessionProvider(props: PropsWithChildren<Record<string, unknown>>) {
	const [session, setSession] = useState<ISession | null>(sessionService.getSession);

	return <SessionContext.Provider value={{ session, setSession }}>{props.children}</SessionContext.Provider>;
}
