import apiclientService from 'api-client/apiclient.service';
import { useQuery, UseQueryOptions } from 'react-query';
import IGetAgreementsResponse from 'types/agreements/IAgreements';
import IError from 'types/network/IError';

export const QK_AGREEMENTS = 'AGREEMENTS';

export function useListAgreementsQuery({ ...queryOptions }: UseQueryOptions<IGetAgreementsResponse, IError> = {}) {
	return useQuery<IGetAgreementsResponse, IError>({
		queryKey: [QK_AGREEMENTS, 'list'],
		queryFn: apiclientService.agreements.read,
		...queryOptions,
	});
}
