import WithQuery from 'utils/WithQuery';
import UsersPageFailed from './components/UsersPageFailed';
import UsersPageLoading from './components/UsersPageLoading';
import UsersPageSuccess from './components/UsersPageSuccess/UsersPageSuccess';
import useUsersPage from './useUsersPage';

export default WithQuery({
	useHook: useUsersPage,
	Component: UsersPageSuccess,
	Loader: UsersPageLoading,
	Error: UsersPageFailed,
});
