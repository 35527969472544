import CopyText from 'components/CopyText/CopyText';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './MobileApiKey.module.scss';

export default function MobileAPIKey({ secret }: { secret: string }) {
	return (
		<PaddingContainer>
			<View className={style.MobileApiKey}>
				<Grid cols="4" isNested className={style.MobileApiKey__grid}>
					<Item colWidth="1">
						<Paragraph size="small" color="contentPrimary">
							Mobile API Key
						</Paragraph>
					</Item>
					<Item colWidth="3">
						<Paragraph size="small" color="contentPrimary" textAlign="right">
							<CopyText text={secret}>
								<span className={style.MobileApiKey__longText}>{secret}</span>
							</CopyText>
						</Paragraph>
					</Item>
				</Grid>
			</View>
		</PaddingContainer>
	);
}
