import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IPaymentMethod from 'types/IPaymentMethod';
import PaymentMethod from './components/PaymentMethod';
import PaymentsHeader from './components/PaymentsHeader';
import style from './PaymentMethodsSectionSuccess.module.scss';
import usePaymentMethodsSectionSuccess from './usePaymentMethodsSectionSuccess';

export interface IPaymentMethodsSectionSuccessProps {
	paymentSources: IPaymentMethod[];
	handleCloseModal: () => void;
	isManagePaymentsEnabled: boolean;
	serverError: string;
}

export default function PaymentMethodsSectionSuccess(props: IPaymentMethodsSectionSuccessProps) {
	const { checkRemoveEnabled } = usePaymentMethodsSectionSuccess();

	return (
		<>
			<PaddingContainer>
				<PaymentsHeader onCloseModal={props.handleCloseModal} isManagePaymentsEnabled={props.isManagePaymentsEnabled} />
				<Notification
					isHidden={!props.serverError}
					type="negative"
					margin="2rem 0"
					title="Error"
					description={props.serverError}
				/>
				<Grid cols="12" isNested>
					<Item colWidth="12" colWidthLg="8">
						{props.paymentSources?.length === 0 ? (
							<View className={style.PaymentMethodsSection__emptyList}>
								<Paragraph size="small" color="contentPrimary" textAlign="center">
									You have no payment methods.
								</Paragraph>
							</View>
						) : (
							props.paymentSources?.map((paymentSource: IPaymentMethod) => {
								return (
									<PaymentMethod
										key={paymentSource.id}
										id={paymentSource.id}
										description={paymentSource.description}
										lastFour={paymentSource.lastFour}
										network={paymentSource.cardNetwork}
										onSuccessfulDelete={props.handleCloseModal}
										isRemoveEnabled={checkRemoveEnabled(paymentSource) && props.paymentSources.length > 1}
									/>
								);
							})
						)}
					</Item>
				</Grid>
			</PaddingContainer>
		</>
	);
}
