// https://apto-api-specs.netlify.app/private/stargateapi#operation/get_developer_transactions
export default {
	transactions: [
		{
			transaction: {
				id: 'dummy_transaction_id_1',
				local_amount: {
					currency: 'EUR',
					amount: 100,
				},
				hold_amount: {
					currency: 'USD',
					amount: 101,
				},
				billing_amount: {
					currency: 'USD',
					amount: 102,
				},
				cashback_amount: {
					currency: 'USD',
					amount: 103,
				},
				fee_amount: {
					currency: 'USD',
					amount: 104,
				},
				created_at: '2016-10-19T23:25:17.000Z',
				description: 'description_1',
				merchant_name: 'merchant_name_1',
				state: 'complete',
			},
		},
		{
			transaction: {
				id: 'dummy_transaction_id_2',
				local_amount: {
					currency: 'EUR',
					amount: 200,
				},
				hold_amount: {
					currency: 'USD',
					amount: 201,
				},
				billing_amount: {
					currency: 'USD',
					amount: 202,
				},
				cashback_amount: {
					currency: 'USD',
					amount: 203,
				},
				fee_amount: {
					currency: 'USD',
					amount: 204,
				},
				created_at: '2016-10-19T23:25:17.000Z',
				description: 'description_2',
				merchant_name: 'merchant_name_2',
				state: 'pending',
			},
		},
	],
	has_more: true,
	total: 6,
};
