// If we use propper types here VSCode takes a huge time to crawl them :/
const styles: any = {
	indicatorSeparator: () => ({
		display: 'none',
	}),
	option: (provided: any, state: any) => ({
		padding: '14px 16px ',
		cursor: 'pointer',
		color: '#000',
		backgroundColor: state.isSelected ? '#EEEEEE' : state.isFocused ? '#F6F6F6' : '#fff',

		':active': {
			backgroundColor: '#F6F6F6',
		},
	}),

	control: (base: any, state: any) => ({
		...base,
		padding: '4px 6px ',
		border: '1px solid #CBCBCB',
		borderRadius: '100px',
		boxShadow: state.isFocused ? '0 0 0 1px #000' : 'none',

		':active': {
			boxShadow: '0 0 0 1px #000',
			borderColor: '#000',
		},

		':focus': {
			boxShadow: '0 0 0 1px #000',
			borderColor: '#000',
		},

		':hover': {
			boxShadow: '0 0 0 1px #000',
			borderColor: '#000',
		},
	}),

	menu: (base: any) => ({
		...base,
		overflow: 'hidden',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.03), 0px 3px 24px rgba(0, 0, 0, 0.08)',
		borderRadius: '20px;',
		padding: '0',
		zIndex: 2,
	}),
};

export default styles;
