import { useListSignedPayloadsKeysQuery } from 'queries/apiKeys.queries';
import { toast } from 'react-toastify';
import Modal from 'styleguide/Modal/Modal';
import ISignedPayloadsPublicKey from 'types/ISignedPayloadsPublicKey';
import ModalAddSignedPayloadsKey from './ModalAddSignedPayloadsKey/ModalAddSignedPayloadsKey';
import ModalDeleteSignedPayloadsKey from './ModalDeleteSignedPayloadsKey/ModalDeleteSignedPayloadsKey';

export interface IUseSignedPayloadsSectionSuccessArgs {
	isProduction: boolean;
}

export default function useSignedPayloadsSectionSuccess(props: IUseSignedPayloadsSectionSuccessArgs) {
	const listSignedPayloadsKeysQuery = useListSignedPayloadsKeysQuery();

	function showModalAddSignedPayloadsKey() {
		Modal.show(<ModalAddSignedPayloadsKey onSuccessfulSave={_handleSuccessfulSave} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Add Signed Payloads Key',
		});
	}

	function _handleSuccessfulSave() {
		Modal.hide();
		toast.success('Public key added.');
	}

	function showModalDeleteSignedPayloadsKey(id: string) {
		Modal.show(
			<ModalDeleteSignedPayloadsKey id={id} onSuccessfulDelete={_handleSuccessfulDelete} onCancelClick={Modal.hide} />,
			{
				canBeClosedOnClickOut: false,
				ariaTitle: 'Delete Signed Payloads Key',
			}
		);
	}

	function _handleSuccessfulDelete() {
		Modal.hide();
		toast.success('Public key deleted.');
	}

	return {
		props: {
			publicKeys: listSignedPayloadsKeysQuery.data?.publicKeys as ISignedPayloadsPublicKey[],
			onAddSignedPayloadsClick: showModalAddSignedPayloadsKey,
			onDeleteSignedPayloadsClick: showModalDeleteSignedPayloadsKey,
			isProduction: props.isProduction,
		},
		status: listSignedPayloadsKeysQuery.status,
	};
}
