import utils from 'utils';

// TODO: Move this to compile time using a REACT_APP_API_KEY env variable ?
export default function getApiKey() {
	switch (utils.getEnvironment()) {
		case 'PRD':
			return '9HHv6lu+jF79ID/1+coBm3C7OPND9/Mbn350J2C4sS7+3qiK1Y/X++dJZZqAX0W2';
		case 'STG':
		default:
			return 'WjnXJ5oyB0WN2kLUt4jtMzWMpxn/36RDyRP0BDHlB6ZW9xn9zIRWuXl8z6HmErnI';
	}
}
