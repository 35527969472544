export default class AptoServerError extends Error {
	code?: number;
	httpStatus?: number;

	constructor(message: string, statusCodes?: { code?: number; httpStatus?: number }) {
		super(message);
		this.code = statusCodes?.code;
		this.httpStatus = statusCodes?.httpStatus;
	}
}
