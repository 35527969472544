import React from 'react';
import Item from 'styleguide/Item/Item';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './BusinessItem.module.scss';

interface IBusinessItemProps {
	name: string;
	value: string;
}

export default function BusinessItem(props: IBusinessItemProps) {
	return (
		<Item padding="18px 0">
			<View className={style.BusinessItem}>
				<Paragraph size="small" color="contentPrimary">
					{props.name}
				</Paragraph>
				<Paragraph size="small" color="contentPrimary">
					{props.value}
				</Paragraph>
			</View>
		</Item>
	);
}
