import apiFetch from 'api-client/utils/fetch';
import paginationService from 'services/pagination.service';
import IAptoCard from 'types/aptoCards/IAptoCard';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import IPaginationConfig from 'types/IPaginationConfig';
import IEnvironment from 'types/session/IEnvironment';
import cardsParser from './cards.parser';

const CARDS_ENDPOINT = '/developer/cards';

interface IReadArgs {
	paginationConfig?: IPaginationConfig;
	environment: IEnvironment;
}

export interface ICardsReadResponse {
	hasMore: boolean;
	total: number;
	cards: Array<IAptoCard>;
}

export function read(args: IReadArgs): Promise<ICardsReadResponse> {
	const params = paginationService.getPaginationParams(args.paginationConfig);
	params.append('environment', args.environment);

	return apiFetch(`${CARDS_ENDPOINT}?${params.toString()}`, 'GET').then(cardsParser.parseCards);
}

interface IGetSummaryArgs {
	id: string;
	environment: IEnvironment;
}

export function getSummary(args: IGetSummaryArgs): Promise<IAptoCardSummaryResponse> {
	return apiFetch(`${CARDS_ENDPOINT}/${args.id}/summary?environment=${args.environment}`, 'GET').then(
		cardsParser.parseCardSummary
	);
}

export default {
	getSummary,
	read,
};
