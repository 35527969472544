import apiClientService from 'api-client/apiclient.service';
import { IListInvitationsResponse } from 'api-client/invitations/invitations';
import useSession from 'hooks/useSession';
import { useQuery, UseQueryOptions } from 'react-query';
import { IInvitationStatus } from 'types/invitations/IInvitationDetails';
import IError from 'types/network/IError';

export const QK_LIST_INVITATIONS = 'LIST_INVITATIONS';

interface IUseListInvitationsQueryArgs extends UseQueryOptions<IListInvitationsResponse, IError> {
	status: IInvitationStatus;
}

export function useListInvitationsQuery({ status, ...args }: IUseListInvitationsQueryArgs) {
	const { environment } = useSession();

	return useQuery<IListInvitationsResponse, IError>({
		queryKey: [environment, QK_LIST_INVITATIONS, status],
		queryFn: () => apiClientService.invitations.list({ status }),
		...args,
	});
}
