import React from 'react';
import View from 'styleguide/View/View';
import './Notification.scss';

export interface NotificationProps {
  description: string | React.ReactNode;
  title?: string;
  type?: 'positive' | 'negative';
  isHidden?: boolean;
  margin?: React.CSSProperties['margin'];
}

const Notification: React.FC<NotificationProps> = (props) => {
  if (props.isHidden) {
    return null;
  }

  let className = 'Notification';
  if (props.type === 'negative') {
    className += ' Notification--negative';
  }
  if (props.type === 'positive') {
    className += ' Notification--positive';
  }

  return (
    <View title={props.title} role="alertdialog" className={className} style={{ margin: props.margin }}>
      <View className="Notification__content">
        {props.title && <div className="Notification__title">{props.title}</div>}
        <div className="Notification__description">{props.description}</div>
      </View>
    </View>
  );
};

export default Notification;
