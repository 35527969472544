import IEnvironment from 'types/session/IEnvironment';

export function identifyUser(args: { email: string; companyName: string; environment: IEnvironment | undefined }) {
	if (!('pendo' in window)) {
		return;
	}
	pendo.identify({
		visitor: {
			id: args.email,
		},
		account: {
			id: args.companyName,
			environment: args.environment || '',
		},
	});
}

export default { identifyUser };
