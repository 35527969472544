import React from 'react';
import View from 'styleguide/View/View';
import style from './FileUploader.module.scss';

export interface IFileUploaderProps {
	value?: File;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	selectedPath?: string;
}

const FileUploader = React.forwardRef<HTMLInputElement, IFileUploaderProps>((props, ref) => {
	return (
		<View className={style.Root}>
			<label tabIndex={0} htmlFor="card-logo" className={style.Label}>
				Add your logo
				<input
					ref={ref}
					onChange={props.onChange}
					className={style.Input}
					id="card-logo"
					name="card-logo"
					type="file"
					accept="image/png"
					multiple={false}
					value={props.selectedPath}
				/>
			</label>
			<span className={style.Preview}>{props.value?.name}</span>
		</View>
	);
});

FileUploader.displayName = 'FileUploader';
export default FileUploader;
