import { useFeature } from '@optimizely/react-sdk';
import { ChangeEvent, useEffect } from 'react';
import decimalNumberService from 'services/decimalNumber.service';
import validationService from 'services/validationService/validation.service';
import IOwner, { IBeneficialOwner } from 'types/activateAccount/IOwner';
import useForm from 'utils/useForm';

const ALWAYS_REQUIRED_FIELDS = [
	'birthDate',
	'businessTitle',
	'citizenship',
	'country',
	'email',
	'firstName',
	'lastName',
	'locality',
	'phoneNumber',
	'countryCode',
	'postalCode',
	'region',
	'streetOne',
];

interface IUseAddOwnerArgs {
	onAddOwner: (owner: IOwner) => void;
	onCloseClicked: () => void;
	onDeleteOwner: (owner: IOwner) => void;
	onUpdateOwner: (owner: IOwner) => void;
	owner: IOwner | undefined;
	type: 'business_owner' | 'beneficial_owner';
}
export default function useAddOwner(args: IUseAddOwnerArgs) {
	const [isShowPassportDateFieldsEnabled] = useFeature('SHOW_PASSPORT_DATE_FIELDS');
	const addressIds = {
		streetOne: 'streetOne',
		streetTwo: 'streetTwo',
		locality: 'locality',
		region: 'region',
		postalCode: 'postalCode',
		country: 'country',
	} as const;

	const { values, bind, isValid, resetForm, setFieldValue, setTouched, setValues } = useForm<IOwner>({
		optionalFields: ['streetTwo'],
		initialValues: _getInitialValues(args.type),
		validate: validationService.createValidator<IOwner>({
			birthDate: [
				validationService.date,
				(value: any) => validationService.dateInThePast(value, true),
				validationService.required,
			],
			businessTitle: [validationService.maxLength(40), validationService.required],
			citizenship: [validationService.required],
			country: [validationService.required],
			email: [validationService.email, validationService.required],
			firstName: [validationService.maxLength(40), validationService.name, validationService.required],
			lastName: [validationService.maxLength(40), validationService.name, validationService.required],
			locality: [validationService.maxLength(40), validationService.required],
			ownershipPercentage: [
				_requiredOwnershipPercentageField,
				validationService.minimumValue(25),
				validationService.maximumValue(100),
			],
			passport: [_requiredPassportField, validationService.alphanumeric, validationService.maxLength(20)],
			passportExpiration: [_checkRequiredPassportField, validationService.date, validationService.dateInTheFuture],
			passportIssueDate: [_checkRequiredPassportField, validationService.date, validationService.dateInThePast],
			passportIssuedCountry: [_requiredPassportField],
			phoneNumber: [validationService.phoneNumber, validationService.required],
			postalCode: [validationService.zipCodeUS, validationService.required],
			region: [validationService.required],
			ssn: [validationService.ssnUS, _validateSSN],
			streetOne: [validationService.maxLength(40), validationService.required],
			streetTwo: [],
		}),
	});

	useEffect(_fillForm, [args.owner, setFieldValue, resetForm, setValues]);

	function _fillForm() {
		const shouldResetForm = !args.owner;
		if (shouldResetForm) {
			return resetForm();
		}

		// If an owner is set we want to display its details in the form. Is safe to cast.
		setValues(args.owner as IOwner);

		/**
		 * TODO: Find a better solution for this.
		 * The <InputPhoneNumber /> needs to receive "US" but BE sends us the dial code, ie, 1.
		 * As a workaround, we are using the Country field in the Address to set the phone's CountryCode.
		 * Currently is consistent because both inputs only allow US to be selected.
		 */
		setFieldValue('countryCode', args.owner?.country);
	}

	function _isButtonEnabled() {
		if (!isValid) {
			return false;
		}

		if (ALWAYS_REQUIRED_FIELDS.some((key) => !values[key as keyof typeof values])) {
			return false;
		}

		if (values.formOfID === 'ssn' && !values.ssn) {
			return false;
		}

		if (values.formOfID === 'passport') {
			if (!values.passport) {
				return false;
			}
			if (!values.passportExpiration && isShowPassportDateFieldsEnabled) {
				return false;
			}
			if (!values.passportIssueDate && isShowPassportDateFieldsEnabled) {
				return false;
			}
			if (!values.passportIssuedCountry) {
				return false;
			}
		}

		if (args.type === 'beneficial_owner' && !(values as IBeneficialOwner).ownershipPercentage) {
			return false;
		}

		return true;
	}

	function handleAddOwner() {
		// SafeCast: This is an IOwner because we validate the fields
		args.onAddOwner({ ...values, type: args.type } as IOwner);
		closeModal();
	}

	function handleUpdateOwner() {
		// SafeCast: This is an IOwner because we validate the fields
		args.onUpdateOwner({ ...values, type: args.type } as IOwner);
		closeModal();
	}

	function deleteOwner() {
		args.onDeleteOwner({ ...values });
		closeModal();
	}

	function closeModal() {
		args.onCloseClicked();
	}

	function getCardTitle(type: string, owner?: IOwner) {
		if (owner) {
			return owner.type === 'business_owner' ? 'Control person' : 'Beneficial owner';
		}

		return type === 'business_owner' ? 'Add control person' : 'Add beneficial owner';
	}

	function handleCitizenshipChange(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.value === 'us_citizen') {
			setFieldValue('citizenship', 'us_citizen');
			_selectSSN();
		}

		if (e.target.value === 'non_resident') {
			setFieldValue('citizenship', 'non_resident');
			_selectPassport();
		}
	}

	function handleFormOfIDChange(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.value === 'ssn') {
			_selectSSN();
		}

		if (e.target.value === 'passport') {
			values.citizenship === 'us_citizen' ? _selectPassport({ issuedCountry: 'US' }) : _selectPassport();
		}
	}

	function handlePercentageChange(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.id === 'ownershipPercentage') {
			setFieldValue('ownershipPercentage', decimalNumberService.format(e.target.value));
		}
	}

	function _selectSSN() {
		setFieldValue('formOfID', 'ssn');

		setFieldValue('passport', '');
		setFieldValue('passportExpiration', '');
		setFieldValue('passportIssueDate', '');
		setFieldValue('passportIssuedCountry', '');

		setTouched({
			passport: false,
			passportExpiration: false,
			passportIssueDate: false,
			passportIssuedCountry: false,
		});
	}

	function _selectPassport({ issuedCountry }: { issuedCountry?: string } = {}) {
		setFieldValue('formOfID', 'passport');
		setFieldValue('passport', '');
		issuedCountry ? setFieldValue('passportIssuedCountry', issuedCountry) : setFieldValue('passportIssuedCountry', '');
		setFieldValue('ssn', '');
		setTouched({ ssn: false });
	}

	// TODO: we can safely delete this function and use _requiredPassportField when the FF is deleted
	function _checkRequiredPassportField(value: string, touched: boolean | undefined, context: any): string | undefined {
		if (!isShowPassportDateFieldsEnabled) {
			return;
		}
		return _requiredPassportField(value, touched, context);
	}

	/**
	 * Validate fields only when type is beneficial owner
	 */
	function _requiredOwnershipPercentageField(value: string, touched: boolean | undefined, context: any) {
		if (args.owner?.type === 'business_owner' || args.type === 'business_owner') {
			return;
		}
		if (touched && !value) {
			return 'Required';
		}
	}

	return {
		addressIds,
		bind,
		cardTitle: getCardTitle(args.type, args.owner),
		closeModal,
		deleteOwner,
		handleAddOwner,
		handleCitizenshipChange,
		handleFormOfIDChange,
		handlePercentageChange,
		handleUpdateOwner,
		isButtonDisabled: !_isButtonEnabled(),
		isFormOfIDVisible: values.citizenship === 'us_citizen',
		isOwnershipPercentage: args.type === 'beneficial_owner',
		isPassportInputVisible: values.formOfID === 'passport',
		isPassportIssuedCountryDisabled: values.formOfID === 'passport' && values.citizenship === 'us_citizen',
		isShowPassportDateFieldsEnabled,
		isSSNInputVisible: values.formOfID === 'ssn',
	};
}

/**
 * Validate fields only when passport is selected as form of ID
 */
function _requiredPassportField(value: string, touched: boolean | undefined, context: any) {
	if (context.values.formOfID !== 'Passport') {
		return;
	}
	if (touched && !value) {
		return 'Required';
	}
}

/**
 * Validate field only if SSN is selected as form of ID
 */
function _validateSSN(value: string, touched: boolean | undefined, context: any) {
	if (context.values.formOfID !== 'ssn') {
		return;
	}
	if (touched && !value) {
		return 'Required';
	}
}

function _getInitialValues(type: 'business_owner' | 'beneficial_owner'): IOwner {
	return {
		birthDate: '',
		businessTitle: '',
		citizenship: 'us_citizen',
		country: 'US',
		countryCode: 'US',
		email: '',
		firstName: '',
		formOfID: 'ssn',
		id: '',
		lastName: '',
		locality: '',
		// We need to initialize this field/value to prevent the uncontrolled input to
		// be controlled error when changing from business to beneficial owner.
		ownershipPercentage: '',
		phoneNumber: '',
		postalCode: '',
		region: '',
		ssn: '',
		streetOne: '',
		streetTwo: '',
		type,
	};
}
