import useSession from 'hooks/useSession';

export default function useProfilePage() {
	const { firstName, lastName, email } = useSession();

	return {
		fullName: `${firstName} ${lastName}`,
		email,
	};
}
