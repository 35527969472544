import apiclientService from 'api-client/apiclient.service';
import IServerRequestState from 'types/network/IServerRequest';
import usePureState from 'utils/usePureState';
import { IModalDeleteMemberProps } from './ModalDeleteMember';

export default function useModalDeleteMember(props: IModalDeleteMemberProps) {
	const { state, dispatch } = usePureState<IServerRequestState>({
		requestStatus: 'IDLE',
		serverError: '',
	});

	function handleRemove() {
		dispatch({ requestStatus: 'PENDING' });
		apiclientService.teamMembers
			.remove(props.memberId)
			.then(() => {
				dispatch({ requestStatus: 'SUCCESS' });
				props.onSuccessfulRemove();
			})
			.catch((err) => {
				dispatch({ requestStatus: 'FAILED', serverError: err.message });
			});
	}

	return {
		handleRemove,
		isLoading: state.requestStatus === 'PENDING',
		serverError: state.serverError,
	};
}
