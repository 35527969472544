import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from '../ModalAccountActivation.module.scss';

interface IModalStartAccountActivation {
	handleClick: () => void;
}

export default function ModalStartAccountActivation(props: IModalStartAccountActivation) {
	return (
		<View className={style.ModalAccountActivation}>
			<Heading size="small" tag="h1" margin=" 0 0 12px">
				Switch to production
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin=" 0 0 24px">
				To switch into production, you must complete an application so we can gather: a) inputs about your card program
				preferences, and b) legal information about your business. Note: if you want to add a logo to your cards, you
				will upload it during this process.
			</Paragraph>
			<View className={style.ModalAccountActivation__footer}>
				<Pill level="primary" size="medium" onClick={props.handleClick} data-testid="start-application-button">
					Start application
				</Pill>
			</View>
		</View>
	);
}
