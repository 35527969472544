import React from 'react';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import style from './PaymentType.module.scss';

export interface IPaymentTypeProps {
	handleClick: () => void;
	imageSrc: string;
	text: string;
}

export default function PaymentType(props: IPaymentTypeProps) {
	return (
		<View className={style.PaymentType} onClick={props.handleClick}>
			<View className={style.PaymentType__image}>
				<img src={props.imageSrc} alt={`${props.text} logo`} />
			</View>
			<View className={style.PaymentType__text}>
				<Text type="small" weight={600}>
					{props.text}
				</Text>
			</View>
		</View>
	);
}
