import Label from 'styleguide/Type/Label/Label';
import style from './AddPaymentMethodItem.module.scss';

interface IAddPaymentMethodProps {
	onAddPaymentMethodClick?: () => void;
}

export default function AddPaymentMethodItem(props: IAddPaymentMethodProps) {
	if (!props.onAddPaymentMethodClick) {
		return null;
	}

	return (
		<button type="button" onClick={props.onAddPaymentMethodClick} className={style.AddPaymentMethodItem}>
			<Label size="small" color="accent" fontWeight="600">
				Add Payment Method
			</Label>
		</button>
	);
}
