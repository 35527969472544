import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import EmptyList from 'pages/dashboard/developers/components/EmptyList/EmptyList';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import IWebhook from 'types/IWebhook';
import Webhook from './components/Webhook/Webhook';
import useWebhooksSectionSuccess from './useWebhooksSectionSuccess';
import style from './WebhooksSectionSuccess.module.scss';

export interface IWebhooksSectionSuccessProps {
	webhooks: IWebhook[];
}

export default function WebhooksSectionSuccess(props: IWebhooksSectionSuccessProps) {
	const { isRemoving, onCreateWebhookClick, onRemoveWebhookClick, onEditWebhookClick } = useWebhooksSectionSuccess();

	return (
		<View className={style.WebhooksSectionSuccess}>
			<PaddingContainer>
				<View className={style.WebhooksSectionSuccess__header}>
					<Text margin="0 20px 0 0" type="h5" tag="h2">
						Endpoints
					</Text>
					<Pill level="primary" size="small" onClick={onCreateWebhookClick}>
						Add
					</Pill>
				</View>
			</PaddingContainer>
			<View className={style.WebhooksSectionSuccess__list}>
				{props.webhooks.length === 0 ? (
					<PaddingContainer>
						<EmptyList message="You have no endpoints." />
					</PaddingContainer>
				) : (
					props.webhooks.map((webhook) => (
						<Webhook
							key={webhook.id}
							webhook={webhook}
							isDeleting={isRemoving}
							onEditClick={onEditWebhookClick}
							onRemoveClick={onRemoveWebhookClick}
						/>
					))
				)}
			</View>
		</View>
	);
}
