import Drawer from 'components/Drawer/Drawer';
import Header from 'components/Header/Header';
import React from 'react';
import View from 'styleguide/View/View';
import './Layout.scss';

export interface ILayoutProps {
	children: React.ReactNode;
	companyName: string;
	firstName: string;
	lastName: string;
	onLogout: () => Promise<any>;
}

export default function Layout(props: ILayoutProps) {
	return (
		<View className="Layout">
			<Header firstName={props.firstName} lastName={props.lastName} onLogout={props.onLogout} />
			<Drawer />
			<main className="Wrapper">
				<View className="ContentWrapper">
					<View className="Content">{props.children}</View>
				</View>
			</main>
		</View>
	);
}
