// https://apto-api-specs.netlify.app/private/stargateapi#operation/get_payment_sources

export default {
	payment_sources: [
		{
			payment_source: {
				id: '1',
				description: 'dummy_description_1',
				type: 'card',
				card_type: 'debit',
				card_network: 'MASTERCARD',
				last_four: '4972',
				preference_types: [],
				postal_code: '1234',
				created_at: '2020-12-10T00:00:00.000Z',
				updated_at: '2020-12-11T00:00:00.000Z',
			},
		},
		{
			payment_source: {
				id: '2',
				description: 'dummy_description_2',
				type: 'card',
				card_type: 'debit',
				card_network: 'VISA',
				last_four: '2424',
				preference_types: ['funding'],
				postal_code: '1234',
				created_at: '2020-12-10T00:00:00.000Z',
				updated_at: '2020-12-11T00:00:00.000Z',
			},
		},
		{
			payment_source: {
				id: '3',
				description: 'dummy_description_3',
				type: 'card',
				card_type: 'debit',
				card_network: 'AMEX',
				last_four: '9026',
				preference_types: ['billing'],
				postal_code: '1234',
				created_at: '2020-12-10T00:00:00.000Z',
				updated_at: '2020-12-11T00:00:00.000Z',
			},
		},
		{
			payment_source: {
				id: '4',
				description: 'dummy_description_4',
				type: 'card',
				card_type: 'debit',
				card_network: 'DISCOVER',
				last_four: '2424',
				preference_types: [],
				postal_code: '1234',
				created_at: '2020-12-10T00:00:00.000Z',
				updated_at: '2020-12-11T00:00:00.000Z',
			},
		},
		{
			payment_source: {
				id: '5',
				description: 'dummy_description_5',
				type: 'card',
				card_type: 'debit',
				card_network: 'UNKNOWN',
				last_four: '1234',
				preference_types: [],
				postal_code: '1234',
				created_at: '2020-12-10T00:00:00.000Z',
				updated_at: '2020-12-11T00:00:00.000Z',
			},
		},
	],
	has_more: false,
	total: 5,
};
