import React, { useState } from 'react';
import Button from 'styleguide/Button/Button';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import style from './CardFlipper.module.scss';

export default function CardFlipper(props: ICardFlipperProps) {
  const [isFlipped, setIsFlipped] = useState(true);

  return (
    <View className={style.CardFlipper}>
      <View className={isFlipped ? style.CardFlipper__content : style['CardFlipper__content--flipped']}>
        <View className={style['CardFlipper__side--front']}>{props.front}</View>
        <View className={style['CardFlipper__side--back']}>{props.back}</View>
      </View>
      <View className={style.CardFlipper__button}>
        <Button level="link" onClick={() => setIsFlipped(!isFlipped)}>
          <Text type="small">
            <span className={style.CardFlipper__icon}>{isFlipped ? 'Flip to back' : 'Flip to front'}</span>
          </Text>
        </Button>
      </View>
    </View>
  );
}

export interface ICardFlipperProps {
  /**
   * ReactNode with the front side of the card
   */
  front: React.ReactNode;
  /**
   * ReactNode with the back side of the card
   */
  back: React.ReactNode;
}
