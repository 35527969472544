import { DASHBOARD_ROUTE, LOGIN_ROUTE } from 'constants/routes';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import imgDone from './done.svg';
import style from './PendingApplication.module.scss';
import usePendingApplication from './usePendingApplication';

export default function PendingApplicationPage() {
	const { isLoggedIn } = usePendingApplication();

	if (!isLoggedIn) {
		// TODO: Save route and redirect to saved route once logged in
		return <Navigate to={LOGIN_ROUTE} />;
	}

	return (
		<main className={style.PendingApplicationPage}>
			<img alt="" src={imgDone} width="80px" height="80px" />
			<Display size="x-small" tag="h1" margin="40px auto 12px" textAlign="center">
				Your application has been received.
			</Display>
			<Paragraph size="medium" color="contentPrimary" margin="12px auto 40px" textAlign="center">
				We’re currently reviewing your application and will notify you within 3 days whether it has been approved.
			</Paragraph>
			<Pill level="primary" size="medium" type="link" to={DASHBOARD_ROUTE}>
				Go back to sandbox
			</Pill>
		</main>
	);
}
