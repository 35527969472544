import apiClientService from 'api-client/apiclient.service';
import { IAcceptInvitationsResponse } from 'api-client/invitations/invitations';
import { CREATE_ACCOUNT_ROUTE, ERROR_ROUTE, EXPIRED_INVITE_LINK_ROUTE, LOGIN_ROUTE } from 'constants/routes';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useAcceptInvite() {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(function _acceptInvitation() {
		const urlSearchParams = new URLSearchParams(location.search);
		const invitationId = urlSearchParams.get('invitation_id') || undefined;
		const verificationId = urlSearchParams.get('verification_id') || undefined;
		const secret = urlSearchParams.get('secret') || undefined;

		if (!invitationId || !verificationId || !secret) {
			return navigate(ERROR_ROUTE);
		}

		apiClientService.invitations
			.accept(invitationId, verificationId, secret)
			.then((res: IAcceptInvitationsResponse) => {
				const searchParams = new URLSearchParams({
					email: res.invitee.emailAddress,
					company: res.developerAccount.companyName,
					invitation_id: invitationId,
					verification_id: verificationId,
					secret,
				});
				return navigate({
					pathname: res.invitee.exists ? LOGIN_ROUTE : CREATE_ACCOUNT_ROUTE,
					search: `?${searchParams.toString()}`,
				});
			})
			.catch((error) => {
				// Error_InvalidInvitationSecret and Error_ExpiredInvitation codes
				if (error.code === 201003 || error.code === 201005) {
					return navigate(EXPIRED_INVITE_LINK_ROUTE);
				}

				return navigate(ERROR_ROUTE, { state: { error: new Error(error.message) } });
			});
		// eslint-disable-next-line
	}, []);
}
