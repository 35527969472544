import AddPaymentMethod from 'components/AddPaymentMethod/AddPaymentMethod';
import Tooltip from 'components/Tooltip/Tooltip';
import useSession from 'hooks/useSession';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import ModalEditPaymentSources from './ModalEditPaymentSources/ModalEditPaymentSources';
import style from './Payments.module.scss';

interface IPaymentsHeaderProps {
	isManagePaymentsEnabled: boolean;
	onCloseModal: () => void;
}

export default function PaymentsHeader(props: IPaymentsHeaderProps) {
	const { isProduction } = useSession();

	function showModalEditPaymentMethod() {
		Modal.show(<ModalEditPaymentSources onPaymentMethodsEdited={hideModal} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Edit preferred payment methods',
		});
	}

	function showModalAddPaymentMethod() {
		Modal.show(<AddPaymentMethod onPaymentMethodAdded={hideModal} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Add payment method',
		});
	}

	function hideModal() {
		Modal.hide();
		props.onCloseModal();
	}

	return (
		<View className={style.PaymentsHeader}>
			<Heading size="small" tag="h2" margin="0 20px 0 0">
				Payment methods
			</Heading>
			<Tooltip data-testid="payments-tooltip" disabled={isProduction} message="Not available in sandbox">
				<Pill level="primary" size="small" disabled={!isProduction} onClick={showModalAddPaymentMethod}>
					Add
				</Pill>
			</Tooltip>

			{props.isManagePaymentsEnabled ? (
				<Tooltip disabled={isProduction} message="Not available in sandbox">
					<Pill level="secondary" size="small" disabled={!isProduction} onClick={showModalEditPaymentMethod}>
						Manage preferred
					</Pill>
				</Tooltip>
			) : null}
		</View>
	);
}
