import apiFetch from 'api-client/utils/fetch';
import getVaultBaseUrl from 'api-client/utils/getVaultBaseUrl';
import toCamelCase from 'api-client/utils/toCamelCase';
import { IGetProgramAgreementResponse } from 'types/activateAccount/IGetProgramAgreementResponse';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IAptoCardType from 'types/aptoCards/IAptoCardType';
import IApplicationStatus from 'types/session/IApplicationStatus';
import companyAddress from './companyAddress/companyAddress';
import owners from './owners/owners';
import publicKeys from './publicKeys/publicKeys';

const ENDPOINT = '/developer/get_started_application';

export interface ICreateApplicationResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

async function createApplication(): Promise<ICreateApplicationResponse> {
	return apiFetch(ENDPOINT, 'POST').then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

export interface ISetCompanyInfoResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

export interface ISetCompanyInfoArgs {
	applicationId: string;
	brandName?: string;
	description: string | null;
	legalName: string;
	taxId: string;
	website: string;
}

async function setCompanyInfo(args: ISetCompanyInfoArgs): Promise<ISetCompanyInfoResponse> {
	const { website, legalName, taxId, brandName, description, applicationId } = args;

	return apiFetch(`${ENDPOINT}/${applicationId}/company_info`, 'PUT', {
		description: description,
		legal_name: legalName,
		tax_id: taxId,
		website: website,
		...(brandName ? { brand_name: brandName } : {}),
	}).then((res) => {
		return {
			id: res.application.id,
			status: res.application.status,
			statusReason: res.application.status_reason,
		};
	});
}

export interface IGetCompanyInfoResponse {
	website: string;
	legalName: string;
	brandName: string;
	taxId: string;
	description: string | null;
}

async function getCompanyInfo(applicationId: string): Promise<IGetCompanyInfoResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/company_info`, 'GET').then(toCamelCase);
}

export interface IGetCardDesignResponse {
	autoPrint: boolean | null;
	logoBase64: string | null;
	network: IAptoCardNetwork | null;
	color: IAptoCardDesignKey | null;
	type: IAptoCardType | null;
}

async function getCardDesign(applicationId: string): Promise<IGetCardDesignResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/card_design`, 'GET').then((res) => ({
		autoPrint: res.card_design_auto_print,
		logoBase64: res.logo,
		network: res.network?.toUpperCase(),
		color: res.template,
		type: res.type,
	}));
}

export interface ISetCardDesignResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

export interface ISetCardDesignAttrs {
	applicationId: string;
	autoPrint?: boolean;
	logo?: string;
	network: IAptoCardNetwork;
	template: string;
	type: string;
}

/**
 * https://apto-api-specs.netlify.app/private/stargateapi#operation/add_application_card_design
 */
async function setCardDesign(attrs: ISetCardDesignAttrs): Promise<ISetCardDesignResponse> {
	return apiFetch(`${ENDPOINT}/${attrs.applicationId}/card_design`, 'PUT', {
		card_design_auto_print: attrs.autoPrint,
		logo: attrs.logo,
		network: attrs.network,
		template: attrs.template,
		type: attrs.type,
	}).then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

export interface ISetBillingResponse {
	id: string;
	status: 'pending_review' | 'success';
	statusReason: string | null;
}

export interface ISetBillingParams {
	applicationId: string;
}

export async function setBilling({ applicationId }: ISetBillingParams): Promise<ISetBillingResponse> {
	const baseURL = getVaultBaseUrl();

	return apiFetch(`${ENDPOINT}/${applicationId}/billing`, 'PUT', {}, { baseURL }).then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

async function getProgramAgreement(applicationId: string): Promise<IGetProgramAgreementResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/program_agreement`, 'GET').then(toCamelCase).then(_parseDate);
}

function _parseDate(raw: any) {
	return {
		...raw,
		timestamp: raw.timestamp ? new Date(raw.timestamp) : null,
	};
}

export interface IAcceptAgreementResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

export async function acceptAgreement(applicationId: string): Promise<IAcceptAgreementResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/program_agreement`, 'PUT').then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

export async function restartApplication(applicationId: string): Promise<void> {
	return apiFetch(`${ENDPOINT}/${applicationId}`, 'DELETE');
}

export default {
	createApplication,
	getCompanyInfo,
	setCompanyInfo,
	// Company address
	getCompanyAddress: companyAddress.getCompanyAddress,
	resendVerifyPhoneNumber: companyAddress.resendVerifyPhoneNumber,
	setCompanyAddress: companyAddress.setCompanyAddress,
	verifyPhoneNumber: companyAddress.verifyPhoneNumber,
	getCardDesign,
	setCardDesign,
	setBilling,
	// Business owners
	listOwners: owners.listOwners,
	getOwner: owners.getOwner,
	addOwner: owners.addOwner,
	updateOwner: owners.updateOwner,
	removeOwner: owners.removeOwner,
	confirmOwners: owners.confirmOwners,
	getProgramAgreement,
	acceptAgreement,
	restartApplication,
	...publicKeys,
};
