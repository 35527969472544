import classnames from 'classnames';
import React from 'react';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import style from './NetworkSelector.module.scss';

export interface INetworkSelectorProps {
	network: IAptoCardNetwork;
	isActive: boolean;
	onClick: (network: IAptoCardNetwork) => void;
}

export default function NetworkSelector(props: INetworkSelectorProps) {
	return (
		<label
			tabIndex={0}
			htmlFor={props.network}
			className={classnames(style.NetworkSelector, {
				[`${style['NetworkSelector--active']}`]: props.isActive,
			})}
		>
			<input
				className={style.NetworkSelector__input}
				onChange={() => props.onClick(props.network)}
				type="radio"
				id={props.network}
				name="cardNetwork"
				value={props.network}
				checked={props.isActive}
			/>
			<Label size="medium" fontWeight="600" className={style.NetworkSelector__title}>
				{props.network.toLowerCase()}
			</Label>
			<View
				className={classnames(
					style.NetworkSelector__icon,
					style[`NetworkSelector__icon--${props.network.toLowerCase()}`]
				)}
			></View>
		</label>
	);
}
