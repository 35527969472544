import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export default function View(props: PropsWithChildren<React.HTMLProps<HTMLDivElement>>) {
  const { className } = props;

  return (
    <div className={classNames('View', className)} {...props}>
      {props.children}
    </div>
  );
}
