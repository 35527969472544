import WithQuery from 'utils/WithQuery';
import SignedPayloadsSectionSuccess, {
	ISignedPayloadsSectionSuccessProps,
} from './components/SignedPayloadsSectionSuccess';
import useSignedPayloadsSectionSuccess, {
	IUseSignedPayloadsSectionSuccessArgs,
} from './components/useSignedPayloadsSectionSuccess';

export default WithQuery<IUseSignedPayloadsSectionSuccessArgs, ISignedPayloadsSectionSuccessProps>({
	useHook: useSignedPayloadsSectionSuccess,
	Component: SignedPayloadsSectionSuccess,
});
