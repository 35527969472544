import apiclientService from 'api-client/apiclient.service';
import { ACTIVATE_ACCOUNT_CARD_ROUTE } from 'constants/routes';
import useApplicationId from 'hooks/useApplicationId';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import makeRequired from 'utils/makeRequired';
import useForm from 'utils/useForm';

export default function useVerifyPhoneNumberStepWithApplicationId() {
	const navigate = useNavigate();
	// SafeCast: this is rendered using WithApplicationId
	const applicationId = useApplicationId() as string;

	const [state, setState] = useState({
		serverError: '',
		phoneNumber: sessionStorage.getItem('ADP_ACTIVATE_ACCOUNT_PHONE_NUMBER') as string,
		hasSMSBeenResent: false,
	});

	const { submitForm, isSubmitting, bind, isButtonEnabled, values, setFieldValue } = useForm({
		initialValues: { code: '' },
		onSubmit: async function (values) {
			return apiclientService.activation
				.verifyPhoneNumber(values.code, applicationId)
				.then(() => {
					navigate(ACTIVATE_ACCOUNT_CARD_ROUTE, { replace: true });
				})
				.catch((error) => {
					if (error.code === 91201) {
						setFieldValue('code', '');
						return setState({
							...state,
							serverError: 'Incorrect code. Please try again.',
						});
					}
					setState({ ...state, serverError: error.message });
				});
		},
		validate: function (values, touched) {
			const errors: { [key in keyof typeof values]?: string } = {};

			makeRequired('code', touched, values, errors);

			if (touched?.code && values.code.length !== 6) {
				return { code: 'Type 6 digits' };
			}

			return errors;
		},
	});

	function resendSMS() {
		return apiclientService.activation
			.resendVerifyPhoneNumber(applicationId)
			.then(() => {
				setState({ ...state, hasSMSBeenResent: true });
			})
			.catch((err) => {
				setState({ ...state, serverError: err.message });
			});
	}

	return {
		bind,
		hasSMSBeenResent: state.hasSMSBeenResent,
		isButtonDisabled: !isButtonEnabled,
		isLoading: isSubmitting,
		phoneNumber: state.phoneNumber,
		resendSMS,
		serverError: _getServerError(state),
		submitForm,
		values,
	};
}

function _getServerError(state: { phoneNumber: string; serverError: string }) {
	if (!state.phoneNumber) {
		return 'Missing phone number. Please try again by restarting the activation account process.';
	}
	return state.serverError;
}
