import ITeamRole from 'types/team/ITeamRole';

const ROLES: ITeamRole[] = [
	{ name: 'owner', isEnabled: false },
	{ name: 'admin', isEnabled: false },
	{ name: 'developer', isEnabled: false },
	{ name: 'viewer', isEnabled: false },
];

export default ROLES;
