import Link from 'components/Link/Link';
import Notification from 'components/Notification/Notification';
import links from 'constants/links';
import React from 'react';
import Button from 'styleguide/Button/Button';
import DrawerModal from 'styleguide/DrawerModal/DrawerModal';
import Input from 'styleguide/Input/Input';
import InputPhoneNumber from 'styleguide/InputPhoneNumber/InputPhoneNumber';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import style from './ContactUs.module.scss';
import useContactUs from './useContactUs';

interface IContactUsProps {
	onClose: () => void;
}

export default function ContactUs({ onClose }: IContactUsProps) {
	const { bind, submitForm, isButtonDisabled, networkError } = useContactUs(onClose);

	return (
		<DrawerModal
			description="If you would like to explore working with us, please fill out the short form and we’ll reach out to better understand how we can help your business."
			onClose={onClose}
			title="How can we help you?"
		>
			<Text type="h4" tag="h2" margin={'0 0 30px'}>
				Contact us
			</Text>
			<form className={style.Form} onSubmit={submitForm}>
				<Notification
					type="negative"
					isHidden={!networkError}
					title="Error"
					description={networkError}
					margin="40px 0"
				/>

				<View className={style.Form__row}>
					<Input type="text" label="First name" {...bind('firstName')} autocomplete="given-name" />
					<Input type="text" label="Last name" {...bind('lastName')} autocomplete="family-name" />
				</View>

				<Input margin="0 0 16px" type="text" label="Work email" {...bind('email')} autocomplete="email" />

				<Input margin="0 0 16px" type="text" label="Company" {...bind('company')} autocomplete="organization" />

				<Input margin="0 0 16px" type="text" label="Website" {...bind('website')} autocomplete="url" />

				<InputPhoneNumber margin="0 0 16px" bind={bind} selectOptions={['US']} autocomplete />

				<Input margin="0 0 40px" type="textarea" label="Tell us how we can help you" {...bind('comment')} />

				<Button testID="btn-submit" type="submit" disabled={isButtonDisabled}>
					Submit
				</Button>

				<Text className={style.Form__userConsent} margin="24px auto">
					By clicking “Submit”, I confirm that I have read, consent, and agree to Apto’s{' '}
					<Link external underline="true" to={links.APTO_TOS}>
						Terms of Service
					</Link>{' '}
					and{' '}
					<Link external underline="true" to={links.APTO_PRIVACY_POLICY}>
						Privacy Policy
					</Link>{' '}
					.
				</Text>
			</form>
		</DrawerModal>
	);
}
