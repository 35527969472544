import { useRemovePaymentMethodMutation } from 'queries/paymentSources';

interface IUseDeletePaymentMethodArgs {
	handleCloseModal: () => void;
	onSuccessfulDelete: () => void;
}

export default function useDeletePaymentMethod(args: IUseDeletePaymentMethodArgs) {
	const removePaymentMethodMutation = useRemovePaymentMethodMutation({
		onSuccess: () => {
			args.onSuccessfulDelete();
			args.handleCloseModal();
		},
	});

	function handleDelete(paymentSourceId: string) {
		removePaymentMethodMutation.mutate({
			id: paymentSourceId,
		});
	}

	return {
		isLoading: removePaymentMethodMutation.isLoading,
		serverError: removePaymentMethodMutation.error?.message || '',
		handleDelete,
	};
}
