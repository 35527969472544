const CODE_TEXT = `\
curl -LX POST https://api.sbx.aptopayments.com/cards/<CARD_ID>/transactions \\
  -H "Authorization: Basic <CORE_API_KEY>" \\
  -H "Content-Type: application/json" \\
  -d '{ "billing_amount": 100.00, "processing_type": "financial_request", "type": "purchase", "description": "Test" }'
`;

export default function useModalAddTransactions() {
	function copyToClipboard() {
		navigator.clipboard.writeText(CODE_TEXT);
	}

	return {
		copyToClipboard,
	};
}
