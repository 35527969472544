import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import View from 'styleguide/View/View';
import { IUseDocumentsSectionReturnedProps } from '../useDocumentsSection';
import Header from './Header/Header';

export default function DocumentsError(props: IUseDocumentsSectionReturnedProps) {
	return (
		<View data-testid="page-team">
			<PaddingContainer>
				<Header />
				<Notification
					type="negative"
					isHidden={!props.errorMessage}
					title="Error"
					description={props.errorMessage}
					margin="16px 0px 28px"
				/>
			</PaddingContainer>
		</View>
	);
}
