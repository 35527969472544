import React from 'react';
import IApplicationStatus from 'types/session/IApplicationStatus';
import ModalResumeAccountActivation from './components/ModalResumeAccountActivation';
import ModalStartAccountActivation from './components/ModalStartAccountActivation';
import useModalAccountActivation from './useModalAccountActivation';

interface IModalAccountActivation {
	lastApplicationStatus: IApplicationStatus | undefined;
	lastApplicationId: string | undefined;
}

export default function ModalAccountActivation(props: IModalAccountActivation) {
	const { goToAccountActivationFlow, isLoading, restartAccountActivationFlow, serverError } = useModalAccountActivation(
		props.lastApplicationId
	);

	return _checkIfIsResumeApplication(props.lastApplicationStatus) ? (
		<ModalResumeAccountActivation
			handleResume={goToAccountActivationFlow}
			handleRestart={restartAccountActivationFlow}
			isLoading={isLoading}
			serverError={serverError}
		/>
	) : (
		<ModalStartAccountActivation handleClick={goToAccountActivationFlow} />
	);
}

function _checkIfIsResumeApplication(lastApplicationStatus: IApplicationStatus | undefined): boolean {
	if (!lastApplicationStatus) {
		return false;
	}
	return lastApplicationStatus !== 'rejected';
}
