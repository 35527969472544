import ContentHeader from 'components/ContentHeader/ContentHeader';
import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import View from 'styleguide/View/View';

export default function UserPageFailed(props: { serverError: string }) {
	return (
		<View data-testid="page-user">
			<ContentHeader title="User" />
			<PaddingContainer>
				<Notification type="negative" margin="24px 0" title="Error" description={props.serverError} />
			</PaddingContainer>
		</View>
	);
}
