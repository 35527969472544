import toCamelcase from 'api-client/utils/toCamelCase';
import IAptoCard from 'types/aptoCards/IAptoCard';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';

export function parseCards(raw: any) {
	return {
		hasMore: raw.has_more,
		total: raw.total,
		cards: raw.cards.map(_parseCard),
	};
}

export function parseCardSummary(raw: any): IAptoCardSummaryResponse {
	const cardSummary = toCamelcase(raw);
	// Parse dates in card fields
	cardSummary.card.activatedAt = cardSummary.card.activatedAt ? new Date(cardSummary.card.activatedAt) : null;
	cardSummary.card.createdAt = cardSummary.card.createdAt ? new Date(cardSummary.card.createdAt) : null;
	cardSummary.card.shippedAt = cardSummary.card.shippedAt ? new Date(cardSummary.card.shippedAt) : null;
	cardSummary.card.lastActivityAt = cardSummary.card.lastActivityAt ? new Date(cardSummary.card.lastActivityAt) : null;
	// Parse dates in transactions server might send undefined when there are no transactions.
	cardSummary.transactions = cardSummary.transactions
		? cardSummary.transactions.map((raw: any) => ({
				...raw.transaction,
				createdAt: new Date(raw.transaction.createdAt),
		  }))
		: [];
	// Parse dates in user fields
	cardSummary.user.createdAt = new Date(cardSummary.user.createdAt);
	cardSummary.user.kyc.passedAt = new Date(cardSummary.user.kyc.passedAt);

	return cardSummary;
}

export function _parseCard({ card }: any): IAptoCard {
	const parsedCard: IAptoCard = toCamelcase(card);
	// Capitalize the name because it comes in uppercase.
	parsedCard.nameOnCard = parsedCard.nameOnCard
		.toLowerCase()
		.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
	return parsedCard;
}

export default { parseCards, parseCardSummary };
