import { ACTIVATE_ACCOUNT_BILLING_ROUTE } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import usePureState from 'utils/usePureState';

//TODO This type will change when the specs are final
type IOptions = 'yes' | 'no' | 'n/a';

export interface IComplianceStepFields {
	moneyTransmitterLicense?: IOptions;
	accessibility?: IOptions;
}

export interface IUseComplianceStepSuccessArgs {
	applicationId: string;
	// TODO This should be IGetComplianceResponse type
	storedValues: IComplianceStepFields;
}

export default function useComplianceStepSuccess(args: IUseComplianceStepSuccessArgs) {
	const { state, dispatch } = usePureState<IComplianceStepFields>({ ..._getInitialValues(args.storedValues) });
	const navigate = useNavigate();

	function handleRadioChange(e: any) {
		dispatch({ [e.target.name]: e.target.value });
	}

	function handleNextButtonClick(e: any) {
		//TODO send this using a mutation and update isLoading and serverError values
		console.log({ ...state }, args.applicationId);
		navigate(ACTIVATE_ACCOUNT_BILLING_ROUTE);
	}

	return {
		state,
		submitForm: handleNextButtonClick,
		isButtonDisabled: !(state.accessibility && state.moneyTransmitterLicense),
		isLoading: false,
		handleRadioChange,
		serverError: '',
	};
}

// TODO Response should be IGetComplianceResponse type
function _getInitialValues(response: IComplianceStepFields): IComplianceStepFields {
	// We make sure we don't return null values
	return {
		moneyTransmitterLicense: response.moneyTransmitterLicense || undefined,
		accessibility: response.accessibility || undefined,
	};
}
