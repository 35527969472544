import apiclientService from 'api-client/apiclient.service';
import { ERROR_ROUTE, VERIFY_2FA_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

/**
 * TODO: Chrome issue on autofill https://github.com/facebook/react/issues/1159
 *
 * - Use a hook + refs to get initial value ?
 **/
export default function useLogin() {
	const navigate = useNavigate();

	const params = useQueryParams();
	const invitationId = params.get('invitation_id') || undefined;
	const verificationId = params.get('verification_id') || undefined;
	const secret = params.get('secret') || undefined;
	const email = params.get('email') || '';

	const [state, setState] = useState({
		loginError: '',
		serverError: {
			title: '',
			message: '',
		},
	});

	const { errors, values, touched, handleChange, handleBlur, submitForm, isSubmitting, handleFocus, isButtonEnabled } =
		useForm({
			initialValues: { email: email, password: '' },
			onSubmit: function (values) {
				return apiclientService.auth
					.login({ email: values.email, password: values.password, invitationId, verificationId, secret })
					.then((res) => {
						return navigate(`${VERIFY_2FA_ROUTE}?attemptID=${res.attempt.id}`);
					})
					.catch((error) => {
						if (error.code === 1000) {
							return setState({
								loginError: 'Unknown email format',
								serverError: { title: '', message: '' },
							});
						}
						if (error.code === 91300 || error.code === 90278) {
							return setState({
								loginError: 'Incorrect email or password. Please try again.',
								serverError: { title: '', message: '' },
							});
						}
						if (error.code === 91301) {
							return setState({
								serverError: {
									title: 'Your account has been locked',
									message:
										"We've detected suspicious activity and have locked your account as a precaution. To unlock your account, please contact us.",
								},
								loginError: '',
							});
						}

						return navigate(ERROR_ROUTE, { state: { error: new Error(error.message) } });
					});
			},
			validate: validationService.createValidator({
				email: [validationService.required, validationService.email],
				password: [validationService.required],
			}),
		});

	return {
		errors,
		handleBlur,
		handleChange,
		handleFocus,
		isButtonDisabled: !isButtonEnabled,
		isEmailDisabled: values.email && !touched.email ? true : false,
		isLoading: isSubmitting,
		login: submitForm,
		loginError: state.loginError,
		serverError: state.serverError,
		values,
	};
}
