// https://apto-api-specs.netlify.app/private/stargateapi#operation/get_invitations
export default {
	type: 'list',
	data: [
		{
			invitation_id: 'dummy_invitation_id',
			status: 'pending',
			invitee_name: { first_name: 'dummy_invitee_first_name', last_name: 'dummy_invitee_last_name' },
			invitee_email: 'dummy@acme.com',
			invitee_user_role: 'admin',
			inviter_name: { first_name: 'dummy_inviter_first_name', last_name: 'dummy_inviter_last_name' },
			inviter_email: 'dummy2@acme.com',
			secret: 'dummy_secret',
			verification_id: 'dummy_verification_id',
		},
	],
	page: 0,
	rows: 0,
	has_more: false,
	total_count: 1,
};
