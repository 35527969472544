import React, { useEffect } from 'react';
import sentryService from 'services/sentry.service';
import StyleguideNotification, { NotificationProps } from 'styleguide/Notification/Notification';

export default function Notification(props: NotificationProps) {
	useEffect(() => {
		if (props.type === 'negative' && props.description !== '' && typeof props.description === 'string') {
			sentryService.captureException(new Error(props.description));
		}
	}, [props.description, props.type]);

	return <StyleguideNotification {...props} />;
}
