import { TRANSACTIONS_ROUTE } from 'constants/routes';
import usePaginationUtils from 'hooks/usePagination';
import useSession from 'hooks/useSession';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'styleguide/Modal/Modal';
import ITransaction from 'types/transactions/ITransaction';
import ModalAddTransactions from './components/ModalAddTransactions/ModalAddTransactions';

interface IUseTransactionsPageSuccessArgs {
	isPaginationNextVisible: boolean;
	isPaginationPrevVisible: boolean;
	total: number;
	transactions: ITransaction[];
}

export default function useTransactionsPageSuccess(args: IUseTransactionsPageSuccessArgs) {
	const navigate = useNavigate();
	const { isProduction } = useSession();
	const { goToPrevPage, goToNextPage } = usePaginationUtils({
		items: args.transactions,
	});

	function onRowClick(item: any) {
		navigate(`${TRANSACTIONS_ROUTE}/${item.id}`);
	}

	function showModalAddTransactions() {
		Modal.show(<ModalAddTransactions />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Add transaction',
		});
	}

	return {
		goToNextPage,
		goToPrevPage,
		isPaginationNextVisible: args.isPaginationNextVisible,
		isPaginationPrevVisible: args.isPaginationPrevVisible,
		isProduction,
		onRowClick,
		showModalAddTransactions: isProduction ? undefined : showModalAddTransactions,
		total: args.total,
		transactions: args.transactions,
	};
}
