import { useAddPaymentMethodCardMutation } from 'queries/paymentSources';
import { useState } from 'react';
import validationService, { ICreateValidatorOpts } from 'services/validationService/validation.service';
import ICreditCard from 'types/financial/ICreditCard';
import IError from 'types/network/IError';
import useForm from 'utils/useForm';

export type IAddPaymentMethodStep = '0_SELECT_METHOD' | '1_FILL_DEBIT_DATA';

interface IAddPaymentMethodFields extends ICreditCard {
	description: string;
}

export default function useAddPaymentMethod(onPaymentMethodAdded: () => void) {
	const [paymentStep, setPaymentStep] = useState<IAddPaymentMethodStep>('0_SELECT_METHOD');
	const addPaymentMethodCardMutation = useAddPaymentMethodCardMutation();

	const { bind, submitForm, isButtonEnabled, isSubmitting } = useForm<IAddPaymentMethodFields>({
		initialValues: { cvv: '', exp: '', name: '', pan: '', postalCode: '', description: '' },
		onSubmit: async (values) => {
			const { description, ...card } = values;

			try {
				await addPaymentMethodCardMutation.mutateAsync({
					card,
					description,
				});
				onPaymentMethodAdded();
			} catch (e) {
				console.error(e);
			}
		},
		optionalFields: ['description'],
		validate: validationService.createValidator<ICreditCard>(_getValidators(paymentStep)),
	});

	function updatePaymentStep(paymentStep: IAddPaymentMethodStep) {
		setPaymentStep(paymentStep);
	}

	return {
		bind,
		isButtonDisabled: !isButtonEnabled,
		isNetworkLoading: isSubmitting,
		paymentStep,
		serverError: _getErrorMessage(addPaymentMethodCardMutation.error),
		submitForm,
		updatePaymentStep,
	};
}

/**
 * If more payment methods added we could switch the validator based on the payment step.
 */
function _getValidators(paymentStep: IAddPaymentMethodStep): ICreateValidatorOpts<ICreditCard> {
	switch (paymentStep) {
		case '1_FILL_DEBIT_DATA':
			return cardValidator;
		default:
			return {};
	}
}

const cardValidator: ICreateValidatorOpts<ICreditCard> = {
	cvv: [validationService.cvv, validationService.required],
	exp: [validationService.exp, validationService.required],
	name: [validationService.name, validationService.required],
	pan: [validationService.pan, validationService.required],
	postalCode: [validationService.zipCodeUS, validationService.required],
};

function _getErrorMessage(err: IError | null) {
	if (!err) return '';
	// https://private-api-specs.s3-us-west-1.amazonaws.com/private/StargateAPI.html#operation/add_payment_source
	const errorCodes = [92000, 200068, 200058, 200072];
	if (err.code && errorCodes.includes(err.code)) {
		return 'This type of card can’t be used as a payment method. Please try a different card.';
	}
	return err.message;
}
