import classNames from 'classnames';
import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import TableHead from './components/TableHead';
import TableRow from './components/TableRow';
import style from './Table.module.scss';

export type ICellRenderer = (value: any, item: any) => React.ReactNode;
export type IColumn = { key: string; name: string; renderer?: ICellRenderer };

export interface ITableProps {
  columns?: IColumn[];
  data: any[];
  isHidden?: boolean;
  isLoading?: boolean;
  minWidth?: React.CSSProperties['minWidth'];
  emptyTableMessage?: string;
  onRowClick?: (item: any) => void;
  className?: string;
}

export default function Table(props: ITableProps) {
  if (props.isHidden) {
    return null;
  }

  if (props.isLoading) {
    return (
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner width="128px" height="128px" />
      </View>
    );
  }

  if (!props.data || !props.data?.length || props.data?.length === 0) {
    const message = props.emptyTableMessage || 'There are no items.';
    return (
      <View className={style.EmptyTable}>
         <Paragraph size="medium" margin="40px 0">
          {message}
        </Paragraph>
      </View>
    );
  }

  const columns = props.columns || _getColumns(props.data);
  const headNames = _getHeadNames(columns);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ minWidth: props.minWidth || 'auto' }}>
        <table className={classNames(style.Table, props.className)}>
          <TableHead headNames={headNames} />
          <tbody>
            {props.data.map((item, i) => (
              <TableRow key={i} item={item} onClick={props.onRowClick} columns={columns} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

/**
 * Utility functions
 */
export const Renderers = {
  DATE: (value: Date) =>
    new Intl.DateTimeFormat('en-US', {
      dateStyle: 'long',
      timeZone: 'UTC',
    }).format(value),
  MONEY: (value: number) => `$${value.toFixed(2)}`,
};

/**
 * If no column names are provided just use column keys
 */
function _getHeadNames(columns: IColumn[]): string[] {
  return columns.map((column) => column.name);
}

function _getColumns(data: any[]): IColumn[] {
  return Object.keys(data[0]).map((key) => ({ key, name: key }));
}
