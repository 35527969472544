import WithQuery from 'utils/WithQuery';
import UserPageFailed from './components/UserPageFailed';
import UserPageLoading from './components/UserPageLoading';
import UserPageSuccess from './components/UserPageSuccess';
import useUser from './useUser';

export default WithQuery({
	Component: UserPageSuccess,
	Error: UserPageFailed,
	Loader: UserPageLoading,
	useHook: useUser,
});
