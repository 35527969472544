import Tippy from '@tippyjs/react/headless';
import React, { PropsWithChildren } from 'react';
import style from './Tooltip.module.scss';

export interface ITooltipProps {
	message: string;
	disabled?: boolean;
	'data-testid'?: string;
}

export default function Tooltip(props: PropsWithChildren<ITooltipProps>) {
	return (
		<Tippy
			render={(attrs) => (
				<span className={style.Tooltip} tabIndex={-1} {...attrs}>
					{props.message}
					<div className={style.Tooltip__arrow} data-popper-arrow></div>
				</span>
			)}
			disabled={props.disabled}
		>
			<span data-testid={props['data-testid']} className={style.Tooltip__target} tabIndex={0}>
				{props.children}
			</span>
		</Tippy>
	);
}
