import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import DetailBox from './components/DetailBox/DetailBox';
import style from './ConfirmationStep.module.scss';
import ConfirmationStepService from './ConfirmationStep.service';

export interface IConfirmationStepProps {
	amount: string;
	onDoneClick: () => void;
	paymentId: string;
	lastFour: string | undefined; // Might need to rethink this for other forms of payment, perhaps a group of subcomponents based on the payment type?
	paymentStatus: 'processed' | 'pending' | 'cancelled' | 'failed';
	paymentTimestamp?: Date;
}

export default function ConfirmationStep(props: IConfirmationStepProps) {
	return (
		<View className={style.ConfirmationStep} data-testid="confirmation-step">
			<View className={style.Card}>
				<Label size="medium" fontWeight="600" className={style.Card__text}>
					{_getComfirmationTitle(props)}
				</Label>
				<DetailBox label="Status" value={props.paymentStatus === 'processed' ? 'Completed' : props.paymentStatus} />
				{props.paymentTimestamp ? (
					<DetailBox label="Time" value={ConfirmationStepService.formatDate(props.paymentTimestamp)} />
				) : null}
				<DetailBox label="From" value={`•••• ${props.lastFour}`} />
				{props.paymentStatus === 'processed' ? <DetailBox label="Authorization" value={props.paymentId} /> : null}

				<Pill level="primary" size="medium" onClick={props.onDoneClick} className={style.Card__cta}>
					Done
				</Pill>
			</View>
		</View>
	);
}

function _getComfirmationTitle(props: IConfirmationStepProps) {
	switch (props.paymentStatus) {
		case 'pending':
			return 'Transfer initiated';
		case 'failed':
			return `Your $${ConfirmationStepService.formatTwoDecimals(props.amount)} deposit failed`;
		case 'processed':
		default:
			return `You deposited $${ConfirmationStepService.formatTwoDecimals(props.amount)}`;
	}
}
