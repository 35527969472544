import { useFeature } from '@optimizely/react-sdk';
import { IGetCompanyInfoResponse } from 'api-client/activateAccount/activateAccount';
import { ACTIVATE_ACCOUNT_OWNERS_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useAddCompanyInfoMutation } from 'queries/activateAccount';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

export interface ICompanyInfoStepFields {
	brandName: string;
	description: string;
	legalName: string;
	// TODO rename this when the api specs are ready
	programWebsite?: string;
	taxId: string;
	website: string;
}

export interface IUseCompanyInfoSuccessStepArgs {
	storedValues: IGetCompanyInfoResponse;
	applicationId: string;
}

export default function useCompanyInfoSuccessStep({ storedValues, applicationId }: IUseCompanyInfoSuccessStepArgs) {
	const navigate = useNavigate();
	const { companyName } = useSession();
	const addCompanyInfoMutation = useAddCompanyInfoMutation();
	const [isCompanyInfoV2Enabled] = useFeature('APPLICATION_COMPANY_INFO_V2');
	const [checkboxValue, setCheckboxValue] = useState(false);

	const { submitForm, isSubmitting, bind, isButtonEnabled, setFieldValue, values } = useForm<ICompanyInfoStepFields>({
		initialValues: _getInitialValues(storedValues),
		optionalFields: isCompanyInfoV2Enabled ? [] : ['programWebsite'],
		onSubmit: async (values) => {
			try {
				await addCompanyInfoMutation.mutateAsync({ ...values, applicationId });
				navigate(ACTIVATE_ACCOUNT_OWNERS_ROUTE, { replace: true });
			} catch (e) {
				// This is handled via serverError and react query
				console.error(e);
			}
		},
		validate: validationService.createValidator<ICompanyInfoStepFields>({
			brandName: [validationService.required],
			description: [validationService.required, validationService.maxLength(300)],
			legalName: [validationService.required, validationService.companyName],
			taxId: [validationService.einUS, validationService.required],
			website: [validationService.required, validationService.url],
		}),
	});

	function handleCheckboxChange() {
		setCheckboxValue(!checkboxValue);
		setFieldValue('programWebsite', !checkboxValue ? values.website : '');
	}

	return {
		bind,
		checkboxValue,
		companyName: companyName,
		handleCheckboxChange,
		isButtonDisabled: !isButtonEnabled,
		isCompanyInfoV2Enabled,
		isLoading: isSubmitting,
		serverError: addCompanyInfoMutation.error?.message || '',
		submitForm,
	};
}

function _getInitialValues(response: IGetCompanyInfoResponse): ICompanyInfoStepFields {
	// We make sure we don't return null values
	return {
		brandName: response.brandName ? response.brandName : '',
		description: response.description ? response.description : '',
		legalName: response.legalName ? response.legalName : '',
		programWebsite: '',
		taxId: response.taxId ? response.taxId : '',
		website: response.website ? response.website : '',
	};
}
