import FullNameFormatter from 'components/formatters/FullNameFormatter/FullNameFormatter';
import ROLES from 'constants/roles';
import React from 'react';
import Table from 'styleguide/Table/Table';
import ITeamMember from 'types/team/ITeamMember';
import { ITeamRoleName } from 'types/team/ITeamRole';
import style from './AcceptedInvitesSuccess.module.scss';
import TeamRoleSelect from './components/TeamRoleSelect/TeamRoleSelect';
import useAcceptedInvitesSuccess from './useAcceptedInvitesSuccess';

interface IAcceptedInvitesSuccessProps {
	teamMembers: ITeamMember[];
}

interface ITeamMembersTableData {
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
	role: ITeamRoleName;
}

export default function AcceptedInvitesSuccess(props: IAcceptedInvitesSuccessProps) {
	const { showModalDeleteMember, isRemoveButtonDisabled } = useAcceptedInvitesSuccess();

	const columns = [
		{
			key: 'firstName',
			name: 'Name',
			renderer: _initialsAndFullNameRenderer,
		},
		{ key: 'email', name: 'Email' },
		{
			key: 'role',
			name: 'Role',
			renderer: (role: ITeamRoleName, member: ITeamMembersTableData) =>
				_roleRenderer(role, member.userId, showModalDeleteMember, isRemoveButtonDisabled(member.userId)),
		},
	];

	const data: ITeamMembersTableData[] = props.teamMembers.map((member) => {
		return {
			userId: member.userId,
			firstName: member.firstName,
			lastName: member.lastName,
			email: member.email,
			role: member.role,
		};
	});

	return (
		<Table
			data={data}
			columns={columns}
			className={style.AcceptedInvitesSuccess__table}
			emptyTableMessage="You have no team members."
		/>
	);
}

function _initialsAndFullNameRenderer(firstName: string, member: ITeamMembersTableData) {
	return <FullNameFormatter firstName={firstName} lastName={member.lastName} />;
}

function _roleRenderer(
	role: ITeamRoleName,
	memberId: string,
	showModalDeleteMember: (memberId: string) => void,
	isRemoveButtonDisabled: boolean
) {
	return (
		<TeamRoleSelect
			teamRoles={ROLES}
			selectedTeamRoleName={role}
			onRemoveTeamMemberClick={() => showModalDeleteMember(memberId)}
			onTeamRoleNameClick={console.log}
			isRemoveButtonDisabled={isRemoveButtonDisabled}
		/>
	);
}
