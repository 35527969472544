import useGetPaginationConfig from 'hooks/useGetPaginationConfig';
import useSession from 'hooks/useSession';
import { useListCardsQuery } from 'queries/cards.queries';
import IAptoCard from 'types/aptoCards/IAptoCard';

export default function useCards() {
	const { isProduction } = useSession();
	const paginationConfig = useGetPaginationConfig();
	const listCardsQuery = useListCardsQuery({ paginationConfig });

	return {
		props: {
			// SafeCast: We are using withQuery
			cards: listCardsQuery.data?.cards as IAptoCard[],
			isPaginationNextVisible: listCardsQuery.data?.hasMore as boolean,
			isPaginationPrevVisible: listCardsQuery.data?.hasMoreBefore as boolean,
			isProduction,
			serverError: listCardsQuery.error?.message || '',
			total: listCardsQuery.data?.total as number,
		},
		status: listCardsQuery.status,
	};
}
