import Initials from 'components/Initials/Initials';
import React from 'react';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IOwner from 'types/activateAccount/IOwner';
import style from './Owner.module.scss';

export interface IOwnerProps {
	owner: IOwner;
	onClick: (owner: IOwner) => void;
}

export default function Owner(props: IOwnerProps) {
	return (
		<View className={style.Owner} onClick={() => props.onClick(props.owner)}>
			<View className={style.Owner__name}>
				<Initials className={style.Owner__initials} firstName={props.owner.firstName} lastName={props.owner.lastName} />
				<Paragraph size="small" color="contentPrimary">
					{props.owner.firstName} {props.owner.lastName}, {props.owner.businessTitle}
				</Paragraph>
			</View>
		</View>
	);
}
