import Header from 'components/LoginHeader/LoginHeader';
import React from 'react';
import CircleIcon from 'styleguide/CircleIcon/CircleIcon';
import Emoji from 'styleguide/Emoji/Emoji';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ExpiredInviteLink.module.scss';

export default function ExpiredInviteLinkPage() {
	return (
		<>
			<Header />
			<Grid cols="16" className={style.ExpiredInviteLink}>
				<Item colWidth="16" colWidthLg="6" className={style.ExpiredInviteLink__gridItem}>
					<View className={style.ExpiredInviteLink__circleIcon}>
						<CircleIcon>
							<Emoji symbol="⌛️" fontSize="52px" />
						</CircleIcon>
					</View>
					<Display size="x-small" tag="h1">
						Oops! This link has expired
					</Display>
					<Paragraph color="contentSecondary" size="medium" margin="24px 0">
						Reach out to the individual who sent you the invite to ask for a new invitation. Invites expire after 7
						days, so make sure you accept it within that timeframe!
					</Paragraph>
				</Item>
			</Grid>
		</>
	);
}
