import classnames from 'classnames';
import { ChangeEvent, FocusEvent } from 'react';
import style from './Checkbox.module.scss';

interface ICheckboxProps {
	checked: boolean;
	className?: string;
	id: string;
	label: string;
	name: string;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function Checkbox(props: ICheckboxProps) {
	// TODO Extract this to the styleguide when the Design System is ready.
	// TODO Refactor it as part of the Input component?
	return (
		<label className={classnames(style.Checkbox, props.className)} htmlFor={props.id}>
			{props.label}
			<input
				className={style.Checkbox__input}
				onChange={props.onChange}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				id={props.id}
				name={props.name}
				type="checkbox"
				checked={props.checked}
			/>
			<span className={style.Checkbox__checkbox}></span>
		</label>
	);
}
