export const CREATE_ACCOUNT_ROUTE = '/create-account';
export const EMAIL_CONFIRMATION_ROUTE = '/email-confirmation';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const LOGIN_ROUTE = '/login';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const SYNC_2FA_ROUTE = '/sync-2fa';
export const VERIFY_2FA_ROUTE = '/verify-2fa';
export const WAIT_FOR_EMAIL_ROUTE = '/wait-for-email';
export const ERROR_ROUTE = '/error';
export const CONTACT_US = '/contact-us';
export const ACCEPT_INVITE_ROUTE = '/accept-invite';
export const EXPIRED_INVITE_LINK_ROUTE = '/expired-invite-link';

// Dashboard
export const DASHBOARD_ROUTE = '/dashboard';
export const OVERVIEW_ROUTE = '/dashboard/overview';
export const PROFILE_ROUTE = '/dashboard/profile';

export const USERS_ROUTE = '/dashboard/users';
export const USER_ROUTE = '/dashboard/users/:id';
export const USER_DETAILS_ROUTE = '/dashboard/users/:id/details';
export const USER_CARDS_ROUTE = '/dashboard/users/:id/cards';
export const USER_TRANSACTIONS_ROUTE = '/dashboard/users/:id/transactions';

export const TRANSACTIONS_ROUTE = '/dashboard/transactions';
export const TRANSACTION_ROUTE = '/dashboard/transactions/:id';

export const CARDS_ROUTE = '/dashboard/cards';
export const CARD_ROUTE = '/dashboard/cards/:id';

// Dashboard settings
export const SETTINGS_ROUTE = '/dashboard/settings';
export const SETTINGS_ACCOUNTS_ROUTE = '/dashboard/settings/accounts';
export const SETTINGS_BUSINESS_ROUTE = '/dashboard/settings/business';
export const SETTINGS_TRANSACTIONS_ROUTE = '/dashboard/settings/transactions';
export const SETTINGS_TEAM_ROUTE = '/dashboard/settings/team';
export const SETTINGS_DOCUMENTS_ROUTE = '/dashboard/settings/documents';

// Dashboard billing
export const BILLING_ROUTE = '/dashboard/billing';
export const BILLING_PLAN_SUMMARY_ROUTE = '/dashboard/billing/plan-summary';
export const BILLING_BALANCES_ROUTE = '/dashboard/billing/balances';
export const BILLING_INVOICES_ROUTE = '/dashboard/billing/invoices';
export const BILLING_PAYMENT_METHODS_ROUTE = '/dashboard/billing/payment-methods';
export const BILLING_TRANSACTION_HISTORY_ROUTE = '/dashboard/billing/transaction-history';

// Dashboard/Developers
export const DEVELOPERS_ROUTE = '/dashboard/developers';
export const WEBHOOKS_CREATE_ROUTE = '/dashboard/developers/webhooks/create'; // This URL doesn't match the folder schema but it's more consistent for the UX
export const CREDENTIALS_ROUTE = '/dashboard/developers/credentials';
export const SDKS_ROUTE = '/dashboard/developers/sdk';
export const WEBHOOKS_ROUTE = '/dashboard/developers/webhooks';

//ActivateAccount
export const ACTIVATE_ACCOUNT_ROUTE = '/activate-account';
export const ACTIVATE_ACCOUNT_OVERVIEW_ROUTE = '/activate-account/overview';
export const ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE = '/activate-account/company-info';
export const ACTIVATE_ACCOUNT_OWNERS_ROUTE = '/activate-account/owners';
export const ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE = '/activate-account/company-address';
export const ACTIVATE_ACCOUNT_VERIFY_PHONE_ROUTE = '/activate-account/verify-phone';
export const ACTIVATE_ACCOUNT_CARD_ROUTE = '/activate-account/card';
export const ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE = '/activate-account/signed-payloads';
export const ACTIVATE_ACCOUNT_LEGAL_ROUTE = '/activate-account/legal';
export const ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE = '/activate-account/compliance';
export const ACTIVATE_ACCOUNT_BILLING_ROUTE = '/activate-account/next-steps';

// Pending activation
export const PENDING_ACTIVATION_ROUTE = '/pending-activation'; // This route doesn't belong to activate account!

// Successful activation
export const SUCCESSFUL_ACTIVATION_ROUTE = '/successful-activation'; // This route doesn't belong to activate account!

// LoadFunds
export const LOAD_FUNDS_ROUTE = '/load-funds';
export const LOAD_FUNDS_LOAD_ROUTE = '/load-funds/load';
export const LOAD_FUNDS_REVIEW_ROUTE = '/load-funds/review';
export const LOAD_FUNDS_CONFIRM_ROUTE = '/load-funds/confirm';

// PushFunds
export const PUSH_FUNDS_LOAD_ROUTE = '/push-funds/:cardholderId/load';
export const PUSH_FUNDS_REVIEW_ROUTE = '/push-funds/:cardholderId/review';
export const PUSH_FUNDS_CONFIRM_ROUTE = '/push-funds/:cardholderId/confirm';

// Diagnostics

export const DIAGNOSTICS_ROUTE = '/diagnostics';
