import { CARDS_ROUTE, USERS_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router-dom';

export default function useTransactionPageSuccess() {
	const navigate = useNavigate();
	const { isProduction } = useSession();

	function onCardClicked(id: string) {
		navigate(`${CARDS_ROUTE}/${id}`);
	}

	function onUserClicked(id: string) {
		navigate(`${USERS_ROUTE}/${id}`);
	}

	return {
		isProduction,
		onCardClicked,
		onUserClicked,
	};
}
