import Toast from 'components/Toast/Toast';
import { worker } from 'mocks/browser';
import IntlRelativeTimeFormatPolyfill from 'polyfills/IntlRelativeTimeFormat';
import FFProvider from 'providers/FFProvider';
import SessionProvider from 'providers/Session.provider';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter as Router } from 'react-router-dom';
import sentryService from 'services/sentry.service';
import Modal from 'styleguide/Modal/Modal';
import 'styleguide/utilities/_spacing.scss';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

if (process.env.NODE_ENV === 'development') {
	worker.start();
	(window as any).__MSW__STOP = worker.stop;
	(window as any).__MSW__START = worker.start;
}

async function init() {
	sentryService.init();

	// Polyfill for Safari
	await IntlRelativeTimeFormatPolyfill();

	ReactDOM.render(
		<sentryService.ErrorBoundary>
			<SessionProvider>
				<FFProvider>
					<QueryClientProvider client={queryClient}>
						<Router>
							<App />
							<Modal />
							<Toast />
						</Router>
					</QueryClientProvider>
				</FFProvider>
			</SessionProvider>
		</sentryService.ErrorBoundary>,
		document.getElementById('root')
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
}

init();
