import React from 'react';
import style from './Dot.module.scss';

export interface IDotProps {
  /**
   * Optional to set the color
   *
   * @default gray
   */
  type?: 'positive' | 'negative';
}

export default function Dot(props: IDotProps) {
  const className = _getClassName(props);
  return <span className={className}></span>;
}

function _getClassName(props: IDotProps) {
  switch (props.type) {
    case 'positive':
      return style['Dot--positive'];
    case 'negative':
      return style['Dot--negative'];
    default:
      return style['Dot'];
  }
}
