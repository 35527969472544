import CardsSelect from 'components/CardsSelect/CardsSelect';
import CardsSelectItem from 'components/CardsSelect/components/CardsSelectItem';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import IPaymentMethod from 'types/IPaymentMethod';
import AddPaymentMethodItem from './components/AddPaymentMethodItem/AddPaymentMethodItem';
import style from './PaymentMethodSelect.module.scss';
import usePaymentMethodSelect from './usePaymentMethodSelect';

export interface IPaymentMethodSelectProps {
	paymentMethods: IPaymentMethod[];
	selectedPaymentId?: string;
	onAddPaymentMethodClick?: () => void;
	onPaymentMethodClick: (paymentMethodId: string) => void;
}

export default function PaymentMethodSelect(props: IPaymentMethodSelectProps) {
	const { isExpanded, setExpanded, selectedPaymentMethod, handlePaymentMethod } = usePaymentMethodSelect(props);

	return (
		<CardsSelect
			isExpanded={isExpanded}
			selectedNode={
				selectedPaymentMethod ? (
					<CardsSelectItem
						key={selectedPaymentMethod.id}
						id={selectedPaymentMethod.id}
						ariaExpanded={isExpanded}
						ariaHaspopup="listbox"
						ariaLabel="Choose your preferred payment method:"
						description={selectedPaymentMethod.description}
						isSelectedItem
						network={selectedPaymentMethod.cardNetwork}
						onItemClick={() => setExpanded(!isExpanded)}
						typeOfCard="external"
						value={selectedPaymentMethod.lastFour}
					/>
				) : (
					<button type="button" onClick={() => setExpanded(true)} className={style.PaymentMethodSelect__placeholder}>
						<Label size="small" color="contentPrimary">
							No method selected
						</Label>
					</button>
				)
			}
			dropdownContent={
				<>
					<View>
						{props.paymentMethods.map((paymentMethod) => (
							<CardsSelectItem
								key={paymentMethod.id}
								id={paymentMethod.id}
								ariaSelected={paymentMethod.id === props.selectedPaymentId}
								description={paymentMethod.description}
								network={paymentMethod.cardNetwork}
								onItemClick={() => handlePaymentMethod(paymentMethod.id)}
								typeOfCard="external"
								value={paymentMethod.lastFour}
							/>
						))}
					</View>
					<AddPaymentMethodItem onAddPaymentMethodClick={props.onAddPaymentMethodClick} />
				</>
			}
		></CardsSelect>
	);
}
