import classnames from 'classnames';
import React from 'react';
import View from 'styleguide/View/View';
import style from './Details.module.scss';

export interface IDetailsProps {
	content: React.ReactNode;
	isExpanded: boolean;
	setExpanded: (isExpanded: boolean) => void;
	summary: React.ReactNode;
}

export default function Details(props: IDetailsProps) {
	if (props.isExpanded) {
		return (
			<View className={classnames(style.Details, style['Details--isExpanded'])}>
				<View className={style.Details__Summary} onClick={() => props.setExpanded(!props.isExpanded)}>
					{props.summary}
				</View>
				<View className={style.Details__Content}>{props.content}</View>
			</View>
		);
	}

	return (
		<View className={style.Details}>
			<View className={style.Details__Summary} onClick={() => props.setExpanded(!props.isExpanded)}>
				{props.summary}
			</View>
		</View>
	);
}
