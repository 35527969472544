import { LOGIN_ROUTE } from 'constants/routes';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import View from 'styleguide/View/View';
import ConfirmationStep, { IPushFundsStatus } from './components/ConfirmationStep/ConfirmationStep';
import LoadStep from './components/LoadStep/LoadStep';
import PushFundsSidebar from './components/PushFundsSidebar/PushFundsSidebar';
import ReviewStep from './components/ReviewStep/ReviewStep';
import style from './PushFundsPage.module.scss';
import usePushFunds from './usePushFunds';

export default function PushFundsRootPage() {
	const {
		amount,
		cardholderId,
		cardId,
		currency,
		designKey,
		editUrl,
		environment,
		handleDoneConfirmationStep,
		handleLoadStepNextClick,
		handleReviewStepConfirmClick,
		isButtonLoading,
		isCardIdPrefilled,
		isLoggedIn,
		lastFour,
		network,
		pushDate,
		pushStatus,
		serverError,
	} = usePushFunds();

	if (!isLoggedIn) {
		// TODO: Save route and redirect to saved route once logged in
		return <Navigate to={LOGIN_ROUTE} />;
	}

	return (
		<View className={style.PushFundsPage}>
			<aside className={style.PushFundsPage__aside}>
				<PushFundsSidebar />
			</aside>
			<main className={style.PushFundsPage__content}>
				<Routes>
					<Route
						path="load"
						element={
							<LoadStep
								amount={amount}
								cardholderId={cardholderId}
								cardId={cardId}
								currency={currency}
								isAptoCardSelectDisabled={isCardIdPrefilled}
								handleNextClick={handleLoadStepNextClick}
							/>
						}
					/>
					<Route
						path="review"
						element={
							<ReviewStep
								amount={amount}
								cardholderId={cardholderId}
								cardId={cardId}
								currency={currency}
								designKey={designKey}
								editUrl={editUrl}
								environment={environment}
								lastFour={lastFour}
								loading={isButtonLoading}
								network={network}
								onConfirmClick={handleReviewStepConfirmClick}
								serverError={serverError}
							/>
						}
					/>
					{/* TODO: This should come from the result of the hook */}
					<Route
						path="confirm"
						element={
							<ConfirmationStep
								amount={amount}
								lastFour={lastFour}
								currency={currency}
								onDoneClick={handleDoneConfirmationStep}
								network={network}
								createdAt={pushDate}
								status={pushStatus as IPushFundsStatus}
								designKey={designKey}
							/>
						}
					/>
				</Routes>
			</main>
		</View>
	);
}
