import apiclientService from 'api-client/apiclient.service';
import { ICurrency } from 'constants/currencies';
import {
	DASHBOARD_ROUTE,
	PUSH_FUNDS_CONFIRM_ROUTE,
	PUSH_FUNDS_LOAD_ROUTE,
	PUSH_FUNDS_REVIEW_ROUTE,
} from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import useSession from 'hooks/useSession';
import { useNavigate, useParams } from 'react-router';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IServerRequestState from 'types/network/IServerRequest';
import IEnvironment from 'types/session/IEnvironment';
import usePureState from 'utils/usePureState';
import { IPushFundsStatus } from './components/ConfirmationStep/ConfirmationStep';
import { IHandleNextClickArgs } from './components/LoadStep/LoadStep';

interface IUsePushFundsState extends IServerRequestState {
	amount: string;
	cardholderId: string;
	cardId: string;
	currency: ICurrency;
	designKey: IAptoCardDesignKey;
	environment: IEnvironment;
	isCardIdPrefilled: boolean;
	lastFour: string;
	network: IAptoCardNetwork;
	pushDate?: Date;
	pushStatus?: IPushFundsStatus;
}

export default function usePushFunds() {
	const { isLoggedIn, environment } = useSession();
	const navigate = useNavigate();
	const searchParams = useQueryParams();
	const { cardholderId } = useParams() as { cardholderId: string };
	const cardId = searchParams.get('cardId') || '';

	const { state, dispatch } = usePureState<IUsePushFundsState>({
		amount: '',
		cardholderId: cardholderId,
		cardId: cardId,
		currency: 'USD',
		designKey: 'black',
		environment: environment,
		isCardIdPrefilled: !!cardId,
		lastFour: '',
		network: 'MASTERCARD',
		requestStatus: 'IDLE',
		serverError: '',
		pushDate: undefined,
		pushStatus: undefined,
	});

	function handleLoadStepNextClick(args: IHandleNextClickArgs) {
		dispatch({ ...args, serverError: '' });
		navigate(PUSH_FUNDS_REVIEW_ROUTE.replace(':cardHolderId', cardholderId));
	}

	function handleReviewStepConfirmClick() {
		dispatch({ requestStatus: 'PENDING', serverError: '' });

		return apiclientService.accounts
			.pushFunds({
				environment: state.environment,
				amount: parseFloat(state.amount),
				currency: state.currency,
				cardId: state.cardId,
			})
			.then((res) => {
				// TODO: Update this logic when the api-spec is ready
				dispatch({ requestStatus: 'SUCCESS', pushStatus: 'processed', pushDate: new Date() });
				navigate(PUSH_FUNDS_CONFIRM_ROUTE.replace(':cardHolderId', cardholderId), { replace: true });
			})
			.catch((error) => {
				// TODO: Add other controlled error cases
				// Error_Failed_Balance_Transfer_Error
				if (error.code === 200105) {
					dispatch({ requestStatus: 'SUCCESS', pushStatus: 'failed' });
					return navigate(PUSH_FUNDS_CONFIRM_ROUTE.replace(':cardHolderId', cardholderId), { replace: true });
				}

				dispatch({ requestStatus: 'FAILED', serverError: error.message });
			});
	}

	function getReviewStepEditLocation(): string {
		if (state.isCardIdPrefilled) {
			searchParams.set('cardId', state.cardId);
			return `${PUSH_FUNDS_LOAD_ROUTE.replace(':cardholderId', state.cardholderId)}?${searchParams.toString()}`;
		}

		return PUSH_FUNDS_LOAD_ROUTE.replace(':cardholderId', state.cardholderId);
	}

	return {
		...state,
		handleDoneConfirmationStep: () => navigate(DASHBOARD_ROUTE),
		handleLoadStepNextClick,
		handleReviewStepConfirmClick,
		navigate,
		editUrl: getReviewStepEditLocation(),
		isButtonLoading: state.requestStatus === 'PENDING',
		isLoggedIn,
		searchParams,
	};
}
