import classnames from 'classnames';
import CompanyPanel from 'components/CompanyPanel/CompanyPanel';
import CompanyToggle from 'components/CompanyToggle/CompanyToggle';
import InitialsService from 'components/Initials/Initials.service';
import Profile from 'components/Profile/Profile';
import useSession from 'hooks/useSession';
import { useState } from 'react';
import Pill from 'styleguide/Pill/Pill';
import View from 'styleguide/View/View';
import style from './Header.module.scss';

export interface IHeaderProps {
	firstName: string;
	lastName: string;
	onLogout: () => Promise<any>;
}

export default function Header(props: IHeaderProps) {
	const { companyName } = useSession();
	const [isProfileVisible, setProfileVisible] = useState(false);
	const [isCompanyProfileHidden, setIsCompanyProfileHidden] = useState(true);

	return (
		<header className={style.Header}>
			<CompanyPanel
				companyName={companyName}
				onCloseClicked={() => setIsCompanyProfileHidden(true)}
				isHidden={isCompanyProfileHidden}
			/>
			<View className={style.Header__company}>
				<CompanyToggle name={companyName} handleClick={() => setIsCompanyProfileHidden(!isCompanyProfileHidden)} />
			</View>
			<View className={style.Header__links}>
				<Pill
					type="link"
					level="tertiary"
					size="small"
					external
					to="//docs.aptopayments.com"
					className={style.Header__pill}
				>
					Docs
				</Pill>
				<Pill
					type="link"
					level="tertiary"
					size="small"
					external
					to="mailto:developers@aptopayments.com"
					className={style.Header__pill}
				>
					Help
				</Pill>
				<Pill
					type="button"
					level="tertiary"
					size="small"
					onClick={() => setProfileVisible(true)}
					className={classnames(style['User'], style[`User--${InitialsService.getColor(props.firstName)}`])}
				>
					{props.firstName}
				</Pill>
			</View>
			<Profile isHidden={!isProfileVisible} onCloseClicked={() => setProfileVisible(false)} {...props} />
		</header>
	);
}
