import { IGetCompanyAddressResponse } from 'api-client/activateAccount/companyAddress/companyAddress';
import useApplicationId from 'hooks/useApplicationId';
import { useGetCompanyAddressQuery } from 'queries/activateAccount';

export default function useCompanyAddressStepWithApplicationId() {
	// SafeCast: This component is rendered using a WithApplicationID HOC that guarantees that the applicationId is set
	const applicationId = useApplicationId() as string;

	const companyAddressQuery = useGetCompanyAddressQuery({
		applicationId: applicationId,
		// Prevent background requests when the user focus the page
		refetchOnWindowFocus: false,
	});

	return {
		props: {
			// SafeCast: This data is only used when status is success
			storedValues: companyAddressQuery.data as IGetCompanyAddressResponse,
			applicationId: applicationId,
		},
		status: companyAddressQuery.status,
	};
}
