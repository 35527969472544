/**
 * Preloads an image to be shown instantly on later steps
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content
 */
export default function preloadImage(src: string) {
	const link = document.createElement('link');
	link.setAttribute('rel', 'preload');
	link.setAttribute('as', 'image');
	link.setAttribute('href', src);
	document.head.appendChild(link);
}
