import Notification from 'components/Notification/Notification';
import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import { PropsWithChildren } from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import IPaymentMethod from 'types/IPaymentMethod';
import style from './ModalEditPaymentSourcesSuccess.module.scss';
import useModalEditPaymentSourcesSuccess from './useModalEditPaymentSourcesSuccess';

interface IModalEditPaymentSourcesSuccessProps {
	paymentMethods: IPaymentMethod[];
	onPaymentMethodsEdited: () => void;
}

export default function ModalEditPaymentSourcesSuccess(props: PropsWithChildren<IModalEditPaymentSourcesSuccessProps>) {
	const {
		handleConfirmClick,
		handleFundingChange,
		handleBillingChange,
		billingSelected,
		fundingSelected,
		isButtonDisabled,
		isLoading,
		serverError,
	} = useModalEditPaymentSourcesSuccess(props);

	return (
		<View className={style.ModalEditPaymentSources}>
			<Heading size="small" color="contentPrimary" tag="h1" margin="0 0 14px 0">
				Preferred payment methods
			</Heading>
			<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} />
			<Label size="medium" fontWeight="600" margin="16px 0 8px">
				Developer funding account
			</Label>
			<PaymentMethodSelect
				paymentMethods={props.paymentMethods}
				onPaymentMethodClick={handleFundingChange}
				selectedPaymentId={fundingSelected?.id}
			/>
			<Label size="medium" fontWeight="600" margin="16px 0 8px">
				Billing account
			</Label>
			<PaymentMethodSelect
				paymentMethods={props.paymentMethods}
				onPaymentMethodClick={handleBillingChange}
				selectedPaymentId={billingSelected?.id}
			/>
			<View className={style.ModalEditPaymentSources__footer}>
				<Pill
					loading={isLoading}
					disabled={isButtonDisabled}
					level="primary"
					size="medium"
					onClick={handleConfirmClick}
				>
					Confirm
				</Pill>
			</View>
		</View>
	);
}
