import { useListApiKeysQuery } from 'queries/apiKeys.queries';
import ICoreApiKey from 'types/ICoreApiKey';

export default function useCredentials() {
	const apiKeysQuery = useListApiKeysQuery();

	return {
		props: {
			coreApiKeys: apiKeysQuery.data?.coreApiKeys.data as ICoreApiKey[],
			mobileApiKey: apiKeysQuery.data?.mobileApiKey as string,
			serverError: apiKeysQuery.error?.message || '',
		},
		status: apiKeysQuery.status,
	};
}
