import apiclientService from 'api-client/apiclient.service';
import { CountryCode } from 'libphonenumber-js/min';
import { useState } from 'react';
import validationService from 'services/validationService/validation.service';
import InputPhoneNumberService from 'styleguide/InputPhoneNumber/InputPhoneNumber.service';
import IRequestStatus from 'types/network/IRequestStatus';
import useForm from 'utils/useForm';

export interface IContactUsFields {
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	website: string;
	countryCode: CountryCode;
	phoneNumber: string;
	comment: string;
}

interface IState {
	requestStatus: IRequestStatus;
	networkError: string;
}

export default function useContactUs(onFinishCallback: () => void) {
	const [state, setState] = useState<IState>({
		requestStatus: 'IDLE',
		networkError: '',
	});

	const { submitForm, bind, isButtonEnabled } = useForm<IContactUsFields>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			company: '',
			website: '',
			countryCode: 'US',
			phoneNumber: '',
			comment: '',
		},

		optionalFields: ['countryCode'],

		validate: validationService.createValidator<IContactUsFields>({
			firstName: [validationService.maxLength(40), validationService.name, validationService.required],
			lastName: [validationService.maxLength(40), validationService.name, validationService.required],
			email: [validationService.email, validationService.required],
			company: [validationService.maxLength(40), validationService.required],
			website: [validationService.maxLength(40), validationService.required],
			countryCode: [validationService.required],
			phoneNumber: [validationService.phoneNumber, validationService.required],
			comment: [validationService.maxLength(300), validationService.required],
		}),

		onSubmit: function (values) {
			setState({ ...state, requestStatus: 'PENDING', networkError: '' });

			return apiclientService.contact
				.send({
					name: {
						firstName: values.firstName,
						lastName: values.lastName,
					},
					company: { name: values.company },
					phone: {
						country_code: InputPhoneNumberService.getDialCode(values.countryCode),
						phone_number: values.phoneNumber,
					},
					email: values.email,
					website: values.website,
					description: values.comment,
				})
				.then(() => {
					setState({ ...state, requestStatus: 'SUCCESS', networkError: '' });
					onFinishCallback();
				})
				.catch((err) => {
					setState({
						...state,
						requestStatus: 'FAILED',
						networkError: err.message,
					});
				});
		},
	});

	return {
		bind,
		isButtonDisabled: !isButtonEnabled,
		isNetworkError: state.networkError !== '',
		networkError: state.networkError,
		submitForm,
	};
}
