import { useListPaymentSourcesQuery } from 'queries/paymentSources';
import IPaymentMethod from 'types/IPaymentMethod';

interface IUseModalEditPaymentSourcesArgs {
	onPaymentMethodsEdited: () => void;
}

export default function useModalEditPaymentSources(args: IUseModalEditPaymentSourcesArgs) {
	const listPaymentSourcesQuery = useListPaymentSourcesQuery();

	return {
		status: listPaymentSourcesQuery.status,
		props: {
			// SafeCast: We only use this value when status is `success`
			paymentMethods: listPaymentSourcesQuery.data as IPaymentMethod[],
			onPaymentMethodsEdited: args.onPaymentMethodsEdited,
		},
	};
}
