import apiClientService from 'api-client/apiclient.service';
import { IListCardholdersResponse, ISummaryUserResponse } from 'api-client/cardholders/cardholders';
import { queryForItemsInThePreviousPage } from 'hooks/usePagination';
import useSession from 'hooks/useSession';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import IPaginationConfig from 'types/IPaginationConfig';
import IError from 'types/network/IError';

const QK_USERS = 'USERS';

interface IUseListUsersQueryArgs extends UseQueryOptions<IListUsersQueryResponse, IError> {
	paginationConfig: IPaginationConfig;
}

export interface IListUsersQueryResponse extends IListCardholdersResponse {
	hasMoreBefore: boolean;
}

export function useListUsersQuery(args?: IUseListUsersQueryArgs) {
	const { environment } = useSession();

	return useQuery<IListUsersQueryResponse, IError>({
		queryKey: [environment, QK_USERS, 'list', args?.paginationConfig],
		queryFn: async () => {
			const res = await apiClientService.cardholders.read({
				paginationConfig: args?.paginationConfig,
				environment,
			});

			// We need this because the server doesn't reply with the hasMoreBefore pagination info :/
			const hasMoreBefore = await queryForItemsInThePreviousPage(
				res.cardholders[0],
				(paginationConfig) =>
					apiClientService.cardholders.read({
						paginationConfig: paginationConfig.paginationConfig,
						environment,
					}),
				'cardholders'
			);

			const curatedResponse = _getCuratedResponse(res);

			return { ...curatedResponse, hasMoreBefore };
		},
		...args,
	});
}

interface IUseGetUserQueryArgs extends UseQueryOptions<ISummaryUserResponse, IError> {
	id: string;
}

export function useGetUserQuery({ id, ...args }: IUseGetUserQueryArgs) {
	const { environment } = useSession();
	return useQuery<ISummaryUserResponse, IError>({
		queryKey: [environment, QK_USERS, 'details', id],
		queryFn: () =>
			apiClientService.cardholders.getSummary({
				id,
				environment,
			}),
		...args,
	});
}

interface IUseRemoveUserMutationArgs extends UseMutationOptions<void, IError, string> {}

export function useRemoveUserMutation(args?: IUseRemoveUserMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<void, IError, string>({
		mutationFn: async (userId: string) => {
			await apiClientService.cardholders.remove({
				id: userId,
				environment,
			});
			_markUserAsDeleted(userId);
		},
		onSuccess: async (data, userId, context) => {
			queryClient.removeQueries([environment, QK_USERS, 'list']);
			queryClient.removeQueries([environment, QK_USERS, 'details', userId]);
			args?.onSuccess?.(data, userId, context);
		},
		...args,
	});
}

/*
 * OPTIMISTIC UPDATES.
 * Our STARGATE API has a race condition that makes the read-nodes to be updated a few seconds
 * after something is changed.
 *
 * This means we can delete an user and if we list the users again the user still appears in the list.
 * In order to prevent this we do optimistic updates.
 *
 * We use a Set to keep track of users that were deleted client side. And using this information we can filter
 * the user list we got from the server and ignore users we know they were deleted.
 */
const deletedUserIds = new Set<string>();

/**
 * Add the userId to the deletedUserIds Set.
 * This function will clean the ID from the set after 5 seconds
 */
function _markUserAsDeleted(userId: string) {
	deletedUserIds.add(String(userId));
	setTimeout(() => {
		deletedUserIds.delete(userId);
	}, 5000);
}

/**
 * Filters the server response updating the cardholders and the length.
 */
function _getCuratedResponse(res: IListCardholdersResponse): IListCardholdersResponse {
	return {
		hasMore: res.hasMore,
		total: res.total - deletedUserIds.size,
		cardholders: res.cardholders.filter((cardholder) => !deletedUserIds.has(String(cardholder.id))),
	};
}
