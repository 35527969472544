import useGetPaginationConfig from 'hooks/useGetPaginationConfig';
import { useListUsersQuery } from 'queries/users.queries';
import ICardholder from 'types/cardholders/ICardholder';

export default function useUsersPage() {
	const paginationConfig = useGetPaginationConfig();
	const usersQuery = useListUsersQuery({ paginationConfig });

	return {
		props: {
			// SafeCast: We are using WithQuery
			cardholders: usersQuery.data?.cardholders as ICardholder[],
			hasMore: usersQuery.data?.hasMore as boolean,
			hasMoreBefore: usersQuery.data?.hasMoreBefore as boolean,
			total: usersQuery.data?.total as number,
			serverError: usersQuery.error?.message || '',
		},
		status: usersQuery.status,
	};
}
