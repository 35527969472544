import ModalInviteMember from 'components/ModalInviteMember/ModalInviteMember';
import React from 'react';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import style from './TeamHeader.module.scss';

export default function TeamHeader() {
	return (
		<View className={style.TeamHeader}>
			<Heading size="small" tag="h2" margin="0 20px 0 0">
				Members
			</Heading>
			<Pill level="primary" size="small" onClick={_showModalInviteMember}>
				Invite
			</Pill>
		</View>
	);
}

function _showModalInviteMember() {
	Modal.show(<ModalInviteMember />, {
		canBeClosedOnClickOut: false,
		ariaTitle: 'Invite team members',
	});
}
