import {
	ACTIVATE_ACCOUNT_BILLING_ROUTE,
	ACTIVATE_ACCOUNT_CARD_ROUTE,
	ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE,
	ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE,
	ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE,
	ACTIVATE_ACCOUNT_LEGAL_ROUTE,
	ACTIVATE_ACCOUNT_OWNERS_ROUTE,
	ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE,
} from 'constants/routes';
import useSession from 'hooks/useSession';
import { useCreateApplicationMutation } from 'queries/activateAccount';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IApplicationStatus from 'types/session/IApplicationStatus';

export default function useOverviewStep() {
	const navigate = useNavigate();
	const { setLastApplicationId, lastApplicationStatus } = useSession();
	const createApplicationMutation = useCreateApplicationMutation();

	const lastApplicationStepRoute = _getLastApplicationStepRoute(lastApplicationStatus);
	useEffect(() => {
		if (lastApplicationStepRoute) {
			navigate(lastApplicationStepRoute);
		}
	}, []); // eslint-disable-line

	function handleNextClick() {
		createApplicationMutation.mutate(undefined, {
			onSuccess: (response) => {
				setLastApplicationId(response.id);
				navigate(ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE);
			},
		});
	}

	return {
		handleNextClick,
		isLoading: createApplicationMutation.isLoading,
		lastApplicationStepRoute,
		serverError: createApplicationMutation.error?.message || '',
	};
}

function _getLastApplicationStepRoute(lastApplicationStatus?: IApplicationStatus) {
	if (!lastApplicationStatus) {
		return null;
	}

	switch (lastApplicationStatus) {
		case 'company_info':
			return ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE;
		case 'business_owners':
			return ACTIVATE_ACCOUNT_OWNERS_ROUTE;
		case 'company_contacts':
			return ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE;
		case 'verify_company_phone':
			return ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE;
		case 'card_design':
			return ACTIVATE_ACCOUNT_CARD_ROUTE;
		case 'signed_payloads':
			return ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE;
		case 'program_agreement':
			return ACTIVATE_ACCOUNT_LEGAL_ROUTE;
		case 'compliance':
			return ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE;
		case 'billing':
			return ACTIVATE_ACCOUNT_BILLING_ROUTE;
	}
}
