import ContentHeader from 'components/ContentHeader/ContentHeader';
import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';

export default function CardDetailsFailed(props: { serverError: string }) {
	return (
		<>
			<ContentHeader title="Cards" />
			<PaddingContainer>
				<Notification type="negative" margin="24px 0" title="Error" description={props.serverError} />
			</PaddingContainer>
		</>
	);
}
