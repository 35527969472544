import React, { useState } from 'react';
import validationService from 'services/validationService/validation.service';
import Modal from 'styleguide/Modal/Modal';
import IServerRequestState from 'types/network/IServerRequest';
import useForm from 'utils/useForm';
import ModalRequestSent from '../ModalRequestSent/ModalRequestSent';

export default function useModalDeactivateAccount() {
	const [state, setState] = useState<IServerRequestState>({
		requestStatus: 'IDLE',
		serverError: '',
	});

	const { submitForm, isSubmitting, bind, isButtonEnabled, values } = useForm({
		initialValues: {
			deactivate: '',
		},
		onSubmit: function (values, actions) {
			// TODO: Send request to backend and show modal on success
			_simulateServerRequest().then(_handleDeactivateAccountSuccess).catch(_handleDeactivateAccountError);
		},
		validate: validationService.createValidator({
			deactivate: [
				validationService.required,
				(value, touched) => (touched && value !== 'DEACTIVATE' ? 'Type DEACTIVATE to confirm' : ''),
			],
		}),
	});

	function showModalRequestSent() {
		Modal.show(<ModalRequestSent onDoneClick={Modal.hide} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Request sent',
		});
	}

	function _simulateServerRequest() {
		return Promise.resolve();
	}

	function _handleDeactivateAccountSuccess() {
		showModalRequestSent();
	}

	function _handleDeactivateAccountError(result: Error) {
		setState((s) => ({ ...s, serverError: result.message, requestStatus: 'FAILED' }));
	}

	return {
		bind,
		isButtonDisabled: !isButtonEnabled,
		isLoading: isSubmitting,
		serverError: state.serverError,
		submitForm,
		values,
	};
}
