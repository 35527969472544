// https://apto-api-specs.netlify.app/private/stargateapi#operation/accept_invitation
export default {
	invitee: {
		exists: true,
		email_address: 'john@acme.com',
	},
	developer_account: {
		company_name: 'ACME',
	},
};
