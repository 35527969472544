import Label from 'styleguide/Type/Label/Label';
import style from './CardSelector.module.scss';
import ICardSelectorOption from './ICardSelectorOption';

export interface ICardSelectorProps {
	option: ICardSelectorOption;
	description: string;
	isActive: boolean;
	onClick: (type: ICardSelectorOption) => void;
}

export default function CardSelector(props: ICardSelectorProps) {
	const className = props.isActive ? style['CardSelector--active'] : style.CardSelector;
	return (
		<label tabIndex={0} htmlFor={props.option} className={className}>
			<input
				className={style.CardSelector__input}
				onChange={() => props.onClick(props.option)}
				type="radio"
				id={props.option}
				name="cardType"
				value={props.option}
				checked={props.isActive}
			/>
			<Label size="medium" fontWeight="600">
				{props.description}
			</Label>
		</label>
	);
}
