import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import Grid from 'styleguide/Grid/Grid/Grid';
import GridItem from 'styleguide/Grid/Item/Item';
import View from 'styleguide/View/View';
import { IUseDocumentsSectionReturnedProps } from '../useDocumentsSection';
import Header from './Header/Header';
import LegalItem from './LegalItem/LegalItem';

export default function DocumentsSuccess(props: IUseDocumentsSectionReturnedProps) {
	return (
		<View data-testid="page-team">
			<PaddingContainer>
				<Header />
			</PaddingContainer>
			<Grid cols="12" isNested>
				<GridItem colWidth="12" colWidthLg="12">
					<LegalItem text="Platform Pricing" url={props.agreements.instantIssuancePricing} />
					{props.agreements.cardProgramAgreement ? (
						<LegalItem text="Apto Card Program Agreement" url={props.agreements.cardProgramAgreement} />
					) : null}
					{props.agreements.bankTermsAgreement ? (
						<LegalItem text="Patriot Customer Account Terms" url={props.agreements.bankTermsAgreement} />
					) : null}
					<LegalItem text="Patriot Privacy Policy" url={props.agreements.bankPrivacyPolicy} />
					<LegalItem text="Apto Terms of Service" url={props.agreements.aptoTermsOfService} />
					<LegalItem text="Apto Privacy Policy" url={props.agreements.aptoPrivacyPolicy} />
				</GridItem>
			</Grid>
		</View>
	);
}
