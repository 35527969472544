import apiFetch from 'api-client/utils/fetch';
import toCamelcase from 'api-client/utils/toCamelCase';
import IDeveloperAccount from 'types/IDeveloperAccount';

const DEVELOPER_ACCOUNTS_ENDPOINT = `/developer/developer_accounts`;
const SET_DEFAULT_DEVELOPER_ACCOUNT_ENDPOINT = `/developer/developer_accounts/default`;

export interface ICreateDeveloperAccountPayload {
	companyName: string;
}

export async function create(payload: ICreateDeveloperAccountPayload) {
	return apiFetch(DEVELOPER_ACCOUNTS_ENDPOINT, 'POST', {
		company_name: payload.companyName,
	});
}

export interface IDeveloperAccountListResponse {
	accountOwner: boolean;
	developerAccounts: Array<IDeveloperAccount>;
}

export async function list(): Promise<IDeveloperAccountListResponse> {
	return apiFetch(DEVELOPER_ACCOUNTS_ENDPOINT, 'GET').then(toCamelcase);
}

export interface IUpdateDeveloperAccountPayload {
	developerAccountId: string;
}

export async function update(payload: IUpdateDeveloperAccountPayload) {
	return apiFetch(SET_DEFAULT_DEVELOPER_ACCOUNT_ENDPOINT, 'POST', {
		developer_account_id: payload.developerAccountId,
	});
}

export default {
	create,
	list,
	update,
};
