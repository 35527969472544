import apiclientService from 'api-client/apiclient.service';
import { useState } from 'react';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

export default function useForgotPassword() {
	const [serverError, setServerError] = useState('');
	const [serverMessage, setServerMessage] = useState('');
	const [isLinkSent, setIsLinkSent] = useState(false);

	const { errors, values, handleChange, handleBlur, submitForm, isSubmitting, handleFocus, isButtonEnabled } = useForm({
		initialValues: { email: '' },
		onSubmit: function (values) {
			return apiclientService.auth
				.forgotPassword(values.email)
				.then(() => {
					setIsLinkSent(true);
					setServerMessage(
						` A link to reset your password will be sent to ${values.email} if that email exists in our system. Please check your email to continue.`
					);
				})
				.catch((error) => {
					if (error.code === 90278) {
						return setServerMessage(
							`A link to reset your password will be sent to ${values.email} if that email exists in our system. Please check your email to continue.`
						);
					}

					setServerError(error.message);
				});
		},
		validate: validationService.createValidator({
			email: [validationService.required, validationService.email],
		}),
	});

	return {
		errors,
		handleBlur,
		handleChange,
		handleFocus,
		isButtonDisabled: !isButtonEnabled || isLinkSent,
		isLoading: isSubmitting,
		requestPasswordReset: submitForm,
		serverError,
		serverMessage,
		values,
	};
}
