import WithQuery from 'utils/WithQuery';
import TeamsListSuccess, { TeamsListSuccessProps } from './components/TeamsListSuccess';
import useTeamsList from './useTeamsList';

export interface ITeamsListProps {
	companyName: string;
	handleSwitchCompany: (teamId: string) => void;
}

export default WithQuery<ITeamsListProps, TeamsListSuccessProps>({
	Component: TeamsListSuccess,
	useHook: useTeamsList,
});
