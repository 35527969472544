import classnames from 'classnames';
import React from 'react';
import Heading from 'styleguide/Type/Heading/Heading';
import style from './SectionTitle.module.scss';

interface ISectionTitleProps {
	borderTop?: boolean;
	marginTopCorrection?: boolean;
	title: string;
}

export default function SectionTitle(props: ISectionTitleProps) {
	return (
		<Heading
			size="x-small"
			tag="h2"
			className={classnames(style.SectionTitle, {
				[`${style['SectionTitle--borderTop']}`]: props.borderTop,
				[`${style['SectionTitle--marginTopCorrection']}`]: props.marginTopCorrection,
			})}
		>
			{props.title}
		</Heading>
	);
}
