import { IBillingAccount, IFundingAccount } from 'api-client/accounts/types/IAccountDetailsResponse';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import View from 'styleguide/View/View';
import style from './AccountsSectionSuccess.module.scss';
import FundingAccount from './components/FundingAccount/FundingAccount';

interface IAccountsSectionSuccessProps {
	billingAccount: IBillingAccount;
	fundingAccount: IFundingAccount;
}

export default function AccountsSectionSuccess(props: IAccountsSectionSuccessProps) {
	return (
		<View className={style.AccountsSectionSuccess}>
			<Grid cols="12" isNested>
				<Item colWidth="12" colWidthLg="8" maxWidth="668px">
					<PaddingContainer>
						<FundingAccount
							id={props.fundingAccount.id}
							amount={props.fundingAccount.balance.amount}
							currency={props.fundingAccount.balance.currency}
							accountNumber={props.fundingAccount.achAccount.accountNumber}
							routingNumber={props.fundingAccount.achAccount.routingNumber}
						/>
					</PaddingContainer>
				</Item>
			</Grid>
		</View>
	);
}
