import CardStatus from 'components/cards/CardStatus/CardStatus';
import LastFour from 'components/cards/LastFour/LastFour';
import Table from 'styleguide/Table/Table';
import IAptoCard from 'types/aptoCards/IAptoCard';
import IAptoCardStatus from 'types/aptoCards/IAptoCardStatus';

interface ICardsSectionProps {
	cards: IAptoCard[];
	isProduction: boolean;
	onClick: (id: string) => void;
}

export default function CardsSection(props: ICardsSectionProps) {
	const COLUMNS = [
		{
			key: 'lastFour',
			name: 'Card Number',
			renderer: (value: string, item: IAptoCard) => _lastFourRenderer(value, item, props.isProduction),
		},
		{ key: 'status', name: 'Status', renderer: _statusRenderer },
		{ key: 'id', name: 'Card ID' },
	];

	return (
		<>
			<Table
				data={props.cards}
				columns={COLUMNS}
				onRowClick={(item) => props.onClick(item.id)}
				emptyTableMessage="You haven't created any cards yet."
			/>
		</>
	);
}

function _lastFourRenderer(value: string, item: IAptoCard, isProduction: boolean) {
	return (
		<LastFour
			value={value}
			designKey={item.designKey}
			network={item.network}
			iconSize="small"
			typeOfCard={isProduction ? 'apto' : 'placeholder'}
		/>
	);
}

function _statusRenderer(value: IAptoCardStatus) {
	return <CardStatus value={value} />;
}
