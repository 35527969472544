const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const TWO_DAYS = DAY * 2;
const LOCALE = 'en-US';

export interface IDateTimeFormat {
	isTimeAgo?: boolean;
	includeHourAndMinute?: boolean;
}

/**
 * Format a date object as a string
 * @param {Date} date The date to format
 * @param {IDateTimeFormat} config Configuration options for the date format
 * @param {boolean} config.isTimeAgo Format date relative to current datetime. Eg: "5 seconds ago"
 * @param {boolean} config.includeHourAndMinute Should the time (HH:MM AM/PM) be included
 */

function format(date: Date, config: IDateTimeFormat = {}) {
	if (config.isTimeAgo) {
		return _formatTimeAgo(date, config.includeHourAndMinute);
	}

	return _formatStandard(date, config.includeHourAndMinute);
}

/**
 * Formats a date relative to current datetime. Eg: 5 seconds/minutes/hours, for older dates, returns standard format
 * @param {Date} date - The date to format
 * @param {boolean} includeHourAndMinute - Should the time (HH:MM AM/PM) be included
 */

function _formatTimeAgo(date: Date, includeHourAndMinute = false) {
	const secondsFromNow = _getSecondsFromNow(date.getTime());

	if (_isToday(secondsFromNow)) {
		return _getTimeAgo(secondsFromNow);
	}

	if (_isYesterday(secondsFromNow)) {
		return includeHourAndMinute ? `${_getTimeAgo(secondsFromNow)}, ${_getTime(date)}` : _getTimeAgo(secondsFromNow);
	}

	if (_isThisYear(date)) {
		const MMDD = new Intl.DateTimeFormat(LOCALE, {
			month: 'short',
			day: '2-digit',
		}).format(date);

		return includeHourAndMinute ? `${MMDD}, ${_getTime(date)}` : MMDD;
	}

	return _formatStandard(date, includeHourAndMinute);
}

/**
 * Formats a date in format MMM DD, YYYY
 * @param {Date} date - The date to format
 * @param {boolean} includeHourAndMinute - Should the time (HH:MM AM/PM) be included
 */

function _formatStandard(date: Date, includeHourAndMinute = false) {
	const standard = new Intl.DateTimeFormat(LOCALE, { dateStyle: 'medium' }).format(date);
	return includeHourAndMinute ? `${standard} ${_getTime(date)}` : standard;
}

function _getSecondsFromNow(timestamp: number): number {
	return (Date.now() - timestamp) / 1000;
}

function _isToday(secondsElapsed: number): boolean {
	return secondsElapsed < DAY;
}

function _isYesterday(secondsElapsed: number): boolean {
	return secondsElapsed >= DAY && secondsElapsed < TWO_DAYS;
}

function _isThisYear(date: Date) {
	return date.getFullYear() === new Date().getFullYear();
}

function _getTime(date: Date) {
	return new Intl.DateTimeFormat(LOCALE, {
		hour: '2-digit',
		minute: '2-digit',
	}).format(date);
}

function _getTimeAgo(secondsElapsed: number) {
	const rel = new Intl.RelativeTimeFormat(LOCALE, { numeric: 'auto' });

	if (secondsElapsed >= DAY) {
		return rel.format(_getRelativeValue(secondsElapsed, DAY), 'day');
	}

	if (secondsElapsed >= HOUR) {
		return rel.format(_getRelativeValue(secondsElapsed, HOUR), 'hour');
	}

	if (secondsElapsed >= MINUTE) {
		return rel.format(_getRelativeValue(secondsElapsed, MINUTE), 'minute');
	}

	return rel.format(_getRelativeValue(secondsElapsed, SECOND), 'second');
}

function _getRelativeValue(secondsElapsed: number, secondsInUnit: number): number {
	return Math.floor(secondsElapsed / secondsInUnit) * -1;
}

function getTimeZone() {
	return new Intl.DateTimeFormat(LOCALE).resolvedOptions().timeZone.replace('_', ' ');
}

/**
 * Date of birth don't need to be normalized per timezone.
 * We display it as UTC
 *
 * @param {Date} date - Date in format dd-mm-yyyy
 *
 * Note: This needs to be manually tested via browser.
 */
function formatDateOfBirth(date: Date) {
	const dateUTC = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
	return new Intl.DateTimeFormat(LOCALE, { dateStyle: 'medium', timeZone: 'UTC' }).format(dateUTC);
}

export default { format, getTimeZone, formatDateOfBirth };
