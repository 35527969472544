import cn from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import style from '../Tabs.module.scss';

if (!NavLink) {
  throw Error('Tabs component requires react-router-dom');
}

export default function TabItem(props: any) {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? cn([style.Tabs__link, style.isActive]) : style.Tabs__link)}
      to={props.to}
    >
      {props.name}
    </NavLink>
  );
}
