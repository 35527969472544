import { shouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill';

export default async function IntlRelativeTimeFormatPolyfill() {
	if (shouldPolyfill()) {
		// Load the polyfill 1st BEFORE loading data
		await import('@formatjs/intl-relativetimeformat/polyfill');
	}

	if ((Intl.RelativeTimeFormat as any).polyfilled) {
		// TODO: Change this when different locales are added
		await import('@formatjs/intl-relativetimeformat/locale-data/en');
	}
}
