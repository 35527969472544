import Header from 'components/LoginHeader/LoginHeader';
import Notification from 'components/Notification/Notification';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import email from './email.svg';
import useWaitForEmailPage from './useWaitForEmailPage';
import style from './waitForEmail.module.scss';

export default function WaitForEmailPage() {
	const {
		isButtonDisabled,
		isMailSent,
		isNotificationHidden,
		resendEmail,
		serverError,
		userEmail,
	} = useWaitForEmailPage();

	return (
		<React.Fragment>
			<Header />
			<View className={style.WaitForEmailPage}>
				<Notification isHidden={isNotificationHidden} description={serverError} type="negative" />
				<main className={style.WaitForEmailPage__main}>
					<View>
						<View className={style.WaitForEmailPage__logo}>
							<img alt="" src={email} />
						</View>
						<Text type="h4"> Email Verification </Text>
						<Text margin="12px auto 24px" color="body">
							We sent a verification email to{' '}
							<Text color="black" weight={600} inline>
								{userEmail}
							</Text>
							. Click the link inside to get started!
						</Text>
						<Pill level="cta-link" size="medium" disabled={isButtonDisabled} onClick={resendEmail}>
							Didn't receive the email?
						</Pill>
						{isMailSent ? (
							<Label size="medium" fontWeight="600" className={style.WaitForEmailPage__confirmationText}>
								<span>Resent</span>
								<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="8.5" cy="8.5" r="8.5" fill="#00DF90" />
									<path
										className={style.WaitForEmailPage__svgPath}
										d="M3 8L6.5 11.5L13 5"
										stroke="white"
										strokeWidth="1.7"
									/>
								</svg>
							</Label>
						) : null}
					</View>
				</main>
			</View>
		</React.Fragment>
	);
}
