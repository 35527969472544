import classnames from 'classnames';
import LastFour from 'components/cards/LastFour/LastFour';
import React from 'react';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IAptoCardType from 'types/aptoCards/IAptoCardType';
import style from './AptoCard.module.scss';
import useAptoCard from './useAptoCard';

export interface IAptoCardProps {
	designKey?: IAptoCardDesignKey;
	firstName?: string;
	lastFour?: string;
	lastName?: string;
	/**
	 * The image URL or a base64 encoded image
	 */
	logo?: string;
	/**
	 * On sandbox network might be undefined.
	 */
	network?: IAptoCardNetwork;
	side: 'front' | 'back';
	type?: IAptoCardType;
}

export default function AptoCard(props: IAptoCardProps) {
	const { networkLogo, imageSrc } = useAptoCard(props);

	if (props.side === 'back') {
		return (
			<View className={style.AptoCard}>
				<img src={imageSrc} alt="Back side card preview" data-testid="aptoCard-bgImage" />
			</View>
		);
	}

	return (
		<View className={style.AptoCard}>
			{props.logo ? <span className={style.AptoCard__logo} style={{ backgroundImage: `url(${props.logo})` }} /> : null}
			{networkLogo ? (
				<View className={style.AptoCard__network}>
					<img src={networkLogo} alt="Card network" data-testid="aptoCard-networkLogo" />
				</View>
			) : null}
			<View className={style.AptoCard__details}>
				{props.firstName ? (
					<Text
						className={classnames(style.AptoCard__text, {
							[style['AptoCard__text--white']]: props.designKey === 'white',
						})}
						weight={600}
						type="small"
						margin="0 0 8px 0"
					>{`${props.firstName} ${props.lastName}`}</Text>
				) : null}
				<Text type="small">
					<LastFour isWhite={!props.designKey || props.designKey !== 'white'} value={props.lastFour} />
				</Text>
			</View>
			<img
				className={style.AptoCard__background}
				src={imageSrc}
				alt="Front side card preview"
				data-testid="aptoCard-bgImage"
			/>
		</View>
	);
}
