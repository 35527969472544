import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import GridColumns from '../GridColumns';
import style from './Grid.module.scss';

export interface IGridProps {
  /**
   * A CSS classname to add additional styles.
   */
  className?: string;

  /**
   * Number of columns of the Grid.
   */
  cols: GridColumns;

  /**
   * Number of columns of the Grid for larger screen sizes.
   */
  colsLg?: GridColumns;

  /**
   * CSS grid-template-rows property to set explicit row tracks to the Grid.
   */
  gridTemplateRows?: React.CSSProperties['gridTemplateRows'];

  /**
   * Needed if the Grid is used inside other container. Adds margins and a min-width restriction to an outer Grid.
   */
  isNested?: boolean;

  /**
   * Handy to set a height: 100% to the Grid.
   */
  fullHeight?: boolean;
}

export default function Grid(props: PropsWithChildren<IGridProps>) {
  const cnNumberOfColumn = style[`Grid--${props.cols || '16'}`];
  const cnNumberOfColumnLg = props.colsLg ? style[`Grid-lg--${props.colsLg}`] : null;

  const cnNested = classnames({ [`${style['Grid--outer']}`]: !props.isNested });

  return (
    <div
      className={classnames(style.Grid, cnNumberOfColumn, cnNested, cnNumberOfColumnLg, props.className)}
      style={{
        gridTemplateRows: props.gridTemplateRows,
        height: props.fullHeight ? '100%' : 'auto',
      }}
    >
      {props.children}
    </div>
  );
}
