import IOwner from 'types/activateAccount/IOwner';

export function parseOwner(raw: any): IOwner {
	let parsedOwner = null;
	const baseOwner = {
		birthDate: raw.birthdate,
		businessTitle: raw.business_title,
		citizenship: raw.citizenship,
		country: raw.address.country,
		countryCode: raw.phone.country_code,
		email: raw.email || '', // This prevents the screen from crashing if this new field is null in some users
		firstName: raw.name.first_name,
		id: raw.id,
		lastName: raw.name.last_name,
		locality: raw.address.locality,
		phoneNumber: raw.phone.phone_number,
		postalCode: raw.address.postal_code,
		region: raw.address.region,
		streetOne: raw.address.street_one,
		streetTwo: raw.address.street_two,
		type: raw.type,
	};

	parsedOwner = { ...baseOwner };

	if (raw.type === 'beneficial_owner') {
		parsedOwner = {
			...parsedOwner,
			ownershipPercentage: raw.ownership_percentage?.toString() || '',
		};
	}

	if (raw.id_document.type === 'passport') {
		return {
			...parsedOwner,
			formOfID: 'passport',
			passport: raw.id_document.value,
			passportExpiration: '',
			passportIssueDate: '',
			passportIssuedCountry: raw.id_document.country,
		};
	}

	return {
		...parsedOwner,
		formOfID: 'ssn',
		ssn: raw.id_document.value,
	};
}

export default { parseOwner };
