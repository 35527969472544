import { useAddSignedPayloadsKeyMutation } from 'queries/apiKeys.queries';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

export interface IUseModalAddSignedPayloadsKeyState {
	name: string;
	publicKey: string;
}

interface IUseModalAddSignedPayloadsKeyArgs {
	onSuccessfulSave: () => void;
}

export default function useModalAddSignedPayloadsKey({ onSuccessfulSave }: IUseModalAddSignedPayloadsKeyArgs) {
	const addSignedPayloadsKeyMutation = useAddSignedPayloadsKeyMutation();

	const { bind, submitForm, isButtonEnabled, isSubmitting } = useForm<IUseModalAddSignedPayloadsKeyState>({
		initialValues: { name: '', publicKey: '' },
		onSubmit: async (values) => {
			return addSignedPayloadsKeyMutation.mutate(
				{ ...values, value: values.publicKey },
				{
					onSuccess: onSuccessfulSave,
				}
			);
		},
		validate: validationService.createValidator({
			name: [validationService.required],
			publicKey: [validationService.required],
		}),
	});

	return {
		bind,
		isButtonDisabled: !isButtonEnabled,
		isButtonLoading: isSubmitting,
		serverError: addSignedPayloadsKeyMutation.error?.message || '',
		submitForm,
	};
}
