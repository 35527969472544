import EmptyList from 'pages/dashboard/developers/components/EmptyList/EmptyList';
import React from 'react';
import View from 'styleguide/View/View';
import ICoreApiKey from 'types/ICoreApiKey';
import IEnvironment from 'types/session/IEnvironment';
import CredentialItem from './components/CredentialItem/CredentialItem';

export interface ICredentialsProps {
	coreApiKeys: ICoreApiKey[];
	onDeleteCredentialClick: (id: string) => void;
	onCreateCredentialClick: () => void;
	environment: IEnvironment;
}

const INTERNAL_KEY_DESC = 'For internal use';

export default function CredentialList(props: ICredentialsProps) {
	const activeApiKeys = props.coreApiKeys.filter((credential) => credential.state !== 'revoked');

	if (activeApiKeys.length === 0) {
		return <EmptyList message={`You don't have any ${props.environment} keys`} />;
	}

	return (
		<View>
			{activeApiKeys
				.filter((credentials) => credentials.description !== INTERNAL_KEY_DESC)
				.map((credential, i) => {
					return (
						<CredentialItem
							index={i}
							credential={credential}
							onRemoveClick={props.onDeleteCredentialClick}
							key={credential.apiClientId}
						/>
					);
				})}
		</View>
	);
}
