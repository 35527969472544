import CurrencyFormatter from 'components/formatters/CurrencyFormatter/CurrencyFormatter';
import { ICurrency } from 'constants/currencies';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './CardTransaction.module.scss';

interface ICardTransactionProps {
	merchant: string;
	createdAt: Date;
	id: string;
	cost: {
		currency: ICurrency;
		amount: number;
	};
	onClick: () => void;
}

export default function CardTransaction(props: ICardTransactionProps) {
	return (
		<View className={style.CardTransaction} onClick={props.onClick} role="button">
			<View>
				<Paragraph size="small"> {props.merchant} </Paragraph>
				<Paragraph size="x-small" color="contentSecondary">
					{dateTimeFormatService.format(props.createdAt, { includeHourAndMinute: true })}
				</Paragraph>
			</View>
			<View>
				<Paragraph size="small">
					<CurrencyFormatter currency={props.cost.currency} value={props.cost.amount} />
				</Paragraph>
			</View>
		</View>
	);
}
