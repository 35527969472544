import useApplicationId from 'hooks/useApplicationId';
import { useGetPublicKeyQuery } from 'queries/activateAccount';

export default function usePublicKeyStepWithApplicationId() {
	// SafeCast: This is rendered inside a WithApplicationId component so we know this is a valid string
	const applicationId = useApplicationId() as string;

	const getPublicKeyQuery = useGetPublicKeyQuery({
		applicationId,
		// Prevent background requests when the user focus the page
		refetchOnWindowFocus: false,
	});

	return {
		props: {
			publicKey: getPublicKeyQuery.data?.publicKey || '',
			name: getPublicKeyQuery.data?.name || '',
			applicationId,
		},
		status: getPublicKeyQuery.status,
	};
}
