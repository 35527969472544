import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import View from 'styleguide/View/View';
import AcceptedInvites from './components/AcceptedInvites/AcceptedInvites';
import PendingInvites from './components/PendingInvites/PendingInvites';
import TeamHeader from './components/TeamHeader/TeamHeader';

export default function TeamSection() {
	return (
		<View data-testid="page-team">
			<PaddingContainer>
				<TeamHeader />
			</PaddingContainer>
			<PendingInvites />
			<AcceptedInvites />
		</View>
	);
}
