import {
	BILLING_ROUTE,
	CARDS_ROUTE,
	DEVELOPERS_ROUTE,
	OVERVIEW_ROUTE,
	SETTINGS_ROUTE,
	TRANSACTIONS_ROUTE,
	USERS_ROUTE,
} from 'constants/routes';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { capitalize } from 'utils/string.service';
import EnvironmentSelector from './components/EnvironmentSelector/EnvironmentSelector';
import './Drawer.scss';
import sandboxSwitch from './icons/sandbox-switch.svg';
import useDrawer from './useDrawer';

export default function Drawer() {
	const { environment, onEnvironmentChange, isBillingEnabled, isSandbox } = useDrawer();

	return (
		<aside className="Drawer">
			<nav className="Drawer__nav">
				<NavLink
					className={({ isActive }) => (isActive ? 'Link Link--home is-active' : 'Link Link--home')}
					to={OVERVIEW_ROUTE}
				>
					<span>Overview</span>
				</NavLink>
				<NavLink
					className={({ isActive }) => (isActive ? 'Link Link--users is-active' : 'Link Link--users')}
					to={USERS_ROUTE}
				>
					<span>Users</span>
				</NavLink>
				<NavLink
					className={({ isActive }) => (isActive ? 'Link Link--cards is-active' : 'Link Link--cards')}
					to={CARDS_ROUTE}
				>
					<span>Cards</span>
				</NavLink>
				<NavLink
					className={({ isActive }) => (isActive ? 'Link Link--transactions is-active' : 'Link Link--transactions')}
					to={TRANSACTIONS_ROUTE}
				>
					<span>Transactions</span>
				</NavLink>
				<NavLink
					className={({ isActive }) => (isActive ? 'Link Link--developers is-active' : 'Link Link--developers')}
					to={DEVELOPERS_ROUTE}
				>
					<span>Developers</span>
				</NavLink>
				{isBillingEnabled ? (
					<NavLink
						className={({ isActive }) => (isActive ? 'Link Link--billing is-active' : 'Link Link--billing')}
						to={BILLING_ROUTE}
					>
						<span>Billing</span>
					</NavLink>
				) : null}
				<NavLink
					className={({ isActive }) => (isActive ? 'Link Link--settings is-active' : 'Link Link--settings')}
					to={SETTINGS_ROUTE}
				>
					<span>Settings</span>
				</NavLink>
			</nav>
			<div className="Drawer__actions"></div>
			{isSandbox ? (
				<div className="Banner">
					<img className="Banner__icon" src={sandboxSwitch} alt="sandbox mode icon" />
					<span className="Banner__text">Switch to production to issue live cards.</span>
				</div>
			) : null}
			<div className="Drawer__bot">
				<EnvironmentSelector
					name="environment"
					value={{ label: capitalize(environment), value: environment }}
					onChange={onEnvironmentChange}
					label="Select environment"
					options={[
						{ label: 'Sandbox', value: 'sandbox' },
						{ label: 'Production', value: 'production' },
					]}
				/>
			</div>
		</aside>
	);
}
