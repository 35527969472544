import LoginHeader from 'components/LoginHeader/LoginHeader';
import Notification from 'components/Notification/Notification';
import PasswordValidator from 'components/PasswordValidator';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import style from './ResetPassword.module.scss';
import useResetPassword from './useResetPassword';

export default function ResetPasswordPage() {
	const {
		errors,
		values,
		onPasswordFocus,
		onPasswordBlur,
		handleChange,
		handleFocus,
		handleBlur,
		isPasswordValidatorVisible,
		submitForm,
		isButtonDisabled,
		isLoading,
		serverError,
	} = useResetPassword();
	return (
		<React.Fragment>
			<LoginHeader />
			<Grid cols="16" colsLg="16">
				<Item colWidth="16" colWidthLg="6" className={style.ResetPasssword__gridItem}>
					<main className={style.ResetPasssword}>
						<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} />
						<form>
							<Text margin="36px 0 24px" type="h4" tag="h1">
								Reset password
							</Text>

							<Input
								margin="0 0 8px 0"
								error={errors.newPassword}
								required
								onFocus={onPasswordFocus}
								onBlur={onPasswordBlur}
								value={values.newPassword}
								onChange={handleChange}
								name="newPassword"
								label="New password"
								id="newPassword"
								type="password"
							/>
							<PasswordValidator isVisible={isPasswordValidatorVisible} password={values.newPassword} />

							<Input
								margin="12px 0 12px 0"
								error={errors.passwordConfirm}
								required
								onFocus={handleFocus}
								onBlur={handleBlur}
								value={values.passwordConfirm}
								onChange={handleChange}
								type="password"
								id="passwordConfirm"
								name="passwordConfirm"
								label="Confirm password"
							></Input>

							<Pill
								level="primary"
								size="medium"
								type="submit"
								disabled={isButtonDisabled}
								loading={isLoading}
								data-testid="btn-reset-password"
								onClick={submitForm}
							>
								Submit
							</Pill>
						</form>
					</main>
				</Item>
			</Grid>
		</React.Fragment>
	);
}
