import AppLoader from 'components/AppLoader';
import { LOAD_FUNDS_LOAD_ROUTE, LOGIN_ROUTE } from 'constants/routes';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import View from 'styleguide/View/View';
import SwitchWithNotFound from 'utils/SwitchWithNotFound';
import LoadFundsLearnMore from './LoadFundsLearnMore/LoadFundsLearnMore';
import style from './LoadFundsPage.module.scss';
import LoadFundsSidebar from './LoadFundsSidebar/LoadFundsSidebar';
import ConfirmationStep from './steps/ConfirmationStep/ConfirmationStep';
import LoadStep from './steps/LoadStep/LoadStep';
import ReviewStep from './steps/ReviewStep/ReviewStep';
import useLoadFunds from './useLoadFunds';

export default function LoadFundsRootPage() {
	const {
		accountType,
		closeLoadFunds,
		handleCloseModal,
		isLoggedIn,
		networkIsLoading,
		onLoadStepSubmitted,
		onReviewStepSubmitted,
		selectedPaymentMethod,
		state,
		updateSelectedPaymentId,
	} = useLoadFunds();

	if (!isLoggedIn) {
		// TODO: Save route and redirect to saved route once logged in
		return <Navigate to={LOGIN_ROUTE} />;
	}

	if (networkIsLoading) {
		return <AppLoader />;
	}

	return (
		<View className={style.LoadFundsPage}>
			<aside className={style.LoadFundsPage__aside}>
				<LoadFundsSidebar accountType={accountType} />
			</aside>
			<main className={style.LoadFundsPage__content}>
				<SwitchWithNotFound>
					<Route index element={<Navigate replace to={LOAD_FUNDS_LOAD_ROUTE} />}></Route>
					<Route
						path="load"
						element={
							<LoadStep
								accountType={accountType}
								amount={state.amount}
								onCloseModal={handleCloseModal}
								onPaymentMethodClick={updateSelectedPaymentId}
								onSubmit={onLoadStepSubmitted}
								paymentMethods={state.paymentMethods}
								selectedPaymentMethodId={state.selectedPaymentMethodId}
							/>
						}
					></Route>
					<Route
						path="review"
						element={
							<ReviewStep
								amount={state.amount}
								buttonIsDisabled={networkIsLoading}
								onSubmit={onReviewStepSubmitted}
								paymentMethod={selectedPaymentMethod}
								serverError={state.serverError}
							/>
						}
					></Route>
					<Route
						path="confirm"
						element={
							<ConfirmationStep
								amount={state.amount}
								onDoneClick={closeLoadFunds}
								paymentId={state.paymentId}
								lastFour={selectedPaymentMethod?.lastFour}
								paymentTimestamp={state.paymentTimestamp}
								paymentStatus={state.paymentStatus}
							/>
						}
					></Route>
				</SwitchWithNotFound>
				<LoadFundsLearnMore accountType={accountType} />
			</main>
		</View>
	);
}
