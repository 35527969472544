import Header from 'components/LoginHeader/LoginHeader';
import Notification from 'components/Notification/Notification';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import style from './TwoFactorAuth.module.scss';
import useTwoFactorAuth from './useTwoFactorAuth';

export default function TwoFactorAuth() {
	const { bind, isSubmitting, isButtonDisabled, submitForm, serverError, isNotificationHidden } = useTwoFactorAuth();
	return (
		<React.Fragment>
			<Header />
			<Grid cols="16" colsLg="16">
				<Item colWidth="16" colWidthLg="6" className={style.TwoFactorAuth__gridItem}>
					<main className={style.TwoFactorAuth}>
						<Notification isHidden={isNotificationHidden} description={serverError} type="negative" />

						<Text type="h4" tag="h1" margin="36px 0 24px">
							2-Step verification
						</Text>
						<Paragraph size="medium" color="contentSecondary" margin="12px 0 24px">
							Enter the 2-step verification code generated by your preferred Authenticator App.
						</Paragraph>

						<form onSubmit={submitForm} className={style.TwoFactorAuth__form}>
							<Input autofocus type="text" label="Verification code" {...bind('code')}></Input>
							<Pill
								level="primary"
								size="medium"
								type="submit"
								disabled={isButtonDisabled}
								loading={isSubmitting}
								data-testid="btn-submit"
							>
								Verify
							</Pill>
						</form>
					</main>
				</Item>
			</Grid>
		</React.Fragment>
	);
}
