import React from 'react';
import Button from 'styleguide/Button/Button';
import Label from 'styleguide/Type/Label/Label';
import checkmark from './checkmark.svg';
import style from './ResendSMS.module.scss';

interface IResendSMSProps {
	hasSMSBeenResent: boolean;
	onButtonClick: () => void;
}

export default function ResendSMS({ hasSMSBeenResent, onButtonClick }: IResendSMSProps) {
	if (hasSMSBeenResent) {
		return (
			<Label size="small" fontWeight="600" className={style.ResendSMS__successText}>
				Resent <img src={checkmark} alt="positive checkmark" />
			</Label>
		);
	}

	return (
		<Button type="button" level="link" color="dark" onClick={onButtonClick}>
			<Label size="small" fontWeight="600">
				Resend the code
			</Label>
		</Button>
	);
}
