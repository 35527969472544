import LoginHeader from 'components/LoginHeader/LoginHeader';
import Notification from 'components/Notification/Notification';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ForgotPassword.module.scss';
import useForgotPassword from './useForgotPassword';

export default function ForgotPasswordPage() {
	const {
		values,
		errors,
		serverError,
		serverMessage,
		handleChange,
		handleBlur,
		handleFocus,
		requestPasswordReset,
		isLoading,
		isButtonDisabled,
	} = useForgotPassword();

	return (
		<React.Fragment>
			<LoginHeader />
			<Grid cols="16" colsLg="16">
				<Item colWidth="16" colWidthLg="6" className={style.ForgotPassword__gridItem}>
					<main className={style.ForgotPassword}>
						<Notification type="positive" isHidden={!serverMessage} description={serverMessage} />
						<Notification type="negative" isHidden={!serverError} title="Error" description={serverError} />

						<form onSubmit={requestPasswordReset}>
							<Text weight="bold" type="h4" tag="h1" margin="36px 0 24px">
								Reset Password
							</Text>
							<Paragraph size="medium" margin="12px 0 24px" color="contentSecondary">
								Enter the email address associated with your Apto account.
							</Paragraph>

							<View style={{ marginBottom: '24px' }}>
								<Input
									error={errors.email}
									autofocus
									value={values.email}
									autocomplete="email"
									onFocus={handleFocus}
									onChange={handleChange}
									onBlur={handleBlur}
									name="email"
									label="Email"
									id="email"
									type="email"
								/>
							</View>

							<Pill
								type="submit"
								level="primary"
								size="medium"
								disabled={isButtonDisabled}
								loading={isLoading}
								data-testid="btn-forgot-password"
							>
								Send reset link
							</Pill>
						</form>
					</main>
				</Item>
			</Grid>
		</React.Fragment>
	);
}
