import useSession from 'hooks/useSession';
import { useEffect } from 'react';

export default function usePendingApplication() {
	const { isLoggedIn, fetchSession } = useSession();

	useEffect(() => {
		// We need to refetch the session to update the last completed step.
		fetchSession();
	}, []); // eslint-disable-line

	return {
		isLoggedIn,
	};
}
