import { useListAccountsQuery } from 'queries/accounts';
import IMoneyAmount from 'types/financial/IMoneyAmount';

export default function useOverviewFundingAccount() {
	const { data: developerAccounts, status } = useListAccountsQuery();
	const { fundingAccount } = developerAccounts || {};

	return {
		status: status,
		props: {
			amount: fundingAccount?.balance.amount as number,
			currency: fundingAccount?.balance.currency as IMoneyAmount['currency'],
		},
	};
}
