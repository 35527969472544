import React from 'react';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './DetailBox.module.scss';

export interface IDetailBoxProps {
	label: string;
	value: string;
}

export default function DetailBox(props: IDetailBoxProps) {
	return (
		<View className={style.DetailBox}>
			<Paragraph size="small" color="contentPrimary">
				{props.label}
			</Paragraph>
			<Paragraph size="small" color="contentPrimary">
				{props.value}
			</Paragraph>
		</View>
	);
}
