import CircleIcon from 'styleguide/CircleIcon/CircleIcon';
import Emoji from 'styleguide/Emoji/Emoji';
import LoginHeader from 'components/LoginHeader/LoginHeader';
import Spinner from 'styleguide/Spinner/Spinner';
import StyledDescription from 'components/StyledDescription/StyledDescription';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import React from 'react';
import './ConfirmEmail.scss';
import useConfirmEmail from './useConfirmEmail';

const ConfirmEmailPage: React.FC<any> = () => {
	const { state } = useConfirmEmail();

	let content = null;

	if (state.isLoading) {
		content = (
			<View>
				<Spinner width="100%" height="100%" />
				<Text center>Just a second, we are confirming your account...</Text>
			</View>
		);
	}

	if (state.hasError) {
		const icon = (
			<CircleIcon>
				<Emoji symbol={state.emoji} fontSize="52px" />
			</CircleIcon>
		);
		content = <StyledDescription title={state.title} description={state.message} icon={icon} />;
	}

	return (
		<React.Fragment>
			<LoginHeader />
			<main className="ConfirmEmail">{content}</main>
		</React.Fragment>
	);
};

export default ConfirmEmailPage;
