/**
 * Confirm if we're loading a Developer Funding Account or Billing Account
 */

import { IFinancialAccountType } from './useLoadFunds';

function getIsBillingAccount(params: URLSearchParams): IFinancialAccountType {
	switch (params.get('account_type')) {
		case 'billing':
			return 'Billing Account';
		case 'funding':
		default:
			return 'Developer Funding Account';
	}
}

export default {
	getIsBillingAccount,
};
