import dummy_payment_sources_list_response from 'mocks/serverResponses/payment_sources/dummy_payment_sources_list_response';
import { rest } from 'msw';

const paymentSourcesHandlers = [
	rest.get('https://vault.stg.aptopayments.com/v1/developer/settings/payment_sources', (req, res, ctx) => {
		return res(ctx.json(dummy_payment_sources_list_response), ctx.status(200));
	}),
];

export default paymentSourcesHandlers;
