import CurrencyFormatter from 'components/formatters/CurrencyFormatter/CurrencyFormatter';
import TransactionStatusFormatter from 'components/formatters/TransactionStatusFormatter/TransactionStatusFormatter';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import ChevronPagination from 'styleguide/Pagination/ChevronPagination/ChevronPagination';
import Table from 'styleguide/Table/Table';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import ITransaction from 'types/transactions/ITransaction';
import ITransactionStatus from 'types/transactions/ITransactionStatus';
import style from './TransactionsHistorySuccess.module.scss';

interface ITransactionsHistoryProps {
	transactions: ITransaction[];
	isPaginationNextVisible: boolean;
	isPaginationPrevVisible: boolean;
	goToNextPage: () => void;
	goToPrevPage: () => void;
}

export default function TransactionsHistorySuccess(props: ITransactionsHistoryProps) {
	return (
		<>
			<PaddingContainer>
				<Heading size="small" tag="h2" className={style.TransactionsHistory__title}>
					Transaction history
				</Heading>
			</PaddingContainer>
			<Table
				minWidth="800px"
				data={props.transactions}
				columns={columns}
				emptyTableMessage="You have no transactions."
			/>
			<PaddingContainer>
				<ChevronPagination
					isInBlock
					isNextVisible={props.isPaginationNextVisible}
					isPrevVisible={props.isPaginationPrevVisible}
					onNextClicked={props.goToNextPage}
					onPrevClicked={props.goToPrevPage}
				/>
			</PaddingContainer>
		</>
	);
}

const columns = [
	{ key: 'description', name: 'Transaction description' },
	{ key: 'id', name: 'Transaction ID' },
	{ key: 'localAmount', name: 'Amount', renderer: _moneyRenderer },
	{ key: 'createdAt', name: `Time (${dateTimeFormatService.getTimeZone()})`, renderer: _createAtRenderer },
	{ key: 'state', name: 'Status', renderer: _stateRenderer },
];

function _moneyRenderer(_: any, item: ITransaction) {
	return <CurrencyFormatter value={item.localAmount} currency={item.localAmountCurrency} />;
}

function _stateRenderer(value: ITransactionStatus) {
	return <TransactionStatusFormatter value={value} />;
}

function _createAtRenderer(date: Date) {
	return (
		<View style={{ textTransform: 'capitalize' }}>
			{dateTimeFormatService.format(date, { includeHourAndMinute: true })}
		</View>
	);
}
