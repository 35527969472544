import toCamelCase from 'api-client/utils/toCamelCase';
import ICardNetwork from 'types/financial/ICardNetwork';
import IPaymentMethod from 'types/IPaymentMethod';
import { IReadPaymentSourcesResponse } from './paymentSources';

function parsePaymentSources(raw: any): IReadPaymentSourcesResponse {
	return toCamelCase({
		...raw,
		paymentSources: raw.payment_sources.map(parsePaymentSource),
	});
}

function parsePaymentSource({ payment_source }: { payment_source: any }): IPaymentMethod {
	return {
		cardNetwork: _getCardNetwork(payment_source.card_network),
		cardType: payment_source.card_type,
		createdAt: new Date(payment_source.created_at),
		description: payment_source.description,
		id: payment_source.id,
		lastFour: payment_source.last_four,
		postalCode: payment_source.postal_code,
		preferenceTypes: payment_source.preference_types,
		type: payment_source.type,
		updatedAt: new Date(payment_source.updated_at),
	};
}

export default {
	parsePaymentSource,
	parsePaymentSources,
};

const KNOWN_NETWORKS: ICardNetwork[] = ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER'];

function _getCardNetwork(network: string): ICardNetwork {
	return KNOWN_NETWORKS.find((item) => item === network) || 'OTHER';
}
