import utils from 'utils';

const PRODUCTION_URL = 'https://api.aptopayments.com/v1';
const STAGING_URL = 'https://api.stg.aptopayments.com/v1';

// TODO: Move this to compile time using a REACT_APP_API_KEY env variable ?
export default function getBaseUrl() {
	switch (utils.getEnvironment()) {
		case 'PRD':
			return PRODUCTION_URL;
		case 'STG':
		default:
			return STAGING_URL;
	}
}
