import { ChangeEvent, FocusEvent } from 'react';
import Input from 'styleguide/Input/Input';
import View from 'styleguide/View/View';
import style from './Address.module.scss';
import { usStates } from './states';

export interface IAddress {
	streetOne: string;
	streetTwo: string;
	locality: string;
	region: string;
	postalCode: string;
	country: string;
}

export interface IAddressProps<T extends Record<keyof IAddress, T[keyof T]>> {
	bind: (id: T[keyof T]) => {
		id: string;
		name: string;
		value: string;
		error?: string;
		onChange: (
			event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>
		) => void;
		onFocus: (
			event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>
		) => void;
		onBlur: (
			event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>
		) => void;
	};
	ids: T;
	labels?: {
		streetOne?: string;
		streetTwo?: string;
		locality?: string;
		region?: string;
		postalCode?: string;
		country?: string;
	};
}

export default function Address<T extends Record<keyof IAddress, T[keyof T]>>(props: IAddressProps<T>) {
	return (
		<>
			<View style={{ marginBottom: '12px' }}>
				<Input
					type="select"
					label={props.labels?.country || 'Select country'}
					{...props.bind(props.ids.country)}
					selectOptions={{ US: 'United States of America' }}
				></Input>
			</View>

			<Input
				type="text"
				label={props.labels?.streetOne || 'Street address'}
				{...props.bind(props.ids.streetOne)}
				margin="12px 0"
				autocomplete="street-address"
			/>

			<View className={style.Address__row}>
				<Input type="text" label={props.labels?.streetTwo || 'Apt, suite.'} {...props.bind(props.ids.streetTwo)} />
				<Input
					type="text"
					label={props.labels?.locality || 'City'}
					{...props.bind(props.ids.locality)}
					autocomplete="address-level2"
				/>
			</View>

			<View className={style.Address__row}>
				<Input
					type="select"
					label={props.labels?.region || 'Select state'}
					{...props.bind(props.ids.region)}
					selectOptions={usStates}
				></Input>
				<Input
					type="text"
					label={props.labels?.postalCode || 'ZIP code'}
					{...props.bind(props.ids.postalCode)}
					autocomplete="postal-code"
				/>
			</View>
		</>
	);
}
