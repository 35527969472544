import CurrencyFormatter from 'components/formatters/CurrencyFormatter/CurrencyFormatter';
import React from 'react';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ITransaction from 'types/transactions/ITransaction';
import style from './HeaderSection.module.scss';

interface IHeaderSectionProps {
	transaction: ITransaction;
}

export default function HeaderSection(props: IHeaderSectionProps) {
	const fullTime = dateTimeFormatService.format(props.transaction.createdAt, { includeHourAndMinute: true });

	return (
		<View className={style.Header}>
			<View className={style.Header__left}>
				<Heading tag="h1" size="x-small">
					{props.transaction.merchantName}
				</Heading>
				<Paragraph size="small"> {fullTime} </Paragraph>
			</View>

			<View className={style.Header__right}>
				<View className={style.Header__descriptionItem}>
					<Label size="small" fontWeight="600">
						Transaction Amount
					</Label>
					<Paragraph size="small" textAlign="right">
						<CurrencyFormatter currency={props.transaction.localAmountCurrency} value={props.transaction.localAmount} />
					</Paragraph>
				</View>
				<View className={style.Header__descriptionItem}>
					<Label size="small" fontWeight="600">
						Billing Amount
					</Label>
					<Paragraph size="small" textAlign="right">
						<CurrencyFormatter
							currency={props.transaction.billingAmountCurrency}
							value={props.transaction.billingAmount}
						/>
					</Paragraph>
				</View>
			</View>
		</View>
	);
}
