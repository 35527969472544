import FullNameFormatter from 'components/formatters/FullNameFormatter/FullNameFormatter';
import KYCFormatter from 'components/formatters/KYCFormatter';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import ChevronPagination from 'styleguide/Pagination/ChevronPagination/ChevronPagination';
import Table, { Renderers } from 'styleguide/Table/Table';
import View from 'styleguide/View/View';
import ICardholder from 'types/cardholders/ICardholder';
import ICardholderKYCStatus from 'types/cardholders/ICardholderKYCStatus';
import UsersHeader from './components/UsersHeader';
import useUsersPageSuccess from './useUsersPageSuccess';

interface IUsersPageSuccessProps {
	cardholders: ICardholder[];
	hasMore: boolean;
	hasMoreBefore: boolean;
	total: number;
}

export default function UsersPageSuccess(props: IUsersPageSuccessProps) {
	const {
		goToNextPage,
		goToPrevPage,
		onRowClick,
		showModalAddUsers,
		isPaginationNextVisible,
		isPaginationPrevVisible,
		isProduction,
		total,
		users,
	} = useUsersPageSuccess(props);

	return (
		<View data-testid="page-users">
			<UsersHeader total={total} isAddButtonVisible={!isProduction} onAddClick={showModalAddUsers} />

			<Table
				minWidth="800px"
				data={users}
				columns={columns}
				onRowClick={onRowClick}
				emptyTableMessage="You have no users."
			/>

			<PaddingContainer>
				<ChevronPagination
					isInBlock
					isNextVisible={isPaginationNextVisible}
					isPrevVisible={isPaginationPrevVisible}
					onNextClicked={goToNextPage}
					onPrevClicked={goToPrevPage}
				/>
			</PaddingContainer>
		</View>
	);
}

const columns = [
	{
		key: 'firstName',
		name: 'Name',
		renderer: _initialsAndFullNameRenderer,
	},
	{ key: 'email', name: 'Email' },
	{ key: 'createdAt', name: 'Created', renderer: Renderers.DATE },
	{
		key: 'kycStatus',
		name: 'KYC Status',
		renderer: (value: ICardholderKYCStatus) => <KYCFormatter value={value} />, // eslint-disable-line react/display-name
	},
];

function _initialsAndFullNameRenderer(firstName: string, { lastName }: ICardholder) {
	return <FullNameFormatter firstName={firstName} lastName={lastName} />;
}
