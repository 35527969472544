import Notification from 'components/Notification/Notification';
import React from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalAddSignedPayloadsKey.module.scss';
import useModalAddSignedPayloadsKey from './useModalAddSignedPayloadsKey';

interface IModalAddSignedPayloadsKeyProps {
	onSuccessfulSave: () => void;
}

export default function ModalAddSignedPayloadsKey(props: IModalAddSignedPayloadsKeyProps) {
	const { bind, isButtonDisabled, isButtonLoading, serverError, submitForm } = useModalAddSignedPayloadsKey({
		onSuccessfulSave: props.onSuccessfulSave,
	});
	return (
		<View className={style.ModalAddSignedPayloadsKey}>
			<Notification isHidden={!serverError} type="negative" margin="0 0 20px" title="Error" description={serverError} />
			<form>
				<Heading tag="h1" margin="0 0 12px 0" size="small" color="contentPrimary">
					Add Signed Payloads Key
				</Heading>
				<Paragraph margin="12px auto 24px" size="small" color="contentPrimary">
					Once you've generated your key pair, copy and paste the public key into the box below. <br />
					<Pill
						type="link"
						level="inline-link"
						size="small"
						to="https://docs.aptopayments.com/docs/overview/getting%20started/signed_payloads/"
						external
					>
						Learn more about the Signed Payloads
					</Pill>
				</Paragraph>

				<Input type="text" label="Name your key" {...bind('name')} margin="0 0 8px" />
				<Paragraph size="x-small" color="contentTertiary" margin="0 0 24px">
					The name can’t be changed after saving.
				</Paragraph>

				<Input type="textarea" label="Enter key here" {...bind('publicKey')} />

				<View className={style.ModalAddSignedPayloadsKey__footer}>
					<Pill
						type="submit"
						level="primary"
						size="medium"
						disabled={isButtonDisabled}
						loading={isButtonLoading}
						onClick={submitForm}
						data-testid="btn-save"
					>
						Save
					</Pill>
				</View>
			</form>
		</View>
	);
}
