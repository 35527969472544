import classnames from 'classnames';
import NotificationPopup from 'components/NotificationPopup/NotificationPopup';
import Tooltip from 'components/Tooltip/Tooltip';
import useSession from 'hooks/useSession';
import { IFundingAccountFlatten } from 'pages/dashboard/settings/sections/Accounts/useAccounts';
import Modal from 'styleguide/Modal/Modal';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import AchDetails from './components/AchDetails/AchDetails';
import style from './FundingAccount.module.scss';

type IFundingAccountProps = IFundingAccountFlatten;

export default function FundingAccount(props: IFundingAccountProps) {
	const { isProduction } = useSession();

	function showAchDetailsPopup() {
		if (isProduction) {
			Modal.show(<AchDetails routingNumber={props.routingNumber} accountNumber={props.accountNumber} />, {
				ariaTitle: 'Developer Funding Account Information',
			});
		} else {
			Modal.show(
				<NotificationPopup title="Account Information is disabled in Sandbox accounts" handleClose={Modal.hide}>
					<Paragraph size="small" color="contentPrimary" margin=" 0 0 12px">
						After switching to Production, you'll be able to click here to view information on your program's bank
						account name, account number and routing number.
					</Paragraph>
				</NotificationPopup>,
				{
					ariaTitle: 'Not enabled on production',
				}
			);
		}
	}

	return (
		<View className={style.AccountsSection} data-testid="FundingAccount">
			<View className={style.AccountsSection__header}>
				<View className={style.AccountsSectionHeading}>
					<Label size="small" fontWeight="600" margin="0 0 12px">
						Developer Funding Account
					</Label>
					<Tooltip message="Funds you add to this account can be disbursed to your cardholders' cards.">
						<View className={style.AccountsSectionHeading__info} data-testid="info-icon" />
					</Tooltip>
				</View>
				<Heading size="medium" tag="p">
					{new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: props.currency,
					}).format(props.amount)}
				</Heading>
			</View>

			<View className={style.FundingAccount__row}>
				<Paragraph size="small" color="contentPrimary">
					Developer Funding Account ID
				</Paragraph>
				<Paragraph size="small" color="contentPrimary">
					{props.id}
				</Paragraph>
			</View>

			<button
				type="button"
				className={classnames(style.FundingAccount__row, style['FundingAccount__row--achdetails'])}
				onClick={showAchDetailsPopup}
			>
				<Paragraph size="small" color="contentPrimary">
					Developer Funding Account Information
				</Paragraph>
			</button>
		</View>
	);
}
