import { useState } from 'react';
import sentryService from 'services/sentry.service';

export default function useError(error?: Error, httpStatus?: number) {
	const [isContactUsOpen, setIsContactUsOpen] = useState(false);

	if (error) {
		_captureError(error, httpStatus);
	}

	return {
		statusDescription: _getStatusDescription(httpStatus),
		errorDescription: _getErrorDescription(httpStatus),
		hideContactUsForm: () => setIsContactUsOpen(false),
		isContactUsOpen,
		showContactUsForm: () => setIsContactUsOpen(true),
	};
}

function _captureError(error: Error, httpStatus?: number) {
	if (httpStatus) {
		error.message = `${error.message}. Error: ${httpStatus}`;
	}

	sentryService.captureException(error);
}

function _getErrorDescription(httpStatus?: number) {
	switch (httpStatus) {
		case 404:
			return 'The page you’re looking for could not be found.';
		default:
			return 'An error has occurred and we are trying to fix the problem.';
	}
}

function _getStatusDescription(httpStatus?: number) {
	if (!httpStatus) {
		return 'Unknown error';
	}

	return `Error ${httpStatus}`;
}
