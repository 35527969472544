import sessionService from 'services/session.service';
import AptoServerError from 'utils/AptoServerError';
import getApiKey from './getApiKey';
import getBaseUrl from './getBaseUrl';

const BASE_URL = getBaseUrl();

/**
 * Helper over window.fetch adding required headers and auth tokens and handling errors.
 *
 * @param {string} endpoint  - The API endpoint to fetch. MUST start with "/"
 * @param {'GET'|'POST'|'PUT'|'DELETE'} method - HTTP Method
 * @param {object} body - Body sent into POST/PUT/PATCH requests.
 * @param {string} opts.baseURL Allows setting a custom base URL
 */
export default function apiFetch(
	endpoint: string,
	method: 'GET' | 'POST' | 'PUT' | 'DELETE',
	body: Record<string, any> = {},
	opts?: { baseURL: string }
) {
	const options: RequestInit = { method: method, headers: _getHeaders() };

	if (method === 'POST' || method === 'PUT') {
		options.body = JSON.stringify(body);
	}

	const baseUrl = opts?.baseURL || BASE_URL;

	return fetch(baseUrl + endpoint, options).then(async (res) => {
		let data;
		switch (res.status) {
			case 200:
				try {
					data = await res.json();
					return data;
				} catch {
					throw new AptoServerError('An unexpected error happened.');
				}
			case 503:
				throw new AptoServerError('It looks the server is not responding. Try again later.', {
					httpStatus: res.status,
				});
			default:
				try {
					data = await res.json();
				} catch {
					throw new AptoServerError('An unexpected error happened.', { httpStatus: res.status });
				}
				throw new AptoServerError(data.message, { code: data.code, httpStatus: res.status });
		}
	});
}

function _getHeaders() {
	const headers = {
		'Api-Key': getApiKey(),
		'Content-type': 'application/json',
	} as any; // TODO? Create a headers type.

	const token = sessionService.getUserToken();

	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}

	return headers;
}
