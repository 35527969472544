import React from 'react';
import Dot from 'styleguide/Dot/Dot';
import ITransactionStatus from 'types/transactions/ITransactionStatus';
import style from './TransactionStatusFormatter.module.scss';

export default function TransactionStatusFormatter({ value }: { value: ITransactionStatus }) {
	switch (value) {
		case 'pending':
			return (
				<span className={style.TransactionStatusFormatter}>
					<Dot /> Pending
				</span>
			);
		case 'declined':
			return (
				<span className={style.TransactionStatusFormatter}>
					<Dot type="negative" /> Declined
				</span>
			);
		case 'complete':
			return (
				<span className={style.TransactionStatusFormatter}>
					<Dot type="positive" /> Complete
				</span>
			);
		default:
			return (
				<span className={style.TransactionStatusFormatter}>
					<Dot type="negative" /> Unknown
				</span>
			);
	}
}
