import ModalLogout from 'components/ModalLogout/ModalLogout';
import { PROFILE_ROUTE } from 'constants/routes';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Modal from 'styleguide/Modal/Modal';

interface IUseProfileArgs {
	onLogout: () => Promise<any>;
	onCloseClicked: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function useProfile(args: IUseProfileArgs) {
	const navigate = useNavigate();

	function onOpenProfile(e: MouseEvent<HTMLButtonElement>) {
		if (args.onCloseClicked) {
			args.onCloseClicked(e);
		}

		navigate(PROFILE_ROUTE);
	}

	function handleLogout() {
		Modal.hide();
		args.onLogout().catch(() => {
			toast.error('There was an error logging you out. Please try again in a few moments.');
		});
	}

	function showModalLogout() {
		Modal.show(<ModalLogout onLogout={handleLogout} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Logout',
		});
	}

	return {
		showModalLogout,
		onOpenProfile,
	};
}
