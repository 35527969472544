import IPaginationConfig from 'types/IPaginationConfig';
import { useQueryParams } from './useQueryParams';

interface IPaginationOptions {
	startKey: string;
	endKey: string;
	limit?: number;
}

const defaultOptions: IPaginationOptions = {
	startKey: 'starting_after',
	endKey: 'ending_before',
};

/**
 * Utility function to get the pagination search parameters from the URL.
 * By default it will use the `starting_after` and `ending_before` keys but can be customized
 *
 * @param args.startKey The key to use for the start parameter
 * @param args.endKey The key to use for the end parameter
 * @param args.limit? The limit to use for the pagination
 */
export default function useGetPaginationConfig(args = defaultOptions): IPaginationConfig {
	const { startKey, endKey } = { ...defaultOptions, ...args };
	const params = useQueryParams();

	return {
		start: params.get(startKey) || undefined,
		end: params.get(endKey) || undefined,
		limit: args.limit,
	};
}
