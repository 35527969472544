import { useState } from 'react';
import { IAptoCardSelectProps } from './AptoCardSelect';

export default function useAptoCardSelect(props: IAptoCardSelectProps) {
	const [isExpanded, setExpanded] = useState(false);

	function handleAptoCardClick(id: string) {
		props.onAptoCardClick && props.onAptoCardClick(id);
		setExpanded(false);
	}

	return {
		isExpanded,
		setExpanded,
		handleAptoCardClick,
	};
}
