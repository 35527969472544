import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import style from './PlanSummarySection.module.scss';

export default function PlanSummarySection() {
	return (
		<PaddingContainer>
			<div className={style.PlanSummarySection}>Current plan</div>
		</PaddingContainer>
	);
}
