import { WEBHOOKS_ROUTE } from 'constants/routes';
import { useCreateWebhookMutation } from 'queries/webhooks.queries';
import { useNavigate } from 'react-router';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

export default function useCreateWebhook() {
	const navigate = useNavigate();
	const createWebhookMutation = useCreateWebhookMutation({
		onSuccess: () => navigate(WEBHOOKS_ROUTE),
	});

	const { values, errors, handleBlur, handleFocus, handleChange, submitForm, isButtonEnabled, setValues } = useForm({
		initialValues: {
			url: '',
			description: '',
			username: '',
			password: '',
			cardSentEvent: false,
			identityUpdateEvent: false,
			kycStatusEvent: false,
			pinUpdateEvent: false,
			statusUpdateEvent: false,
			transactionUpdateEvent: false,
			userUpdateEvent: false,
		},
		onSubmit: function onSubmit(v) {
			createWebhookMutation.mutate({
				event_types: _transformEventNamesToSnakeCase(v),
				url: v.url,
				description: v.description,
				authentication: {
					auth_type: 'basic_auth',
					data: {
						password: v.password,
						username: v.username,
					},
				},
			});
		},
		validate: validationService.createValidator({
			description: [validationService.maxLength(300), validationService.required],
			username: [validationService.maxLength(300), validationService.required],
			url: [validationService.maxLength(2000), validationService.url, validationService.required],
			password: [validationService.maxLength(300), validationService.required],
		}),
	});

	const areAllSelected =
		values.cardSentEvent &&
		values.pinUpdateEvent &&
		values.statusUpdateEvent &&
		values.identityUpdateEvent &&
		values.kycStatusEvent &&
		values.transactionUpdateEvent &&
		values.userUpdateEvent;

	function _transformEventNamesToSnakeCase(v: any): string[] {
		const events = [];
		if (v.cardSentEvent) {
			events.push('card_sent');
		}
		if (v.pinUpdateEvent) {
			events.push('pin_update');
		}
		if (v.identityUpdateEvent) {
			events.push('identity_update');
		}
		if (v.statusUpdateEvent) {
			events.push('status_update');
		}
		if (v.kycStatusEvent) {
			events.push('kyc_status_update');
		}
		if (v.transactionUpdateEvent) {
			events.push('transaction_update');
		}
		if (v.userUpdateEvent) {
			events.push('user_update');
		}
		return events;
	}

	function selectAll() {
		return setValues(
			{
				...values,
				cardSentEvent: true,
				pinUpdateEvent: true,
				statusUpdateEvent: true,
				identityUpdateEvent: true,
				kycStatusEvent: true,
				transactionUpdateEvent: true,
				userUpdateEvent: true,
			},
			false
		);
	}

	function deselectAll() {
		return setValues(
			{
				...values,
				cardSentEvent: false,
				pinUpdateEvent: false,
				statusUpdateEvent: false,
				identityUpdateEvent: false,
				kycStatusEvent: false,
				transactionUpdateEvent: false,
				userUpdateEvent: false,
			},
			false
		);
	}

	return {
		areAllSelected,
		deselectAll,
		errors,
		handleBlur,
		handleChange,
		handleFocus,
		isButtonEnabled,
		isSubmitting: createWebhookMutation.isLoading,
		selectAll,
		submitForm,
		serverError: createWebhookMutation.error?.message || '',
		values,
	};
}
