import ITeamMember from 'types/team/ITeamMember';
import apiFetch from '../utils/fetch';
import teamMembersParser from './teamMembers.parser';

const TEAM_MEMBERS_ENDPOINT = '/developer/settings/team_members';

export interface ITeamMembersReadResponse {
	teamMembers: Array<ITeamMember>;
	hasMore: boolean;
	total: number;
}

async function read(): Promise<ITeamMembersReadResponse> {
	return apiFetch(TEAM_MEMBERS_ENDPOINT, 'GET').then(teamMembersParser.parseTeamMembers);
}

async function remove(teamMemberId: string) {
	return apiFetch(`${TEAM_MEMBERS_ENDPOINT}/${teamMemberId}`, 'DELETE');
}

export default {
	read,
	remove,
};
