import React, { ChangeEvent, FocusEvent } from 'react';
import amountInputService from 'services/amountInput.service';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './AmountInput.module.scss';

export interface IAmountInputProps {
	id: string;
	name: string;
	value?: any;
	error?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function AmountInput(props: IAmountInputProps) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (!props.onChange) {
			return;
		}
		const newValue = amountInputService.format(e.currentTarget.value);
		e.currentTarget.value = newValue;
		props.onChange(e);
	}

	return (
		<View className={style.AmountInput}>
			<View className={style.AmountInput__amount}>
				<span className={style.AmountInput__currency}>$</span>
				<input
					type="text"
					aria-label="Load funds amount"
					className={style.AmountInput__input}
					placeholder="0.00"
					id={props.id}
					name={props.name}
					value={props.value}
					onChange={handleChange}
					onFocus={props.onFocus}
					onBlur={props.onBlur}
				/>
			</View>
			{props.error ? (
				<Paragraph size="small" color="contentPrimary" margin="8px 0 0">
					{props.error}
				</Paragraph>
			) : null}
		</View>
	);
}
