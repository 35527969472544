import { rest } from 'msw';
import accounts from './accounts.handlers';
import activateAccount from './activateAccount';
import agreements from './agreements';
import apiKeys from './apiKeys';
import cards from './cards';
import developerAccounts from './developerAccounts';
import invitations from './invitations';
import paymentSources from './paymentSources.handlers';
import teamMembers from './teamMembers';
import transactions from './transactions';
import users from './users';

export const handlers = [
	rest.get('https://api.stg.aptopayments.com/v1/developer/transactions', transactions.get),
	rest.get('https://api.stg.aptopayments.com/v1/developer/settings/transactions', transactions.get),
	rest.get('https://api.stg.aptopayments.com/v1/developer/transactions/:id/summary', transactions.getSummary),
	rest.get('https://api.stg.aptopayments.com/v1/developer/cards', cards.get),
	rest.get('https://api.stg.aptopayments.com/v1/developer/cards/:id/summary', cards.getSummary),
	rest.get('https://api.stg.aptopayments.com/v1/developer/developer_accounts', developerAccounts.get),
	rest.get('https://api.stg.aptopayments.com/v1/developer/agreements', agreements.get),
	...apiKeys,
	...paymentSources,
	...accounts,
	...teamMembers,
	...invitations,
	...activateAccount,
	...users,
];
