import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ICoreApiKey from 'types/ICoreApiKey';
import CredentialBlock from './components/CredentialBlock/CredentialBlock';
import CredentialList from './components/CredentialList/CredentialList';
import MobileAPIKey from './components/MobileApiKey/MobileApiKey';
import SignedPayloadsSection from './components/SignedPayloadsSection/SignedPayloadsSection';
import style from './CredentialsSectionSuccess.module.scss';
import useCredentialSectionSuccess from './useCredentialSectionSuccess';

export interface ICredentialsSectionProps {
	coreApiKeys: ICoreApiKey[];
	mobileApiKey: string;
}

export default function CredentialsSectionSuccess(props: ICredentialsSectionProps) {
	const {
		accessLevel,
		environment,
		handleApiKeyDeleteClicked,
		isProduction,
		lastApplicationStatus,
		showModalAccountActivation,
		showModalApiKeys,
	} = useCredentialSectionSuccess();

	return (
		<View>
			<PaddingContainer>
				<Heading size="small" margin="50px 0 30px 0" tag="h2">
					Mobile API
				</Heading>
			</PaddingContainer>
			<CredentialBlock
				title="Mobile API Key"
				description="Our Mobile API provides a secure connection between an individual cardholder’s application and our backend. Use this key in our SDKs."
				linkUrl="https://docs.aptopayments.com/api/mobile-api"
				linkText="Learn more about the Mobile API."
			>
				<MobileAPIKey secret={props.mobileApiKey} />
			</CredentialBlock>

			<SignedPayloadsSection isProduction={isProduction} />

			<PaddingContainer>
				<Heading size="small" margin="50px 0 30px 0" tag="h2">
					Core API
				</Heading>
			</PaddingContainer>

			<CredentialBlock
				title="Core API Keys"
				description="Our Core API provides a secure connection between your backend and ours, revealing data for your entire card program."
				linkUrl="https://docs.aptopayments.com/api/core-api"
				linkText="Learn more about the Core API."
				handleButtonClick={showModalApiKeys}
			>
				<CredentialList
					environment={environment}
					coreApiKeys={props.coreApiKeys}
					onCreateCredentialClick={showModalApiKeys}
					onDeleteCredentialClick={handleApiKeyDeleteClicked}
				/>
			</CredentialBlock>
			{accessLevel === 'sandbox' && lastApplicationStatus !== 'pending_review' ? (
				<View className={style.CredentialsSectionSuccess__cta}>
					<Paragraph color="contentPrimary" size="medium">
						Activate your account to generate production API keys.
					</Paragraph>
					<Pill type="button" onClick={showModalAccountActivation} size="medium" level="cta-link">
						Activate my account
					</Pill>
				</View>
			) : null}
		</View>
	);
}
