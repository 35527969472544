import LastFour from 'components/cards/LastFour/LastFour';
import DescriptionItem from 'components/DescriptionItem/DescriptionItem';
import TransactionStatusFormatter from 'components/formatters/TransactionStatusFormatter/TransactionStatusFormatter';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IAptoCard from 'types/aptoCards/IAptoCard';
import ICardholder from 'types/cardholders/ICardholder';
import ITransaction from 'types/transactions/ITransaction';
import HeaderSection from './components/HeaderSection/Header.section';
import PaymentMethodPreview from './components/PaymentMethodPreview/PaymentMethodPreview';
import style from './Transaction.module.scss';
import useTransactionPageSuccess from './useTransactionPageSuccess';

interface ITransactionPageSuccessProps {
	card: IAptoCard;
	transaction: ITransaction;
	user: ICardholder;
}

export default function TransactionPageSuccess(props: ITransactionPageSuccessProps) {
	const { onUserClicked, onCardClicked, isProduction } = useTransactionPageSuccess();

	return (
		<View data-testid="page-transaction">
			<PaddingContainer>
				<HeaderSection transaction={props.transaction} />

				<View className={style.TransactionPageSuccess__details}>
					<DescriptionItem className={style.TransactionPageSuccess__descriptionItem} title="Transaction ID">
						<Paragraph size="small">{props.transaction.id}</Paragraph>
					</DescriptionItem>
					<DescriptionItem
						className={style.TransactionPageSuccess__descriptionItem}
						title="Card Number"
						onClick={() => props.card && onCardClicked(props.card.id)}
					>
						<View style={{ marginTop: '4px' }}>
							<LastFour value={props.card.lastFour}></LastFour>
						</View>
					</DescriptionItem>
					<DescriptionItem
						className={style.TransactionPageSuccess__descriptionItem}
						title="User"
						onClick={() => props.user && onUserClicked(props.user.id)}
					>
						<Paragraph size="small">
							{props.user.firstName} {props.user.lastName}
						</Paragraph>
					</DescriptionItem>
					<DescriptionItem className={style.TransactionPageSuccess__descriptionItem} title="Status">
						<Paragraph size="small">
							<TransactionStatusFormatter value={props.transaction.state} />
						</Paragraph>
					</DescriptionItem>
					{isProduction ? (
						<DescriptionItem className={style.TransactionPageSuccess__descriptionItem} title="Payment Method">
							<PaymentMethodPreview network={props.card.network} />
						</DescriptionItem>
					) : null}
				</View>
			</PaddingContainer>
		</View>
	);
}
