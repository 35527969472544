import { APTO_CARD_NETWORKS, CARD_STATUSES, DESIGN_KEYS, TRANSACTION_STATUSES } from 'mocks/statuses';
import mocksService from '../mocks.service';

function get(req: any, res: any, ctx: any) {
	const { startingAfter, endingBefore } = mocksService.getPaginationParams(req.url.searchParams);

	if (!isNaN(endingBefore)) {
		return res(
			ctx.json({
				cards: mocksService.createItems(_createCard, Math.max(endingBefore - 10, 1)),
				has_more: true,
				total: 30,
			})
		);
	}

	return res(
		ctx.json({
			cards: mocksService.createItems(_createCard, isNaN(startingAfter) ? 0 : startingAfter),
			has_more: isNaN(startingAfter) ? true : startingAfter <= 20,
			total: 30,
		})
	);
}

function getSummary(req: any, res: any, ctx: any) {
	return res(ctx.json(_createCardSummary()));
}

export default {
	get,
	getSummary,
};

function _createCard(id: number) {
	return {
		card: {
			designKey: mocksService.randomizeStatus(DESIGN_KEYS),
			id,
			lastFour: '1234',
			nameOnCard: 'JANE COOPER',
			network: mocksService.randomizeStatus(APTO_CARD_NETWORKS),
			status: 'ACTIVATED',
		},
	};
}

function _createCardSummary() {
	return {
		card: {
			id: 'crd_fde2d61d233455b9',
			design_key: mocksService.randomizeStatus(DESIGN_KEYS),
			created_at: '2016-10-19T23:25:17.000Z',
			activated_at: '2016-10-19T23:25:17.000Z',
			shipped_at: '2016-10-19T23:25:17.000Z',
			last_activity_at: '2016-10-19T23:25:17.000Z',
			card_type: 'DIGITAL',
			logo_url: 'string',
			billing_address: {
				street_one: 'Malibu Point 10880',
				street_two: '',
				locality: 'Malibu',
				region: 'CA',
				postal_code: '90265',
				country: 'US',
			},
			last_four: '5542',
			name_on_card: 'JANE COOPER',
			status: mocksService.randomizeStatus(CARD_STATUSES),
			network: mocksService.randomizeStatus(APTO_CARD_NETWORKS),
		},
		user: {
			id: 'crdhldr_1cd68f70917cb5ed',
			custodian_uid: 'your-external-user-id',
			created_at: '2016-10-19T23:20:17.000Z',
			email: 'pat@wilson.com',
			name: {
				first_name: 'Jane',
				last_name: 'Cooper',
			},
			kyc: {
				passed_at: '2016-10-19T23:20:21.034Z',
				status: 'PASSED',
			},
		},
		transactions: [
			{
				transaction: {
					id: 'txn_12aa2c96a548d7d2',
					local_amount: {
						currency: 'EUR',
						amount: -20.44,
					},
					hold_amount: {
						currency: 'USD',
						amount: 0,
					},
					billing_amount: {
						currency: 'USD',
						amount: -23.57,
					},
					cashback_amount: {
						currency: 'USD',
						amount: 0,
					},
					fee_amount: {
						currency: 'USD',
						amount: -2.5,
					},
					created_at: '2016-10-19T23:25:17.000Z',
					description: 'string',
					merchant_name: 'LORDS PHARMACY LTD',
					state: mocksService.randomizeStatus(TRANSACTION_STATUSES),
				},
			},
		],
	};
}
