import React from 'react';
import Modal from 'styleguide/Modal/Modal';
import IApplicationStatus from 'types/session/IApplicationStatus';
import ModalAccountActivation from './ModalAccountActivation';

function showModalAccountActivation(
	lastApplicationStatus: IApplicationStatus | undefined,
	lastApplicationId: string | undefined
) {
	Modal.show(
		<ModalAccountActivation lastApplicationStatus={lastApplicationStatus} lastApplicationId={lastApplicationId} />,
		{
			canBeClosedOnClickOut: true,
			ariaTitle: 'Activate your account',
		}
	);
}

export default {
	showModalAccountActivation,
};
