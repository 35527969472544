import IPaginationConfig from 'types/IPaginationConfig';

function getPaginationParams(paginationConfig?: IPaginationConfig): URLSearchParams {
	const params = new URLSearchParams();

	if (paginationConfig?.limit) {
		params.append('limit', paginationConfig.limit.toString());
	}

	if (paginationConfig?.start) {
		params.append('starting_after', paginationConfig.start);
		return params;
	}

	if (paginationConfig?.end) {
		params.append('ending_before', paginationConfig.end);
		return params;
	}

	return params;
}

export default {
	getPaginationParams,
};
