import dummy_list_team_members_response from 'mocks/serverResponses/team_members/dummy_list_team_members_response';
import { rest } from 'msw';

const teamMembersHandlers = [
	rest.get('https://api.stg.aptopayments.com/v1/developer/settings/team_members', (req, res, ctx) => {
		return res(ctx.json(dummy_list_team_members_response), ctx.status(200));
	}),
	rest.get('https://api.stg.aptopayments.com/v1/developer/settings/team_members/:id', (req, res, ctx) => {
		return res(ctx.json({}), ctx.status(200));
	}),
];

export default teamMembersHandlers;
