const MAX_ALLOWED_LENGTH = 64;
const MIN_REQUIRED_LENGTH = 12;
const REGEX_SPECIAL_CHARS = /[!#$%&+,-./:;<=>?@_{|}]/;
const INVALID_CHARS = /[*\s]/;

export function isValidPassword(password: string): boolean {
	const {
		hasLowercaseChar,
		hasMinimunLength,
		hasUppercaseChar,
		hasNumber,
		hasSpecialChar,
		isShorterThanMaxAllowedLength,
		hasInvalidChars,
	} = validatePassword(password);
	return (
		hasLowercaseChar &&
		hasMinimunLength &&
		hasUppercaseChar &&
		hasNumber &&
		hasSpecialChar &&
		isShorterThanMaxAllowedLength &&
		!hasInvalidChars
	);
}

export function validatePassword(password: string) {
	const hasLowercaseChar = /[a-z]/.test(password);
	const hasMinimunLength = password.length >= MIN_REQUIRED_LENGTH;
	const hasNumber = /\d/.test(password);
	const hasSpecialChar = REGEX_SPECIAL_CHARS.test(password);
	const hasUppercaseChar = /[A-Z]/.test(password);
	const isShorterThanMaxAllowedLength = password.length < MAX_ALLOWED_LENGTH;
	const hasInvalidChars = INVALID_CHARS.test(password);

	return {
		hasLowercaseChar,
		hasMinimunLength,
		hasNumber,
		hasSpecialChar,
		hasUppercaseChar,
		isShorterThanMaxAllowedLength,
		hasInvalidChars,
	};
}

export default { isValidPassword, validatePassword };
