import CurrencyFormatter from 'components/formatters/CurrencyFormatter/CurrencyFormatter';
import TransactionStatusFormatter from 'components/formatters/TransactionStatusFormatter/TransactionStatusFormatter';
import { ICurrency } from 'constants/currencies';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import Table from 'styleguide/Table/Table';
import View from 'styleguide/View/View';
import ITransaction from 'types/transactions/ITransaction';
import ITransactionStatus from 'types/transactions/ITransactionStatus';

interface ITransactionsTableData {
	id: string;
	merchantName: string;
	createdAt: Date;
	state: ITransactionStatus;
	localAmount: {
		value: number;
		currency: ICurrency;
	};
	billingAmount: {
		value: number;
		currency: ICurrency;
	};
}

interface ITransactionsSectionProps {
	transactions: ITransaction[];
	isProduction: boolean;
	onClick: (id: string) => void;
}

export default function TransactionsSection(props: ITransactionsSectionProps) {
	const columns = [
		{ key: 'merchantName', name: 'Merchant' },
		{ key: 'id', name: 'Transaction ID' },
		{ key: 'createdAt', name: `Date/Time (${dateTimeFormatService.getTimeZone()})`, renderer: _createAtRenderer },
		{ key: 'state', name: 'Status', renderer: _stateRenderer },
		{ key: 'localAmount', name: 'Trans. Amt.', renderer: _MoneyRenderer },
		{ key: 'billingAmount', name: 'Billing Amt.', renderer: _MoneyRenderer },
	];

	const data: ITransactionsTableData[] = props.transactions.map((item: any) => {
		return {
			id: item.id,
			merchantName: item.merchantName,
			createdAt: item.createdAt,
			state: item.state,
			localAmount: {
				value: item.localAmount,
				currency: item.localAmountCurrency,
			},
			billingAmount: {
				value: item.billingAmount,
				currency: item.billingAmountCurrency,
			},
			cardLastFour: item.cardLastFour,
			cardDesignKey: item.cardDesignKey,
			cardNetwork: item.cardNetwork,
		};
	});

	return (
		<View data-testid="page-transactions">
			<Table
				minWidth="800px"
				data={data}
				columns={columns}
				onRowClick={(item) => props.onClick(item.id)}
				emptyTableMessage="You haven't made any transactions yet."
			/>
		</View>
	);
}

function _MoneyRenderer({ value, currency }: { value: number; currency: ICurrency }) {
	return <CurrencyFormatter currency={currency} value={value} />;
}

function _stateRenderer(value: ITransactionStatus) {
	return <TransactionStatusFormatter value={value} />;
}

function _createAtRenderer(date: Date) {
	return (
		<View style={{ textTransform: 'capitalize' }}>
			{dateTimeFormatService.format(date, { includeHourAndMinute: true })}
		</View>
	);
}
