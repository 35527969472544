import Notification from 'components/Notification/Notification';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalDeleteMember.module.scss';
import useModalDeleteMember from './useModalDeleteMember';

export interface IModalDeleteMemberProps {
	memberId: string;
	onCancelClick: () => void;
	onSuccessfulRemove: () => void;
}

export default function ModalDeleteMember(props: IModalDeleteMemberProps) {
	const { handleRemove, isLoading, serverError } = useModalDeleteMember(props);
	return (
		<View className={style.ModalDeleteMember}>
			<Notification isHidden={!serverError} type="negative" margin="0 0 20px" title="Error" description={serverError} />
			<form>
				<Heading size="small" tag="h1" margin="0 0 12px 0">
					Remove team member
				</Heading>
				<Paragraph size="small" color="contentPrimary" className={style.ModalDeleteMember__body}>
					Are you sure you want to remove this person from the team?
				</Paragraph>
				<View className={style.ModalDeleteMember__footer}>
					<Pill onClick={props.onCancelClick} size="medium" level="secondary">
						Cancel
					</Pill>
					<Pill
						level="primary"
						size="medium"
						className={style.ModalDeleteMember__cta}
						loading={isLoading}
						onClick={handleRemove}
						data-testid="btn-remove"
					>
						Remove
					</Pill>
				</View>
			</form>
		</View>
	);
}
