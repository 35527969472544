import Notification from 'components/Notification/Notification';
import React from 'react';
import Item from 'styleguide/Item/Item';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import addImg from './button-plus.svg';
import AddOwnerCard from './components/AddOwnerCard/AddOwnerCard';
import Owners from './components/Owners/Owners';
import style from './OwnersStepWithApplicationId.module.scss';
import useOwnersStepWithApplicationId from './useOwnersStepWithApplicationId';

export default function OwnersStepWithApplicationId() {
	const {
		addOwner,
		updateOwner,
		beneficialOwners,
		businessOwners,
		cardType,
		errorMessage,
		handleAddBeneficialOwnerClick,
		handleAddBusinessOwnerClick,
		handleNextBtnClick,
		handleOwnerClick,
		hideCard,
		isNextButtonDisabled,
		isNextButtonLoading,
		isOwnerListLoading,
		isSidebarHidden,
		removeOwner,
		selectedOwner,
	} = useOwnersStepWithApplicationId();

	return (
		<View>
			<View style={{ display: 'flex' }}>
				<View style={{ marginRight: '52px', maxWidth: '500px' }}>
					<Text margin="0 0 38px 0" type="h4">
						Business owners
					</Text>

					<Notification
						type="negative"
						isHidden={!errorMessage}
						title="Error"
						description={errorMessage}
						margin="40px 0"
					/>

					<Text margin="auto 0 8px 0" type="h7">
						Control person
					</Text>

					<span className={style.OwnersStep__description}>
						Add profiles for at least one individual with significant responsibility for managing the legal entity (i.e.
						CEO, CFO, or COO).
					</span>

					<View>
						<View>
							<Owners owners={businessOwners} isLoading={isOwnerListLoading} onClick={handleOwnerClick} />
						</View>
						<Item padding="10px 0" hasBorderBottom>
							<View
								onClick={handleAddBusinessOwnerClick}
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<img src={addImg} alt="" />
								<Text margin="0 0 0 20px"> Add control person (Required)</Text>
							</View>
						</Item>
					</View>

					<Text margin="34px 0 8px 0" type="h7">
						Beneficial owners
					</Text>

					<span className={style.OwnersStep__description}>
						Add profiles for any individuals who own 25% or more of your company equity.
					</span>

					<View style={{ marginBottom: '24px' }}>
						<View>
							<Owners owners={beneficialOwners} isLoading={isOwnerListLoading} onClick={handleOwnerClick} />
						</View>
						<Item padding="10px 0" hasBorderBottom>
							<View
								onClick={handleAddBeneficialOwnerClick}
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<img src={addImg} alt="" />
								<Text margin="0 0 0 20px"> Add beneficial owner</Text>
							</View>
						</Item>
					</View>

					<Pill
						type="button"
						level="primary"
						size="medium"
						onClick={handleNextBtnClick}
						disabled={isNextButtonDisabled}
						loading={isNextButtonLoading}
					>
						Next
					</Pill>
				</View>

				<AddOwnerCard
					owner={selectedOwner}
					onAddOwner={addOwner}
					onUpdateOwner={updateOwner}
					isHidden={isSidebarHidden}
					type={cardType}
					onCloseClicked={hideCard}
					onDeleteOwner={removeOwner}
				/>
			</View>
		</View>
	);
}
