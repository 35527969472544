import Notification from 'components/Notification/Notification';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Spinner from 'styleguide/Spinner/Spinner';
import Text from 'styleguide/Text/Text';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './DeletePaymentMethod.module.scss';
import useDeletePaymentMethod from './useDeletePaymentMethod';

interface IDeletePaymentMethod {
	paymentMethodId: string;
	handleCloseModal: () => void;
	onSuccessfulDelete: () => void;
}

export default function DeletePaymentMethod({
	paymentMethodId,
	handleCloseModal,
	onSuccessfulDelete,
}: IDeletePaymentMethod) {
	const { handleDelete, isLoading, serverError } = useDeletePaymentMethod({ handleCloseModal, onSuccessfulDelete });

	if (isLoading) {
		return (
			<View className={style.PendingDeletePaymentMethod}>
				<Spinner height="96px" width="96px" />
				<Text> Deleting payment method... </Text>
			</View>
		);
	}

	return (
		<View className={style.DeletePaymentMethod}>
			<Heading tag="h1" size="small">
				Remove payment method
			</Heading>
			<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} />
			<View className={style.DeletePaymentMethod__paragraph}>
				<Paragraph size="small" color="contentPrimary">
					Are you sure you want to remove this payment method?
				</Paragraph>
			</View>
			<View className={style.DeletePaymentMethod__buttons}>
				<Pill onClick={handleCloseModal} className={style.DeletePaymentMethod__button} size="medium" level="secondary">
					Cancel
				</Pill>
				<Pill
					onClick={() => handleDelete(paymentMethodId)}
					className={style.DeletePaymentMethod__button}
					size="medium"
					level="primary"
				>
					Remove
				</Pill>
			</View>
		</View>
	);
}
