import modalAccountActivationService from 'components/ModalAccountActivation/modalAccountActivation.service';
import useSession from 'hooks/useSession';

export default function useOverviewPage() {
	const { firstName, isProduction, lastApplicationStatus, lastApplicationId, session } = useSession();

	return {
		firstName,
		isActivateAccountButtonVisible:
			session?.developerAccount.accessLevel === 'sandbox' && lastApplicationStatus !== 'pending_review',
		isPendingReviewNotificationVisible: lastApplicationStatus === 'pending_review',
		isProduction,
		isRejectedNotificationVisible: lastApplicationStatus === 'rejected',
		showModalAccountActivation: () =>
			modalAccountActivationService.showModalAccountActivation(lastApplicationStatus, lastApplicationId),
	};
}
