import View from 'styleguide/View/View';
import Text from 'styleguide/Text/Text';
import React from 'react';

export interface StyledDescriptionProps {
	icon: React.ReactNode;
	title: string;
	description: string;
}

const StyledDescription: React.FC<StyledDescriptionProps> = (props) => {
	return (
		<View>
			<View style={{ width: '90px', height: '90px', marginBottom: '32px' }}>{props.icon}</View>
			<Text type="h4"> {props.title} </Text>
			<Text margin="32px auto"> {props.description} </Text>
		</View>
	);
};

export default StyledDescription;
