import React from 'react';
import ModalAddApiKeysStep0 from './ModalAddApiKeysStep0/ModalAddApiKeysStep0';
import ModalAddApiKeysStep1 from './ModalAddApiKeysStep1/ModalAddApiKeysStep1';

interface IModalAppApiKeysProps {
	apiKey: string;
	apiSecret: string;
	created: Date;
	description: string;
	generateApiKey: () => void;
	isButtonDisabled: boolean;
	serverError: string;
	setDescription: (e: any) => void;
	step: number;
	updated: Date;
}

export default function ModalAddApiKeys(props: IModalAppApiKeysProps) {
	if (props.step === 1) {
		return (
			<ModalAddApiKeysStep1
				apiKey={props.apiKey}
				apiSecret={props.apiSecret}
				created={props.created}
				updated={props.updated}
			/>
		);
	}

	return (
		<ModalAddApiKeysStep0
			error={props.serverError}
			setDescription={props.setDescription}
			description={props.description}
			onCreateClick={props.generateApiKey}
			isButtonDisabled={props.isButtonDisabled}
		/>
	);
}
