import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, useState } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './Password.module.scss';

export interface IPasswordProps extends ICommonInputProps {
  type: 'password';
  value?: string;
  showPasswordToggle?: boolean;
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function Password(props: IPasswordProps) {
  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  if (props.showPasswordToggle) {
    return (
      <div
        style={{ margin: props.margin }}
        className={classnames(style.Password, style[cnContent], style[cnError], style[cnDisabled])}
      >
        <input
          className={style.Password__input}
          disabled={props.disabled}
          autoComplete={props.autocomplete}
          pattern={props.pattern}
          required={props.required}
          autoFocus={props.autofocus}
          id={props.id}
          type={isPasswordVisible ? 'text' : 'password'}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
        <label className={style.Password__label} htmlFor={props.id}>
          {' '}
          {props.label}{' '}
        </label>
        {isPasswordVisible ? (
          <button
            type="button"
            aria-label="Hide password"
            className={classnames(style.Password__button, style['Password__button--closed'])}
            onClick={() => setPasswordVisible(false)}
          ></button>
        ) : (
          <button
            type="button"
            aria-label="Show password"
            className={classnames(style.Password__button, style['Password__button--open'])}
            onClick={() => setPasswordVisible(true)}
          ></button>
        )}
        {props.error ? <span className={style.Password__error}>{props.error}</span> : null}
      </div>
    );
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(style.Password, style[cnContent], style[cnError], style[cnDisabled])}
    >
      <input
        className={style.Password__input}
        disabled={props.disabled}
        autoComplete={props.autocomplete}
        pattern={props.pattern}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        type={isPasswordVisible ? 'text' : 'password'}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      <label className={style.Password__label} htmlFor={props.id}>
        {' '}
        {props.label}{' '}
      </label>
      {props.error ? <span className={style.Password__error}>{props.error}</span> : null}
    </div>
  );
}
