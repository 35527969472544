import classnames from 'classnames';
import Notification from 'components/Notification/Notification';
import React from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import style from './ModalInviteMemberForm.module.scss';

export interface IModalInviteMemberFormProps {
	bind: any;
	isSubmitting: boolean;
	isButtonDisabled: boolean;
	serverError: string;
	submitForm: any;
}

export default function ModalInviteMemberForm({
	bind,
	isSubmitting,
	isButtonDisabled,
	serverError,
	submitForm,
}: IModalInviteMemberFormProps) {
	return (
		<View className={style.ModalInviteMemberForm}>
			<form>
				<Heading size="small" tag="h1" margin="0 0 12px 0">
					Invite team member
				</Heading>
				<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} />
				<View className={style.ModalInviteMemberForm__body}>
					<View className={classnames(style.ModalInviteMemberForm__tagsInput, style.TagsInput)}>
						<Input margin="12px 0 0" label="Invite someone..." {...bind('email')} type="email" />
					</View>
					<View className={style['ModalInviteMemberForm__dropdown--disabled']}>Administrator</View>
				</View>
				<View className={style.ModalInviteMemberForm__footer}>
					<Pill
						type="submit"
						level="primary"
						size="medium"
						className={style.ModalInviteMemberForm__link}
						disabled={isButtonDisabled}
						loading={isSubmitting}
						onClick={submitForm}
						data-testid="btn-send"
					>
						Send invite
					</Pill>
				</View>
			</form>
		</View>
	);
}
