import classnames from 'classnames';
import React from 'react';
import style from './Initials.module.scss';
import InitialsService from './Initials.service';

export type IInitalsColors = 'orange' | 'green' | 'yellow' | 'gray' | 'blue' | 'purple' | 'empty';

export interface IInitialsProps {
	className?: string;
	firstName: string;
	lastName: string;
}

export default function Initials(props: IInitialsProps) {
	return (
		<div
			className={classnames(
				_getClass(InitialsService.getColor(props.firstName)),
				_getContentClass(InitialsService.getColor(props.firstName)),
				props.className
			)}
			data-testid="initials"
		>
			<span>
				{props.firstName ? props.firstName[0] : ''}
				{props.lastName ? props.lastName[0] : ''}
			</span>
		</div>
	);
}

function _getClass(color: IInitalsColors): string {
	switch (color) {
		case 'empty':
			return style['Initials--empty'];
		case 'orange':
			return style['Initials--orange'];
		case 'green':
			return style['Initials--green'];
		case 'yellow':
			return style['Initials--yellow'];
		case 'blue':
			return style['Initials--blue'];
		case 'purple':
			return style['Initials--purple'];
		case 'gray':
		default:
			return style['Initials--gray'];
	}
}

function _getContentClass(color: IInitalsColors): string {
	switch (color) {
		case 'orange':
			return style['Initials__content--orange'];
		case 'green':
			return style['Initials__content--green'];
		case 'yellow':
			return style['Initials__content--yellow'];
		case 'blue':
			return style['Initials__content--blue'];
		case 'purple':
			return style['Initials__content--purple'];
		case 'gray':
		default:
			return style['Initials__content--gray'];
	}
}
