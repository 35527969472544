import { useFeature } from '@optimizely/react-sdk';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import {
	BILLING_BALANCES_ROUTE,
	BILLING_INVOICES_ROUTE,
	BILLING_PAYMENT_METHODS_ROUTE,
	BILLING_PLAN_SUMMARY_ROUTE,
	BILLING_TRANSACTION_HISTORY_ROUTE,
} from 'constants/routes';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TabItem from 'styleguide/Tabs/TabItem/TabItem';
import TabsList from 'styleguide/Tabs/TabsList/TabsList';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import style from './BillingPage.module.scss';
import BalancesSection from './sections/Balances/Balances.section';
import InvoicesSection from './sections/Invoices/Invoices.section';
import PaymentMethodsSection from './sections/PaymentMethods/PaymentMethods.section';
import PlanSummarySection from './sections/PlanSummary/PlanSummary.section';
import TransactionHistorySection from './sections/TransactionHistory/TransactionHistory.section';

export default function BillingRootPage() {
	const [isPlanSummaryEnabled] = useFeature('BILLING_PLAN_SUMMARY');
	const [isBalancesEnabled] = useFeature('BILLING_BALANCES');
	const [isInvoicesEnabled] = useFeature('BILLING_INVOICES');

	return (
		<>
			<PaddingContainer>
				<Heading size="large" tag="h1" margin="0 0 24px">
					Billing
				</Heading>

				<View className={style.TabsNav}>
					<TabsList>
						{isPlanSummaryEnabled ? <TabItem name="Plan summary" to={BILLING_PLAN_SUMMARY_ROUTE} /> : null}
						{isBalancesEnabled ? <TabItem name="Balances" to={BILLING_BALANCES_ROUTE} /> : null}
						{isInvoicesEnabled ? <TabItem name="Invoices" to={BILLING_INVOICES_ROUTE} /> : null}
						<TabItem name="Payment methods" to={BILLING_PAYMENT_METHODS_ROUTE} />
						<TabItem name="Transaction history" to={BILLING_TRANSACTION_HISTORY_ROUTE} />
					</TabsList>
				</View>
			</PaddingContainer>
			<Routes>
				{isPlanSummaryEnabled ? <Route index element={<Navigate replace to={BILLING_PLAN_SUMMARY_ROUTE} />} /> : null}
				{isPlanSummaryEnabled ? <Route path="plan-summary" element={<PlanSummarySection />} /> : null}
				{isBalancesEnabled ? <Route path="balances" element={<BalancesSection />} /> : null}
				{isInvoicesEnabled ? <Route path="invoices" element={<InvoicesSection />} /> : null}
				<Route path="payment-methods" element={<PaymentMethodsSection />} />
				<Route path="transaction-history" element={<TransactionHistorySection />} />
			</Routes>
		</>
	);
}
