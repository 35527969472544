import React from 'react';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import style from './Header.module.scss';

export default function TeamHeader() {
	return (
		<View className={style.Header}>
			<Heading size="small" tag="h2" margin="0 20px 0 0">
				Legal
			</Heading>
		</View>
	);
}
