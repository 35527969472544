import usePaginationUtils from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { useListDeveloperTransactionsQuery } from 'queries/accounts';
import ITransaction from 'types/transactions/ITransaction';

const QUERY_PARAMS_TR_START = 'tr_start';
const QUERY_PARAMS_TR_END = 'tr_end';
const TRANSACTIONS_PER_PAGE = 10;

export default function useTransactionHistorySection() {
	const params = useQueryParams();

	const transactionsQuery = useListDeveloperTransactionsQuery({
		paginationConfig: {
			start: params.get(QUERY_PARAMS_TR_START) ?? undefined,
			end: params.get(QUERY_PARAMS_TR_END) ?? undefined,
			limit: TRANSACTIONS_PER_PAGE,
		},
	});

	const transactions = transactionsQuery.data?.transactions || [];

	// TODO: This should be moved to a success hook on the transactions history success page
	const { goToNextPage, goToPrevPage } = usePaginationUtils({
		startKey: QUERY_PARAMS_TR_START,
		endKey: QUERY_PARAMS_TR_END,
		items: transactions,
	});

	return {
		props: {
			transactions: transactions as ITransaction[],
			serverError: transactionsQuery.error?.message || '',
			goToNextPage,
			goToPrevPage,
			isPaginationNextVisible: transactionsQuery.data?.hasMore as boolean,
			isPaginationPrevVisible: transactionsQuery.data?.hasMoreBefore as boolean,
		},
		status: transactionsQuery.status,
	};
}
