import { ICurrency } from 'constants/currencies';
import React from 'react';
import style from './CurrencyFormatter.module.scss';

export default function CurrencyFormatter({ currency, value }: { currency: ICurrency; value: number }) {
	switch (currency) {
		case 'EUR':
			return value >= 0 ? <span className={style['Currency--positive']}>+{value} €</span> : <>{Math.abs(value)} €</>;
		case 'USD':
			return value >= 0 ? <span className={style['Currency--positive']}>+${value}</span> : <>${Math.abs(value)}</>;
		default:
			return value >= 0 ? (
				<span className={style['Currency--positive']}>
					+{currency} {value}
				</span>
			) : (
				<>
					{currency} {Math.abs(value)}
				</>
			);
	}
}
