function formatTwoDecimals(amount: string) {
	const amountAsNumber = Number(amount);
	return isNaN(amountAsNumber) ? amount : amountAsNumber.toFixed(2);
}

function formatDate(date: Date) {
	return new Intl.DateTimeFormat('en-US', {
		dateStyle: 'long',
		timeStyle: 'short',
	} as any).format(date);
}

export default {
	formatDate,
	formatTwoDecimals,
};
