import { useGetCardQuery } from 'queries/cards.queries';
import { useParams } from 'react-router';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';

export default function useCardDetailsPage() {
	const { id } = useParams() as { id: string };
	const cardQuery = useGetCardQuery({ id });

	return {
		props: {
			// SafeCast: This will be defined because WithQuery is being used
			...(cardQuery.data as IAptoCardSummaryResponse),
			serverError: cardQuery.error?.message || '',
		},
		status: cardQuery.status,
	};
}
