import apiFetch from 'api-client/utils/fetch';
import toCamelCase from 'api-client/utils/toCamelCase';
import ICoreApiKey from 'types/ICoreApiKey';
import IEnvironment from 'types/session/IEnvironment';

const API_KEYS_ENDPOINT = '/developer/api_keys';

interface ICreateArgs {
	environment: IEnvironment;
	description: string;
}

export interface ICreateApiKeyResponse {
	apiClientId: string;
	apiClientSecret: string;
	created: Date;
	description: string;
	keyId: string;
	status: 'active' | 'revoked';
	updated: Date;
}

async function create(args: ICreateArgs): Promise<ICreateApiKeyResponse> {
	return apiFetch(API_KEYS_ENDPOINT, 'POST', {
		environment: args.environment,
		description: args.description,
	})
		.then(toCamelCase)
		.then(_parseDatesInCoreApiKey);
}

interface IReadArgs {
	environment: IEnvironment;
}
export interface IReadApiKeysResponse {
	mobileApiKey: string;
	coreApiKeys: {
		data: ICoreApiKey[];
		hasMore: boolean;
		page: number;
		row: number;
		totalRows: number;
	};
}

async function read(args: IReadArgs): Promise<IReadApiKeysResponse> {
	return apiFetch(`${API_KEYS_ENDPOINT}?environment=${args.environment}`, 'GET').then(toCamelCase).then(_parseDates);
}

function _parseDates({ mobileApiKey, coreApiKeys }: any): IReadApiKeysResponse {
	coreApiKeys.data = coreApiKeys.data.map(_parseDatesInCoreApiKey);
	return { mobileApiKey, coreApiKeys };
}

function _parseDatesInCoreApiKey(camelCaseCoreApiKey: any): ICreateApiKeyResponse {
	return {
		...camelCaseCoreApiKey,
		created: new Date(camelCaseCoreApiKey.created),
		updated: new Date(camelCaseCoreApiKey.updated),
	};
}

interface IRemoveArgs {
	id: string;
	environment: IEnvironment;
}

async function remove(args: IRemoveArgs) {
	return apiFetch(`${API_KEYS_ENDPOINT}/${args.id}?environment=${args.environment}`, 'DELETE');
}

export default {
	create,
	read,
	remove,
};
