import LastFour from 'components/cards/LastFour/LastFour';
import Item from 'styleguide/Item/Item';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import View from 'styleguide/View/View';
import ICardNetwork from 'types/financial/ICardNetwork';
import DeletePaymentMethod from './DeletePaymentMethod/DeletePaymentMethod';
import style from './Payments.module.scss';

interface IPaymentMethodProps {
	id: string;
	network: ICardNetwork;
	description: string;
	lastFour: string;
	onSuccessfulDelete: () => void;
	isRemoveEnabled: boolean;
}

export default function PaymentMethod(props: IPaymentMethodProps) {
	function showDeletePaymentMethodModal() {
		Modal.show(
			<DeletePaymentMethod
				paymentMethodId={props.id}
				handleCloseModal={Modal.hide}
				onSuccessfulDelete={props.onSuccessfulDelete}
			/>,
			{
				canBeClosedOnClickOut: false,
				ariaTitle: 'Delete payment method',
			}
		);
	}

	return (
		<Item padding="8px 0">
			<View className={style.PaymentMethodItem} data-testid={`PaymentMethodItem-${props.id}`}>
				<View className={style.PaymentMethodItem__cardInfo}>
					<LastFour
						value={props.lastFour}
						description={props.description}
						network={props.network}
						bold={true}
						iconSize="medium"
						typeOfCard="external"
					/>
				</View>
				<Pill level="secondary" size="small" onClick={showDeletePaymentMethodModal} disabled={!props.isRemoveEnabled}>
					Remove
				</Pill>
			</View>
		</Item>
	);
}
