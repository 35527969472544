import { useState } from 'react';
import { ITeamRoleName } from 'types/team/ITeamRole';
import { ITeamRoleSelectProps } from './TeamRoleSelect';

export default function useTeamRoleSelect(props: ITeamRoleSelectProps) {
	const [isExpanded, setExpanded] = useState(false);

	function handleChangeTeamRole(teamRoleName: ITeamRoleName) {
		props.onTeamRoleNameClick(teamRoleName);
		setExpanded(false);
	}

	function handleRemoveTeamRole() {
		props.onRemoveTeamMemberClick();
		setExpanded(false);
	}

	return {
		isExpanded,
		setExpanded,
		handleChangeTeamRole,
		handleRemoveTeamRole,
	};
}
