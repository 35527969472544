import React from 'react';
import './Text.scss';

export interface TextProps {
  block?: boolean;
  center?: boolean;
  children: React.ReactNode;
  className?: string;
  color?: 'negative' | 'positive' | 'body' | 'muted' | 'primary' | 'black';
  id?: React.HTMLAttributes<HTMLElement>['id'];
  inline?: boolean;
  margin?: React.CSSProperties['margin'];
  padding?: React.CSSProperties['padding'];
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  type?: 'h1' | 'h4' | 'h5' | 'h6' | 'h7' | 'medium' | 'small' | 'footnote';
  weight?: React.CSSProperties['fontWeight'];
}

const Text: React.FC<TextProps> = (props) => {
  let className = props.className ? 'Text ' + props.className : 'Text';

  if (props.color) {
    className += _getColorClass(props.color);
  }
  if (props.center) {
    className += ' isCenter';
  }
  if (props.block) {
    className += ' isBlock';
  }
  if (props.inline) {
    className += ' isInline';
  }

  let tag = '';
  switch (props.type) {
    case 'h1':
      className += ' h1';
      tag = props.tag || 'h1';
      break;
    case 'h4':
      className += ' h4';
      tag = props.tag || 'h4';
      break;
    case 'h5':
      className += ' h5';
      tag = props.tag || 'h5';
      break;
    case 'h6':
      className += ' h6';
      tag = props.tag || 'h6';
      break;
    case 'h7':
      className += ' h7';
      break;
    case 'medium':
      className += ' medium';
      break;
    case 'small':
      className += ' small';
      break;
    case 'footnote':
      className += ' footnote';
      break;
  }

  switch (tag) {
    case 'h1':
      return (
        <h1
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </h4>
      );
    case 'h5':
      return (
        <h5
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </h5>
      );
    case 'h6':
      return (
        <h6
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </h6>
      );
    default:
      return (
        <span
          id={props.id}
          style={{
            padding: props.padding,
            margin: props.margin,
            fontWeight: props.weight,
          }}
          className={className}
        >
          {props.children}
        </span>
      );
  }
};

function _getColorClass(color: string) {
  switch (color) {
    case 'negative':
      return ' isNegative';
    case 'positive':
      return ' isPositive';
    case 'body':
      return ' isBody';
    case 'muted':
      return ' isMuted';
    case 'primary':
      return ' isPrimary';
    case 'black':
      return ' isBlack';
    default:
      return '';
  }
}

export default Text;
