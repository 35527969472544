import ContentHeader from 'components/ContentHeader/ContentHeader';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';

interface IUsersHeaderProps {
	total: number;
	isAddButtonVisible: boolean;
	onAddClick?: () => void;
}

export default function UsersHeader(props: IUsersHeaderProps) {
	if (props.isAddButtonVisible) {
		return (
			<ContentHeader title="Users" total={props.total}>
				<Pill level="primary" size="small" onClick={() => props.onAddClick && props.onAddClick()}>
					Add
				</Pill>
			</ContentHeader>
		);
	}

	return <ContentHeader title="Users" total={props.total} />;
}
