import React, { PropsWithChildren } from 'react';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ContentHeader.module.scss';

interface IContentHeader {
	title: string;
	total?: number;
}

export default function ContentHeader(props: PropsWithChildren<IContentHeader>) {
	return (
		<View className={style.ContentHeader}>
			<View className={style.ContentHeader__row}>
				<Heading size="large" tag="h1" margin="0 20px 4px 0">
					{props.title}
				</Heading>
				{props.children}
			</View>
			{props.total !== undefined ? (
				<Paragraph size="small" color="contentPrimary">
					{props.total} Total
				</Paragraph>
			) : null}
		</View>
	);
}
