import AddPaymentMethod from 'components/AddPaymentMethod/AddPaymentMethod';
import { ILoadFundsFields } from 'pages/loadFunds/useLoadFunds';
import { useEffect } from 'react';
import validationService from 'services/validationService/validation.service';
import Modal from 'styleguide/Modal/Modal';
import useForm from 'utils/useForm';
import FundingAccountTutorialModal from './components/FundingAccountTutorialModal/FundingAccountTutorialModal';
import { ILoadStepProps } from './LoadStep';
import loadStepService from './loadStep.service';

const MAX_NUM_TUTORIAL_VIEWS = 2;

export default function useLoadStep(props: ILoadStepProps) {
	const { bind, submitForm, isButtonEnabled } = useForm({
		initialValues: {
			amount: props.amount,
		},
		onSubmit: props.onSubmit,
		validate: validationService.createValidator<ILoadFundsFields>({
			amount: [validationService.minimunValue(0.01), validationService.maximumValue(5000)],
		}),
	});

	useEffect(showTutorial, [props.accountType]);

	function showTutorial() {
		if (
			props.accountType === 'Developer Funding Account' &&
			loadStepService.getNumberOfViews() < MAX_NUM_TUTORIAL_VIEWS
		) {
			Modal.show(<FundingAccountTutorialModal hideTutorial={hideTutorial} />, {
				canBeClosedOnClickOut: false,
				ariaTitle: 'Loading funds',
			});
		}
	}

	function hideTutorial() {
		loadStepService.incrementNumberOfViews();
		Modal.hide();
	}

	function showModalAddPaymentMethod() {
		Modal.show(<AddPaymentMethod onPaymentMethodAdded={hideModalAddPaymentMethod} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Add payment method',
		});
	}

	function hideModalAddPaymentMethod() {
		Modal.hide();
		props.onCloseModal();
	}

	return {
		bind,
		isButtonDisabled: !isButtonEnabled,
		showModalAddPaymentMethod,
		submitForm,
	};
}
