import { PENDING_ACTIVATION_ROUTE, SUCCESSFUL_ACTIVATION_ROUTE } from 'constants/routes';
import useApplicationId from 'hooks/useApplicationId';
import { useSetBillingMutation } from 'queries/activateAccount';
import { useNavigate } from 'react-router';

export default function useBillingStepWithApplicationId() {
	// SafeCast: This is rendered inside a WithApplicationId component so we know this is a valid string
	const applicationId = useApplicationId() as string;
	const navigate = useNavigate();

	const setBillingMutation = useSetBillingMutation({
		onSuccess: (res) => {
			if (res.status === 'success') {
				navigate(SUCCESSFUL_ACTIVATION_ROUTE, { replace: true });
			} else {
				navigate(PENDING_ACTIVATION_ROUTE, { replace: true });
			}
		},
	});

	function handleSubmitButton() {
		return setBillingMutation.mutateAsync({
			applicationId,
		});
	}

	return {
		isLoading: setBillingMutation.isLoading,
		serverError: setBillingMutation.error?.message || '',
		handleSubmitButton,
	};
}
