import { CARDS_ROUTE } from 'constants/routes';
import usePaginationUtils from 'hooks/usePagination';
import { useNavigate } from 'react-router';
import IAptoCard from 'types/aptoCards/IAptoCard';

interface IUseCardsPageSuccessArgs {
	cards: IAptoCard[];
}

export default function useCardsPageSuccess(args: IUseCardsPageSuccessArgs) {
	const navigate = useNavigate();

	const { goToNextPage, goToPrevPage } = usePaginationUtils({
		items: args.cards,
	});

	function onRowClick(card: IAptoCard) {
		navigate(`${CARDS_ROUTE}/${card.id}`);
	}

	return {
		goToNextPage: goToNextPage,
		goToPrevPage: goToPrevPage,
		onRowClick,
	};
}
