import { FormikErrors, FormikHelpers, FormikTouched, FormikValues, useFormik } from 'formik';

export interface IUseFormArguments<Values extends FormikValues> {
	initialValues: Values;
	onSubmit?: (values: Values, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
	validate: (
		values: Values,
		touched: FormikTouched<Values>
	) => void | Record<string, unknown> | Promise<FormikErrors<Values>>;
	validateOnMount?: boolean;
	validateOnBlur?: boolean;
	validateOnChange?: boolean;
	optionalFields?: Array<keyof Values>;
}

export default function useForm<Values extends FormikValues>({
	initialValues,
	onSubmit,
	validate,
	validateOnMount,
	optionalFields = [],
	validateOnBlur = true,
	validateOnChange = true,
}: IUseFormArguments<Values>) {
	const {
		dirty,
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isSubmitting,
		validateForm,
		isValid,
		resetForm,
		setFieldError,
		setFieldTouched,
		setFieldValue,
		setValues,
		submitForm,
		touched,
		values,
		setTouched,
	} = useFormik({
		validateOnBlur,
		validateOnChange,
		initialValues,
		onSubmit: _onSubmit,
		validate: _validate,
		validateOnMount,
	});

	// Wrapper over the onSubmit function preventing browser reload when submitting the form
	function _onSubmit(values: Values, formikHelpers: FormikHelpers<Values>) {
		if (typeof onSubmit !== 'function') {
			return;
		}
		window.event?.preventDefault();
		return onSubmit(values, formikHelpers);
	}

	// Wrapper over the validate function forwarding touched as second parameter
	function _validate(values: Values): void | Record<string, unknown> | Promise<FormikErrors<Values>> {
		return validate(values, touched);
	}

	function handleFocus(e: React.FocusEvent) {
		// Note: keep in mind that the field id must be the same as the touchedFields key
		setFieldTouched(e.target.id, true, false);
	}

	function _checkIsButtonEnabled() {
		const hasAllRequiredFieldsFilled = Object.keys(values).every((key) =>
			_checkFieldIsOptionalOrIsFilled(key as keyof Values)
		);
		return hasAllRequiredFieldsFilled ? isValid : false;
	}

	// Check if a field is optional
	function _checkFieldIsOptionalOrIsFilled(key: keyof Values) {
		if (optionalFields.includes(key)) {
			return true;
		}
		const value = values[key as keyof Values];
		if (typeof value === 'string') {
			return value !== '';
		}
		if (typeof value === 'number' || typeof value === 'boolean') {
			return value !== null && value !== undefined;
		}
		throw new Error(`useForm hook only supports strings and numbers: ${value}`);
	}

	// Helper to create an input
	function bind(id: keyof Values) {
		return {
			id: id,
			name: id,
			value: values[id],
			error: errors[id],
			onChange: handleChange,
			onFocus: handleFocus,
			onBlur: handleBlur,
		};
	}

	return {
		bind,
		handleBlur,
		handleChange,
		handleFocus,
		handleSubmit,
		validateForm,
		isButtonEnabled: _checkIsButtonEnabled(),
		isSubmitting,
		isValid,
		resetForm,
		setFieldError,
		setFieldValue,
		setTouched,
		setValues,
		submitForm,
		dirty,
		errors,
		touched,
		values,
	};
}
