import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './DateInput.module.scss';
export interface IDateInputProps extends ICommonInputProps {
  type: 'date';
  value: string;
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function DateInput(props: IDateInputProps) {
  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(style.Input, style[cnContent], style[cnError], style[cnDisabled])}
    >
      <label className={style.Input__label} htmlFor={props.id}>
        {' '}
        {props.label}{' '}
      </label>
      <input
        className={style['Input__input--date']}
        placeholder="yyyy-mm-dd"
        disabled={props.disabled}
        autoComplete={props.autocomplete}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        type="date"
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        readOnly={props.readOnly}
      />
      {props.error ? <span className={style.Input__error}>{props.error}</span> : null}
    </div>
  );
}
