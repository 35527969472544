import { IEditPaymentSourceArgs } from 'api-client/paymentSources/paymentSources';
import { MutateOptions } from 'react-query';
import IPaymentMethod, { IPaymentMethodCard } from 'types/IPaymentMethod';
import IError from 'types/network/IError';

interface IUpdatePaymentSourcesArgs {
	billing: IPaymentMethod | undefined;
	funding: IPaymentMethod | undefined;
	mutationFn: (
		args: Omit<IEditPaymentSourceArgs, 'environment'>,
		options?:
			| MutateOptions<IPaymentMethodCard, IError, Omit<IEditPaymentSourceArgs, 'environment'>, unknown>
			| undefined
	) => void;
	onSuccess: () => void;
}

/**
 * There are four possible situations:
 * 	A) No funding method selected
 *  B) Only funding selected
 *  C) Only billing selected
 *  D) Both selected
 * 		D1) same ID
 *    D2) different ID
 */
async function updatePaymentSources(args: IUpdatePaymentSourcesArgs) {
	// A)
	if (!args.funding && !args.billing) {
		// This should never happen since button should be disabled
		return;
	}

	if (args.funding) {
		// B
		if (!args.billing) {
			return args.mutationFn(
				{ id: args.funding.id, preferenceTypes: ['funding'], description: args.funding.description },
				{
					onSuccess: args.onSuccess,
				}
			);
		}

		// D1
		if (args.billing.id === args.funding.id) {
			return args.mutationFn(
				{ id: args.funding.id, preferenceTypes: ['billing', 'funding'], description: args.funding.description },
				{
					onSuccess: args.onSuccess,
				}
			);
		}

		// D2
		await args.mutationFn(
			{ id: args.billing.id, preferenceTypes: ['billing'], description: args.billing.description },
			{
				onSuccess: args.onSuccess,
			}
		);
		return args.mutationFn(
			{ id: args.funding.id, preferenceTypes: ['funding'], description: args.funding.description },
			{
				onSuccess: args.onSuccess,
			}
		);
	}

	if (args.billing) {
		// C
		return args.mutationFn(
			{ id: args.billing.id, preferenceTypes: ['billing'], description: args.billing.description },
			{
				onSuccess: args.onSuccess,
			}
		);
	}
}

export default {
	updatePaymentSources,
};
