import { APTO_CARD_NETWORKS, CARD_STATUSES, TRANSACTION_STATUSES } from 'mocks/statuses';
import { rest } from 'msw';
import mocksService from '../mocks.service';

function getUserList(req: any, res: any, ctx: any) {
	const { startingAfter, endingBefore } = mocksService.getPaginationParams(req.url.searchParams);

	if (!isNaN(endingBefore)) {
		return res(
			ctx.json({
				cardholders: mocksService.createItems(_createUser, Math.max(endingBefore - 10, 1)),
				has_more: true,
				total: 30,
			})
		);
	}

	return res(
		ctx.json({
			cardholders: mocksService.createItems(_createUser, isNaN(startingAfter) ? 0 : startingAfter),
			has_more: isNaN(startingAfter) ? true : startingAfter <= 20,
			total: 30,
		})
	);
}

function getUserSummary(req: any, res: any, ctx: any) {
	return res(ctx.json(_createUserSummary(req.params.id)));
}

function deleteUser(req: any, res: any, ctx: any) {
	return res(ctx.json({}));
}

const usersHandlers = [
	rest.get('https://api.stg.aptopayments.com/v1/developer/users', getUserList),
	rest.get('https://api.stg.aptopayments.com/v1/developer/users/:id/summary', getUserSummary),
	rest.delete('https://api.stg.aptopayments.com/v1/developer/users/:id', deleteUser),
];

export default usersHandlers;

const FIRST_NAMES = ['Nancy', 'Leslie', 'Jenny', 'Guy', 'Robert', 'Jacob', 'Cody', 'Bessie', 'Ralph', 'Kathyrn'];

function _createUser(id: number) {
	return {
		cardholder: {
			id: String(id),
			custodian_uid: 'your-external-user-id',
			created_at: '2016-10-19T23:20:17.000Z',
			email: `email${id}@example.com`,
			name: {
				first_name: mocksService.randomizeStatus(FIRST_NAMES),
				last_name: `Notarobot`,
			},
			phone: {
				country_code: 1,
				phone_number: 9366666715,
			},
			kyc: {
				passed_at: '2016-10-19T23:20:21.034Z',
				status: 'PASSED',
			},
		},
	};
}

function _createUserSummary(id: string) {
	return {
		cardholder: {
			id,
			custodian_uid: 'your-external-user-id',
			created_at: '2016-10-19T23:20:17.000Z',
			email: 'pat@wilson.com',
			date_of_birth: '1992-10-06',
			name: {
				first_name: mocksService.randomizeStatus(FIRST_NAMES),
				last_name: 'Wilson',
			},
			phone: {
				country_code: 1,
				phone_number: 9366666715,
			},
			address: {
				street_one: 'Malibu Point 10880',
				street_two: '',
				locality: 'Malibu',
				region: 'CA',
				postal_code: '90265',
				country: 'US',
			},
			kyc: {
				passed_at: '2016-10-19T23:20:21.034Z',
				status: 'PASSED',
			},
		},
		cards: [
			{
				card: {
					id: 'crd_fde2d61d233455b9',
					design_key: 'blue',
					last_four: '5542',
					status: mocksService.randomizeStatus(CARD_STATUSES),
					network: mocksService.randomizeStatus(APTO_CARD_NETWORKS),
				},
			},
			{
				card: {
					id: 'crd_fde2d61d233455b8',
					design_key: 'green',
					last_four: '5334',
					status: mocksService.randomizeStatus(CARD_STATUSES),
					network: mocksService.randomizeStatus(APTO_CARD_NETWORKS),
				},
			},
		],
		transactions: [
			{
				transaction: {
					id: 'txn_12aa2c96a548d7d2',
					local_amount: {
						currency: 'EUR',
						amount: -20.44,
					},
					hold_amount: {
						currency: 'USD',
						amount: 0,
					},
					billing_amount: {
						currency: 'USD',
						amount: -23.57,
					},
					cashback_amount: {
						currency: 'USD',
						amount: 0,
					},
					fee_amount: {
						currency: 'USD',
						amount: -2.5,
					},
					created_at: '2016-10-19T23:25:17.000Z',
					description: 'string',
					merchant_name: 'LORDS PHARMACY LTD',
					state: mocksService.randomizeStatus(TRANSACTION_STATUSES),
				},
			},
		],
	};
}
