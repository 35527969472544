import CopyText from 'components/CopyText/CopyText';
import Details from 'components/Details/Details';
import DateFormatter from 'components/formatters/DateFormatter/DateFormatter';
import Tooltip from 'components/Tooltip/Tooltip';
import React, { useState } from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ISignedPayloadsPublicKey from 'types/ISignedPayloadsPublicKey';
import style from './SignedPayloadsKey.module.scss';

interface ISignedPayloadsKeyProps {
	publicKey: ISignedPayloadsPublicKey;
	onDeleteClick: (id: string) => void;
	isDeleteDisabled: boolean;
	isProduction: boolean;
}

export default function SignedPayloadsKey(props: ISignedPayloadsKeyProps) {
	const [isExpanded, setExpanded] = useState(false);
	return (
		<Details
			isExpanded={isExpanded}
			setExpanded={setExpanded}
			summary={
				<Grid cols="3" isNested className={style.SignedPayloadsKey__grid}>
					<Item colWidth="2">
						<Paragraph size="small" color="contentPrimary" margin="0 0 3px">
							{props.publicKey.name}
						</Paragraph>
						{!props.isProduction ? (
							<Paragraph size="small" color="contentTertiary">
								<DateFormatter date={props.publicKey.created} />
							</Paragraph>
						) : null}
					</Item>
					<Item colWidth="1">
						<Paragraph size="small" color="contentPrimary" textAlign="right">
							<CopyText text={props.publicKey.value}>
								<span className={style.SignedPayloadsKey__ellipsis}>{_getPublicKeyPreview(props.publicKey.value)}</span>
							</CopyText>
						</Paragraph>
					</Item>
				</Grid>
			}
			content={
				<>
					<View className={style.SignedPayloadsKey__body}>
						<Grid cols="12" isNested>
							<Item colWidth="8">
								<Label size="small" color="contentPrimary" fontWeight="600">
									Public Key
								</Label>
								<Paragraph margin="4px 0 8px" size="small" color="contentPrimary">
									<span className={style.SignedPayloadsKey__publicKey}>{props.publicKey.value}</span>
								</Paragraph>
							</Item>

							<Item colWidth="4">
								<Label size="small" color="contentPrimary" fontWeight="600">
									Key ID
								</Label>
								<Paragraph margin="4px 0 8px" size="small" color="contentPrimary">
									<span className={style.SignedPayloadsKey__longText}>{props.publicKey.id}</span>
								</Paragraph>
							</Item>
						</Grid>
					</View>

					<View className={style.SignedPayloadsKey__footer}>
						{props.isDeleteDisabled ? (
							<Tooltip message="You must add a second public key to delete your existing one.">
								<View data-testid="delete-button-disabled">
									<Pill level="secondary" size="medium" disabled={props.isDeleteDisabled} data-testid="delete-button">
										<Label size="medium" color="contentTertiary">
											Delete
										</Label>
									</Pill>
								</View>
							</Tooltip>
						) : (
							<Pill level="secondary" size="medium" onClick={() => props.onDeleteClick(props.publicKey.id)}>
								<Label size="medium" color="negative">
									Delete
								</Label>
							</Pill>
						)}
					</View>
				</>
			}
		/>
	);
}

function _getPublicKeyPreview(publicKeyValue: string) {
	return publicKeyValue.replace('-----BEGIN PUBLIC KEY-----', '').replace('-----END PUBLIC KEY-----', '');
}
