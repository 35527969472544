import { useListTeamMembersQuery } from 'queries/teamMember';
import ITeamMember from 'types/team/ITeamMember';

export default function useAcceptedInvites() {
	const listTeamMemberQuery = useListTeamMembersQuery();

	return {
		props: {
			teamMembers: listTeamMemberQuery.data?.teamMembers as ITeamMember[],
			serverError: listTeamMemberQuery.error?.message as string,
		},
		status: listTeamMemberQuery.status,
	};
}
