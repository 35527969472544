import usersParser from 'api-client/cardholders/cardholders.parser';
import apiFetch from 'api-client/utils/fetch';
import summaryCardParser from 'api-client/utils/summaryCard.parser';
import paginationService from 'services/pagination.service';
import IAptoCard from 'types/aptoCards/IAptoCard';
import ICardholder from 'types/cardholders/ICardholder';
import IPaginationConfig from 'types/IPaginationConfig';
import IEnvironment from 'types/session/IEnvironment';
import ITransaction from 'types/transactions/ITransaction';
import transactionsParser from './transactions.parser';

const TRANSACTIONS_ENDPOINT = '/developer/transactions';

interface IReadTransactionsArgs {
	paginationConfig?: IPaginationConfig;
	environment: IEnvironment;
}

export interface IReadTransactionsResponse {
	transactions: ITransaction[];
	hasMore: boolean;
	total: number;
}

async function read(args: IReadTransactionsArgs): Promise<IReadTransactionsResponse> {
	const params = paginationService.getPaginationParams(args.paginationConfig);
	params.append('environment', args.environment);

	return apiFetch(`${TRANSACTIONS_ENDPOINT}?${params.toString()}`, 'GET').then(transactionsParser.parseTransactions);
}

interface IGetSummaryArgs {
	id: string;
	environment: IEnvironment;
}

export interface ISummaryTransactionsResponse {
	transaction: ITransaction;
	user: ICardholder;
	card: IAptoCard;
}

async function getSummary(args: IGetSummaryArgs): Promise<ISummaryTransactionsResponse> {
	return apiFetch(`${TRANSACTIONS_ENDPOINT}/${args.id}/summary?environment=${args.environment}`, 'GET').then(
		(raw: any) => {
			return {
				card: summaryCardParser.parseSummaryCard(raw),
				transaction: transactionsParser.parseTransaction(raw),
				user: usersParser.parseCardholder(raw),
			};
		}
	);
}

export default {
	getSummary,
	read,
};
