import React from 'react';
import AddPaymentMethodDebitCard from './components/AddPaymentMethodDebitCard';
import AddPaymentMethodLoading from './components/AddPaymentMethodLoading';
import AddPaymentMethodSelectType from './components/AddPaymentMethodSelectType/AddPaymentMethodSelectType';
import useAddPaymentMethod from './useAddPaymentMethod';

export interface IAddPaymentMethodProps {
	onPaymentMethodAdded: () => void;
}

export default function AddPaymentMethod(props: IAddPaymentMethodProps) {
	const {
		bind,
		isButtonDisabled,
		isNetworkLoading,
		paymentStep,
		serverError,
		submitForm,
		updatePaymentStep,
	} = useAddPaymentMethod(props.onPaymentMethodAdded);

	if (isNetworkLoading) {
		return <AddPaymentMethodLoading />;
	}

	if (paymentStep === '1_FILL_DEBIT_DATA') {
		return (
			<AddPaymentMethodDebitCard
				bind={bind}
				isButtonDisabled={isButtonDisabled}
				serverError={serverError}
				submitForm={submitForm}
			/>
		);
	}

	return <AddPaymentMethodSelectType updatePaymentStep={updatePaymentStep} />;
}
