import WithQuery from 'utils/WithQuery';
import CardDetailsFailed from './components/CardDetailsFailed/CardDetailsFailed';
import CardDetailsSuccess from './components/CardDetailsSuccess/CardDetailsSuccess';
import useCardDetailsPage from './useCard';

export default WithQuery({
	Component: CardDetailsSuccess,
	Error: CardDetailsFailed,
	useHook: useCardDetailsPage,
});
