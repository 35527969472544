import { useFeature } from '@optimizely/react-sdk';
import modalAccountActivationService from 'components/ModalAccountActivation/modalAccountActivation.service';
import { DASHBOARD_ROUTE, PENDING_ACTIVATION_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router';
import IEnvironment from 'types/session/IEnvironment';

export default function useDrawer() {
	const navigate = useNavigate();
	const { setEnvironment, lastApplicationId, lastApplicationStatus, accessLevel, environment, isProduction } =
		useSession();
	const [isBillingEnabled] = useFeature('BILLING');

	function onEnvironmentChange(value?: { label: string; value: string }) {
		if (!value) {
			return;
		}

		if (lastApplicationStatus === 'pending_review') {
			return navigate(PENDING_ACTIVATION_ROUTE);
		}

		if (accessLevel === 'sandbox') {
			return modalAccountActivationService.showModalAccountActivation(lastApplicationStatus, lastApplicationId);
		}

		if (value.value === 'production') {
			return _switchEnvironment('production');
		}

		if (value.value === 'sandbox') {
			return _switchEnvironment('sandbox');
		}

		throw new Error(`Unexpected environment: ${value.value}`);
	}

	function _switchEnvironment(environment: IEnvironment) {
		// Set the actual environment
		setEnvironment(environment);
		// Redirect to dashboard to prevent issues (STARGATE-2576)
		navigate(DASHBOARD_ROUTE);
		// Reload the whole page to ensure we handle api-keys and status properly
		return window.location.reload(); // TODO: Use context to handle env status!
	}

	return {
		environment,
		onEnvironmentChange,
		isBillingEnabled,
		isSandbox: !isProduction,
	};
}
