import { ICurrency } from 'constants/currencies';
import { useListAccountsQuery } from 'queries/accounts';
import validationService from 'services/validationService/validation.service';
import IAptoCard from 'types/aptoCards/IAptoCard';
import useForm from 'utils/useForm';
import usePureState from 'utils/usePureState';
import { IHookReturnedProps } from '../../LoadStep';

interface IState {
	amount: string;
	currency: ICurrency;
}

export default function useLoadStepSuccess(props: IHookReturnedProps) {
	const { state, dispatch } = usePureState({
		selectedCard: _findSelectedCard(props.cards, props.cardId),
	});

	const { data: developerAccounts } = useListAccountsQuery();

	const { bind, submitForm, isButtonEnabled } = useForm<IState>({
		initialValues: {
			amount: props.amount || '',
			currency: 'USD',
		},
		onSubmit(values) {
			props.handleNextClick({
				amount: values.amount,
				currency: values.currency,
				cardId: state.selectedCard.id,
				designKey: state.selectedCard.designKey,
				lastFour: state.selectedCard.lastFour,
				network: state.selectedCard.network,
			});
		},
		validate: validationService.createValidator({
			amount: [validationService.minimumValue(0.01)],
		}),
	});

	function handleAptoCardChange(cardId: string) {
		const selectedCard = _findSelectedCard(props.cards, cardId);
		dispatch({ selectedCard });
	}

	return {
		bind,
		handleAptoCardChange,
		handleNextClick: props.handleNextClick,
		submitForm,
		filteredCards: _filterCards(props.cards),
		isButtonDisabled: !isButtonEnabled,
		selectedCard: state.selectedCard,
		fundingAccountBalance: developerAccounts?.fundingAccount?.balance ?? null,
	};
}

function _findSelectedCard(cards: IAptoCard[], cardId?: string): IAptoCard {
	if (cards.length <= 0) {
		throw new Error('Unexpected error: This cardholder has no cards.');
	}
	const filteredCards = _filterCards(cards);
	if (!cardId) {
		return filteredCards[0];
	}
	// SafeCast: By definition the card should exist in the list (ACQ-1456)
	return filteredCards.find((card) => card.id === cardId) as IAptoCard;
}

function _filterCards(cards: IAptoCard[]): IAptoCard[] {
	return cards.filter((card) => card.status === 'ACTIVATED');
}
