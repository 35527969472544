import { IGetCardDesignResponse } from 'api-client/activateAccount/activateAccount';
import { ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE } from 'constants/routes';
import { useSetCardDesignMutation } from 'queries/activateAccount';
import React, { createRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IAptoCardType from 'types/aptoCards/IAptoCardType';
import usePureState from 'utils/usePureState';
import ICardSelectorOption from './components/CardTypeRadioGroup/components/CardSelector/ICardSelectorOption';

export interface ICardDesignFields {
	type: IAptoCardType;
	autoPrint?: boolean;
	color: IAptoCardDesignKey;
	logoBase64?: string;
	logo?: File;
	network: IAptoCardNetwork;
	selectedPath?: string;
}

interface IUseCardDesignArgs {
	storedValues: IGetCardDesignResponse;
	applicationId: string;
}

const reader = new FileReader();

export default function useCardDesignSuccess(args: IUseCardDesignArgs) {
	const fileRef = createRef<HTMLInputElement>();
	const navigate = useNavigate();
	const setCardDesignMutation = useSetCardDesignMutation();

	useEffect(() => {
		reader.addEventListener('load', _onFileLoaded, false);

		function _onFileLoaded() {
			dispatch({ logoBase64: mapJpegToJpg(reader.result as string) });
		}

		return () => reader.removeEventListener('load', _onFileLoaded);
	}, []); // eslint-disable-line

	const { state, dispatch } = usePureState<ICardDesignFields>({
		..._getInitialValues(args.storedValues),
	});

	function onFileChanged(e: React.ChangeEvent<HTMLInputElement>) {
		if (e && e.target && e.target.files && e.target.files.length) {
			const logo = e.target.files[0] || undefined;
			dispatch({ logo });
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	async function onNextClick() {
		setCardDesignMutation.mutate(
			{
				applicationId: args.applicationId,
				type: state.type,
				template: state.color,
				logo: state.logoBase64,
				network: state.network,
				autoPrint: state.autoPrint,
			},
			{
				onSuccess: () => {
					navigate(ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE, {
						replace: true,
					});
				},
			}
		);
	}

	function mapJpegToJpg(logoBase64: string) {
		const unrecognizedFormat = 'data:image/jpeg';
		const recognizedFormat = 'data:image/jpg';
		if (logoBase64.startsWith(unrecognizedFormat)) {
			return `${recognizedFormat}${logoBase64.slice(unrecognizedFormat.length)}`;
		}
		return logoBase64;
	}

	function handleRemoveLogoClick() {
		dispatch({ logoBase64: undefined, logo: undefined });
		if (fileRef.current) {
			fileRef.current.value = '';
		}
	}

	function selectCard(value: ICardSelectorOption) {
		switch (value) {
			case 'physical': {
				dispatch({ type: 'physical', autoPrint: true });
				break;
			}
			case 'mixed': {
				dispatch({ type: 'physical', autoPrint: false });
				break;
			}
			case 'digital': {
				dispatch({ type: 'digital', autoPrint: false });
				break;
			}
		}
	}

	return {
		fileRef,
		handleRemoveLogoClick,
		initialValues: state,
		isButtonLoading: setCardDesignMutation.isLoading,
		onFileChanged,
		onNextClick,
		selectCard,
		selectColor: (color: IAptoCardDesignKey) => dispatch({ color }),
		selectNetwork: (network: IAptoCardNetwork) => dispatch({ network }),
		serverError: setCardDesignMutation.error?.message || '',
		state,
	};
}

function _getInitialValues(response: IGetCardDesignResponse): ICardDesignFields {
	return {
		type: response.type ?? 'physical',
		autoPrint: response.autoPrint ?? true,
		color: response.color ?? 'white',
		logoBase64: response.logoBase64 ?? undefined,
		network: response.network ?? 'MASTERCARD',
	};
}
