import useSession from 'hooks/useSession';
import { useGetTransactionQuery } from 'queries/transactions.queries';
import { useParams } from 'react-router-dom';
import IAptoCard from 'types/aptoCards/IAptoCard';
import ICardholder from 'types/cardholders/ICardholder';
import ITransaction from 'types/transactions/ITransaction';

export default function useTransaction() {
	const { id } = useParams() as { id: string };
	const { isProduction } = useSession();
	const transactionQuery = useGetTransactionQuery({ id });

	return {
		props: {
			// SafeCast: we're using <WithQuery >
			card: transactionQuery.data?.card as IAptoCard,
			transaction: transactionQuery.data?.transaction as ITransaction,
			user: transactionQuery.data?.user as ICardholder,
			// SafeCast: we're using <WithQuery >
			serverError: transactionQuery.error?.message as string,
			isProduction,
		},
		status: transactionQuery.status,
	};
}
