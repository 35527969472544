import WithQuery from 'utils/WithQuery';
import TransactionHistorySectionError from './components/TransactionHistoryError.section';
import TransactionHistorySectionLoading from './components/TransactionHistoryLoading.section';
import TransactionHistorySectionSuccess from './components/TransactionHistorySuccess.section';
import useTransactionHistorySection from './useTransactionHistorySection';

export default WithQuery({
	useHook: useTransactionHistorySection,
	Component: TransactionHistorySectionSuccess,
	Error: TransactionHistorySectionError,
	Loader: TransactionHistorySectionLoading,
});
