import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';

export default function ModalAddApiKeysLoading() {
	return (
		<View
			style={{
				height: '600px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<Spinner height="96px" width="96px" />
			<Text> Creating API key...</Text>
		</View>
	);
}
