import DescriptionItem from 'components/DescriptionItem/DescriptionItem';
import DateFormatter from 'components/formatters/DateFormatter/DateFormatter';
import KYCFormatter from 'components/formatters/KYCFormatter';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ISummaryCardholder from 'types/cardholders/ISummaryCardholder';
import Address from './components/Address/Address';
import style from './DetailsSection.module.scss';

interface IDetailsSectionProps {
	user: ISummaryCardholder;
}

export default function DetailsSection(props: IDetailsSectionProps) {
	return (
		<PaddingContainer>
			<View className={style.DetailsSection}>
				<DescriptionItem title="Created Date">
					<Paragraph size="small">{<DateFormatter date={props.user.createdAt} />}</Paragraph>
				</DescriptionItem>
				<DescriptionItem title="User ID">
					<Paragraph size="small">{props.user.id}</Paragraph>
				</DescriptionItem>
				<DescriptionItem title="KYC Status">
					<Paragraph size="small">{<KYCFormatter value={props.user.kycStatus} />}</Paragraph>
				</DescriptionItem>
				<DescriptionItem title="Date of Birth">
					<Paragraph size="small">{dateTimeFormatService.formatDateOfBirth(props.user.dateOfBirth)}</Paragraph>
				</DescriptionItem>
				<DescriptionItem title="Phone Number">
					<Paragraph size="small">{props.user.phoneNumber}</Paragraph>
				</DescriptionItem>
				<DescriptionItem title="Address">
					<Address
						country={props.user.addressCountry}
						locality={props.user.addressLocality}
						postalCode={props.user.addressPostalCode}
						region={props.user.addressRegion}
						streetOne={props.user.addressStreetOne}
						streetTwo={props.user.addressStreetTwo}
					/>
				</DescriptionItem>
			</View>
		</PaddingContainer>
	);
}
