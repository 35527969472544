import { IInitalsColors } from './Initials';

/**
 * Here are the probabilities of having a certain letter as the first letter of your name over 80 years in the USA (1933-2012)
 * This is used to assure a fairly equal assignment of colors to user profiles in the application.
 * source: Birth records, US Social Security Service & University of Chicago: https://home.uchicago.edu/~jsfalk/misc/baby_names/
 * a: 0.0798
 * b: 0.04978
 * c: 0.07598
 * d: 0.07507
 * e: 0.04076
 * f: 0.01168
 * g: 0.02945
 * h: 0.01872
 * i: 0.00767
 * j: 0.13583
 * k: 0.05349
 * l: 0.05167
 * m: 0.09116
 * n: 0.02254
 * o: 0.00446
 * p: 0.02821
 * q: 0.00074
 * r: 0.06938
 * s: 0.06726
 * t: 0.04543
 * u: 0.00038
 * v: 0.01208
 * w: 0.02141
 * x: 0.0006
 * y: 0.00249
 * z: 0.00396
 */

// Each value is the key's probability summed with its preceeding letters' probabilities in alphabetical order
const firstInitialPositionOnDistribution = {
	a: 0.0798,
	b: 0.12958,
	c: 0.20556,
	d: 0.28063,
	e: 0.3214,
	f: 0.33307,
	g: 0.36252,
	h: 0.38124,
	i: 0.38891,
	j: 0.52474,
	k: 0.57823,
	l: 0.6299,
	m: 0.72106,
	n: 0.7436,
	o: 0.74806,
	p: 0.77627,
	q: 0.77701,
	r: 0.84639,
	s: 0.91365,
	t: 0.95908,
	u: 0.95947,
	v: 0.97154,
	w: 0.99296,
	x: 0.99356,
	y: 0.99604,
	z: 1,
};

const COLORS: IInitalsColors[] = ['blue', 'gray', 'green', 'orange', 'purple', 'yellow'];

function getColor(firstName: string): IInitalsColors {
	if (firstName === '') {
		return 'empty';
	}

	// Get the first inital of the name
	const firstInitial = firstName[0].toLowerCase();
	// return the postion (between 0 and 1) of the inital on our distribution
	const position =
		firstInitial in firstInitialPositionOnDistribution
			? firstInitialPositionOnDistribution[firstInitial as keyof typeof firstInitialPositionOnDistribution]
			: 1;
	// get the color that maps to the distribution position
	const color = COLORS.find((color: IInitalsColors, idx: number) => {
		const whereColorEndsOnDistribution = (1 / COLORS.length) * (idx + 1);
		return position <= whereColorEndsOnDistribution;
	});

	if (color === undefined) {
		throw new TypeError('Values should always fall between 0 and 1 and, in turn, return a color');
	}

	return color;
}

export default {
	getColor,
};
