import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalAddTransactions.module.scss';
import useModalAddTransactions from './useModalAddTransactions';

export default function ModalAddTransactions() {
	const { copyToClipboard } = useModalAddTransactions();

	return (
		<View className={style.ModalAddTransactions}>
			<Heading size="small" tag="h1" margin="0 0 12px 0">
				Add transaction
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin="0 0 14px">
				Copy and paste this code snippet into your terminal to create a transaction.
			</Paragraph>
			<Pill
				type="link"
				level="inline-link"
				size="medium"
				className={style.ModalAddTransactions__link}
				external
				to="https://docs.aptopayments.com/docs/overview/Getting%20Started/transactions/#indicating-transaction-type"
			>
				Guide to simulate transactions
			</Pill>
			<pre className={style.ModalAddTransactions__terminal}>
				<Paragraph size="x-small" className={style.ModalAddTransactions__code}>
					curl -LX POST https://api.sbx.aptopayments.com/cards/
					<span className={style.ModalAddTransactions__highlight}>&#60;CARD_ID&#62;</span>
					/transactions \ <br />
					-H "Authorization: Basic <span className={style.ModalAddTransactions__highlight}>&#60;CORE_API_KEY&#62;</span>
					" \ <br />
					-H "Content-Type: application/json" \<br />
					{`-d '{
	"billing_amount": 100.00,
	"processing_type": "financial_request",
	"type": "purchase",
	"description": "Test"
}'`}
				</Paragraph>
			</pre>
			<View className={style.ModalAddTransactions__footer}>
				<Pill level="primary" size="medium" onClick={copyToClipboard}>
					Copy code
				</Pill>
			</View>
		</View>
	);
}
