import LastFour from 'components/cards/LastFour/LastFour';
import CurrencyFormatter from 'components/formatters/CurrencyFormatter/CurrencyFormatter';
import TransactionStatusFormatter from 'components/formatters/TransactionStatusFormatter/TransactionStatusFormatter';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import { ICurrency } from 'constants/currencies';
import React from 'react';
import dateTimeFormatService from 'services/formatters/datetime/dateTimeFormat.service';
import ChevronPagination from 'styleguide/Pagination/ChevronPagination/ChevronPagination';
import Table from 'styleguide/Table/Table';
import View from 'styleguide/View/View';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import ITransaction from 'types/transactions/ITransaction';
import ITransactionStatus from 'types/transactions/ITransactionStatus';
import TransactionsHeader from './components/TransactionsHeader';
import useTransactionsPageSuccess from './useTransactionsPageSuccess';

interface ITransactionsTableData {
	id: string;
	merchantName: string;
	createdAt: Date;
	state: ITransactionStatus;
	localAmount: {
		value: number;
		currency: ICurrency;
	};
	cardDesignKey: IAptoCardDesignKey;
	cardLastFour: string;
	cardNetwork: IAptoCardNetwork;
}

interface ITransactionsPageSuccessProps {
	isPaginationNextVisible: boolean;
	isPaginationPrevVisible: boolean;
	total: number;
	transactions: ITransaction[];
}

export default function TransactionsPageSuccess(props: ITransactionsPageSuccessProps) {
	const {
		goToNextPage,
		goToPrevPage,
		isPaginationNextVisible,
		isPaginationPrevVisible,
		isProduction,
		onRowClick,
		showModalAddTransactions,
		total,
		transactions,
	} = useTransactionsPageSuccess({ ...props });

	const columns = [
		{ key: 'merchantName', name: 'Merchant' },
		{ key: 'id', name: 'Transaction ID' },
		{
			key: 'cardLastFour',
			name: 'Card',
			renderer: (value: string, row: ITransactionsTableData) => _cardRenderer(value, row, isProduction),
		},
		{ key: 'localAmount', name: 'Amount', renderer: _MoneyRenderer },
		{ key: 'createdAt', name: `Time (${dateTimeFormatService.getTimeZone()})`, renderer: _createAtRenderer },
		{ key: 'state', name: 'Status', renderer: _stateRenderer },
	];

	const data: ITransactionsTableData[] = transactions.map((item) => {
		return {
			id: item.id,
			merchantName: item.merchantName,
			createdAt: item.createdAt,
			state: item.state,
			localAmount: {
				value: item.localAmount,
				currency: item.localAmountCurrency,
			},
			cardLastFour: item.cardLastFour,
			cardDesignKey: item.cardDesignKey,
			cardNetwork: item.cardNetwork,
		};
	});

	return (
		<View data-testid="page-transactions">
			<TransactionsHeader total={total} onAddClick={showModalAddTransactions} />
			<Table
				minWidth="800px"
				data={data}
				columns={columns}
				onRowClick={onRowClick}
				emptyTableMessage="You have no transactions."
			/>
			<PaddingContainer>
				<ChevronPagination
					isInBlock
					isNextVisible={isPaginationNextVisible}
					isPrevVisible={isPaginationPrevVisible}
					onNextClicked={goToNextPage}
					onPrevClicked={goToPrevPage}
				/>
			</PaddingContainer>
		</View>
	);
}

function _MoneyRenderer({ value, currency }: { value: number; currency: ICurrency }) {
	return <CurrencyFormatter currency={currency} value={value} />;
}

function _stateRenderer(value: ITransactionStatus) {
	return <TransactionStatusFormatter value={value} />;
}

function _createAtRenderer(date: Date) {
	return (
		<View style={{ textTransform: 'capitalize' }}>
			{dateTimeFormatService.format(date, { includeHourAndMinute: true })}
		</View>
	);
}

function _cardRenderer(value: string, row: ITransactionsTableData, isProduction: boolean) {
	return (
		<LastFour
			value={value}
			designKey={row.cardDesignKey}
			network={row.cardNetwork}
			iconSize="small"
			typeOfCard={isProduction ? 'apto' : 'placeholder'}
		/>
	);
}
