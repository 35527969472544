import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './EditPublicKeySection.module.scss';

interface EditPublicKeySectionProps {
	publicKey: string;
	onEdit: () => void;
}

export default function EditPublicKeySection(props: EditPublicKeySectionProps) {
	return (
		<section>
			<View className={style.EditPublicKeySection__heading}>
				<Label size="medium" fontWeight="600" margin="0 20px 0 0">
					Signed Payloads
				</Label>
				<Pill data-testid="add-signed-payloads-btn" level="secondary" size="small" onClick={props.onEdit}>
					Edit
				</Pill>
			</View>
			<View className={style.EditPublicKeySection__body}>
				<Paragraph size="small" color="contentPrimary">
					Public Key
				</Paragraph>
			</View>
		</section>
	);
}
