import Header from 'components/LoginHeader/LoginHeader';
import Notification from 'components/Notification/Notification';
import { FORGOT_PASSWORD_ROUTE } from 'constants/routes';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './Login.module.scss';
import useLogin from './useLogin';

export default function LoginPage() {
	const {
		values,
		errors,
		loginError,
		serverError,
		handleChange,
		handleBlur,
		handleFocus,
		login,
		isLoading,
		isButtonDisabled,
		isEmailDisabled,
	} = useLogin();

	return (
		<React.Fragment>
			<Header />
			<Grid cols="16" colsLg="16">
				<Item colWidth="16" colWidthLg="6" className={style.LoginPage__gridItem}>
					<main className={style.LoginPage}>
						<Notification
							type="negative"
							isHidden={!serverError.message}
							title={serverError.title}
							description={serverError.message}
						/>
						<form onSubmit={login}>
							<Text weight="bold" type="h4" tag="h1" margin="36px 0 24px">
								Log in to Apto
							</Text>
							<Input
								margin="0 0 12px 0"
								error={errors.email}
								autofocus
								value={values.email}
								autocomplete="email"
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								name="email"
								label="Email"
								id="email"
								type="email"
								disabled={isEmailDisabled}
							/>
							<Input
								margin="0 0 12px 0"
								error={errors.password}
								value={values.password}
								autocomplete="current-password"
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								name="password"
								label="Password"
								id="password"
								type="password"
								showPasswordToggle
							/>

							{loginError ? (
								<Text margin="12px 0 24px 0" type="footnote" block color="negative">
									{loginError}
								</Text>
							) : null}

							<View style={{ marginTop: '24px' }}>
								<Pill
									level="primary"
									size="medium"
									disabled={isButtonDisabled}
									loading={isLoading}
									id="login"
									data-testid="btn-login"
								>
									Log in
								</Pill>
							</View>
							<View style={{ marginTop: '26px' }}>
								<Pill
									type="link"
									level="inline-link"
									size="medium"
									to={FORGOT_PASSWORD_ROUTE}
									className={style.LoginPage__inlineCta}
								>
									Forgot password?
								</Pill>
							</View>
							<Paragraph size="medium" color="contentTertiary" margin="22px 0 0">
								Don't have an account?{' '}
								<Pill
									type="link"
									level="inline-link"
									size="medium"
									external
									to="https://aptopayments.typeform.com/to/w54rZXEC"
									className={style.LoginPage__inlineCta}
								>
									Sign up
								</Pill>
							</Paragraph>
						</form>
					</main>
				</Item>
			</Grid>
		</React.Fragment>
	);
}
