import WithQuery from 'utils/WithQuery';
import TransactionPageFailed from './components/TransactionPageFailed/TransactionPageFailed';
import TransactionPageLoading from './components/TransactionPageLoading/TransactionPageLoading';
import TransactionPageSuccess from './components/TransactionPageSuccess/TransactionPageSuccess';
import useTransaction from './useTransaction';

export default WithQuery({
	Component: TransactionPageSuccess,
	Error: TransactionPageFailed,
	Loader: TransactionPageLoading,
	useHook: useTransaction,
});
