import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './Overview.module.scss';
import useOverview from './useOverview';

export default function Overview() {
	const { isProduction, showModalRequestExampleApp } = useOverview();

	if (isProduction) {
		return (
			<View className={style.Overview}>
				<Grid cols="12" isNested>
					<Item colWidth="12">
						<Heading size="small" tag="h2" margin="0 0 12px">
							Overview
						</Heading>
						<Paragraph size="small" margin="0 0 16px">
							Our SDKs provide a speed-to-market solution that is safe and secure and PCI compliant. Leverage these
							discrete components to quickly build your own applications.
						</Paragraph>
					</Item>
				</Grid>
			</View>
		);
	}

	return (
		<View className={style.Overview}>
			<Grid cols="12" isNested>
				<Item colWidth="12" colWidthLg="6">
					<Heading size="small" tag="h2" margin="0 0 12px">
						Overview
					</Heading>
					<Paragraph size="medium" margin="0 0 32px">
						Our SDKs provide a speed-to-market solution that is safe and secure and PCI compliant. Leverage these
						discrete components to quickly build your own applications.
					</Paragraph>
				</Item>
				<Item colWidth="12" colWidthLg="6">
					<View className={style.Overview__banner}>
						<Heading size="x-small" tag="h3" margin="0 0 12px">
							Test with our cardholder demonstration app
						</Heading>
						<Paragraph size="small" margin="0 0 20px">
							Generate links to download a demonstration app tied to your production environment (available for iOS and
							Android). The app is representative of our full white-label cardholder experience you gain access to when
							you integrate with our UI SDK. You can view all cardholders created in the <i>Users</i> section of the
							portal.
						</Paragraph>
						<Pill level="primary" size="medium" onClick={showModalRequestExampleApp}>
							Get link
						</Pill>
					</View>
				</Item>
			</Grid>
		</View>
	);
}
