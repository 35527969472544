import Initials from 'components/Initials/Initials';
import React from 'react';
import View from 'styleguide/View/View';
import style from './FullNameFormatter.module.scss';

interface IFullNameFormatterProps {
	firstName: string;
	lastName: string;
}

export default function FullNameFormatter(props: IFullNameFormatterProps) {
	return (
		<View className={style.FullNameFormatter__name}>
			<Initials className={style.FullNameFormatter__initials} firstName={props.firstName} lastName={props.lastName} />
			{props.firstName} {props.lastName}
		</View>
	);
}
