import apiClientService from 'api-client/apiclient.service';
import { useQueryParams } from 'hooks/useQueryParams';
import { useState } from 'react';

export default function useWaitForEmailPage() {
	const params = useQueryParams();
	const attemptID = params.get('attemptID') as string; // TODO: Fail if no attemptID
	const userEmail = params.get('email') as string;

	const [state, setState] = useState({
		serverError: '',
		requestStatus: 'IDLE',
	});

	function resendEmail() {
		setState({ serverError: '', requestStatus: 'PENDING' });

		return apiClientService.auth
			.resendEmail(attemptID)
			.then(() => {
				setState({ serverError: '', requestStatus: 'SUCCESS' });
			})
			.catch((err) => {
				setState({ serverError: err.message, requestStatus: 'FAILED' });
			});
	}

	return {
		resendEmail,
		userEmail,
		isNotificationHidden: state.requestStatus !== 'FAILED',
		isButtonDisabled: state.requestStatus === 'PENDING',
		serverError: state.serverError,
		isMailSent: state.requestStatus === 'SUCCESS',
	};
}
