import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';

export default function WebhooksSectionError(props: { serverError: string }) {
	return (
		<PaddingContainer>
			<Notification type="negative" margin="24px 0" title="Error" description={props.serverError} />
		</PaddingContainer>
	);
}
