import classNames from 'classnames';
import {
	ACTIVATE_ACCOUNT_BILLING_ROUTE,
	ACTIVATE_ACCOUNT_CARD_ROUTE,
	ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE,
	ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE,
	ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE,
	ACTIVATE_ACCOUNT_OVERVIEW_ROUTE,
	ACTIVATE_ACCOUNT_OWNERS_ROUTE,
	ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE,
	DASHBOARD_ROUTE,
	LOGIN_ROUTE,
	PENDING_ACTIVATION_ROUTE,
} from 'constants/routes';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import View from 'styleguide/View/View';
import style from './ActivateAccount.module.scss';
import BillingStep from './steps/BillingStep/BillingStep';
import CardDesignStep from './steps/CardDesignStep/CardDesignStep';
import CompanyAddressStep from './steps/CompanyAddressStep/CompanyAddressStep';
import CompanyInfoStep from './steps/CompanyInfoStep/CompanyInfoStep';
import ComplianceStep from './steps/ComplianceStep/ComplianceStep';
import LegalStep from './steps/LegalStep/LegalStep';
import OverviewStep from './steps/OverviewStep/OverviewStep';
import OwnersStep from './steps/OwnersStep/OwnersStep';
import PublicKeyStep from './steps/PublicKeyStep/PublicKeyStep';
import VerifyPhoneNumberStep from './steps/VerifyPhoneNumberStep/VerifyPhoneNumberStep';
import useActivateAccountLayout from './useActivateAccount';

export default function ActivateAccountRootPage() {
	const {
		currentStep,
		totalSteps,
		isComplianceStepEnabled,
		isLoggedIn,
		hasAccessToProduction,
		hasAPendingApplication,
	} = useActivateAccountLayout();

	if (!isLoggedIn) {
		// TODO: Save route and redirect to saved route once logged in
		return <Navigate to={LOGIN_ROUTE} />;
	}

	if (hasAccessToProduction()) {
		return <Navigate to={DASHBOARD_ROUTE} />;
	}

	if (hasAPendingApplication()) {
		return <Navigate to={PENDING_ACTIVATION_ROUTE} />;
	}

	// TODO: Temporal screen, this should be a modal
	if (currentStep === '04b') {
		return <VerifyPhoneNumberStep />;
	}

	return (
		<View className={style.ActivateAccount}>
			<aside data-testid="layout-aside" className={style.ActivateAccount__aside}>
				<View className={style.Counter}>
					<span className={style.Counter__current}>{currentStep}</span>
					<span className={style.Counter__total}>/ {totalSteps}</span>
				</View>
				<nav className={style.Nav}>
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_OVERVIEW_ROUTE}>
						Overview
					</NavLink>
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE}>
						Company info
					</NavLink>
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_OWNERS_ROUTE}>
						Beneficial owners
					</NavLink>
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE}>
						Company address
					</NavLink>
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_CARD_ROUTE}>
						Card design
					</NavLink>
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE}>
						Signed payloads
					</NavLink>
					{isComplianceStepEnabled ? (
						<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE}>
							Compliance
						</NavLink>
					) : null}
					<NavLink className={_getClassName} to={ACTIVATE_ACCOUNT_BILLING_ROUTE}>
						Next steps
					</NavLink>
				</nav>
			</aside>
			<main className={style.ActivateAccount__content}>
				<Routes>
					<Route index element={<Navigate replace to={ACTIVATE_ACCOUNT_OVERVIEW_ROUTE} />}></Route>
					<Route path="overview" element={<OverviewStep />} />
					<Route path="company-info" element={<CompanyInfoStep />} />
					<Route path="owners" element={<OwnersStep />} />
					<Route path="company-address" element={<CompanyAddressStep />} />
					<Route path="card" element={<CardDesignStep />} />
					<Route path="signed-payloads" element={<PublicKeyStep />} />
					<Route path="legal" element={<LegalStep />} />
					<Route path="compliance" element={<ComplianceStep />} />
					<Route path="next-steps" element={<BillingStep />} />
				</Routes>
			</main>
		</View>
	);
}

function _getClassName({ isActive }: { isActive: boolean }): string {
	return isActive ? classNames(style.Nav__link, style['Nav__link--isActive']) : style.Nav__link;
}
