import React from 'react';
import Modal from 'styleguide/Modal/Modal';
import ModalInviteMemberForm from './components/ModalInviteMemberForm/ModalInviteMemberForm';
import ModalInviteMemberSent from './components/ModalInviteMemberSent/ModalInviteMemberSent';
import useModalInviteMember from './useModalInviteMember';

export default function ModalInviteMember() {
	const {
		step,
		companyName,
		email,
		bind,
		isSubmitting,
		isButtonDisabled,
		serverError,
		submitForm,
		showModalInviteMember,
	} = useModalInviteMember();

	return step === 'initial' ? (
		<ModalInviteMemberForm
			bind={bind}
			isSubmitting={isSubmitting}
			isButtonDisabled={isButtonDisabled}
			serverError={serverError}
			submitForm={submitForm}
		/>
	) : (
		<ModalInviteMemberSent
			companyName={companyName}
			email={email}
			onInviteMorePeopleClick={showModalInviteMember}
			onDoneClick={Modal.hide}
		/>
	);
}
