import { useState } from 'react';
import { IPaymentMethodSelectProps } from './PaymentMethodSelect';

export default function usePaymentMethodSelect(props: IPaymentMethodSelectProps) {
	const [isExpanded, setExpanded] = useState(false);
	const selectedPaymentMethod = props.paymentMethods.find(
		(paymentMethod) => paymentMethod.id === props.selectedPaymentId
	);

	function handlePaymentMethod(paymentMethodId: string) {
		props.onPaymentMethodClick(paymentMethodId);
		setExpanded(false);
	}

	return {
		isExpanded,
		setExpanded,
		selectedPaymentMethod,
		handlePaymentMethod,
	};
}
