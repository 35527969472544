import { WEBHOOKS_CREATE_ROUTE } from 'constants/routes';
import { useRemoveWebhookMutation } from 'queries/webhooks.queries';
import { useNavigate } from 'react-router';

export default function useWebhooksSectionSuccess() {
	const navigate = useNavigate();
	const removeWebhookMutation = useRemoveWebhookMutation();

	function goToCreateWebhookScreen() {
		navigate(WEBHOOKS_CREATE_ROUTE);
	}

	function removeWebhook(id: string) {
		removeWebhookMutation.mutate(id);
	}

	// TODO
	function goToEditWebhookScreen(id: string) {
		alert('Not implemented yet');
	}

	return {
		isRemoving: removeWebhookMutation.isLoading,
		onCreateWebhookClick: goToCreateWebhookScreen,
		onRemoveWebhookClick: removeWebhook,
		onEditWebhookClick: goToEditWebhookScreen,
	};
}
