import LastFour from 'components/cards/LastFour/LastFour';
import React, { AriaAttributes, HTMLAttributes } from 'react';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import ICardNetwork from 'types/financial/ICardNetwork';
import style from '../CardsSelect.module.scss';

interface ICardsSelectItemProps {
	id: string;
	ariaExpanded?: AriaAttributes['aria-expanded'];
	ariaHaspopup?: AriaAttributes['aria-haspopup'];
	ariaLabel?: AriaAttributes['aria-label'];
	ariaSelected?: AriaAttributes['aria-selected'];
	description?: string;
	designKey?: IAptoCardDesignKey;
	isDisabled?: boolean;
	isSelectedItem?: boolean;
	network?: ICardNetwork;
	onItemClick: () => void;
	role?: HTMLAttributes<HTMLButtonElement>['role'];
	typeOfCard?: 'apto' | 'external' | 'placeholder';
	value?: string;
}

export default function CardsSelectItem(props: ICardsSelectItemProps) {
	return (
		<button
			key={props.id}
			id={props.isSelectedItem ? 'CardsSelect__input' : undefined}
			type="button"
			aria-expanded={props.ariaExpanded}
			aria-haspopup={props.ariaHaspopup}
			aria-label={props.ariaLabel}
			aria-selected={props.ariaSelected}
			className={style.CardsSelect__item}
			onClick={props.onItemClick}
			role={props.role}
			disabled={props.isDisabled}
			data-testid="cards-select-input"
		>
			<LastFour
				key={props.id}
				bold
				description={props.description}
				iconSize="small"
				network={props.network}
				typeOfCard={props.typeOfCard}
				value={props.value}
				designKey={props.designKey}
			/>
		</button>
	);
}
