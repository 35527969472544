import WithQuery from 'utils/WithQuery';
import WebhooksSectionError from './components/WebhooksSectionError/WebhooksSectionError';
import WebhooksSectionLoading from './components/WebhooksSectionLoading/WebhooksSectionLoading';
import WebhooksSectionSuccess from './components/WebhooksSectionSuccess/WebhooksSectionSuccess';
import useWebhooksSection from './useWebhooksSection';

export default WithQuery({
	Component: WebhooksSectionSuccess,
	Error: WebhooksSectionError,
	Loader: WebhooksSectionLoading,
	useHook: useWebhooksSection,
});
