import useSession from 'hooks/useSession';
import { useRequestExampleAppMutation } from 'queries/sdks';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

const USA_COUNTRY_CODE = 1;

export default function useModalRequestExampleApp() {
	const { environment } = useSession();
	const requestExampleAppMutation = useRequestExampleAppMutation();

	const { handleSubmit, isButtonEnabled, bind } = useForm({
		initialValues: { phoneNumber: '', countryCode: 'US' },
		onSubmit: (values) =>
			requestExampleAppMutation.mutate({
				countryCode: USA_COUNTRY_CODE,
				number: Number.parseInt(values.phoneNumber),
			}),
		validate: validationService.createValidator({
			phoneNumber: [validationService.phoneNumber, validationService.required],
		}),
	});

	return {
		bind,
		environment,
		isButtonDisabled: !isButtonEnabled,
		isLoading: requestExampleAppMutation.isLoading,
		requestSms: handleSubmit,
		isWaitingForConfirmation: requestExampleAppMutation.isSuccess,
		serverError: requestExampleAppMutation.error?.message || '',
	};
}
