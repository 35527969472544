import WithQuery from 'utils/WithQuery';
import TransactionsPageFailed from './components/TransactionsPageFailed/TransactionsPageFailed';
import TransactionsPageLoading from './components/TransactionsPageLoading/TransactionsPageLoading';
import TransactionsPageSuccess from './components/TransactionsPageSuccess/TransactionsPageSuccess';
import useTransactions from './useTransactionsPage';

export default WithQuery({
	Component: TransactionsPageSuccess,
	Error: TransactionsPageFailed,
	Loader: TransactionsPageLoading,
	useHook: useTransactions,
});
