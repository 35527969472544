import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import style from './Display.module.scss';

export interface IDisplayProps {
  /**
   * A CSS classname to add additional styles
   */
  className?: string;

  /**
   * Color of the text.
   *
   * @default contentPrimary
   */
  color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary';

  /**
   * HTML tag element to render as.
   */
  tag?: 'h1' | 'h2' | 'h3' | 'h4';

  /**
   * Alignment of the text.
   *
   * @default left
   */
  textAlign?: 'left' | 'center' | 'right' | 'justified';

  /**
   * Add magin to the element.
   */
  margin?: React.CSSProperties['margin'];

  /**
   * This will define the size of the font and the margin-bottom.
   */
  size: 'large' | 'medium' | 'small' | 'x-small';
}

export default function Display(props: PropsWithChildren<IDisplayProps>) {
  const cnColor = _getColorClass(props.color);
  const cnTextAlign = style[`Display--${props.textAlign || 'left'}`];
  const cnSize = style[`Display--${props.size}`];

  switch (props.tag) {
    case 'h1':
      return (
        <h1
          style={{ margin: props.margin }}
          className={classnames(style.Display, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          style={{ margin: props.margin }}
          className={classnames(style.Display, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          style={{ margin: props.margin }}
          className={classnames(style.Display, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          style={{ margin: props.margin }}
          className={classnames(style.Display, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h4>
      );
    default:
      return (
        <span
          style={{ margin: props.margin }}
          className={classnames(style.Display, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </span>
      );
  }
}

function _getColorClass(color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary') {
  switch (color) {
    case 'contentSecondary':
      return style['Display--secondary'];
    case 'contentTertiary':
      return style['Display--tertiary'];
    case 'contentPrimary':
    default:
      return style['Display--primary'];
  }
}
