import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import AddPublicKeySection from './components/AddPublicKeySection/AddPublicKeySection';
import EditPublicKeySection from './components/EditPublicKeySection/EditPublicKeySection';
import style from './PublicKeyStepSuccess.module.scss';
import usePublicKeyStepSuccess from './usePublicKeyStepSuccess';

interface IPublicKeyStepSuccessProps {
	applicationId: string;
	publicKey: string;
	name: string;
}

export default function PublicKeyStepSuccess(props: IPublicKeyStepSuccessProps) {
	const { handleConfirm, publicKey, showModalSetPublicKey } = usePublicKeyStepSuccess(props);

	return (
		<View>
			<Display size="x-small" tag="h1" margin="0 0 12px">
				Upload public key
			</Display>
			<Paragraph
				size="medium"
				color="contentPrimary"
				margin="12px 0 24px"
				className={style.PublicKeyStepSuccess__summary}
			>
				As part of Apto's security enhancements, all card programs applying for Production that use Apto's SDKs or
				Mobile API are required to implement Signed Payloads. Please note that your public key must be unique to
				production - you may not reuse a public key that was uploaded to Sandbox. Please upload a new public key before
				submitting your application.{' '}
				<Pill
					to="https://docs.aptopayments.com/docs/overview/Getting%20Started/signed_payloads"
					level="inline-link"
					external
					size="medium"
				>
					Learn more
				</Pill>
				.
			</Paragraph>
			{publicKey ? (
				<EditPublicKeySection onEdit={showModalSetPublicKey} publicKey={publicKey} />
			) : (
				<AddPublicKeySection onAdd={showModalSetPublicKey} />
			)}
			<Pill
				disabled={!publicKey}
				level="primary"
				onClick={handleConfirm}
				size="medium"
				data-testid="btn-next"
				type="button"
			>
				Next
			</Pill>
		</View>
	);
}
