import Notification from 'components/Notification/Notification';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './BillingStepWithApplicationId.module.scss';
import useBillingStepWithApplicationId from './useBillingStepWithApplicationId';

export default function BillingStepWithApplicationId() {
	const { handleSubmitButton, isLoading, serverError } = useBillingStepWithApplicationId();

	return (
		<View className={style.BillingStep__container}>
			<Display size="x-small" tag="h1" margin="0 0 12px">
				Next steps
			</Display>

			<Notification
				type="negative"
				isHidden={!serverError}
				title="Error"
				description={serverError}
				margin="16px 0px 28px"
			/>

			<Paragraph size="medium" margin="0 0 40px">
				After submitting your application, here's what to expect:
			</Paragraph>
			<View className={style['BillingStep__item--interview']}>
				<Heading size="x-small" tag="h2" className={style.BillingStep__heading}>
					Interview
				</Heading>
				<Paragraph size="medium">
					A member of our team will be reaching out to set up a 20-30 minute interview to confirm a few details about
					your business.
				</Paragraph>
			</View>
			<View className={style['BillingStep__item--program-setup']}>
				<Heading size="x-small" tag="h2" className={style.BillingStep__heading}>
					Program setup
				</Heading>
				<Paragraph size="medium">
					After your production application interview, we'll guide you through the bank approvals process and get
					started on setting up your cardholder support team.
				</Paragraph>
			</View>
			<View className={style['BillingStep__item--launch']}>
				<Heading size="x-small" tag="h2" className={style.BillingStep__heading}>
					Launch!
				</Heading>
				<Paragraph size="medium">You’re ready to go live! Start onboarding cardholders and issuing cards.</Paragraph>
			</View>

			<Pill
				type="button"
				level="primary"
				size="medium"
				className={style.BillingStep__cta}
				loading={isLoading}
				onClick={handleSubmitButton}
				data-testid="btn-submit"
			>
				Submit application
			</Pill>
		</View>
	);
}
