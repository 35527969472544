import React from 'react';
import Select, { ActionMeta } from 'react-select';
import styles from './EnvironmentSelector.style';

interface IEnvironmentSelectorProps {
	label: string;
	name: string;
	onChange:
		| (((value: any, actionMeta: ActionMeta<any>) => void) & ((value: any, action: ActionMeta<any>) => void))
		| undefined;
	options: Array<{ label: string; value: string }>;
	value: { value: string; label: string };
}

export default function EnvironmentSelector(props: IEnvironmentSelectorProps) {
	return (
		<Select
			isSearchable={typeof global.jest !== 'undefined'} // FIXME: Find a better way to test this
			menuPlacement="auto"
			inputId={props.name}
			name={props.name}
			aria-label={props.label}
			options={props.options}
			value={props.value}
			onChange={props.onChange}
			styles={styles}
		/>
	);
}
