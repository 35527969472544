import {
	ACCEPT_INVITE_ROUTE,
	CONTACT_US,
	CREATE_ACCOUNT_ROUTE,
	DIAGNOSTICS_ROUTE,
	EMAIL_CONFIRMATION_ROUTE,
	ERROR_ROUTE,
	EXPIRED_INVITE_LINK_ROUTE,
	FORGOT_PASSWORD_ROUTE,
	LOGIN_ROUTE,
	PENDING_ACTIVATION_ROUTE,
	RESET_PASSWORD_ROUTE,
	SUCCESSFUL_ACTIVATION_ROUTE,
	SYNC_2FA_ROUTE,
	VERIFY_2FA_ROUTE,
	WAIT_FOR_EMAIL_ROUTE,
} from 'constants/routes';
import ForgotPasswordPage from 'pages/2FA/forgotPassword/ForgotPassword.page';
import ResetPasswordPage from 'pages/2FA/resetPassword/ResetPassword.page';
import Verify2FAPage from 'pages/2FA/TwoFactorAuth.page';
import AcceptInvitePage from 'pages/acceptInvite/AcceptInvite.page';
import ActivateAccountRootPage from 'pages/activateAccount/ActivateAccount.root.page';
import ConfirmEmailPage from 'pages/confirmEmail/ConfirmEmail.page';
import ContactUsPage from 'pages/contactUs/ContactUs.page';
import CreateAccountPage from 'pages/createAccount/CreateAccount.page';
import DashboardRootPage from 'pages/dashboard/Dashboard.root.page';
import DiagnosticsPage from 'pages/diagnostics/Diagnostics.page';
import ErrorPage from 'pages/error/Error.page';
import ExpiredInviteLinkPage from 'pages/expiredInviteLink/ExpiredInviteLink.page';
import LoadFundsRootPage from 'pages/loadFunds/LoadFunds.root.page';
import LoginPage from 'pages/login/Login.page';
import PendingApplicationPage from 'pages/PendingApplication/PendingApplication.page';
import PushFundsRootPage from 'pages/pushFunds/PushFunds.root.page';
import SuccessfulApplicationPage from 'pages/successfulApplication/SuccessfulApplication.page';
import Sync2FAPage from 'pages/Sync2FA/Sync2FA.page';
import WaitForEmailPage from 'pages/waitForEmail/WaitForEmail.page';
import { Route } from 'react-router-dom';
import useApp from 'useApp';
import SwitchWithNotFound from 'utils/SwitchWithNotFound';

export default function App() {
	useApp();

	return (
		<>
			<SwitchWithNotFound>
				<Route path="/" element={<LoginPage />} />
				<Route path="push-funds/*" element={<PushFundsRootPage />} />
				<Route path="dashboard/*" element={<DashboardRootPage />} />
				<Route path="activate-account/*" element={<ActivateAccountRootPage />} />

				<Route path="load-funds/*" element={<LoadFundsRootPage />} />
				<Route path="push-funds/:cardholderId/*" element={<PushFundsRootPage />} />

				<Route path={CREATE_ACCOUNT_ROUTE} element={<CreateAccountPage />} />
				<Route path={WAIT_FOR_EMAIL_ROUTE} element={<WaitForEmailPage />} />
				<Route path={EMAIL_CONFIRMATION_ROUTE} element={<ConfirmEmailPage />} />
				<Route path={SYNC_2FA_ROUTE} element={<Sync2FAPage />} />
				<Route path={ACCEPT_INVITE_ROUTE} element={<AcceptInvitePage />} />
				<Route path={LOGIN_ROUTE} element={<LoginPage />} />
				<Route path={VERIFY_2FA_ROUTE} element={<Verify2FAPage />} />
				<Route path={RESET_PASSWORD_ROUTE} element={<ResetPasswordPage />} />
				<Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPasswordPage />} />

				<Route path={PENDING_ACTIVATION_ROUTE} element={<PendingApplicationPage />} />
				<Route path={SUCCESSFUL_ACTIVATION_ROUTE} element={<SuccessfulApplicationPage />} />
				<Route path={ERROR_ROUTE} element={<ErrorPage location={{}} />} />
				<Route path={CONTACT_US} element={<ContactUsPage />} />
				<Route path={EXPIRED_INVITE_LINK_ROUTE} element={<ExpiredInviteLinkPage />} />

				<Route path={DIAGNOSTICS_ROUTE} element={<DiagnosticsPage />} />
			</SwitchWithNotFound>
		</>
	);
}
