import React from 'react';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import Section from '../Section/Section';
import SectionTitle from '../SectionTitle/SectionTitle';
import CardTransaction from './components/CardTransaction/CardTransaction';

interface ITransactionsSectionProps {
	transactions: IAptoCardSummaryResponse['transactions'];
	onTransactionClicked: (id: string) => void;
}

export default function TransactionsSection(props: ITransactionsSectionProps) {
	return (
		<Section>
			<SectionTitle title="Recent Transactions" marginTopCorrection />
			{props.transactions.length > 0 ? (
				props.transactions.map((transaction) => (
					<CardTransaction
						key={transaction.id}
						merchant={transaction.merchantName}
						createdAt={transaction.createdAt}
						id={transaction.id}
						cost={transaction.localAmount}
						onClick={() => props.onTransactionClicked(transaction.id)}
					/>
				))
			) : (
				<Paragraph size="small" margin="3rem auto 2rem auto">
					This card has no transactions yet
				</Paragraph>
			)}
		</Section>
	);
}
