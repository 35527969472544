import WithQuery from 'utils/WithQuery';
import DocumentsError from './components/DocumentsError';
import DocumentsSuccess from './components/DocumentsSuccess';
import useDocumentsSection from './useDocumentsSection';

export default WithQuery({
	Component: DocumentsSuccess,
	Error: DocumentsError,
	useHook: useDocumentsSection,
});
