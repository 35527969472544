import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import SlideCard from 'styleguide/SlideCard/SlideCard';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import style from './CompanyPanel.module.scss';
import CompanyLogo from './components/CompanyLogo/CompanyLogo';
import TeamsList from './components/TeamsList/TeamsList';
import useCompanyPanel from './useCompanyPanel';

export interface ICompanyPanelProps {
	companyName: string;
	isHidden: boolean;
	onCloseClicked: () => void;
}

export default function CompanyPanel(props: ICompanyPanelProps) {
	const { handleSwitchCompany, showModalInviteMember } = useCompanyPanel(props.onCloseClicked);

	return (
		<View className={style.CompanyPanel}>
			<SlideCard isHidden={props.isHidden} onCloseClicked={props.onCloseClicked} slideDirection="left">
				<View className={style.CompanyPanel__inner}>
					<View className={style.CompanyPanel__header}>
						<CompanyLogo companyName={props.companyName} size="large" />
						<Label size="large" margin="16px 0 0 0" fontWeight="600">
							{props.companyName}
						</Label>
					</View>
					<TeamsList companyName={props.companyName} handleSwitchCompany={handleSwitchCompany} />
					<View className={style.CompanyPanel__modalBlock}>
						<Pill
							level="inline-link"
							type="button"
							size="small"
							onClick={showModalInviteMember}
							className={style.CompanyPanel__link}
							to="/dashboard/"
						>
							Invite people to {props.companyName}
						</Pill>
					</View>
					{/* <View className={style.CompanyPanel__modalBlock}>
						<Pill
							level="inline-link"
							type="link"
							size="small"
							onClick={() => null}
							className={style.CompanyPanel__link}
							to=" "
						>
							Create a new team
						</Pill>
					</View> */}
				</View>
			</SlideCard>
		</View>
	);
}
