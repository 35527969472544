import ICreditCard from 'types/financial/ICreditCard';
import luhn10 from './luhn10';

export function validate(creditCard: ICreditCard) {
	return {
		isPanValid: validatePan(creditCard.pan),
		isCvvValid: validateCVV(creditCard.cvv),
		isExpValid: validateExp(creditCard.exp),
	};
}

function validatePan(pan: string): boolean {
	const hasKnownPattern = Object.values(CREDIT_CARD_REGEX).some((regex) => regex.test(pan));
	return hasKnownPattern && luhn10(pan);
}

// TODO: Amex has 4 digits
function validateCVV(cvv: string): boolean {
	return CVV_REGEX.test(cvv);
}

function validateExp(exp: string): boolean {
	try {
		const [rawMonth, rawYear] = exp.split('/');
		const month = parseInt(rawMonth);
		const year = parseInt(`20${rawYear}`);

		// Validate dates locally. Should we do this in UTC?
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth() + 1;

		if (isNaN(month) || isNaN(year)) {
			return false;
		}

		if (year < currentYear) {
			return false;
		}

		if (year === currentYear && month < currentMonth) {
			return false;
		}

		if (month < 1 || month > 12) {
			return false;
		}

		return true;
	} catch (err) {
		return false;
	}
}

function formatExpirationDate(value: string): string {
	const [mm, yy] = value.split('/');
	return `20${yy}-${mm}`;
}

export default {
	formatExpirationDate,
	validate,
	validateCVV,
	validateExp,
	validatePan,
};

const CREDIT_CARD_REGEX = {
	VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
	MASTERCARD: /^(5[1-5][0-9]{14}|2221[0-9]{12}|222[2-9][0-9]{12}|22[3-9][0-9]{13}|2[3-6][0-9]{14}|27[01][0-9]{13}|2720[0-9]{12})$/,
	AMEX: /^3[47][0-9]{13}$/,
	DISCIVER: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
};

const CVV_REGEX = /^[0-9]{3}$/;
