import apiClientService from 'api-client/apiclient.service';
import { IPhoneNumber, IRequestExampleAppResponse } from 'api-client/sdks/sdks';
import useSession from 'hooks/useSession';
import { useMutation, UseMutationOptions } from 'react-query';
import IError from 'types/network/IError';

interface IUseRequestExampleAppArgs extends UseMutationOptions<IRequestExampleAppResponse, IError, IPhoneNumber> {}

export function useRequestExampleAppMutation(args?: IUseRequestExampleAppArgs) {
	const { environment } = useSession();

	return useMutation<IRequestExampleAppResponse, IError, IPhoneNumber>({
		mutationFn: (phoneNumber) =>
			apiClientService.sdks.requestExampleApp({
				phoneNumber,
				environment,
			}),
		onSuccess: (data, variables, context) => args?.onSuccess?.(data, variables, context),
		...args,
	});
}
