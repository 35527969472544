import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import { WEBHOOKS_ROUTE } from 'constants/routes';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import style from './CreateWebhook.module.scss';
import useCreateWebhook from './useCreateWebhook';

export default function CreateWebhook() {
	const {
		values,
		errors,
		handleChange,
		handleFocus,
		handleBlur,
		submitForm,
		isSubmitting,
		isButtonEnabled,
		selectAll,
		deselectAll,
		areAllSelected,
		serverError,
	} = useCreateWebhook();

	return (
		<PaddingContainer>
			<form className={style.CreateWebhook} onSubmit={submitForm}>
				<Text type="h5"> Add a webhook endpoint </Text>

				<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} />

				<fieldset className={style.CreateWebhook__FieldSet}>
					<legend>
						<Text type="h7"> Basic data</Text>
					</legend>
					<Input
						autofocus
						value={values.url}
						error={errors.url}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						margin="8px 0"
						type="text"
						id="url"
						name="url"
						label="Endpoint url"
					/>
					<Input
						value={values.description}
						error={errors.description}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						margin="8px 0"
						type="text"
						id="description"
						name="description"
						label="Description"
					/>
				</fieldset>

				<fieldset className={style.CreateWebhook__FieldSet}>
					<legend>
						<Text type="h7"> Credentials</Text>
					</legend>
					<Input
						value={values.username}
						error={errors.username}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						margin="8px 0"
						type="text"
						id="username"
						name="username"
						label="Username"
					/>
					<Input
						type="password"
						value={values.password}
						error={errors.password}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						margin="8px 0"
						id="password"
						name="password"
						label="Password"
						showPasswordToggle
					/>
				</fieldset>

				<fieldset className={style.CreateWebhook__FieldSet}>
					<legend
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Text margin="8px 0" type="h7">
							Events to send
						</Text>
						{areAllSelected ? (
							<Pill type="button" level="inline-link" size="small" onClick={deselectAll}>
								Clear
							</Pill>
						) : (
							<Pill type="button" level="inline-link" size="small" onClick={selectAll}>
								Select all
							</Pill>
						)}
					</legend>
					<CheckBox
						value={values.cardSentEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="cardSentEvent"
						label="Card sent"
					/>
					<CheckBox
						value={values.pinUpdateEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="pinUpdateEvent"
						label="Pin update"
					/>
					<CheckBox
						value={values.statusUpdateEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="statusUpdateEvent"
						label="Status update"
					/>
					<CheckBox
						value={values.identityUpdateEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="identityUpdateEvent"
						label="Identity update"
					/>
					<CheckBox
						value={values.kycStatusEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="kycStatusEvent"
						label="KYC status"
					/>
					<CheckBox
						value={values.transactionUpdateEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="transactionUpdateEvent"
						label="Transaction update"
					/>
					<CheckBox
						value={values.userUpdateEvent}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						name="userUpdateEvent"
						label="User update"
					/>
				</fieldset>

				<View className={style.CreateWebhook__ButtonArea}>
					<Pill type="link" level="secondary" size="medium" to={WEBHOOKS_ROUTE}>
						<Label size="medium" color="negative">
							Cancel
						</Label>
					</Pill>

					<Pill
						type="submit"
						level="primary"
						size="medium"
						disabled={!isButtonEnabled}
						loading={isSubmitting}
						data-testid="btn-create-webhook"
					>
						Add
					</Pill>
				</View>
			</form>
		</PaddingContainer>
	);
}

// TODO: This should be a component under the components folder but it needs more refinement
function CheckBox(props: any) {
	const id = props.name;
	const name = props.name;
	return (
		<View className={style.CreateWebhook__CheckBox}>
			<label htmlFor={id}> {props.label} </label>
			<input
				onChange={props.onChange}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				id={id}
				name={name}
				type="checkbox"
				value={props.value}
				checked={props.value}
			/>
		</View>
	);
}
