import { PUSH_FUNDS_LOAD_ROUTE, TRANSACTIONS_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router';

interface IUseCardDetailsSuccessArgs {
	userId: string;
	cardId: string;
}

export default function useCardDetailsSuccess(args: IUseCardDetailsSuccessArgs) {
	const navigate = useNavigate();
	const { isProduction } = useSession();

	function onTransactionClicked(id: string) {
		navigate(`${TRANSACTIONS_ROUTE}/${id}`);
	}

	function onLoadFundsClicked() {
		navigate(`${PUSH_FUNDS_LOAD_ROUTE.replace(':cardholderId', args.userId)}?cardId=${args.cardId}`);
	}

	return {
		isProduction,
		onTransactionClicked,
		onLoadFundsClicked,
	};
}
