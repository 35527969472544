import { useCreateApiKeyMutation } from 'queries/apiKeys.queries';
import usePureState from 'utils/usePureState';

export interface IUseModalAddApiKeysState {
	description: string;
	step: number;
}

interface IUseModalAddApiKeysProps {
	onCredentialGenerated: () => void;
}

export default function useModalAddApiKeys({ onCredentialGenerated }: IUseModalAddApiKeysProps) {
	const createApiKeyMutation = useCreateApiKeyMutation();

	const { state, dispatch } = usePureState<IUseModalAddApiKeysState>({
		description: '',
		step: 0,
	});

	function generateApiKey() {
		return createApiKeyMutation.mutate(state.description, {
			onSuccess: () => {
				dispatch({ step: 1 });
				onCredentialGenerated();
			},
		});
	}

	return {
		props: {
			generateApiKey,
			isButtonDisabled: state.description === '',
			setDescription: (e: any) => dispatch({ description: e.target.value }),
			apiKey: createApiKeyMutation.data?.apiClientId || '',
			apiSecret: createApiKeyMutation.data?.apiClientSecret || '',
			created: createApiKeyMutation.data?.created || new Date(),
			description: state.description,
			serverError: createApiKeyMutation.error?.message || '',
			step: state.step,
			updated: createApiKeyMutation.data?.updated || new Date(),
		},
		status: createApiKeyMutation.status,
	};
}
