import Notification from 'components/Notification/Notification';
import React from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalSetPublicKey.module.scss';
import useModalSetPublicKey from './useModalSetPublicKey';

interface IModalAddOriginVerificationKeyProps {
	onSuccess: () => void;
	applicationId: string;
	currentPublicKey: string;
	currentName: string;
}

export default function ModalSetPublicKey(props: IModalAddOriginVerificationKeyProps) {
	const { onSuccess, applicationId, currentPublicKey, currentName } = props;

	const { isButtonEnabled, isButtonLoading, hasPublicKey, bind, onSubmit, serverError } = useModalSetPublicKey({
		onSuccess,
		applicationId,
		currentPublicKey,
		currentName,
	});

	return (
		<View className={style.ModalSetPublicKey} data-testid="modal-set-public-key">
			<Notification isHidden={!serverError} type="negative" margin="0 0 20px" title="Error" description={serverError} />
			<form>
				<Heading tag="h1" margin="0 0 12px 0" size="small" color="contentPrimary">
					{hasPublicKey ? 'Edit Signed Payloads Key' : 'Add Signed Payloads Key'}
				</Heading>
				<Paragraph margin="12px auto 24px" size="small" color="contentPrimary">
					Once you've generated your key pair, copy and paste the public key into the box below.{' '}
					<Pill
						type="link"
						level="inline-link"
						size="small"
						to="https://docs.aptopayments.com/docs/overview/Getting%20Started/signed_payloads"
						external
					>
						Learn more about Signed Payloads
					</Pill>
					.
				</Paragraph>

				<Input type="text" label="Enter name here" {...bind('name')} />
				<Input type="textarea" label="Enter key here" {...bind('publicKey')} />

				<View className={style.ModalSetPublicKey__footer}>
					<Pill
						type="submit"
						level="primary"
						size="medium"
						disabled={!isButtonEnabled}
						loading={isButtonLoading}
						onClick={onSubmit}
						data-testid="btn-save"
					>
						Save
					</Pill>
				</View>
			</form>
		</View>
	);
}
