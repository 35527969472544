import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IAptoCardStatus from 'types/aptoCards/IAptoCardStatus';
import ICardNetwork from 'types/financial/ICardNetwork';
import ITransactionStatus from 'types/transactions/ITransactionStatus';

export const DESIGN_KEYS: IAptoCardDesignKey[] = ['black', 'blue', 'coral', 'green', 'grey', 'indigo', 'white'];
export const CARD_NETWORKS: ICardNetwork[] = ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER', 'OTHER'];
export const APTO_CARD_NETWORKS: IAptoCardNetwork[] = ['MASTERCARD', 'VISA'];
export const CARD_STATUSES: IAptoCardStatus[] = ['CREATED', 'ACTIVATED', 'DEACTIVATED', 'CLOSED', 'PAID', 'NEW'];
export const TRANSACTION_STATUSES: ITransactionStatus[] = ['pending', 'complete', 'declined'];
