import useGetPaginationConfig from 'hooks/useGetPaginationConfig';
import { useListTransactionsQuery } from 'queries/transactions.queries';
import ITransaction from 'types/transactions/ITransaction';

export default function useTransactionsPage() {
	const paginationConfig = useGetPaginationConfig();
	const transactionsQuery = useListTransactionsQuery({ paginationConfig });

	return {
		props: {
			// SafeCast: We are using WithQuery
			isPaginationNextVisible: transactionsQuery.data?.hasMore as boolean,
			// SafeCast: We are using WithQuery
			isPaginationPrevVisible: transactionsQuery.data?.hasMoreBefore as boolean,
			// SafeCast: We are using WithQuery
			total: transactionsQuery.data?.total as number,
			// SafeCast: We are using WithQuery
			transactions: transactionsQuery.data?.transactions as ITransaction[],
			serverError: transactionsQuery.error?.message || '',
		},
		status: transactionsQuery.status,
	};
}
