import apiclientService from 'api-client/apiclient.service';
import { SessionContext } from 'providers/Session.provider';
import { useCallback, useContext, useEffect } from 'react';
import pendoService from 'services/pendo.service';
import sessionService from 'services/session.service';
import IEnvironment from 'types/session/IEnvironment';
import ILastApplication from 'types/session/ILastApplication';
import I2FAResponse from 'types/session/ITwoFAResponse';

// FIXME: Rewrite this hook. It is wrong and causes re-renders :/
export default function useSession() {
	const context = useContext(SessionContext);

	if (!context) {
		throw Error('useSession cannot be called outside a session context');
	}

	const { session, setSession } = context;

	/**
	 * Everytime the session changes we save the new version on sessionstorage.
	 */
	useEffect(() => {
		sessionService.setSession(session);
	}, [session]);

	function initSession(response: I2FAResponse): void {
		const session = sessionService.init(response);
		return setSession(session);
	}

	function logOut() {
		return apiclientService.auth.logout().then(() => setSession(null));
	}

	function setEnvironment(currentEnv: IEnvironment): void {
		if (!session) {
			throw Error('Cannot call setEnvironment on a uninitialized session');
		}
		setSession({ ...session, currentEnv });

		// Update env in pendo
		pendoService.identifyUser({
			email: session.session.userData.email,
			companyName: session.developerAccount.companyName,
			environment: currentEnv,
		});
	}

	function fetchSession(env?: IEnvironment) {
		return apiclientService.auth.getSession().then((res) =>
			setSession({
				...res,
				currentEnv: env || (sessionService.getEnvironment() as IEnvironment),
			})
		);
	}

	//This should only be called from useOverviewStep
	function setLastApplicationId(id: string) {
		const lastApplication: ILastApplication = {
			id,
			// As this should be only called from OverviewStep, the next step is company_info
			status: 'company_info',
			// TODO
			type: 'get_started',
		};
		setSession((s) => {
			if (!s) {
				//TODO register an error if this happens
				return null;
			}
			return {
				...s,
				developerAccount: {
					...s.developerAccount,
					lastApplication: lastApplication,
				},
			};
		});
	}

	return {
		accessLevel: session?.developerAccount.accessLevel,
		companyName: session?.developerAccount.companyName as string,
		email: session?.session.userData.email,
		environment: session?.currentEnv as IEnvironment,
		firstName: session?.session.userData.firstName as string,
		isLoggedIn: !!session?.session.userToken,
		isProduction: session?.currentEnv === 'production',
		lastApplicationId: session?.developerAccount.lastApplication?.id,
		lastApplicationStatus: session?.developerAccount.lastApplication?.status,
		lastName: session?.session.userData.lastName as string,
		session,
		userId: session?.session.userId,
		initSession,
		logOut: useCallback(logOut, [setSession]),
		setLastApplicationId,
		setEnvironment,
		setSession,
		fetchSession: useCallback(fetchSession, [setSession]),
	};
}
