import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './SelectInput.module.scss';

export interface ISelectInputProps extends ICommonInputProps {
  type: 'select';
  value: string | number;
  /** An array of a key value object with all the possible options for a selectable input */
  selectOptions: Record<string, string> | string[];
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLSelectElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
}

export default function SelectInput(props: ISelectInputProps) {
  if (!props.selectOptions) {
    return null;
  }

  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }

  let selectOptions: any = props.selectOptions; // eslint-disable-line
  if (Array.isArray(props.selectOptions)) {
    selectOptions = {};
    props.selectOptions.forEach((value) => (selectOptions[value] = value));
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(style.Input, style[cnContent], style[cnError], style[cnDisabled])}
    >
      <label className={style.Input__label} htmlFor={props.id}>
        {' '}
        {props.label}{' '}
      </label>
      <select
        className={style['Input__input--select']}
        disabled={props.disabled}
        autoComplete={props.autocomplete}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        <option value="" disabled>
          {props.label || 'Select option'}
        </option>
        {Object.keys(selectOptions).map((key) => {
          return (
            <option key={key} value={key}>
              {selectOptions && selectOptions[key]}
            </option>
          );
        })}
      </select>
      {props.error ? <span className={style.Input__error}>{props.error}</span> : null}
    </div>
  );
}
