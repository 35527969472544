import ITeamMember from 'types/team/ITeamMember';

export function parseTeamMembers(raw: any) {
	return {
		teamMembers: raw.data.map(_parseTeamMember),
		hasMore: raw.has_more,
		total: raw.total,
	};
}

export function _parseTeamMember(teamMember: any): ITeamMember {
	return {
		firstName: teamMember.name.first_name,
		lastName: teamMember.name.last_name,
		email: teamMember.email,
		lastSeen: new Date(teamMember.last_seen),
		role: teamMember.role,
		userId: teamMember.user_id,
	};
}

export default { parseTeamMembers };
