import apiClientService from 'api-client/apiclient.service';
import { INewWebhook, IReadWebhooksResponse } from 'api-client/webhooks/webhooks';
import useSession from 'hooks/useSession';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import IWebhook from 'types/IWebhook';
import IError from 'types/network/IError';

export const QK_WEBHOOKS = 'QK_WEBHOOKS';

interface IUseListWebhooksQueryArgs extends UseQueryOptions<IReadWebhooksResponse, IError> {}

export function useListWebhooksQuery(args?: IUseListWebhooksQueryArgs) {
	const { environment } = useSession();

	return useQuery<IReadWebhooksResponse, IError>({
		queryKey: [environment, QK_WEBHOOKS, 'list'],
		queryFn: () =>
			apiClientService.webhooks.read({
				environment,
			}),
		...args,
	});
}

interface IUseRemoveWebhookMutationArgs extends UseMutationOptions<void, IError, string> {}

export function useRemoveWebhookMutation(args?: IUseRemoveWebhookMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<void, IError, string>({
		mutationFn: (id) =>
			apiClientService.webhooks.remove({
				id,
				environment,
			}),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_WEBHOOKS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseCreateWebhookMutationArgs extends UseMutationOptions<IWebhook, IError, INewWebhook> {}

export function useCreateWebhookMutation(args?: IUseCreateWebhookMutationArgs) {
	const { environment } = useSession();
	const queryClient = useQueryClient();

	return useMutation<IWebhook, IError, INewWebhook>({
		mutationFn: (newWebhook: INewWebhook) =>
			apiClientService.webhooks.create({
				webhook: newWebhook,
				environment,
			}),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_WEBHOOKS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}
