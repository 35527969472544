import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './TextAreaInput.module.scss';

export interface ITextAreaInputProps extends ICommonInputProps {
  type: 'textarea';
  /** type textarea only. The number of rows to display */
  rows?: number;
  /** The actual value of the input */
  value: string | undefined;
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
}

export default function TextAreaInput(props: ITextAreaInputProps) {
  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(style.Input, style[cnContent], style[cnError], style[cnDisabled])}
    >
      <textarea
        className={style['Input__input--textarea']}
        rows={props.rows}
        autoComplete={props.autocomplete}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        readOnly={props.readOnly}
      />
      <label className={classnames(style.Input__label, style['Input__label--textarea'])} htmlFor={props.id}>
        {props.label}
      </label>
      {props.error ? <span className={style.Input__error}>{props.error}</span> : null}
    </div>
  );
}
