import { useRemoveApiKeyMutation } from 'queries/apiKeys.queries';

interface IUseModalDeleteApiKeyProps {
	apiKeyId: string;
	onApiKeyRemoved: () => void;
}

export default function useModalDeleteApiKey({ apiKeyId, onApiKeyRemoved }: IUseModalDeleteApiKeyProps) {
	const removeApiKeyMutation = useRemoveApiKeyMutation();

	function handleRemoveApiKey() {
		return removeApiKeyMutation.mutate(apiKeyId, {
			onSuccess: () => {
				onApiKeyRemoved();
			},
		});
	}

	return {
		handleRemoveApiKey,
		isLoading: removeApiKeyMutation.isLoading,
		serverError: removeApiKeyMutation.error?.message || '',
	};
}
