import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import style from './InvoicesSection.module.scss';

export default function InvoicesSection() {
	return (
		<PaddingContainer>
			<div className={style.InvoicesSection}>Invoices</div>
		</PaddingContainer>
	);
}
