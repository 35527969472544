import Notification from 'components/Notification/Notification';
import { useEffect } from 'react';
import Item from 'styleguide/Item/Item';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ModalBeforeGetStarted from './components/ModalBeforeGetStarted';
import style from './OverviewStep.module.scss';
import useOverviewStep from './useOverviewStep';

export default function OverviewStep() {
	const { isLoading, handleNextClick, serverError, lastApplicationStepRoute } = useOverviewStep();

	useEffect(
		function showBeforeStartModal() {
			if (lastApplicationStepRoute === null) {
				Modal.show(<ModalBeforeGetStarted />);
			}
		},
		[lastApplicationStepRoute]
	);

	return (
		<View className={style.ActivateAccount}>
			<View className={style.ActivateAccount__column}>
				<Display size="x-small" tag="h1" margin="0 0 12px">
					It’s go time
				</Display>
				<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} margin="32px 0" />
				<Paragraph size="medium" margin="0 0 24px">
					Congrats! This is the first step towards issuing cards that work in the real world. In production mode, you’ll
					be able to onboard real users with our mobile SDK, issue branded debit cards linked to an accompanying demand
					deposit account (DDA), and fund your Program Balance. Once you have completed this application, you can invite
					anyone to sign up for your card and they can start using their cards as soon as today.
				</Paragraph>

				<Label size="medium" fontWeight="600" className={style.ActivateAccount__subtitle}>
					Features
				</Label>
				<View className={style.ActivateAccount__list}>
					<View className={style.ActivateAccount__itemsGroup}>
						<Item padding="14px 18px 14px 0">
							<Paragraph size="small">Physical and/or Digital Cards</Paragraph>
						</Item>
						<Item padding="14px 18px 14px 0">
							<Paragraph size="small"> Cardholder DDA </Paragraph>
						</Item>
						<Item padding="14px 18px 14px 0">
							<Paragraph size="small"> ACH & Direct Deposit </Paragraph>
						</Item>
						<Item padding="14px 18px 14px 0" hasBorderBottom={true}>
							<Paragraph size="small"> Digital Wallets </Paragraph>
						</Item>
					</View>

					<View className={style.ActivateAccount__itemsGroup}>
						<Item padding="14px 18px 14px 0">
							<Paragraph size="small"> ATM Withdrawals </Paragraph>
						</Item>
						<Item padding="14px 18px 14px 0">
							<Paragraph size="small"> Branded Card Design </Paragraph>
						</Item>
						<Item padding="14px 18px 14px 0">
							<Paragraph size="small"> Standard Pricing </Paragraph>
						</Item>
						<Item padding="14px 18px 14px 0" hasBorderBottom={true}>
							<Paragraph size="small"> Automated Approval Process </Paragraph>
						</Item>
					</View>
				</View>
			</View>
			<View className={style.ActivateAccount__cta}>
				<Pill type="button" level="primary" size="medium" onClick={handleNextClick} loading={isLoading}>
					Next
				</Pill>
			</View>
		</View>
	);
}
