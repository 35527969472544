import React from 'react';
import Dot from 'styleguide/Dot/Dot';
import View from 'styleguide/View/View';
import IAptoCardStatus from 'types/aptoCards/IAptoCardStatus';

interface IProps {
	value: IAptoCardStatus;
}

export default function CardStatus({ value }: IProps) {
	switch (value) {
		case 'NEW':
			return (
				<View style={{ textTransform: 'capitalize' }}>
					<Dot /> {value.toLowerCase()}
				</View>
			);
		case 'ACTIVATED':
		case 'CREATED':
		case 'PAID':
			return (
				<View style={{ textTransform: 'capitalize' }}>
					<Dot type="positive" /> {value.toLowerCase()}
				</View>
			);
		case 'CLOSED':
		case 'DEACTIVATED':
		default:
			return (
				<View style={{ textTransform: 'capitalize' }}>
					<Dot type="negative" /> {value.toLowerCase()}
				</View>
			);
	}
}
