import React from 'react';

export default function TableCell(props: any) {
  if (!props.value) {
    return <td></td>;
  }

  if (props.renderer) {
    return <td>{props.renderer(props.value, props.item)}</td>;
  }

  return <td>{props.value.toString().trim()}</td>;
}
