import Notification from 'components/Notification/Notification';
import Tooltip from 'components/Tooltip/Tooltip';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ComplianceStepSuccess.module.scss';
import useComplianceStepSuccess, { IComplianceStepFields } from './useComplianceStepSuccess';

interface IComplianceStepSuccessProps {
	applicationId: string;
	storedValues: IComplianceStepFields;
}

export default function ComplianceStepSuccess(props: IComplianceStepSuccessProps) {
	const { isButtonDisabled, isLoading, submitForm, handleRadioChange, serverError, state } = useComplianceStepSuccess({
		applicationId: props.applicationId,
		storedValues: props.storedValues,
	});

	return (
		<View className={style.ComplianceStepSuccess}>
			<Display size="x-small" color="contentPrimary" margin="0 0 32px" tag="h1">
				Compliance
			</Display>

			<form className={style.ComplianceStepSuccess__form}>
				<Notification isHidden={!serverError} type="negative" margin="32px 0" title="Error" description={serverError} />
				<Heading size="x-small" tag="h2" margin="0 0 12px" className={style.ComplianceStepSuccess__title}>
					Money Transmitter License
					<Tooltip message="To be a money transmitter, you must be transmitting money either in the US or abroad on behalf of another person or persons. This means your business is engaged in receiving money or some form of monetary value to transmit it. If that is the case, you will likely need a money transmitter license.">
						<View className={style.ComplianceStepSuccess__infoIcon} />
					</Tooltip>
				</Heading>
				<Paragraph size="medium">
					Apto Payments customers must review the requirements for money transmitter licenses in the states in which
					they operate. Is your business required to maintain a money transmitter license?
				</Paragraph>
				{/* TODO Refactor these options into a new component */}
				<View role="radiogroup" data-testid="money-transmitter-license">
					<View className={style.ComplianceStepSuccess__radio}>
						<input
							type="radio"
							id="moneyTransmitterLicense_yes"
							name="moneyTransmitterLicense"
							value="yes"
							checked={state.moneyTransmitterLicense === 'yes'}
							onChange={handleRadioChange}
						/>
						<label htmlFor="moneyTransmitterLicense_yes">Yes</label>
					</View>
					<View className={style.ComplianceStepSuccess__radio}>
						<input
							type="radio"
							id="moneyTransmitterLicense_no"
							name="moneyTransmitterLicense"
							value="no"
							checked={state.moneyTransmitterLicense === 'no'}
							onChange={handleRadioChange}
						/>
						<label htmlFor="moneyTransmitterLicense_no">No</label>
					</View>
					<View className={style.ComplianceStepSuccess__radio}>
						<input
							type="radio"
							id="moneyTransmitterLicense_n/a"
							name="moneyTransmitterLicense"
							value="n/a"
							checked={state.moneyTransmitterLicense === 'n/a'}
							onChange={handleRadioChange}
						/>
						<label htmlFor="moneyTransmitterLicense_n/a">I don't know</label>
					</View>
				</View>

				<Heading size="x-small" tag="h2" margin="44px 0 12px" className={style.ComplianceStepSuccess__title}>
					Accessibility
					<Tooltip message="Title III of the Americans with Disabilities Act (ADA) prohibits discrimination on the basis of disability in the activities of public accommodations. Public Accommodations are considered to be businesses including private entities that are open to the public or that provide goods or services to the public.">
						<View className={style.ComplianceStepSuccess__infoIcon} />
					</Tooltip>
				</Heading>
				<Paragraph size="medium">
					Based on the ADA and public accommodation best practices, websites should be accessible to those with
					disabilities. Has your business performed an accessibility assessment of your website(s) and resolved any
					issues identified?
				</Paragraph>
				<View role="radiogroup" data-testid="accessibility">
					<View className={style.ComplianceStepSuccess__radio}>
						<input
							type="radio"
							id="accessibility_yes"
							name="accessibility"
							value="yes"
							checked={state.accessibility === 'yes'}
							onChange={handleRadioChange}
						/>
						<label htmlFor="accessibility_yes">Yes</label>
					</View>
					<View className={style.ComplianceStepSuccess__radio}>
						<input
							type="radio"
							id="accessibility_no"
							name="accessibility"
							value="no"
							checked={state.accessibility === 'no'}
							onChange={handleRadioChange}
						/>
						<label htmlFor="accessibility_no">No</label>
					</View>
					<View className={style.ComplianceStepSuccess__radio}>
						<input
							type="radio"
							id="accessibility_n/a"
							name="accessibility"
							value="n/a"
							checked={state.accessibility === 'n/a'}
							onChange={handleRadioChange}
						/>
						<label htmlFor="accessibility_n/a">Not Applicable - I do not have a website</label>
					</View>
				</View>

				<Pill
					type="button"
					level="primary"
					size="medium"
					loading={isLoading}
					disabled={isButtonDisabled}
					onClick={submitForm}
					className={style.ComplianceStepSuccess__cta}
					data-testid="btn-next"
				>
					Next
				</Pill>
			</form>
		</View>
	);
}
