export default {
	revenue_account: {
		id: 'dummy_revenue_account_id',
		balance: {
			currency: 'USD',
			amount: 1000.01,
		},
		past_month_revenue: {
			currency: 'USD',
			amount: 1000.02,
		},
		monthly_revenue_growth: 89,
	},
	funding_account: {
		id: 'dummy_funding_account_id',
		balance: {
			currency: 'USD',
			amount: 1000.06,
		},
		ach_account: {
			routing_number: '082000549',
			account_number: '123456789',
		},
	},
	billing_account: {
		id: 'dummy_billing_account_id',
		balance: {
			currency: 'USD',
			amount: 1000.04,
		},
		auto_refill_amount: {
			currency: 'USD',
			amount: 1000.05,
		},
		source: {
			payment_source: {
				card_network: 'VISA',
				card_type: 'debit',
				created_at: '2020-12-10T11:47:39Z',
				description: 'My Credit card',
				id: 'payment_source_abcde46376482478',
				last_four: '8792',
				postal_code: '94103',
				preference_types: ['billing', 'funding'],
				type: 'card',
				updated_at: '2020-12-10T11:47:39Z',
			},
		},
	},
	settlement_account: {
		id: 'dummy_settlement_account_id',
		balance: {
			currency: 'USD',
			amount: 1000.06,
		},
	},
};
