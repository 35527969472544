import { IAddPaymentMethodStep } from 'components/AddPaymentMethod/useAddPaymentMethod';
import React from 'react';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import PaymentType from './components/PaymentType/PaymentType';
import card from './grey-card.svg';

export interface IAddPaymentMethodSelectTypeProps {
	updatePaymentStep: (paymentStep: IAddPaymentMethodStep) => void;
}

export default function AddPaymentMethodSelectType(props: IAddPaymentMethodSelectTypeProps) {
	return (
		<View
			style={{
				marginTop: '30px',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '360px',
			}}
			data-testid="modal-add-payment-method"
		>
			<Heading size="small" color="contentPrimary" tag="h1">
				Add payment method
			</Heading>

			<View style={{ margin: '24px 0' }}>
				<PaymentType
					handleClick={() => props.updatePaymentStep('1_FILL_DEBIT_DATA')}
					imageSrc={card}
					text="Debit Card"
				/>
			</View>
		</View>
	);
}
