import DateFormatter from 'components/formatters/DateFormatter/DateFormatter';
import React from 'react';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalAddApiKeysStep1.module.scss';

interface IModalAddApiKeysStep1Props {
	apiKey: string;
	apiSecret: string;
	created: Date;
	updated: Date;
}

export default function ModalAddApiKeysStep1(props: IModalAddApiKeysStep1Props) {
	return (
		<View className={style.ModalAddApiKeysStep1}>
			<Heading tag="h2" size="small" color="contentPrimary">
				Core API Key
			</Heading>
			<Paragraph margin="12px auto 24px" size="small" color="contentPrimary">
				Please store these credentials in a safe place.{' '}
				<span className={style.ModalAddApiKeysStep1__important}>
					For your protection, we will not show your Private Key again.
				</span>{' '}
				If you misplace your API key, you should delete this API key and generate a new one.
			</Paragraph>

			<View className={style.ModalAddApiKeysStep1__list}>
				<Label className={style.ModalAddApiKeysStep1__listTitle} size="medium" color="contentPrimary" fontWeight="600">
					Keys Generated
				</Label>

				<View className={style.ModalAddApiKeysStep1__listItem}>
					<Paragraph size="small" color="contentPrimary">
						Public Key
					</Paragraph>
					<Paragraph size="small" color="contentPrimary">
						{props.apiKey}
					</Paragraph>
				</View>

				<View className={style.ModalAddApiKeysStep1__listItem}>
					<Paragraph size="small" color="contentPrimary">
						Private Key
					</Paragraph>
					<Paragraph size="small" color="contentPrimary">
						{props.apiSecret}
					</Paragraph>
				</View>
			</View>

			<View className={style.ModalAddApiKeysStep1__footer}>
				<View>
					<Paragraph size="x-small" color="contentPrimary">
						Created at: <DateFormatter date={props.created} />
					</Paragraph>
					<Paragraph size="x-small" color="contentPrimary">
						Last updated at: <DateFormatter date={props.updated} />
					</Paragraph>
				</View>
				<View>
					<Pill level="primary" size="medium" onClick={Modal.hide}>
						Done
					</Pill>
				</View>
			</View>
		</View>
	);
}
