import Link from 'components/Link/Link';
import { CREDENTIALS_ROUTE, SDKS_ROUTE, WEBHOOKS_ROUTE } from 'constants/routes';
import React from 'react';
import Label from 'styleguide/Type/Label/Label';

interface IDocsLinkProps {
	pathname: string;
	className?: string;
}

export default function DocsLink(props: IDocsLinkProps) {
	switch (props.pathname) {
		case SDKS_ROUTE:
			return (
				<Link to="https://docs.aptopayments.com/docs/sdks/overview" className={props.className} external>
					<Label size="small" fontWeight="600">
						SDK Docs
					</Label>
				</Link>
			);
		case WEBHOOKS_ROUTE:
			return (
				<Link to="https://docs.aptopayments.com/docs/guides/webhooks" className={props.className} external>
					<Label size="small" fontWeight="600">
						Webhook Docs
					</Label>
				</Link>
			);
		case CREDENTIALS_ROUTE:
		default:
			return (
				<Link to="https://docs.aptopayments.com/api/mobile-api" className={props.className} external>
					<Label size="small" fontWeight="600">
						API Docs
					</Label>
				</Link>
			);
	}
}
