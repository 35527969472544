import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import style from './Paragraph.module.scss';

export interface IParagraphProps {
  /**
   * A CSS classname to add additional styles
   */
  className?: string;
  /**
   * Color of the text.
   *
   * @default contentSecondary
   */
  color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary' | 'disabled';

  /**
   * Add magin to the element.
   */
  margin?: React.CSSProperties['margin'];

  /**
   * Alignment of the text.
   *
   * @default left
   */
  textAlign?: 'left' | 'center' | 'right' | 'justified';
  /**
   * This will define the size of the font and the margin-bottom.
   */
  size: 'large' | 'medium' | 'small' | 'x-small';
}

export default function Paragraph(props: PropsWithChildren<IParagraphProps>) {
  const cnColor = _getColorClass(props.color);
  const cnTextAlign = style[`Paragraph--${props.textAlign || 'left'}`];
  const cnSize = style[`Paragraph--${props.size}`];

  return (
    <p
      style={{ margin: props.margin }}
      className={classnames(style.Paragraph, cnColor, cnTextAlign, cnSize, props.className)}
    >
      {props.children}
    </p>
  );
}

function _getColorClass(color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary' | 'disabled') {
  switch (color) {
    case 'contentSecondary':
      return style['Paragraph--secondary'];
    case 'contentTertiary':
      return style['Paragraph--tertiary'];
    case 'disabled':
      return style['Paragraph--disabled'];
    case 'contentPrimary':
    default:
      return style['Paragraph--primary'];
  }
}
