import ModalRequestExampleApp from 'components/ModalRequestExampleApp/ModalRequestExampleApp';
import React from 'react';
import Modal from 'styleguide/Modal/Modal';

export default function useModalAddUsers() {
	function showModalRequestExampleApp() {
		Modal.show(<ModalRequestExampleApp />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Download example app',
		});
	}

	return {
		showModalRequestExampleApp,
	};
}
