import Notification from 'components/Notification/Notification';
import React from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalAddApiKeysStep0.module.scss';

export interface IModalAddApiKeysStep0Props {
	description: string;
	error: string;
	isButtonDisabled: boolean;
	onCreateClick: () => void;
	setDescription: (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
			| React.ChangeEvent<HTMLSelectElement>
	) => void;
}

export default function ModalAddApiKeysStep0(props: IModalAddApiKeysStep0Props) {
	return (
		<View className={style.ModalAddApiKeysStep0}>
			<Heading tag="h2" size="small" color="contentPrimary">
				Create Core API Key
			</Heading>

			<Notification
				isHidden={!props.error}
				type="negative"
				margin="2rem 0 0"
				title="Error creating Core API key"
				description={props.error}
			/>

			<View style={{ margin: '24px 0' }}>
				<Input
					id="api-key-description"
					autofocus
					name="api-key-description"
					type="text"
					label="Name your API Key"
					onChange={props.setDescription}
					value={props.description}
				/>
			</View>

			<View>
				<Paragraph size="small" color="contentPrimary">
					Core API keys give you access to every API resource, including the ability to programmatically create and
					update cardholders via PUT and POST requests in sandbox. In production, your API keys for the Green Program
					will only allow GET requests.
				</Paragraph>
			</View>

			<View className={style.ModalAddApiKeysStep0__footer}>
				<Pill size="medium" level="primary" disabled={props.isButtonDisabled} onClick={props.onCreateClick}>
					Create
				</Pill>
			</View>
		</View>
	);
}
