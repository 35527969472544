import { IReadWebhooksResponse } from 'api-client/webhooks/webhooks';
import { useListWebhooksQuery } from 'queries/webhooks.queries';

export default function useWebhooks() {
	const webhooksQuery = useListWebhooksQuery();

	return {
		props: {
			webhooks: (webhooksQuery.data as IReadWebhooksResponse)?.eventRecipients,
			serverError: webhooksQuery.error?.message || '',
		},
		status: webhooksQuery.status,
	};
}
