import Notification from 'components/Notification/Notification';
import React, { ChangeEvent, FocusEvent } from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import style from './AddPaymentMethodDebitCard.module.scss';

export interface IAddPaymentMethodDebitCardProps {
	bind: (id: 'cvv' | 'exp' | 'name' | 'pan' | 'postalCode' | 'description') => {
		id: string;
		name: string;
		value: string;
		error?: string;
		onChange: (
			event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>
		) => void;
		onFocus: (
			event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>
		) => void;
		onBlur: (
			event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>
		) => void;
	};
	isButtonDisabled: boolean;
	serverError: string;
	submitForm: () => void;
}

export default function AddPaymentMethodDebitCard(props: IAddPaymentMethodDebitCardProps) {
	return (
		<View
			style={{
				marginTop: '30px',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '360px',
			}}
		>
			<Heading size="small" color="contentPrimary" tag="h1">
				{' '}
				Debit card{' '}
			</Heading>
			<form style={{ margin: '12px auto' }}>
				<Notification isHidden={!props.serverError} type="negative" title="Error" description={props.serverError} />
				<Input margin="12px 0 0" label="Name on card" {...props.bind('name')} type="text" autocomplete="cc-name" />
				<Input
					margin="12px 0 0"
					label="Debit card number"
					{...props.bind('pan')}
					type="text"
					autocomplete="cc-number"
				/>

				<View style={{ display: 'flex' }}>
					<Input margin="12px 8px 0 0" label="MM/YY" {...props.bind('exp')} type="exp-date" autocomplete="cc-exp" />
					<Input margin="12px 8px 0 0" label="CVV" {...props.bind('cvv')} type="text" autocomplete="cc-csc" />
					<Input
						margin="12px 0 0"
						label="ZIP code"
						{...props.bind('postalCode')}
						type="text"
						autocomplete="postal-code"
					/>
				</View>

				<Input
					margin="12px 0 76px"
					label="Card description (optional; for personal reference)"
					{...props.bind('description')}
					type="text"
				/>

				<Pill
					level="primary"
					size="medium"
					data-testid="btn-submit"
					disabled={props.isButtonDisabled}
					onClick={props.submitForm}
					type="submit"
					className={style.AddPaymentMethod__button}
				>
					Add Card
				</Pill>
			</form>
		</View>
	);
}
