import ContentHeader from 'components/ContentHeader/ContentHeader';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ProfilePage.module.scss';
import useProfilePage from './useProfilePage';

export default function ProfilePage() {
	const { fullName, email } = useProfilePage();

	return (
		<View>
			<ContentHeader title="My Profile" />
			<Grid cols="12" isNested>
				<Item colWidth="12" colWidthLg="8" maxWidth="672px">
					<PaddingContainer>
						<View className={style.ProfilePage__row}>
							<Paragraph size="small" color="contentPrimary">
								Name
							</Paragraph>
							<Paragraph size="small" color="contentPrimary">
								{fullName}
							</Paragraph>
						</View>
						<View className={style.ProfilePage__row}>
							<Paragraph size="small" color="contentPrimary">
								Email
							</Paragraph>
							<Paragraph size="small" color="contentPrimary">
								{email}
							</Paragraph>
						</View>
					</PaddingContainer>
				</Item>
			</Grid>
		</View>
	);
}
