import ContactUs from 'components/ContactUs/ContactUs';
import Header from 'components/LoginHeader/LoginHeader';
import React from 'react';
import CircleIcon from 'styleguide/CircleIcon/CircleIcon';
import Emoji from 'styleguide/Emoji/Emoji';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './Error.module.scss';
import useError from './useError';

interface IErrorPageProps {
	location: {
		state?: {
			error?: Error;
			httpStatus?: number; // TODO: Build enum type of status codes
		};
	};
}

export default function ErrorPage(props: IErrorPageProps) {
	const { statusDescription, errorDescription, hideContactUsForm, isContactUsOpen, showContactUsForm } = useError(
		props.location.state?.error,
		props.location.state?.httpStatus
	);

	if (isContactUsOpen) {
		return <ContactUs onClose={hideContactUsForm} />;
	}

	return (
		<>
			<Header />
			<View className={style.ErrorPage}>
				<View className={style.ErrorPage__circleIcon}>
					<CircleIcon>
						<Emoji symbol="😢" fontSize="52px" />
					</CircleIcon>
				</View>
				<Display size="x-small" tag="h1">
					Oops! This is unexpected...
				</Display>
				<Paragraph color="contentPrimary" size="medium" margin="10px 0 24px">
					{statusDescription}
				</Paragraph>
				<Paragraph color="contentSecondary" size="medium" margin="24px 0 0">
					{errorDescription}
				</Paragraph>
				<Paragraph color="contentSecondary" size="medium" margin="0">
					Please feel free to refresh this page or try again later.
				</Paragraph>
				<Paragraph color="contentSecondary" size="medium" margin="24px 0">
					If you need immediate help from our customer service team, please{' '}
					<Pill size="medium" level="inline-link" onClick={showContactUsForm}>
						contact us
					</Pill>
					.
				</Paragraph>
			</View>
		</>
	);
}
