import React, { PropsWithChildren } from 'react';
import Pill from 'styleguide/Pill/Pill';
import copyIcon from './copy-icon.svg';
import style from './CopyText.module.scss';
import useCopyText from './useCopyText';

interface ICopyTextProps {
	text: string;
}

export default function CopyText(props: PropsWithChildren<ICopyTextProps>) {
	const { handleClick } = useCopyText(props.text);

	return (
		<span className={style.CopyText}>
			{props.children}
			<Pill level="inline-link" size="small" onClick={handleClick} className={style.CopyText__logo}>
				<img src={copyIcon} alt="copy text" />
			</Pill>
		</span>
	);
}
