import Notification from 'components/Notification/Notification';
import React from 'react';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalDeactivateAccount.module.scss';
import useModalDeactivateAccount from './useModalDeactivateAccount';

export interface IModalDeactivateAccountProps {
	onCancelClick: () => void;
}

export default function ModalDeactivateAccount(props: IModalDeactivateAccountProps) {
	const { bind, isButtonDisabled, isLoading, serverError, submitForm } = useModalDeactivateAccount();

	return (
		<View className={style.ModalDeactivateAccount}>
			<Notification
				type="negative"
				isHidden={!serverError}
				title="Error"
				description={serverError}
				margin="0 0 24px 0"
			/>
			<form>
				<Heading size="small" color="contentPrimary" tag="h1" margin="0 0 12px 0">
					Deactivate account
				</Heading>
				<Paragraph size="small" color="contentPrimary">
					Please confirm your desire to close your account and a member of our team will contact you to begin the
					process.
				</Paragraph>
				<Input type="text" label="Please type “DEACTIVATE” to confirm" margin="24px 0" {...bind('deactivate')} />

				<View className={style.ModalDeactivateAccount__footer}>
					<Pill level="secondary" size="medium" onClick={props.onCancelClick}>
						Cancel
					</Pill>
					<Pill
						level="primary"
						size="medium"
						className={style.ModalDeactivateAccount__cta}
						disabled={isButtonDisabled}
						loading={isLoading}
						onClick={submitForm}
						data-testid="modal-deactivate-btn"
					>
						Deactivate account
					</Pill>
				</View>
			</form>
		</View>
	);
}
