import Company from 'components/Company/Company';
import React from 'react';
import View from 'styleguide/View/View';
import style from './CompanyToggle.module.scss';

interface ICompanyToggleProps {
	name: string;
	handleClick: () => void;
}

export default function CompanyToggle(props: ICompanyToggleProps) {
	return (
		<View className={style.CompanyToogle} onClick={props.handleClick} tabIndex={0}>
			<Company name={props.name} />
		</View>
	);
}
