import summaryCardParser from 'api-client/utils/summaryCard.parser';
import paginationService from 'services/pagination.service';
import IAptoCard from 'types/aptoCards/IAptoCard';
import ICardholder from 'types/cardholders/ICardholder';
import ISummaryCardholder from 'types/cardholders/ISummaryCardholder';
import IPaginationConfig from 'types/IPaginationConfig';
import IEnvironment from 'types/session/IEnvironment';
import ITransaction from 'types/transactions/ITransaction';
import transactionsParser from '../transactions/transactions.parser';
import apiFetch from '../utils/fetch';
import cardholderParser from './cardholders.parser';

const USERS_ENDPOINT = '/developer/users';

interface IReadArgs {
	paginationConfig?: IPaginationConfig;
	environment: IEnvironment;
}

export interface IListCardholdersResponse {
	cardholders: ICardholder[];
	hasMore: boolean;
	total: number;
}

async function read(args: IReadArgs): Promise<IListCardholdersResponse> {
	const params = paginationService.getPaginationParams(args.paginationConfig);
	params.append('environment', args.environment);

	return apiFetch(`${USERS_ENDPOINT}?${params.toString()}`, 'GET').then(cardholderParser.parseCardHolderList);
}

interface IGetSummaryArgs {
	id: string;
	environment: IEnvironment;
}

export interface ISummaryUserResponse {
	user: ISummaryCardholder;
	cards: IAptoCard[];
	transactions: ITransaction[];
}

async function getSummary(args: IGetSummaryArgs): Promise<ISummaryUserResponse> {
	return apiFetch(`${USERS_ENDPOINT}/${args.id}/summary?environment=${args.environment}`, 'GET').then((raw: any) => {
		const transactions = raw.transactions || [];
		return {
			cards: summaryCardParser.parseSummaryCards(raw),
			transactions: transactions.map(transactionsParser.parseTransaction),
			user: cardholderParser.parseCardholderSummary(raw),
		};
	});
}

interface IRemoveArgs {
	id: string;
	environment: IEnvironment;
}

async function remove(args: IRemoveArgs) {
	return apiFetch(`${USERS_ENDPOINT}/${args.id}?environment=${args.environment}`, 'DELETE');
}

export default {
	getSummary,
	read,
	remove,
};
