import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import style from './BalancesSection.module.scss';

export default function BalancesSection() {
	return (
		<PaddingContainer>
			<div className={style.BalancesSection}>Balances</div>
		</PaddingContainer>
	);
}
