import View from 'styleguide/View/View';
import ISignedPayloadsPublicKey from 'types/ISignedPayloadsPublicKey';
import SignedPayloadsKey from './components/SignedPayloadsKey/SignedPayloadsKey';

interface ISignedPayloadsListProps {
	publicKeys: ISignedPayloadsPublicKey[];
	onDeleteSignedPayloadsClick: (id: string) => void;
	isProduction: boolean;
}

export default function SignedPayloadsList(props: ISignedPayloadsListProps) {
	return (
		<View>
			{props.publicKeys.map((publicKey) => {
				return (
					<SignedPayloadsKey
						key={publicKey.id}
						publicKey={publicKey}
						onDeleteClick={props.onDeleteSignedPayloadsClick}
						isDeleteDisabled={props.publicKeys.length < 2}
						isProduction={props.isProduction}
					/>
				);
			})}
		</View>
	);
}
