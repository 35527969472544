import ICardholder from 'types/cardholders/ICardholder';
import ISummaryCardholder from 'types/cardholders/ISummaryCardholder';
import { IListCardholdersResponse } from './cardholders';

function parseCardHolderList(raw: any): IListCardholdersResponse {
	return {
		...raw,
		cardholders: raw.cardholders.map(parseCardholder),
		hasMore: raw.has_more,
	};
}

function parseCardholder({ cardholder }: { cardholder: any }): ICardholder {
	return {
		createdAt: new Date(cardholder.created_at),
		custodianUid: cardholder.custodian_uid,
		email: cardholder.email,
		firstName: cardholder.name.first_name,
		id: cardholder.id,
		kycPassedAt: new Date(cardholder.kyc.passed_at),
		kycStatus: cardholder.kyc.status,
		lastName: cardholder.name.last_name,
		isCardholder: cardholder.is_cardholder,
	};
}

function parseCardholderSummary({ cardholder }: { cardholder: any }): ISummaryCardholder {
	return {
		addressCountry: cardholder.address.country,
		addressLocality: cardholder.address.locality,
		addressPostalCode: cardholder.address.postal_code,
		addressRegion: cardholder.address.region,
		addressStreetOne: cardholder.address.street_one,
		addressStreetTwo: cardholder.address.street_two,
		countryCode: cardholder.phone.country_code,
		createdAt: new Date(cardholder.created_at),
		custodianUid: cardholder.custodian_uid,
		dateOfBirth: new Date(cardholder.date_of_birth),
		email: cardholder.email,
		firstName: cardholder.name.first_name,
		id: cardholder.id,
		kycPassedAt: new Date(cardholder.kyc.passed_at),
		kycStatus: cardholder.kyc.status,
		lastName: cardholder.name.last_name,
		phoneNumber: cardholder.phone.phone_number,
		isCardholder: cardholder.is_cardholder,
	};
}

export default {
	parseCardholder,
	parseCardholderSummary,
	parseCardHolderList,
};
