import { RefObject, useEffect } from 'react';

export default function useOutsideClickListener(ref: RefObject<HTMLElement>, handleOutsideClick: () => void) {
	useEffect(() => {
		function onOutsideClick(event: MouseEvent) {
			if (ref && ref.current && !ref.current.contains(event.target as Node)) {
				handleOutsideClick();
			}
		}

		document.addEventListener('mousedown', onOutsideClick);
		return () => document.removeEventListener('mousedown', onOutsideClick);
	}, [ref, handleOutsideClick]);
}
