import Notification from 'components/Notification/Notification';
import React from 'react';
import QRCode from 'react-qr-code';
import Button from 'styleguide/Button/Button';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Spinner from 'styleguide/Spinner/Spinner';
import Text from 'styleguide/Text/Text';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './Sync2FA.module.scss';
import useSync2FA from './useSync2FA';

export default function Sync2FAPage() {
	const {
		bind,
		email,
		secret,
		serverError,
		showUserData,
		isSecondInputDisabled,
		isDataVisible,
		requestStatus,
		hideUserData,
	} = useSync2FA();

	if (requestStatus === 'PENDING') {
		return (
			<div className={style.Spinner}>
				<Spinner height="128" width="128" />
			</div>
		);
	}

	return (
		<Grid cols="16">
			<Item colWidth="16" className={style.Sync2FA}>
				<Text type="h4" tag="h1">
					Set up 2-step verification
				</Text>
				<Paragraph size="medium" margin="12px 0 40px" color="contentSecondary">
					To add an extra layer of security to your account, we require you to set up 2-step verification (also known as
					two-factor authentication).
				</Paragraph>

				<Grid cols="8" isNested className={style.Sync2FA__grid}>
					<Item colWidthLg="3" colWidth="8">
						{isDataVisible ? (
							<View
								style={{
									width: '224px',
									height: '224px',
									margin: '4px auto 40px',
									padding: '20px',
									backgroundColor: '#F6F6F6',
									wordBreak: 'break-all',
								}}
								data-testid="sync2FA-data"
							>
								<Text weight={500}>Account:</Text>
								<Text>{email}</Text>
								<Text margin="20px auto 0" weight={500}>
									Key:
								</Text>
								<Text>{secret}</Text>
							</View>
						) : (
							<View
								style={{
									width: '100%',
									height: '200px',
									margin: '4px auto 40px',
									display: 'flex',
									justifyContent: 'flex-start',
								}}
								data-testid="sync2FA-qr"
							>
								<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" width="200">
									<QRCode
										size={200}
										value={`otpauth://totp/AptoPayments:${email}?secret=${secret}&issuer=AptoPayments`}
									/>
								</svg>
							</View>
						)}
					</Item>
					<Item colWidthLg="5" colWidth="8">
						<View className={style.Sync2FA__block}>
							<Label size="medium" fontWeight="600">
								Scan this QR code into your authenticator app
							</Label>
							<Paragraph size="medium" color="contentSecondary" margin="8px 0">
								Install any authenticator app on your mobile device. If you don’t already have one installed, we
								recommend downloading{' '}
								<Pill type="link" level="inline-link" size="medium" external to="https://authy.com/download/">
									Authy
								</Pill>{' '}
								on your mobile or desktop device. Do not delete this app as you will need to use 2-step verification
								every time you log in.
							</Paragraph>
							{isDataVisible ? (
								<Paragraph size="medium" margin="8px 0 0">
									<Button level="link" onClick={hideUserData}>
										<span className={style.Sync2FA__accent}>Click here to scan a QR code</span>
									</Button>
								</Paragraph>
							) : (
								<Paragraph size="medium" margin="8px 0 0">
									Can’t scan it?{' '}
									<Button level="link" onClick={showUserData}>
										<span className={style.Sync2FA__accent}>Enter key manually</span>
									</Button>
								</Paragraph>
							)}
						</View>
						<View className={style.Sync2FA__block}>
							<Label size="medium" fontWeight="600">
								Enter the 6-digit code generated by the app
							</Label>
							<Input
								autofocus
								margin="12px 0 0"
								type="text"
								label="Enter your first verification code"
								{...bind('firstCode')}
							></Input>
						</View>
						<View className={style.Sync2FA__block}>
							<Label size="medium" fontWeight="600">
								Wait for a few seconds and enter the next code you see generated by the app
							</Label>
							<Input
								disabled={isSecondInputDisabled}
								margin="12px 0 0"
								type="text"
								label="Enter your second verification code"
								{...bind('secondCode')}
							></Input>
						</View>
						<Notification isHidden={!serverError} type="negative" title="" description={serverError} />
					</Item>
				</Grid>
			</Item>
		</Grid>
	);
}
