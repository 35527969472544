import AmountInput from 'components/AmountInput/AmountInput';
import AptoCardSelect from 'components/AptoCardSelect/AptoCardSelect';
import { useNavigate } from 'react-router';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import { IHookReturnedProps } from '../../LoadStep';
import style from './LoadStep.module.scss';
import useLoadStepSuccess from './useLoadStepSuccess';

export default function LoadStepSuccess(props: IHookReturnedProps) {
	const navigate = useNavigate();
	const {
		bind,
		handleAptoCardChange,
		isButtonDisabled,
		selectedCard,
		filteredCards,
		submitForm,
		fundingAccountBalance,
	} = useLoadStepSuccess(props);

	return (
		<main className={style.LoadStep} data-testid="push-funds-step">
			<form onSubmit={submitForm} style={{ padding: '28px 24px 40px' }}>
				<Label size="medium" fontWeight="600" margin="14px 0" className={style.LoadStep__text}>
					Amount
				</Label>
				<AmountInput {...bind('amount')} />
				<Label size="medium" fontWeight="600" margin="14px 0" className={style.LoadStep__text}>
					From
				</Label>
				<div>
					<Label size="small" fontWeight="600" margin="14px 0">
						Developer funding account
					</Label>
					{fundingAccountBalance ? (
						<Label size="x-small" color="contentSecondary" fontWeight="400" margin="14px 0 0 18px">
							Current balance:{' '}
							{new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: fundingAccountBalance.currency,
							}).format(fundingAccountBalance.amount)}
						</Label>
					) : null}
				</div>
				<Label size="medium" fontWeight="600" margin="14px 0" className={style.LoadStep__text}>
					To
				</Label>
				<AptoCardSelect
					aptoCards={filteredCards}
					selectedAptoCard={selectedCard}
					onAptoCardClick={handleAptoCardChange}
					isDisabled={props.isAptoCardSelectDisabled}
				/>
				<Pill
					level="primary"
					size="medium"
					data-testid="btn-next"
					type="submit"
					disabled={isButtonDisabled}
					className={style.LoadStep__cta}
				>
					Next
				</Pill>
				<View className={style.LoadStep__row}>
					<Pill level="inline-link" type="button" size="medium" onClick={() => navigate(-1)}>
						Cancel
					</Pill>
				</View>
			</form>
		</main>
	);
}
