function getPaginationParams(searchParams: URLSearchParams) {
	const params = new URLSearchParams(searchParams);
	return {
		startingAfter: parseInt(params.get('starting_after') as string),
		endingBefore: parseInt(params.get('ending_before') as string),
	};
}

function createItems(createItem: (id: number) => void, from?: number, to?: number) {
	from = from || 1;
	to = to || from + 10;
	const items = [];
	while (from <= to) {
		items.push(createItem(from));
		from++;
	}

	return items;
}

function randomizeStatus<S>(statusArray: S[]): S {
	return statusArray[Math.floor(Math.random() * statusArray.length)];
}

export default {
	getPaginationParams,
	createItems,
	randomizeStatus,
};
