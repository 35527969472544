import LastFour from 'components/cards/LastFour/LastFour';
import Link from 'components/Link/Link';
import Notification from 'components/Notification/Notification';
import { ICurrency } from 'constants/currencies';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IEnvironment from 'types/session/IEnvironment';
import style from './ReviewStep.module.scss';

export interface IReviewStepProps {
	amount: string;
	cardholderId: string;
	cardId: string;
	currency: ICurrency;
	designKey: IAptoCardDesignKey;
	editUrl: string;
	environment: IEnvironment;
	lastFour: string;
	loading?: boolean;
	network: IAptoCardNetwork;
	onConfirmClick: () => void;
	serverError: string;
}

export default function ReviewStep(props: IReviewStepProps) {
	return (
		<main>
			<View className={style.ReviewStep} data-testid="review-step">
				<Notification isHidden={!props.serverError} type="negative" title="Error" description={props.serverError} />
				<Label size="medium" fontWeight="600" className={style.ReviewStep__text}>
					Review
				</Label>
				<View className={style.DetailsBox}>
					<Paragraph size="small" color="contentPrimary">
						Amount
					</Paragraph>
					<Paragraph size="small" color="contentPrimary">{`$${props.amount}`}</Paragraph>
				</View>
				<View className={style.DetailsBox}>
					<Paragraph size="small" color="contentPrimary">
						To
					</Paragraph>
					<View className={style.DetailsBox__card}>
						<LastFour
							designKey={props.designKey}
							iconSize="small"
							network={props.network}
							typeOfCard="apto"
							value={props.lastFour}
						/>
					</View>
				</View>
				<Pill
					level="primary"
					size="medium"
					type="submit"
					loading={props.loading}
					onClick={props.onConfirmClick}
					className={style.ReviewStep__cta}
				>
					Confirm
				</Pill>
				<View className={style.ReviewStep__exit}>
					<Link to={props.editUrl}>
						<Label size="medium" fontWeight="600">
							Edit
						</Label>
					</Link>
				</View>
			</View>
		</main>
	);
}
