import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/min';

function isNumberValid(countryCode: CountryCode, number: string) {
	const phoneNumber = parsePhoneNumberFromString(number, countryCode);
	return phoneNumber?.isValid();
}

function removeDialCode(countryCode: CountryCode, validPhoneNumber: string) {
	if (!validPhoneNumber) {
		return '';
	}

	const phoneNumber = parsePhoneNumberFromString(validPhoneNumber, countryCode);
	return phoneNumber?.nationalNumber;
}

export default {
	removeDialCode,
	isNumberValid,
};
