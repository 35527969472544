import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import style from './Label.module.scss';

export interface ILabelProps {
  /**
   * A CSS classname to add additional styles.
   */
  className?: string;

  /**
   * Color of the text.
   *
   * @default contentPrimary
   */
  color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary' | 'disabled' | 'accent' | 'positive' | 'negative';

  /**
   * Font-weight of the font.
   *
   * @default 400
   */
  fontWeight?: '400' | '500' | '600' | '700';

  /**
   * Add magin to the element.
   */
  margin?: React.CSSProperties['margin'];

  /**
   * This will define the size of the font and the margin-bottom.
   */
  size: 'large' | 'medium' | 'small' | 'x-small';
}

export default function Label(props: PropsWithChildren<ILabelProps>) {
  const cnColor = _getColorClass(props.color);
  const cnFontWeight = style[`Label--${props.fontWeight || '400'}`];
  const cnSize = style[`Label--${props.size}`];

  return (
    <span
      style={{ margin: props.margin }}
      className={classnames(style.Label, cnColor, cnFontWeight, cnSize, props.className)}
    >
      {props.children}
    </span>
  );
}

function _getColorClass(
  color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary' | 'disabled' | 'accent' | 'positive' | 'negative'
) {
  switch (color) {
    case 'contentSecondary':
      return style['Label--secondary'];
    case 'contentTertiary':
      return style['Label--tertiary'];
    case 'disabled':
      return style['Label--disabled'];
    case 'accent':
      return style['Label--accent'];
    case 'positive':
      return style['Label--positive'];
    case 'negative':
      return style['Label--negative'];
    case 'contentPrimary':
    default:
      return style['Label--primary'];
  }
}
