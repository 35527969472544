import React from 'react';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import style from './EmptyList.module.scss';

interface IProps {
	message: string;
}

export default function EmptyList({ message }: IProps) {
	return (
		<View className={style.EmptyList}>
			<Text type="small" margin="40px 0">
				{message}
			</Text>
		</View>
	);
}
