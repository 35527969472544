import WithQuery from 'utils/WithQuery';
import PendingInvitesError from './components/PendingInvitesError';
import PendingInvitesSuccess from './components/PendingInvitesSuccess';
import usePendingInvitesList from './usePendingInvitesList';

export default WithQuery({
	Component: PendingInvitesSuccess,
	Error: PendingInvitesError,
	useHook: usePendingInvitesList,
});
