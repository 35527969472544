import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ISignedPayloadsPublicKey from 'types/ISignedPayloadsPublicKey';
import EmptyKeyState from './EmptyKeyState/EmptyKeyState';
import SignedPayloadsList from './SignedPayloadsList/SignedPayloadsList';
import style from './SignedPayloadsSection.module.scss';

export interface ISignedPayloadsSectionSuccessProps {
	publicKeys: ISignedPayloadsPublicKey[];
	onAddSignedPayloadsClick: () => void;
	onDeleteSignedPayloadsClick: (id: string) => void;
	isProduction: boolean;
}

export default function SignedPayloadsSectionSuccess(props: ISignedPayloadsSectionSuccessProps) {
	return (
		<section data-testid="signed-payloads">
			<PaddingContainer>
				<header>
					<View className={style.SignedPayloadsSection__heading}>
						<Label size="large" fontWeight="500" margin="0 20px 0 0">
							Signed Payloads
						</Label>
						{props.publicKeys.length < 2 ? (
							<Pill
								data-testid="add-signed-payloads-btn"
								level="primary"
								size="small"
								onClick={props.onAddSignedPayloadsClick}
							>
								Add
							</Pill>
						) : null}
					</View>

					<Paragraph size="small" color="contentPrimary" className={style.SignedPayloadsSection__description}>
						Signed Payloads allows you to sign API requests sent to Apto with a cryptographic key for an extra layer of
						security. You can add up to two public keys.{' '}
						<Pill
							level="inline-link"
							size="small"
							to="https://docs.aptopayments.com/docs/overview/getting%20started/signed_payloads/"
							external
						>
							Learn more about Signed Payloads.
						</Pill>
					</Paragraph>
				</header>
			</PaddingContainer>
			<View className={style.SignedPayloadsSection__body}>
				{props.publicKeys.length > 0 ? (
					<SignedPayloadsList
						publicKeys={props.publicKeys}
						onDeleteSignedPayloadsClick={props.onDeleteSignedPayloadsClick}
						isProduction={props.isProduction}
					/>
				) : (
					<EmptyKeyState />
				)}
			</View>
		</section>
	);
}
