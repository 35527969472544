import React from 'react';
import View from 'styleguide/View/View';
import style from './ColorSelector.module.scss';

export default function ColorSelector(props: any) {
	const labelClassName = props.isActive ? style['ColorSelector--active'] : style.ColorSelector;
	const swatchClassName = style[`ColorSelector__swatch--${props.color}`];

	return (
		<label tabIndex={0} htmlFor={props.name} className={labelClassName}>
			<input
				className={style.ColorSelector__input}
				onChange={() => props.onClick(props.color)}
				type="radio"
				id={props.name}
				name="cardColor"
				value={props.color}
				checked={props.isActive}
			/>
			<View className={swatchClassName}></View>
			<span className={style.ColorSelector__text}> {props.name} </span>
		</label>
	);
}
