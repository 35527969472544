import Address from 'components/Address/Address';
import COUNTRIES from 'constants/countries';
import Input from 'styleguide/Input/Input';
import InputPhoneNumber from 'styleguide/InputPhoneNumber/InputPhoneNumber';
import Pill from 'styleguide/Pill/Pill';
import SlideCard from 'styleguide/SlideCard/SlideCard';
import Text from 'styleguide/Text/Text';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import IOwner from 'types/activateAccount/IOwner';
import style from './AddOwnerCard.module.scss';
import useAddOwner from './useAddOwnerCard';

export interface IAddOwnerCardProps {
	isHidden: boolean;
	onAddOwner: (owner: IOwner) => void;
	onCloseClicked: () => void;
	onDeleteOwner: (owner: IOwner) => void;
	onUpdateOwner: (owner: IOwner) => void;
	owner?: IOwner;
	type: 'business_owner' | 'beneficial_owner';
}

export default function AddOwnerCard(props: IAddOwnerCardProps) {
	const {
		addressIds,
		bind,
		cardTitle,
		closeModal,
		deleteOwner,
		handleCitizenshipChange,
		handleFormOfIDChange,
		handlePercentageChange,
		isButtonDisabled,
		isFormOfIDVisible,
		isOwnershipPercentage,
		isPassportInputVisible,
		isPassportIssuedCountryDisabled,
		isShowPassportDateFieldsEnabled,
		isSSNInputVisible,
		handleAddOwner,
		handleUpdateOwner,
	} = useAddOwner({
		onCloseClicked: props.onCloseClicked,
		onDeleteOwner: props.onDeleteOwner,
		onAddOwner: props.onAddOwner,
		onUpdateOwner: props.onUpdateOwner,
		owner: props.owner,
		type: props.type,
	});

	return (
		<View className={style.AddOwnerCard}>
			<SlideCard
				isHidden={props.isHidden}
				onCloseClicked={closeModal}
				header={
					props.owner ? (
						<View className={style.AddOwnerCard__header}>
							<Pill type="button" level="secondary" size="small" onClick={deleteOwner}>
								<Label size="x-small" color="negative" fontWeight="600">
									Delete
								</Label>
							</Pill>
						</View>
					) : null
				}
				footer={
					props.owner ? (
						<Pill type="button" level="primary" size="medium" onClick={handleUpdateOwner} disabled={isButtonDisabled}>
							Save
						</Pill>
					) : (
						<Pill type="button" level="primary" size="medium" onClick={handleAddOwner} disabled={isButtonDisabled}>
							Add
						</Pill>
					)
				}
			>
				<View className={style.AddOwnerCard__content}>
					<Text type="h5" margin="12px 0 32px">
						{cardTitle}
					</Text>

					<form>
						<fieldset className={style.Form__fieldset}>
							<legend> Legal name </legend>
							<Input margin="12px 0" label="First name" type="text" {...bind('firstName')} />
							<Input margin="12px 0" label="Last name" type="text" {...bind('lastName')} />
						</fieldset>

						<fieldset className={style['Form__fieldset--about']}>
							<legend>About</legend>
							<Input margin="12px 0" label="Business title" type="text" {...bind('businessTitle')} />
							{isOwnershipPercentage ? (
								<Input
									type="percentage"
									margin="12px 0"
									label="Ownership percentage"
									{...bind('ownershipPercentage' as any)}
									onChange={handlePercentageChange}
								/>
							) : null}
							<Input
								margin="12px 0"
								label="Citizenship status"
								type="select"
								selectOptions={{
									us_citizen: 'US citizen',
									non_resident: 'Non-US citizen',
								}}
								{...bind('citizenship')}
								onChange={handleCitizenshipChange as any}
							/>

							{isFormOfIDVisible ? (
								<Input
									margin="12px 0"
									label="Form of ID"
									type="select"
									selectOptions={{
										ssn: 'Social security number / ITIN',
										passport: 'Passport',
									}}
									{...bind('formOfID')}
									onChange={handleFormOfIDChange as any}
								/>
							) : null}

							{isSSNInputVisible ? (
								<Input margin="12px 0" label="Social security number / ITIN" type="text" {...bind('ssn' as any)} />
							) : null}

							{isPassportInputVisible ? (
								<>
									<Input margin="12px 0" label="Passport number" type="text" {...bind('passport' as any)} />
									{isShowPassportDateFieldsEnabled ? (
										<View className={style.Passport__row}>
											<Input label="Issue date" type="date" {...bind('passportIssueDate' as any)} />
											<Input label="Expiration date" type="date" {...bind('passportExpiration' as any)} />
										</View>
									) : null}
									<Input
										margin="12px 0"
										label="Issued country"
										selectOptions={COUNTRIES}
										type="select"
										disabled={isPassportIssuedCountryDisabled}
										{...bind('passportIssuedCountry' as any)}
									/>
								</>
							) : null}

							<InputPhoneNumber bind={bind} selectOptions={['US']} />
							<Input margin="12px 0" label="Email" type="text" {...bind('email' as any)} />
						</fieldset>

						<fieldset className={style.Form__fieldset}>
							<legend> Date of birth </legend>
							<View style={{ margin: '12px 0' }}>
								<Input type="date" label="Date of birth" {...bind('birthDate')} />
							</View>
						</fieldset>

						<fieldset className={style.Form__fieldset}>
							<legend style={{ marginBottom: '8px' }}>Home address</legend>
							<Address bind={bind} ids={addressIds} />
						</fieldset>
					</form>
				</View>
			</SlideCard>
		</View>
	);
}
