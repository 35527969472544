import Link from 'components/Link/Link';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import ItemGrid from 'styleguide/Grid/Item/Item';
import Item from 'styleguide/Item/Item';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import styles from './Sdks.module.scss';

export default function Sdks() {
	return (
		<View>
			<Grid cols="12" isNested>
				<ItemGrid colWidth="12" colWidthLg="6">
					<Heading size="x-small" tag="h3" margin="24px 0 12px">
						Mobile SDKs
					</Heading>
					<Paragraph size="small" margin="0 0 20px">
						Our mobile SDKs help you quickly integrate our card management functionality into your mobile applications.
						The SDKs are wrappers around our API, and are designed so final user applications interact directly with our
						Platform. They also ensure actions are initiated by the authorized cardholder. The mobile SDKs can be used
						to implement a fully customized cardholder UI/UX.
					</Paragraph>
					<Item padding="15px 0">
						<Link
							style={{ fontSize: '14px', lineHeight: '16px', fontWeight: 600 }}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/Android/mobile_sdk_android"
						>
							Android SDK (Kotlin + Java)
						</Link>
					</Item>
					<Item padding="15px 0" hasBorderBottom={true}>
						<Link
							style={{ fontSize: '14px', lineHeight: '16px', fontWeight: 600 }}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/iOS/mobile_sdk_ios"
						>
							iOS SDK (Swift + Objective-C)
						</Link>
					</Item>
				</ItemGrid>

				<ItemGrid colWidth="12" colWidthLg="6">
					<Heading size="x-small" tag="h3" margin="24px 0 12px">
						UI SDKs
					</Heading>
					<Paragraph size="small" margin="0 0 20px">
						For speed-to-market, we also offer a compiled version of the SDKs, which means you can take this white label
						mobile SDK and drop it into your existing application or you can distribute it as standalone mobile
						applications. The Android and iOS SDKs implement a standard cardholder experience but are configurable to
						match your branding look and feel.
					</Paragraph>
					<Item padding="15px 0">
						<Link
							style={{ fontSize: '14px', lineHeight: '16px', fontWeight: 600 }}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/Android/ui_sdk_android"
						>
							Android UI SDK (Kotlin + Java)
						</Link>
					</Item>
					<Item padding="15px 0" hasBorderBottom={true}>
						<Link
							style={{ fontSize: '14px', lineHeight: '16px', fontWeight: 600 }}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/iOS/ui_sdk_ios"
						>
							iOS UI SDK (Swift + Objective-C)
						</Link>
					</Item>
				</ItemGrid>
				<ItemGrid colWidth="12" colWidthLg="6">
					<Heading size="x-small" tag="h3" margin="24px 0 12px">
						PCI SDKs
					</Heading>
					<Paragraph size="small" margin="0 0 20px">
						The PCI SDK provides a compliant solution to share PCI-protected data without taking on the burden of PCI
						certification. The PCI SDK protects sensitive card data and displays it to the cardholder without the data
						ever passing through your servers.
					</Paragraph>
					<Item padding="15px 0">
						<Link
							className={styles.SdksLink}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/Android/pci_sdk_android"
						>
							Android PCI SDK (Kotlin + Java)
						</Link>
					</Item>
					<Item padding="15px 0">
						<Link
							className={styles.SdksLink}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/iOS/pci_sdk_ios"
						>
							iOS PCI SDK (Swift + Objective-C)
						</Link>
					</Item>
					<Item padding="15px 0" hasBorderBottom={true}>
						<Link
							className={styles.SdksLink}
							color="primary"
							external
							to="https://docs.aptopayments.com/docs/sdks/Web/pci_sdk_web"
						>
							Web PCI SDK
						</Link>
					</Item>
				</ItemGrid>
			</Grid>
		</View>
	);
}
