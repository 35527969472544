import WithQuery from 'utils/WithQuery';
import OverviewFundingAccountError from './components/OverviewFundingAccountError/OverviewFundingAccountError';
import OverviewFundingAccountSuccess from './components/OverviewFundingAccountSuccess/OverviewFundingAccountSuccess';
import useOverviewFundingAccount from './useOverviewFundingAccount';

export default WithQuery({
	useHook: useOverviewFundingAccount,
	Component: OverviewFundingAccountSuccess,
	Error: OverviewFundingAccountError,
});
