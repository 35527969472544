import LastFour from 'components/cards/LastFour/LastFour';
import Link from 'components/Link/Link';
import Notification from 'components/Notification/Notification';
import { LOAD_FUNDS_LOAD_ROUTE } from 'constants/routes';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IPaymentMethod from 'types/IPaymentMethod';
import style from './ReviewStep.module.scss';

export interface IReviewStepProps {
	amount: string;
	buttonIsDisabled: boolean;
	onSubmit: () => void;
	paymentMethod?: IPaymentMethod;
	serverError: string;
}

export default function ReviewStep(props: IReviewStepProps) {
	if (!props.paymentMethod || !props.amount) {
		return <Navigate to={LOAD_FUNDS_LOAD_ROUTE} />;
	}

	return (
		<View className={style.ReviewStep} data-testid="review-step">
			<Notification isHidden={!props.serverError} type="negative" title="Error" description={props.serverError} />
			<Label size="medium" fontWeight="600" className={style.ReviewStep__text}>
				Review
			</Label>
			<View className={style.DetailsBox}>
				<Paragraph size="small" color="contentPrimary">
					Amount
				</Paragraph>
				<Paragraph size="small" color="contentPrimary">{`$${props.amount}`}</Paragraph>
			</View>
			<View className={style.DetailsBox}>
				<Paragraph size="small" color="contentPrimary">
					From
				</Paragraph>
				<View className={style.DetailsBox__card}>
					<LastFour description={props.paymentMethod.description} value={props.paymentMethod.lastFour} />
				</View>
			</View>
			<Pill
				level="primary"
				size="medium"
				disabled={props.buttonIsDisabled}
				onClick={props.onSubmit}
				className={style.ReviewStep__cta}
			>
				Confirm
			</Pill>
			<View className={style.ReviewStep__exit}>
				<Link to={LOAD_FUNDS_LOAD_ROUTE}>
					<Label size="medium" fontWeight="600">
						Edit
					</Label>
				</Link>
			</View>
		</View>
	);
}
