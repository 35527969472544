import useSession from 'hooks/useSession';
import React, { useCallback, useEffect } from 'react';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalIdle.module.scss';

const ONE_MINUTE = 60 * 1000;

let timer: NodeJS.Timeout | null = null;

export default function ModalIdle() {
	const { logOut } = useSession();

	const _logOut = useCallback(() => {
		logOut();
		Modal.hide();
	}, [logOut]);

	useEffect(
		function logOutAfterOneMinuteOnceTheModalIsVisible() {
			if (timer) return;
			timer = setTimeout(_logOut, ONE_MINUTE);

			return function cancelLogoutTimerOnCloseModal() {
				if (!timer) return;
				clearTimeout(timer);
				timer = null;
			};
		},
		[_logOut]
	);

	function _stayLogged() {
		if (timer) {
			clearTimeout(timer);
		}
		Modal.hide();
	}

	return (
		<View className={style.ModalIdle}>
			<Heading margin="20px 0 0 0" tag="h2" size="small">
				Are you still there?
			</Heading>

			<Paragraph margin="12px 0 24px 0" size="medium" color="contentPrimary">
				For your security, your session will expire after 10 minutes of inactivity.
			</Paragraph>

			<View className={style.Warning}>
				<Label size="large" fontWeight="600" margin="0 0 8px">
					Your session will expire in 60 seconds.
				</Label>
				<Paragraph size="medium">Click “Stay logged in” or close the pop-up to continue.</Paragraph>
			</View>

			<View className={style.ModalIdle__footer}>
				<Pill level="secondary" size="medium" onClick={_logOut}>
					Log out now
				</Pill>
				<Pill level="primary" size="medium" onClick={_stayLogged}>
					Stay logged in
				</Pill>
			</View>
		</View>
	);
}
