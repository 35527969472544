import NotificationPopup from 'components/NotificationPopup/NotificationPopup';
import Modal from 'styleguide/Modal/Modal';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './AchDetails.module.scss';

export interface IAchDetailsProps {
	routingNumber: string;
	accountNumber: string;
}

export default function AchDetails(props: IAchDetailsProps) {
	return (
		<NotificationPopup title="Account information" handleClose={Modal.hide}>
			<View className={style.AchDetails}>
				<Paragraph size="small" color="contentPrimary" margin="0 0 20px">
					This routing number can only be used to set up direct deposits online and ACH transactions.
				</Paragraph>
				<View className={style.AchDetails__item}>
					<Paragraph size="small" color="contentPrimary">
						Bank Name
					</Paragraph>
					<Paragraph size="medium" color="contentPrimary">
						Patriot Bank, N.A.
					</Paragraph>
				</View>
				<View className={style.AchDetails__item}>
					<Paragraph size="small" color="contentPrimary">
						Account Type
					</Paragraph>
					<Paragraph size="medium" color="contentPrimary">
						Checking
					</Paragraph>
				</View>
				<View className={style.AchDetails__item}>
					<Paragraph size="small" color="contentPrimary">
						ACH Account Number
					</Paragraph>
					<Paragraph size="medium" color="contentPrimary">
						{props.accountNumber}
					</Paragraph>
				</View>
				<View className={style.AchDetails__item}>
					<Paragraph size="small" color="contentPrimary">
						Routing Number
					</Paragraph>
					<Paragraph size="medium" color="contentPrimary">
						{props.routingNumber}
					</Paragraph>
				</View>
				<Paragraph size="x-small" color="contentTertiary" margin="16px 0 0">
					Your account is offered by Patriot Bank, N.A.
				</Paragraph>
			</View>
		</NotificationPopup>
	);
}
