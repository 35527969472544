import { DASHBOARD_ROUTE } from 'constants/routes';
import { useNavigate } from 'react-router';
import Modal from 'styleguide/Modal/Modal';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalBeforeGetStarted.module.scss';

export default function ModalBeforeGetStarted() {
	const navigate = useNavigate();

	return (
		<View className={style.ModalBeforeGetStarted}>
			<Heading tag="h1" size="small">
				Before you get started
			</Heading>

			<Paragraph size="medium" color="contentPrimary" margin="12px 0 24px 0">
				Please note this application requires a significant amount of information, and once you get started you cannot
				go back to a previous page; you may only “Restart” or “Resume” the application. Before you proceed, we recommend
				you have the following information at hand:
			</Paragraph>

			<View className={style.Warning}>
				<Label className={style.Warning__heading} size="large" fontWeight="600">
					You will be asked for:
				</Label>
				<ul className={style.Warning__list}>
					<li> Company legal info (e.g., EIN, contact, address)</li>
					<li> Personal info of business owners (e.g., CEO or COO)</li>
					<li> Card design (choice of physical/digital, color, logo)</li>
					<li> Signed Payloads Key (unique to production)</li>
				</ul>
			</View>
			<View className={style.ModalBeforeGetStarted__footer}>
				<Pill
					level="secondary"
					size="medium"
					onClick={() => {
						Modal.hide();
						navigate(DASHBOARD_ROUTE);
					}}
				>
					Cancel
				</Pill>
				<Pill level="primary" size="medium" onClick={Modal.hide}>
					Get started
				</Pill>
			</View>
		</View>
	);
}
