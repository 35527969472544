import apiClientService from 'api-client/apiclient.service';
import { ICardsReadResponse } from 'api-client/cards/cards';
import { queryForItemsInThePreviousPage } from 'hooks/usePagination';
import useSession from 'hooks/useSession';
import { useQuery, UseQueryOptions } from 'react-query';
import IAptoCardSummaryResponse from 'types/aptoCards/IAptoCardSummaryResponse';
import IPaginationConfig from 'types/IPaginationConfig';
import IError from 'types/network/IError';

const QK_CARDS = 'cards';

interface IUseGetCardQueryArgs extends UseQueryOptions<IAptoCardSummaryResponse, IError> {
	id: string;
}

export function useGetCardQuery({ id, ...queryOptions }: IUseGetCardQueryArgs) {
	const { environment } = useSession();

	return useQuery<IAptoCardSummaryResponse, IError>({
		queryKey: [environment, QK_CARDS, 'details', id],
		queryFn: () =>
			apiClientService.cards.getSummary({
				id,
				environment,
			}),
		...queryOptions,
	});
}

interface IUseListCardsQueryArgs extends UseQueryOptions<IUseListCardsQueryResponse, IError> {
	paginationConfig: IPaginationConfig;
}

interface IUseListCardsQueryResponse extends ICardsReadResponse {
	hasMoreBefore: boolean;
}

export function useListCardsQuery(args?: IUseListCardsQueryArgs) {
	const { environment } = useSession();
	return useQuery<IUseListCardsQueryResponse, IError>({
		queryKey: [environment, QK_CARDS, 'list', args?.paginationConfig],
		queryFn: async () => {
			const res = await apiClientService.cards.read({
				paginationConfig: args?.paginationConfig,
				environment,
			});

			// We need this because the server doesn't reply with the hasMoreBefore pagination info :/
			const hasMoreBefore = await queryForItemsInThePreviousPage(
				res.cards[0],
				(paginationConfig) =>
					apiClientService.cards.read({
						paginationConfig: paginationConfig.paginationConfig,
						environment,
					}),
				'cards'
			);

			return { ...res, hasMoreBefore };
		},
		...args,
	});
}
