import classnames from 'classnames';
import React from 'react';
import { Link as L } from 'react-router-dom';
import './Link.scss';

export interface ILinkProps {
	button?: 'button' | 'secondary' | 'rounded';
	className?: string;
	color?: 'negative' | 'primary' | 'black';
	external?: boolean;
	onClick?: () => void;
	replace?: boolean;
	style?: React.CSSProperties;
	to: string;
	underline?: string;
}

export default function Link(props: React.PropsWithChildren<ILinkProps>) {
	const className = _getClassName(props);

	if (props.external) {
		return (
			<a style={props.style} className={className} href={props.to} target="blank" rel="noopener noreferer">
				{props.children}
			</a>
		);
	}

	return <L style={props.style} className={className} {...props} />;
}

function _getClassName(props: ILinkProps) {
	let cnButton = '';
	let cnColor = '';
	let cnUnderline = '';
	let cnCustom = '';

	if (props.color) {
		cnColor += _getColorClass(props.color);
	}

	if (props.button) {
		cnButton += _getButtonClass(props.button);
	}

	if (props.underline) {
		cnUnderline += 'isUnderline';
	}

	if (props.className) {
		cnCustom += props.className;
	}

	return classnames('Link', cnColor, cnButton, cnUnderline, cnCustom);
}

function _getColorClass(color?: string) {
	switch (color) {
		case 'negative':
			return 'isNegative';
		case 'primary':
			return 'isPrimary';
		case 'black':
			return 'isBlack';
		default:
			return '';
	}
}

function _getButtonClass(level: string) {
	switch (level) {
		case 'button':
			return 'isButton';
		case 'secondary':
			return 'isSecondary';
		case 'rounded':
			return 'isRounded';
		default:
			return '';
	}
}
