import { useListInvitationsQuery } from 'queries/invitations';
import IInvitationDetails from 'types/invitations/IInvitationDetails';

export default function usePendingInvitesList() {
	const invitationsQuery = useListInvitationsQuery({ status: 'pending' });
	return {
		props: {
			invitations: invitationsQuery.data?.invitations as IInvitationDetails[],
			serverError: invitationsQuery.error?.message || '',
		},
		status: invitationsQuery.status,
	};
}
