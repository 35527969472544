import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React, { PropsWithChildren } from 'react';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './CredentialBlock.module.scss';

interface ICredentialBlockProps {
	title: string;
	description: string;
	linkUrl: string;
	linkText: string;
	handleButtonClick?: () => void;
}

export default function CredentialBlock(props: PropsWithChildren<ICredentialBlockProps>) {
	return (
		<View>
			<PaddingContainer>
				<View className={style.CredentialBlock__titleWrap}>
					<Label size="large" fontWeight="500" margin="0 20px 0 0">
						{props.title}
					</Label>
					{props.handleButtonClick ? (
						<Pill data-testid="add-core-api-key-btn" level="primary" size="small" onClick={props.handleButtonClick}>
							Add
						</Pill>
					) : null}
				</View>
				<View className={style.CredentialBlock__contentWrap}>
					<Paragraph size="small" color="contentPrimary">
						{props.description}
					</Paragraph>
					<Pill level="inline-link" size="small" to={props.linkUrl} external>
						{props.linkText}
					</Pill>
				</View>
			</PaddingContainer>
			<View>{props.children}</View>
		</View>
	);
}
