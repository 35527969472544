import apiFetch from 'api-client/utils/fetch';
import ISignedPayloadsPublicKey from 'types/ISignedPayloadsPublicKey';
import IEnvironment from 'types/session/IEnvironment';

const SIGNED_PAYLOADS_ENDPOINT = '/developer/signed_payloads';

interface IListArgs {
	environment: IEnvironment;
}

export interface ISignedPayloadsListResponse {
	hasMore: boolean;
	totalRows: number;
	publicKeys: ISignedPayloadsPublicKey[];
}

async function list(args: IListArgs): Promise<ISignedPayloadsListResponse> {
	return apiFetch(`${SIGNED_PAYLOADS_ENDPOINT}?environment=${args.environment}`, 'GET').then((res) => {
		return {
			hasMore: res.has_more,
			totalRows: res.total_rows,
			publicKeys: res.public_keys.map((raw: any) => ({
				id: raw.key_id,
				name: raw.name,
				value: raw.public_key,
				created: new Date(raw.created),
			})),
		};
	});
}

export interface IAddSignedPayloadsKeyArgs {
	environment: IEnvironment;
	name: string;
	value: string;
}
export interface IAddSignedPayloadsKeyResponse {}

async function add(args: IAddSignedPayloadsKeyArgs): Promise<IAddSignedPayloadsKeyResponse> {
	return apiFetch(`${SIGNED_PAYLOADS_ENDPOINT}?environment=${args.environment}`, 'POST', {
		name: args.name,
		public_key: args.value,
	});
}

export interface IRemoveSignedPayloadsArgs {
	environment: IEnvironment;
	id: string;
}

export interface IRemoveSignedPayloadsKeyResponse {}

async function remove(args: IRemoveSignedPayloadsArgs): Promise<IRemoveSignedPayloadsKeyResponse> {
	return apiFetch(`${SIGNED_PAYLOADS_ENDPOINT}/${args.id}?environment=${args.environment}`, 'DELETE');
}

export default {
	list,
	add,
	remove,
};
