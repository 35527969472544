import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import { CREDENTIALS_ROUTE, SDKS_ROUTE, WEBHOOKS_ROUTE } from 'constants/routes';
import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import TabItem from 'styleguide/Tabs/TabItem/TabItem';
import TabsList from 'styleguide/Tabs/TabsList/TabsList';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import SwitchWithNotFound from 'utils/SwitchWithNotFound';
import style from './DevelopersPage.module.scss';
import DocsLink from './components/DocsLink/DocsLink';
import CredentialsSection from './sections/credentials/Credentials.section';
import SdkSection from './sections/sdk/Sdk.section';
import WebhooksSection from './sections/webhooks/Webhooks.section';

export default function DevelopersRootPage() {
	const { pathname } = useLocation();

	return (
		<React.Fragment>
			<PaddingContainer>
				<Heading size="large" tag="h1">
					Developers
				</Heading>
				<View className={style.TabsNav}>
					<TabsList>
						<TabItem name="API Keys" to={CREDENTIALS_ROUTE} />
						<TabItem name="SDKs" to={SDKS_ROUTE} />
						<TabItem name="Webhooks" to={WEBHOOKS_ROUTE} />
						<DocsLink pathname={pathname} className={style.DocsLink} />
					</TabsList>
				</View>
			</PaddingContainer>
			<SwitchWithNotFound>
				<Route index element={<Navigate replace to={CREDENTIALS_ROUTE} />} />
				<Route path="credentials" element={<CredentialsSection />} />
				<Route path="sdk" element={<SdkSection />} />
				<Route path="webhooks" element={<WebhooksSection />} />
			</SwitchWithNotFound>
		</React.Fragment>
	);
}
