import dummy_accept_invitation_existing_response from 'mocks/serverResponses/invitations/dummy_accept_invitation_existing_response';
import dummy_pending_invitations_response from 'mocks/serverResponses/invitations/dummy_list_pending_invitations_response';
import { rest } from 'msw';

const invitationsHandlers = [
	rest.get('https://api.stg.aptopayments.com/v1/developer/settings/invitations', (req, res, ctx) => {
		return res(ctx.json(dummy_pending_invitations_response), ctx.status(200));
	}),
	rest.post('https://api.stg.aptopayments.com/v1/developer/settings/invitations', (req, res, ctx) => {
		return res(ctx.json({}), ctx.status(200));
	}),
	rest.post('https://api.stg.aptopayments.com/v1/developer/invitations/accept', (req, res, ctx) => {
		return res(ctx.json(dummy_accept_invitation_existing_response), ctx.status(200));
	}),
];

export default invitationsHandlers;
