import React, { PropsWithChildren } from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import './Button.scss';

export interface IButtonProps {
  id?: string;
  type?: 'submit' | 'button';
  level?: 'primary' | 'secondary' | 'link';
  size?: 'default' | 'small';
  color?: 'default' | 'dark';
  icon?: 'arrow';
  onClick?: () => void;
  disabled?: boolean;
  testID?: string;
  /**
   * This allows the button to render in full width as a block element
   */
  block?: boolean;
  loading?: boolean;
  autofocus?: boolean;
}

export default function Button(props: PropsWithChildren<IButtonProps>) {
  let className = props.block ? 'Button isBlock' : 'Button';

  if (props.level) {
    className += _getLevelClass(props.level);
  }

  if (props.size) {
    className += _getSizeClass(props.size);
  }

  if (props.color) {
    className += _getColorClass(props.color);
  }

  if (props.icon) {
    className += _getIconClass(props.icon);
  }

  if (props.loading) {
    className += ' isLoading';
    return (
      <button disabled data-testid={props.testID} className={className} id={props.id}>
        <Spinner color="#fff" width="100%" height="100%" />
        <span className="Button__text">{props.children}</span>
      </button>
    );
  }

  return (
    <button
      type={props.type}
      autoFocus={props.autofocus}
      data-testid={props.testID}
      disabled={props.disabled}
      className={className}
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

function _getLevelClass(level: string) {
  switch (level) {
    case 'secondary':
      return ' isSecondary';
    case 'link':
      return ' isLink';
    default:
      return '';
  }
}

function _getSizeClass(size: string) {
  switch (size) {
    case 'small':
      return ' isSmall';
    default:
      return '';
  }
}

function _getColorClass(color: string) {
  switch (color) {
    case 'dark':
      return ' isDark';
    default:
      return '';
  }
}

function _getIconClass(icon: string) {
  switch (icon) {
    case 'arrow':
      return ' hasArrow';
    default:
      return '';
  }
}
