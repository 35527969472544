import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';

export default function TransactionsHistoryLoading() {
	return (
		<PaddingContainer>
			<Heading size="small" tag="h2" margin="36px 0 0 0">
				Transaction history
			</Heading>
			<View
				data-testid="TransactionsHistoryLoader"
				style={{
					height: '400px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Spinner height="96px" width="96px" />
			</View>
		</PaddingContainer>
	);
}
