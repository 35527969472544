import classnames from 'classnames';
import React, { ReactNode } from 'react';
import View from 'styleguide/View/View';
import style from './CardsSelect.module.scss';
import CardsSelectDropdown from './components/CardsSelectDropdown';

export interface ICardsSelectProps {
	isDisabled?: boolean;
	isExpanded: boolean;
	dropdownContent: ReactNode;
	selectedNode: ReactNode;
}

export default function CardsSelect(props: ICardsSelectProps) {
	return (
		<View className={style.CardsSelect}>
			<View
				className={classnames(style.CardsSelect__input, {
					[`${style['CardsSelect__input--disabled']}`]: props.isDisabled,
					[`${style['CardsSelect__input--expanded']}`]: props.isExpanded && !props.isDisabled,
				})}
			>
				{props.selectedNode}
			</View>
			{props.isExpanded && !props.isDisabled ? (
				<CardsSelectDropdown>{props.dropdownContent}</CardsSelectDropdown>
			) : null}
		</View>
	);
}
