import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './CountryInput.module.scss';
import CountryInputService from './CountryInput.service';

export interface ICountryInputProps extends ICommonInputProps {
  type: 'country';
  value: string;
  /** An array of a key value object with all the possible options for a selectable input */
  selectOptions: Record<string, string> | string[];
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLSelectElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
}

export default function CountryInput(props: ICountryInputProps) {
  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }

  if (!props.selectOptions) {
    return null;
  }

  let options: Record<string, string> = {};

  if (Array.isArray(props.selectOptions)) {
    props.selectOptions.forEach((value) => (options[value] = value));
  } else {
    options = props.selectOptions;
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(style.CountryInput, style[cnContent], style[cnError], style[cnDisabled])}
    >
      <label className={style.CountryInput__label} htmlFor={props.id}>
        {CountryInputService.getFlagEmoji(props.value)}
      </label>
      <select
        className={style.CountryInput__input}
        disabled={props.disabled}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        {Object.keys(options).map((key) => {
          const countryInfo = CountryInputService.getCountryInfo(options[key]);
          return (
            <option key={key} value={key}>
              {`${countryInfo?.flag} ${countryInfo?.name}`}
            </option>
          );
        })}
      </select>
      {props.error ? <span className={style.Input__error}>{props.error}</span> : null}
    </div>
  );
}
