import Paragraph from 'styleguide/Type/Paragraph/Paragraph';

interface IAddressProps {
	country: string;
	locality: string;
	postalCode: string;
	region: string;
	streetOne: string;
	streetTwo: string;
}

export default function Address(props: IAddressProps) {
	return (
		<Paragraph size="small" margin={'2px 0 0'}>
			{`${props.streetOne}${props.streetTwo ? ` ${props.streetTwo}` : ''}, ${props.locality}, ${props.region} ${
				props.postalCode
			} ${props.country}`}
		</Paragraph>
	);
}
