import decimalNumberService from './decimalNumber.service';

const NON_CURRENCY_REGEX = /[^0-9.]/g;

/**
 * Forces a numeric value to be a valid monetary amount with 2 decimals maximum.
 * @param value
 */
export function format(value: string): string {
	// TODO: Add trailing zeros when necessary to round to 10th of cent
	value = _removeNonCurrencyCharacters(value);

	return decimalNumberService.format(value, 2);
}

function _removeNonCurrencyCharacters(value: string): string {
	return value.replace(NON_CURRENCY_REGEX, '');
}

export default { format };
