import accounts from './accounts/accounts';
import activation from './activateAccount/activateAccount';
import agreements from './agreements/agreements';
import apiKeys from './apiKeys/apiKeys';
import auth from './auth';
import cardholders from './cardholders/cardholders';
import cards from './cards/cards';
import contact from './contact/contact.api';
import developerAccounts from './developerAccounts/developerAccounts';
import invitations from './invitations/invitations';
import paymentSources from './paymentSources/paymentSources';
import sdks from './sdks/sdks';
import signedPayloads from './signedPayloads/signedPayloads';
import teamMembers from './teamMembers/teamMembers';
import transactions from './transactions/transactions';
import webhooks from './webhooks/webhooks';

export default {
	accounts,
	activation,
	agreements,
	apiKeys,
	auth,
	cardholders,
	cards,
	contact,
	developerAccounts,
	invitations,
	paymentSources,
	sdks,
	signedPayloads,
	teamMembers,
	transactions,
	webhooks,
};
