import { ISummaryUserResponse } from 'api-client/cardholders/cardholders';
import { useGetUserQuery } from 'queries/users.queries';
import { useParams } from 'react-router-dom';

export default function useUser() {
	const { id } = useParams() as { id: string };
	const userQuery = useGetUserQuery({ id });

	return {
		props: {
			// SafeCast: This will be defined because WithQuery is being used
			...(userQuery.data as ISummaryUserResponse),
			serverError: userQuery.error?.message || '',
			id,
		},
		status: userQuery.status,
	};
}
