import React from 'react';
import passwordService from 'services/password.service';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';

export default function PasswordValidator({ password, isVisible }: { password: string; isVisible: boolean }) {
	const {
		hasLowercaseChar,
		hasMinimunLength,
		hasUppercaseChar,
		hasNumber,
		hasSpecialChar,
		isShorterThanMaxAllowedLength,
		hasInvalidChars,
	} = passwordService.validatePassword(password);

	if (!isVisible) {
		return null;
	}

	if (passwordService.isValidPassword(password)) {
		return (
			<View>
				<Text type="footnote" color="positive">
					{' '}
					✓ Your password is secure and you're all set!{' '}
				</Text>
			</View>
		);
	}

	return (
		<View data-testid="PasswordValidator" style={{ display: 'flex', flexDirection: 'column' }}>
			{hasInvalidChars ? (
				<Text type="footnote" color="negative">
					{' '}
					✕ Your password contains characters that are not allowed: whitespace or * characters.
				</Text>
			) : null}
			<View style={{ display: 'flex' }}>
				<View style={{ flex: 1 }}>
					{hasMinimunLength ? (
						<Text type="footnote" color="positive">
							{' '}
							✓ 12 characters minimum
						</Text>
					) : (
						<Text type="footnote" color="body">
							{' '}
							✕ 12 characters minimum
						</Text>
					)}
					{isShorterThanMaxAllowedLength ? (
						<Text type="footnote" color="positive">
							{' '}
							✓ 64 characters maximum
						</Text>
					) : (
						<Text type="footnote" color="body">
							{' '}
							✕ 64 characters maximum
						</Text>
					)}
					{hasLowercaseChar ? (
						<Text type="footnote" color="positive">
							{' '}
							✓ One lowercase character
						</Text>
					) : (
						<Text type="footnote" color="body">
							{' '}
							✕ One lowercase character
						</Text>
					)}
				</View>
				<View title={'! # $ % & + , - . / : ; < = > ? @ _ { | }'} style={{ flex: 1 }}>
					{hasUppercaseChar ? (
						<Text type="footnote" color="positive">
							{' '}
							✓ One uppercase character
						</Text>
					) : (
						<Text type="footnote" color="body">
							{' '}
							✕ One uppercase character
						</Text>
					)}
					{hasNumber ? (
						<Text type="footnote" color="positive">
							{' '}
							✓ One number
						</Text>
					) : (
						<Text type="footnote" color="body">
							{' '}
							✕ One number
						</Text>
					)}
					{hasSpecialChar ? (
						<Text type="footnote" color="positive">
							{' '}
							✓ One special character
						</Text>
					) : (
						<Text type="footnote" color="body">
							{' '}
							✕ One special character
						</Text>
					)}
					{hasSpecialChar ? null : (
						<span
							style={{
								alignSelf: 'flex-end',
								fontSize: '10px',
								color: '#757575',
							}}
						>
							{' '}
							{'! # $ % & + , - . / : ; < = > ? @ _ { | }'}
						</span>
					)}
				</View>
			</View>
		</View>
	);
}
