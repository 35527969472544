import Initials from 'components/Initials/Initials';
import React from 'react';
import Table from 'styleguide/Table/Table';
import View from 'styleguide/View/View';
import IInvitationDetails from 'types/invitations/IInvitationDetails';
import style from '../PendingInvites.module.scss';

interface IPendingInvitesSuccessProps {
	invitations: IInvitationDetails[];
}

interface IPendingInvitesTableData {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
}

const columns = [
	{
		key: 'id',
		name: 'Pending invites',
		renderer: InviteRow,
	},
	{ key: 'email', name: '' },
];

export default function PendingInvitesSuccess(props: IPendingInvitesSuccessProps) {
	const data: IPendingInvitesTableData[] = props.invitations.map((invitation) => {
		return {
			id: invitation.invitationId,
			firstName: invitation.inviteeFirstName,
			lastName: invitation.inviteeLastName,
			email: invitation.inviteeEmail,
		};
	});

	if (!props.invitations.length) return null;

	return (
		<View data-testid="team-pending-invites">
			<Table data={data} columns={columns} className={style.PendingInvites__table} />
		</View>
	);
}

function InviteRow(props: any) {
	return (
		<View className={style.PendingInvites__emptyInitials}>
			<Initials firstName="" lastName="" />
			<span>--</span>
		</View>
	);
}
