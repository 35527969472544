import React from 'react';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import style from './PaymentMethodPreview.module.scss';

export interface IPaymentMethodPreviewProps {
	network: IAptoCardNetwork;
}

export default function PaymentMethodPreview(props: IPaymentMethodPreviewProps) {
	switch (props.network) {
		case 'MASTERCARD':
			return (
				<View className={style.PaymentMethodPreview}>
					<View
						className={style['PaymentMethodPreview__card--mastercard']}
						data-testid="PaymentMethodPreviewCardIcon"
					></View>
					<Paragraph size="small" color="contentPrimary">
						Mastercard
					</Paragraph>
				</View>
			);
		case 'VISA':
			return (
				<View className={style.PaymentMethodPreview}>
					<View className={style['PaymentMethodPreview__card--visa']} data-testid="PaymentMethodPreviewCardIcon"></View>
					<Paragraph size="small" color="contentPrimary">
						Visa
					</Paragraph>
				</View>
			);
	}
}
