import React, { ReactNode } from 'react';
import View from 'styleguide/View/View';
import style from '../CardsSelect.module.scss';

export default function CardsSelectDropdown({ children }: { children: ReactNode }) {
	return (
		<View className={style.CardsSelect__dropdown} role="listbox" aria-labelledby="CardsSelect__input">
			<View className={style.CardsSelect__list}>{children}</View>
		</View>
	);
}
