import CompanyLogo from 'components/CompanyPanel/components/CompanyLogo/CompanyLogo';
import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import IDeveloperAccount from 'types/IDeveloperAccount';
import style from './TeamsListItem.module.scss';

interface ITeamsListItemProps {
	team: IDeveloperAccount;
	switchTeam: (teamId: string) => void;
}

export default function TeamsListItem(props: ITeamsListItemProps) {
	return (
		<View className={style.TeamsListItem}>
			<CompanyLogo companyName={props.team.companyName} size="small" />
			<Paragraph size="small" color="contentPrimary" margin="0 16px">
				{props.team.companyName}
			</Paragraph>
			<Pill
				className={style.TeamsListItem__action}
				level="secondary"
				type="button"
				size="small"
				onClick={() => props.switchTeam(props.team.developerAccountId)}
			>
				Switch
			</Pill>
		</View>
	);
}
