import { ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE } from 'constants/routes';
import useApplicationId from 'hooks/useApplicationId';
import {
	useAddOwnerMutation,
	useConfirmOwnersMutation,
	useListOwnersQuery,
	useRemoveOwnerMutation,
	useUpdateOwnerMutation,
} from 'queries/activateAccount';
import { useNavigate } from 'react-router';
import IOwner from 'types/activateAccount/IOwner';
import IError from 'types/network/IError';
import usePureState from 'utils/usePureState';

interface IState {
	cardType: 'business_owner' | 'beneficial_owner';
	isSidebarVisible: boolean;
	selectedOwner: IOwner | undefined;
}

export default function useOwnersStepWithApplicationId() {
	const navigate = useNavigate();
	// SafeCast: This component is rendered using a WithApplicationID HOC that guarantees that the applicationId is set
	const applicationId = useApplicationId() as string;
	const listOwnersQuery = useListOwnersQuery({ applicationId });
	const addOwnerMutation = useAddOwnerMutation();
	const updateOwnerMutation = useUpdateOwnerMutation();
	const removeOwnerMutation = useRemoveOwnerMutation();
	const confirmOwnersMutation = useConfirmOwnersMutation();

	const { state, dispatch } = usePureState<IState>({
		cardType: 'business_owner',
		isSidebarVisible: false,
		selectedOwner: undefined,
	});

	const { businessOwners, beneficialOwners } = _classifyOwners(listOwnersQuery.data?.owners);

	function addOwner(owner: IOwner) {
		return addOwnerMutation.mutate({ owner, applicationId });
	}

	function updateOwner(owner: IOwner) {
		return updateOwnerMutation.mutate({ owner, applicationId });
	}

	function removeOwner(owner: IOwner) {
		return removeOwnerMutation.mutate({ ownerId: owner.id, applicationId });
	}

	function handleOwnerClick(owner: IOwner) {
		return dispatch({ selectedOwner: owner, cardType: owner.type, isSidebarVisible: true });
	}

	function handleNextBtnClick() {
		return confirmOwnersMutation.mutate(applicationId, {
			onSuccess: () => navigate(ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE),
		});
	}

	function handleAddBusinessOwnerClick() {
		return dispatch({ cardType: 'business_owner', selectedOwner: undefined, isSidebarVisible: true });
	}

	function handleAddBeneficialOwnerClick() {
		return dispatch({ cardType: 'beneficial_owner', selectedOwner: undefined, isSidebarVisible: true });
	}

	function hideCard() {
		// We set selectedOwner to undefined to reset the form
		return dispatch({ selectedOwner: undefined, isSidebarVisible: false });
	}

	return {
		addOwner,
		updateOwner,
		handleAddBeneficialOwnerClick,
		handleAddBusinessOwnerClick,
		handleNextBtnClick,
		handleOwnerClick,
		hideCard,
		removeOwner,

		beneficialOwners,
		businessOwners,
		cardType: state.cardType,
		isNextButtonDisabled: businessOwners.length <= 0,
		isNextButtonLoading: confirmOwnersMutation.isLoading,
		isOwnerListLoading: listOwnersQuery.isLoading,
		isSidebarHidden: !state.isSidebarVisible,
		errorMessage: _getErrorMessage([
			listOwnersQuery.error,
			addOwnerMutation.error,
			updateOwnerMutation.error,
			confirmOwnersMutation.error,
			removeOwnerMutation.error,
		]),
		selectedOwner: state.selectedOwner,
	};
}

function _classifyOwners(owners: IOwner[] | undefined) {
	const beneficialOwners: IOwner[] = [];
	const businessOwners: IOwner[] = [];

	if (!owners) {
		return { beneficialOwners, businessOwners };
	}

	owners.forEach((owner) => {
		if (owner.type === 'beneficial_owner') {
			beneficialOwners.push(owner);
		}
		if (owner.type === 'business_owner') {
			businessOwners.push(owner);
		}
	});

	return { beneficialOwners, businessOwners };
}

function _getErrorMessage(errors: (IError | null)[]) {
	const error = errors.find((error) => error?.message);
	return error?.message || '';
}
