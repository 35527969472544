import apiClientService from 'api-client/apiclient.service';
import ModalInviteMember from 'components/ModalInviteMember/ModalInviteMember';
import { ERROR_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import React from 'react';
import { useNavigate } from 'react-router';
import Modal from 'styleguide/Modal/Modal';
import AptoServerError from 'utils/AptoServerError';

export default function useCompanyPanel(onCloseClicked: () => void) {
	const { fetchSession } = useSession();
	const navigate = useNavigate();

	function showModalInviteMember() {
		Modal.show(<ModalInviteMember />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Invite team members',
		});
	}

	function handleSwitchCompany(teamId: string) {
		apiClientService.developerAccounts
			.update({ developerAccountId: teamId })
			.then(async () => {
				// TODO: Update this logic (ACQ-1137)
				await fetchSession('sandbox'); // Set sandbox to prevent 404 (ACQ-1136)
				// Reload the page to update the main view data ACQ-1079
				// TODO: Invalidate queries
				window.location.reload();
				onCloseClicked();
			})
			.catch((error: AptoServerError) => {
				navigate(ERROR_ROUTE, { state: { error: new Error(error.message), httpStatus: error.httpStatus } });
			});
	}

	return {
		handleSwitchCompany,
		showModalInviteMember,
	};
}
