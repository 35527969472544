import apiFetch from 'api-client/utils/fetch';
import toCamelCase from 'api-client/utils/toCamelCase';
import phoneNumberService from 'services/phoneNumber.service';
import { ICountryCode } from 'styleguide/InputPhoneNumber/ICountryCode';
import InputPhoneNumberService from 'styleguide/InputPhoneNumber/InputPhoneNumber.service';
import IOwner from 'types/activateAccount/IOwner';
import IApplicationStatus from 'types/session/IApplicationStatus';
import ownerParser from './owner.parser';

const ENDPOINT = '/developer/get_started_application';
export interface IListOwnersResponse {
	owners: Array<IOwner>;
	hasMore: boolean;
	total: number;
}

export async function listOwners(applicationId: string): Promise<IListOwnersResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/owners`, 'GET').then((res) => {
		const owners: IOwner[] = res.business_owners.map(({ business_owner }: any) =>
			ownerParser.parseOwner(business_owner)
		);

		return {
			owners,
			hasMore: res.has_more,
			total: res.total,
		};
	});
}

type IGetOwnerResponse = IOwner;

export async function getOwner(applicationId: string, ownerId: string): Promise<IGetOwnerResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/owners/${ownerId}`, 'GET').then((res) => ownerParser.parseOwner(res));
}

type IAddOwnerResponse = IOwner;

export interface IAddOwnerArgs {
	owner: IOwner;
	applicationId: string;
}

export async function addOwner(args: IAddOwnerArgs): Promise<IAddOwnerResponse> {
	const { owner, applicationId } = args;

	return apiFetch(`${ENDPOINT}/${applicationId}/owners`, 'POST', _getOwnerData(owner)).then((res) =>
		ownerParser.parseOwner(res)
	);
}

type IUpdateOwnerResponse = IOwner;
export interface IUpdateOwnerArgs {
	owner: IOwner;
	applicationId: string;
}

export async function updateOwner(args: IUpdateOwnerArgs): Promise<IUpdateOwnerResponse> {
	const { owner, applicationId } = args;

	return apiFetch(`${ENDPOINT}/${applicationId}/owners/${owner.id}`, 'PUT', _getOwnerData(owner)).then((res) =>
		ownerParser.parseOwner(res)
	);
}

type IRemoveOwnerResponse = void;

export interface IRemoveOwnerArgs {
	applicationId: string;
	ownerId: string;
}

export async function removeOwner(args: IRemoveOwnerArgs): Promise<IRemoveOwnerResponse> {
	return apiFetch(`${ENDPOINT}/${args.applicationId}/owners/${args.ownerId}`, 'DELETE');
}

export interface ISetOwnersResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

export async function confirmOwners(applicationId: string): Promise<ISetOwnersResponse> {
	return apiFetch(`${ENDPOINT}/${applicationId}/owners/finish`, 'POST').then(toCamelCase);
}

function _getOwnerData(owner: IOwner) {
	const ownerTypeData = _getOwnerTypeData(owner);

	return {
		...ownerTypeData,
		name: {
			first_name: owner.firstName,
			last_name: owner.lastName,
		},
		phone: {
			country_code: parseInt(InputPhoneNumberService.getDialCode(owner.countryCode), 10),
			phone_number: phoneNumberService.removeDialCode(owner.countryCode, owner.phoneNumber),
		},
		address: {
			street_one: owner.streetOne,
			street_two: owner.streetTwo,
			locality: owner.locality,
			region: owner.region,
			postal_code: owner.postalCode,
			country: owner.country,
		},
		birthdate: owner.birthDate,
		business_title: owner.businessTitle,
		id_document: _getIdDocumentData(owner),
		citizenship: owner.citizenship,
		email: owner.email,
	};
}

function _getOwnerTypeData(owner: IOwner) {
	if (owner.type === 'beneficial_owner') {
		return {
			type: owner.type,
			ownership_percentage: parseFloat(owner.ownershipPercentage),
		};
	}

	return {
		type: owner.type,
	};
}

function _getIdDocumentData(owner: IOwner): {
	type: 'passport' | 'ssn';
	country: ICountryCode;
	value: string;
} {
	if (owner.citizenship === 'us_citizen') {
		if (owner.formOfID === 'passport') {
			return { type: 'passport', country: 'US', value: owner.passport };
		}

		if (owner.formOfID === 'ssn') {
			return { type: 'ssn', country: 'US', value: owner.ssn };
		}
	}

	return {
		type: 'passport',
		country: owner.passportIssuedCountry,
		value: owner.passport,
	};
}

export default {
	listOwners,
	getOwner,
	addOwner,
	updateOwner,
	removeOwner,
	confirmOwners,
};
