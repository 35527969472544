import apiClientService from 'api-client/apiclient.service';
import { ICreateApiKeyResponse, IReadApiKeysResponse } from 'api-client/apiKeys/apiKeys';
import {
	IAddSignedPayloadsKeyArgs,
	IAddSignedPayloadsKeyResponse,
	IRemoveSignedPayloadsArgs,
	IRemoveSignedPayloadsKeyResponse,
	ISignedPayloadsListResponse,
} from 'api-client/signedPayloads/signedPayloads';
import useSession from 'hooks/useSession';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import IError from 'types/network/IError';

export const QK_API_KEYS = 'API_KEYS';
export const QK_SIGNED_PAYLOADS = 'SIGNED_PAYLOADS';

interface IUseListApiKeysQueryArgs extends UseQueryOptions<IReadApiKeysResponse, IError> {}

export function useListApiKeysQuery(args?: IUseListApiKeysQueryArgs) {
	const { environment } = useSession();

	return useQuery<IReadApiKeysResponse, IError>({
		queryKey: [environment, QK_API_KEYS, 'list'],
		queryFn: () => apiClientService.apiKeys.read({ environment }),
		...args,
	});
}

interface IUseRemoveApiKeyMutation extends UseMutationOptions<void, IError, string> {}

export function useRemoveApiKeyMutation(args?: IUseRemoveApiKeyMutation) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<void, IError, string>({
		mutationFn: (id) => apiClientService.apiKeys.remove({ id, environment }),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_API_KEYS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseCreateApiKeyMutationArgs extends UseMutationOptions<ICreateApiKeyResponse, IError, string> {}

export function useCreateApiKeyMutation(args?: IUseCreateApiKeyMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<ICreateApiKeyResponse, IError, string>({
		mutationFn: (description) => apiClientService.apiKeys.create({ environment, description }),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_API_KEYS]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseListSignedPayloadsKeysQueryArgs extends UseQueryOptions<ISignedPayloadsListResponse, IError> {}

export function useListSignedPayloadsKeysQuery(args?: IUseListSignedPayloadsKeysQueryArgs) {
	const { environment } = useSession();

	return useQuery<ISignedPayloadsListResponse, IError>({
		queryKey: [environment, QK_SIGNED_PAYLOADS, 'list'],
		queryFn: () => apiClientService.signedPayloads.list({ environment }),
		...args,
	});
}

interface IUseAddSignedPayloadsKeyMutationArgs
	extends UseMutationOptions<IAddSignedPayloadsKeyResponse, IError, Omit<IAddSignedPayloadsKeyArgs, 'environment'>> {}

export function useAddSignedPayloadsKeyMutation(args?: IUseAddSignedPayloadsKeyMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<IAddSignedPayloadsKeyResponse, IError, Omit<IAddSignedPayloadsKeyArgs, 'environment'>>({
		mutationFn: ({ name, value }) => apiClientService.signedPayloads.add({ name, environment, value }),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_SIGNED_PAYLOADS, 'list']);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseRemoveSignedPayloadsKeyMutationArgs
	extends UseMutationOptions<
		IRemoveSignedPayloadsKeyResponse,
		IError,
		Omit<IRemoveSignedPayloadsArgs, 'environment'>
	> {}

export function useRemoveSignedPayloadsKeyMutation(args?: IUseRemoveSignedPayloadsKeyMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<IRemoveSignedPayloadsKeyResponse, IError, Omit<IRemoveSignedPayloadsArgs, 'environment'>>({
		mutationFn: ({ id }) => apiClientService.signedPayloads.remove({ id, environment }),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_SIGNED_PAYLOADS, 'list']);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}
