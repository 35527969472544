import Link from 'components/Link/Link';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import { CONTACT_US } from 'constants/routes';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import ListItem from 'styleguide/Item/Item';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './Business.module.scss';
import BusinessItem from './components/BusinessItem/BusinessItem';
import useBusiness from './useBusiness';

export default function BusinessSection() {
	const { bussinessInfo, showModalDeactivateAccount } = useBusiness();

	return (
		<PaddingContainer>
			<Grid cols="12" isNested>
				<Item colWidth="12" colWidthLg="8">
					<Heading size="small" tag="h2" margin="0 0 12px 0">
						Business Info
					</Heading>
					<BusinessItem name="Account Name" value={bussinessInfo.accountName} />
					<BusinessItem name="Legal Business Name" value={bussinessInfo.legalName} />
					<BusinessItem name="Contact Email" value={bussinessInfo.email} />
					<BusinessItem name="Company Address" value={bussinessInfo.address} />
					<BusinessItem name="Company Website" value={bussinessInfo.website} />
					<ListItem padding="18px 0" hasBorderBottom={true}>
						<View className={style['Business__item--last']}>
							<Paragraph size="small">
								<button className={style.buttonReset} onClick={showModalDeactivateAccount}>
									<Label color="negative" size="small">
										Deactivate account
									</Label>
								</button>
							</Paragraph>
						</View>
					</ListItem>
				</Item>
				<Item colWidth="12" colWidthLg="3" className={style.Business__asideItem}>
					<View className={style.Business__aside}>
						<Paragraph size="small" color="contentPrimary">
							We are unable to update this information without having to redo your KYB verification. Please contact our
							team if you require changes.
						</Paragraph>
						<Link to={CONTACT_US}>
							<Label color="accent" size="small" fontWeight="500">
								Contact us
							</Label>
						</Link>
					</View>
				</Item>
			</Grid>
		</PaddingContainer>
	);
}
