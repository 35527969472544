import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';

export default function AddPaymentMethodLoading() {
	return (
		<View
			style={{
				minHeight: '360px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<Spinner height="96px" width="96px" />
			<Paragraph size="medium" color="contentPrimary"> Adding payment method... </Paragraph>
		</View>
	);
}
