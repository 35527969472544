import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import style from './EmptyKeyState.module.scss';

export default function EmptyKeyState() {
	return (
		<PaddingContainer>
			<Paragraph size="small" color="contentPrimary" textAlign="center" className={style.EmptyKeyState}>
				You have no public key.
			</Paragraph>
		</PaddingContainer>
	);
}
