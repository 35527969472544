import dummy_get_company_address_response from 'mocks/serverResponses/activate_account/dummy_get_company_address_response';
import dummy_get_company_info_response from 'mocks/serverResponses/activate_account/dummy_get_company_info_response';
import dummy_get_program_agreement_response from 'mocks/serverResponses/activate_account/dummy_get_program_agreement_response';
import dummy_get_public_key_response from 'mocks/serverResponses/activate_account/dummy_get_public_key_response';
import dummy_set_public_key_response from 'mocks/serverResponses/activate_account/dummy_set_public_key_response';
import { rest } from 'msw';

export default [
	rest.get('https://api.stg.aptopayments.com/v1/developer/get_started_application/*/company_info', (req, res, ctx) => {
		return res(ctx.json(dummy_get_company_info_response), ctx.status(200));
	}),

	rest.get('https://api.stg.aptopayments.com/v1/developer/get_started_application/*/contacts', (req, res, ctx) => {
		return res(ctx.json(dummy_get_company_address_response), ctx.status(200));
	}),

	rest.get(
		'https://api.stg.aptopayments.com/v1/developer/get_started_application/*/program_agreement',
		(req, res, ctx) => {
			return res(ctx.json(dummy_get_program_agreement_response), ctx.status(200));
		}
	),

	rest.put(
		'https://api.stg.aptopayments.com/v1/developer/get_started_application/*/program_agreement',
		(req, res, ctx) => {
			return res(ctx.json(dummy_get_program_agreement_response), ctx.status(200), ctx.delay(3000));
		}
	),

	rest.get(
		'https://api.stg.aptopayments.com/v1/developer/get_started_application/*/signed_payloads',
		(req, res, ctx) => {
			return res(ctx.json(dummy_get_public_key_response), ctx.status(200));
		}
	),

	rest.put(
		'https://api.stg.aptopayments.com/v1/developer/get_started_application/*/signed_payloads',
		(req, res, ctx) => {
			return res(ctx.json(dummy_set_public_key_response), ctx.status(200), ctx.delay(2000));
		}
	),
];
