import Link from 'components/Link/Link';
import { CREATE_ACCOUNT_ROUTE, LOGIN_ROUTE } from 'constants/routes';
import React from 'react';
import Text from 'styleguide/Text/Text';
import style from './LoginHeader.module.scss';

export default function LoginHeader() {
	return (
		<header className={style.LoginHeader}>
			<div className={style.LoginHeader__left}>
				<Link external to="https://aptopayments.com">
					<div title="Go to aptopayments.com" className={style.LoginHeader__logo}></div>
				</Link>
			</div>
			<div className={style.LoginHeader__right}>
				<Link to={LOGIN_ROUTE}>
					<Text inline margin="auto 24px" type="h7">
						{' '}
						Log in{' '}
					</Text>
				</Link>
				<Link button="rounded" to={CREATE_ACCOUNT_ROUTE}>
					{' '}
					Sign up{' '}
				</Link>
			</div>
		</header>
	);
}
