import apiclientService from 'api-client/apiclient.service';
import { ACTIVATE_ACCOUNT_ROUTE } from 'constants/routes';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router';
import Modal from 'styleguide/Modal/Modal';
import IServerRequestState from 'types/network/IServerRequest';
import AptoServerError from 'utils/AptoServerError';
import usePureState from 'utils/usePureState';

export default function useModalAccountActivation(lastApplicationId?: string) {
	const navigate = useNavigate();
	const { fetchSession } = useSession();

	const { state, dispatch } = usePureState<IServerRequestState>({
		requestStatus: 'IDLE',
		serverError: '',
	});

	async function goToAccountActivationFlow() {
		Modal.hide();
		await fetchSession(); // We need to refetch the session to update the last completed step.
		return navigate(ACTIVATE_ACCOUNT_ROUTE);
	}

	function restartAccountActivationFlow() {
		if (!lastApplicationId) {
			dispatch({ requestStatus: 'FAILED', serverError: 'No valid application ID' });
			return;
		}

		dispatch({ requestStatus: 'PENDING', serverError: '' });

		apiclientService.activation
			.restartApplication(lastApplicationId)
			.then(async () => {
				await goToAccountActivationFlow();
				dispatch({ requestStatus: 'SUCCESS' });
			})
			.catch((err: AptoServerError) => {
				dispatch({ requestStatus: 'FAILED', serverError: err.message });
			});
	}

	return {
		goToAccountActivationFlow,
		isLoading: state.requestStatus === 'PENDING',
		restartAccountActivationFlow,
		serverError: state.serverError,
	};
}
