import { APTO_CARD_NETWORKS, DESIGN_KEYS, TRANSACTION_STATUSES } from 'mocks/statuses';
import mocksService from '../mocks.service';

function get(req: any, res: any, ctx: any) {
	const { startingAfter, endingBefore } = mocksService.getPaginationParams(req.url.searchParams);

	if (!isNaN(endingBefore)) {
		return res(
			ctx.json({
				transactions: mocksService.createItems(createTransaction, isNaN(startingAfter) ? 0 : startingAfter),
				has_more: isNaN(startingAfter) ? true : startingAfter <= 20,
				total: 30,
			})
		);
	}

	if (endingBefore === 1) {
		return res(
			ctx.json({
				transactions: [],
				has_more: true,
				total: 30,
			})
		);
	}

	return res(
		ctx.json({
			transactions: mocksService.createItems(createTransaction, Math.max(endingBefore - 10, 1)),
			has_more: true,
			total: 30,
		})
	);
}

function getSummary(req: any, res: any, ctx: any) {
	return res(ctx.json(createTransactionSummary()));
}

export default {
	get,
	getSummary,
};

function createTransaction(id: number) {
	return {
		transaction: {
			id: id,
			local_amount: {
				currency: 'EUR',
				amount: -20.44,
			},
			hold_amount: {
				currency: 'USD',
				amount: 0,
			},
			billing_amount: {
				currency: 'USD',
				amount: -23.57,
			},
			cashback_amount: {
				currency: 'USD',
				amount: 0,
			},
			fee_amount: {
				currency: 'USD',
				amount: -2.5,
			},
			created_at: '2016-10-19T23:25:17.000Z',
			description: 'string',
			merchant_name: 'LORDS PHARMACY LTD',
			card_last_four: 1234,
			card_network: mocksService.randomizeStatus(APTO_CARD_NETWORKS),
			card_design_key: mocksService.randomizeStatus(DESIGN_KEYS),
			state: TRANSACTION_STATUSES[Math.floor(Math.random() * TRANSACTION_STATUSES.length)],
		},
	};
}

function createTransactionSummary() {
	return {
		card: {
			last_four: '1234',
			livemode: true,
			balance_id: 'bal_44be95f5716699f9',
			spendable_balance: {
				native_currency: 'USD',
				amount: 0.0,
				currency: 'USD',
				native_amount: 0.0,
			},
			metadata: null,
			aba_routing_number: null,
			total_balance: {
				native_currency: 'USD',
				amount: 8.34,
				currency: 'USD',
				native_amount: 8.34,
			},
			cardholder_first_name: 'Jane',
			program_id: 'Apto_Card_V',
			ordered_status: 'ORDERED',
			status: 'ACTIVATED',
			allow_international: false,
			cardholder_last_name: 'Cooper',
			name_on_card: 'JANE COOPER',
			program_profile_id: 'ivan+0116',
			activated_at: '2020-07-21T23:46:37Z',
			ledge_account_id: null,
			corporation_id: null,
			wait_list: false,
			created_at: '2020-07-21T23:46:36Z',
			network: 'MASTERCARD',
			allow_set_pin: true,
			design_key: null,
			id: 'crd_fde2d61d233455b9',
			spendable_today: {
				native_currency: 'USD',
				amount: 0.0,
				currency: 'USD',
				native_amount: 0.0,
			},
			dda_number: null,
			shipped_at: null,
			additional_fields: [],
			wallets: [],
			cardholder_id: 'crdhldr_1cd68f70917cb5ed',
			issued_at: null,
			allow_get_pin: false,
		},
		transaction: {
			native_balance_amount: {
				amount: -20.44,
				currency: 'USD',
			},
			hold_amount: {
				amount: -0.0,
				currency: 'USD',
			},
			created_at: '2020-07-22T01:13:11Z',
			billing_amount: {
				amount: -23.57,
				currency: 'USD',
			},
			fee_amount: {
				amount: -0.0,
				currency: 'USD',
			},
			description: 'AMZN Mktp US Amzn.com/billWAUSA',
			id: 'txn_8cb0d39915b24d51',
			mcc_icon: 'cart',
			state: 'complete',
			local_amount: {
				amount: -20.44,
				currency: 'USD',
			},
			merchant_name: 'AMZN Mktp US',
			cashback_amount: {
				amount: 0.0,
				currency: 'USD',
			},
		},
		cardholder: {
			address: {
				street_one: '541 33rd Street',
				street_two: '',
				locality: 'Oakland',
				region: 'CA',
				postal_code: '94609',
				country: 'USA',
			},
			corporation_id: null,
			email: 'janecooper@example.com',
			created_at: '2020-07-21T23:46:33Z',
			kyc: {
				status: 'PASSED',
				passed_at: '2020-07-21T23:46:35Z',
			},
			phone: {
				phone_number: '6508617544',
				country_code: '1',
			},
			custodian_uid: 'janecooper@example.com',
			date_of_birth: '1988-09-09',
			id: 'crdhldr_1cd68f70917cb5ed',
			name: {
				last_name: 'Cooper',
				first_name: 'Jane',
			},
		},
	};
}
