import { IListInvitationsResponse } from 'api-client/invitations/invitations';

function parseInvitationsList(raw: any): IListInvitationsResponse {
	const { total_count, data, has_more } = raw;
	return {
		total: total_count,
		hasMore: has_more,
		invitations: data.map((invitation: any) => {
			return {
				inviterFirstName: invitation.inviter_name.first_name,
				inviterLastName: invitation.inviter_name.last_name,
				inviterEmail: invitation.inviter_email,
				inviteeFirstName: invitation.invitee_name.first_name,
				inviteeLastName: invitation.invitee_name.last_name,
				inviteeEmail: invitation.invitee_email,
				inviteeUserRole: invitation.invitee_user_role,
				invitationId: invitation.invitation_id,
				verificationId: invitation.verification_id,
				secret: invitation.secret,
				status: invitation.status,
			};
		}),
	};
}

export default {
	parseInvitationsList,
};
