import Header from 'components/LoginHeader/LoginHeader';
import Notification from 'components/Notification/Notification';
import PasswordValidator from 'components/PasswordValidator';
import links from 'constants/links';
import { LOGIN_ROUTE } from 'constants/routes';
import React from 'react';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './CreateAccount.module.scss';
import useCreateAccount from './useCreateAccount';

export default function CreateAccountPage() {
	const {
		serverError,
		isCompanyDisabled,
		isEmailDisabled,
		isPasswordValidatorVisible,
		isLoading,
		isButtonDisabled,
		createAccount,
		password,
		onPasswordFocus,
		onPasswordBlur,
		bind,
	} = useCreateAccount();

	return (
		<React.Fragment>
			<Header />
			<Grid cols="16" colsLg="16">
				<Item colWidth="16" colWidthLg="6" className={style.CreateAccount__gridItem}>
					<main className={style.CreateAccount}>
						<Notification isHidden={!serverError} type="negative" title="Error" description={serverError} />

						<form onSubmit={createAccount} className={style.CreateAccount__form}>
							<Text type="h4" tag="h1" margin="36px 0 24px">
								Sign up for Apto
							</Text>

							<View className={style.CreateAccount__Group}>
								<Input autofocus {...bind('firstName')} label="First name" type="text" />
								<Input margin="0 0 12px 0" {...bind('lastName')} label="Last name" type="text" />
							</View>

							<Input
								margin="0 0 8px 0"
								{...bind('company')}
								label="Company name"
								type="text"
								disabled={isCompanyDisabled}
							/>
							<Paragraph size="x-small" color="contentTertiary" margin="0 0 12px 0">
								Enter the company name your users know you by (i.e. don’t include LLC or Inc).
							</Paragraph>

							<Input margin="0 0 12px 0" {...bind('email')} label="Work email" type="text" disabled={isEmailDisabled} />

							<Input
								margin="0 0 12px 0"
								{...bind('password')}
								label="Password"
								type="password"
								onBlur={onPasswordBlur}
								onFocus={onPasswordFocus}
								showPasswordToggle
							/>
							<PasswordValidator isVisible={isPasswordValidatorVisible} password={password} />

							<Paragraph className={style.CreateAccount__terms} size="small" color="contentTertiary" margin="24px 0">
								By continuing, I confirm that I have read, consent, and agree to Apto’s{' '}
								<Pill level="inline-link" size="small" type="link" external to={links.APTO_TOS}>
									Terms and Conditions
								</Pill>
								.
							</Paragraph>

							<Pill
								level="primary"
								type="submit"
								size="medium"
								disabled={isButtonDisabled}
								loading={isLoading}
								data-testid="btn-sign-up"
								id="submit"
							>
								Sign up
							</Pill>

							<Paragraph size="medium" color="contentTertiary" margin="24px 0 0">
								Already have an account?{' '}
								<Pill
									type="link"
									level="inline-link"
									size="medium"
									to={LOGIN_ROUTE}
									className={style.CreateAccount__inlineCta}
								>
									Log in
								</Pill>
							</Paragraph>
						</form>
					</main>
				</Item>
			</Grid>
		</React.Fragment>
	);
}
