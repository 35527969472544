import React from 'react';
import InputPhoneNumber from 'styleguide/InputPhoneNumber/InputPhoneNumber';
import Modal from 'styleguide/Modal/Modal';
import Notification from 'styleguide/Notification/Notification';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import useModalRequestExampleApp from './useModalRequestExampleApp';

export default function ModalRequestExampleApp() {
	const { bind, environment, serverError, isWaitingForConfirmation, isButtonDisabled, isLoading, requestSms } =
		useModalRequestExampleApp();

	if (serverError) {
		return (
			<View style={{ paddingTop: '26px' }} data-testid="ModalRequestExampleApp">
				<Notification type="negative" title="Error" description={serverError} />
			</View>
		);
	}

	if (isWaitingForConfirmation) {
		return (
			<View data-testid="ModalRequestExampleApp">
				<Text margin="26px 0 0 0" type="h5">
					Demonstration App
				</Text>
				<Text margin="12px 0 0 0 "> An SMS has been sent to the provided number. </Text>

				<View
					style={{
						paddingTop: '26px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Pill type="button" level="primary" size="medium" onClick={Modal.hide}>
						Close
					</Pill>
				</View>
			</View>
		);
	}

	// TODO: For some reason the form is not submitted (Formik bug?) so we have to bind request sms method to the button.onClick callback
	return (
		<form onSubmit={requestSms} data-testid="ModalRequestExampleApp">
			<Text margin="26px 0 0 0" type="h5">
				Demonstration App
			</Text>
			<Text margin="12px 0 0 0 ">
				{`Enter a phone number and we'll send a link to download a demonstration app tied to your ${environment} environment.`}
			</Text>
			<View style={{ margin: '24px 0 12px' }}>
				<InputPhoneNumber bind={bind} selectOptions={['US']} />
			</View>
			{environment === 'sandbox' ? (
				<Text>When prompted, please enter '000000' as the verification code on your mobile device.</Text>
			) : null}
			<View style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
				<Pill
					type="submit"
					level="primary"
					size="medium"
					disabled={isButtonDisabled}
					data-testid="btn-request-link"
					loading={isLoading}
				>
					Send Link
				</Pill>
			</View>
		</form>
	);
}
