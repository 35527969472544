import Link from 'components/Link/Link';
import { LOAD_FUNDS_CONFIRM_ROUTE } from 'constants/routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import { IFinancialAccountType } from '../useLoadFunds';
import style from './LoadFundsLearnMore.module.scss';

export default function LoadFundsLearnMore({ accountType }: { accountType: IFinancialAccountType }) {
	const location = useLocation();

	// Learn more is hidden in the last step
	if (location.pathname === LOAD_FUNDS_CONFIRM_ROUTE) {
		return null;
	}

	return (
		<View className={style.LoadFundsLearnMore}>
			<Link external to="https://docs.aptopayments.com/docs/guides/Card%20Programs/funding">
				<Text className={style.LoadFundsLearnMore__text}>Learn more about loading funds into your {accountType}</Text>
			</Link>
		</View>
	);
}
