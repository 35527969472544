import React, { PropsWithChildren } from 'react';
import style from './Item.module.scss';

export interface ItemProps {
  padding?: React.CSSProperties['padding'];
  hasBorderBottom?: boolean;
}

export default function Item(props: PropsWithChildren<ItemProps>) {
  const className = props.hasBorderBottom ? style['Item--borderBottom'] : style.Item;

  return (
    <div className={className} style={{ padding: props.padding }}>
      {props.children}
    </div>
  );
}
