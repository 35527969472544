import classnames from 'classnames';
import useOutsideClickListener from 'hooks/useOutsideClickListener';
import React, { useRef } from 'react';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import ITeamRole, { ITeamRoleName } from 'types/team/ITeamRole';
import style from '../TeamRoleSelect.module.scss';

interface ITeamRoleDropdownProps {
	teamRoles: ITeamRole[];
	selectedTeamRoleName: ITeamRoleName;
	onTeamRoleNameClick: (teamRole: ITeamRoleName) => void;
	onRemoveTeamMemberClick: () => void;
	onClickOutside: () => void;
	isRemoveButtonDisabled: boolean;
}

export default function TeamRoleDropdown(props: ITeamRoleDropdownProps) {
	const ref = useRef<HTMLDivElement>(null);
	useOutsideClickListener(ref, props.onClickOutside);

	return (
		<div ref={ref}>
			<View className={style.TeamRoleSelect__dropdown} role="listbox" aria-labelledby="TeamRoleSelect__toggle">
				<View className={style.TeamRoleSelect__list}>
					{props.teamRoles.map((teamRole) => (
						<button
							type="button"
							key={teamRole.name}
							className={classnames(style.TeamRoleSelect__item, {
								[`${style['TeamRoleSelect__item--disabled']}`]: !teamRole.isEnabled,
							})}
							onClick={() => props.onTeamRoleNameClick(teamRole.name)}
							role="option"
							aria-selected={teamRole.name === props.selectedTeamRoleName}
							disabled={!teamRole.isEnabled}
							data-testid={`btn-role-${teamRole.name.toLowerCase()}`}
						>
							<Paragraph size="medium" color={teamRole.isEnabled ? 'contentPrimary' : 'disabled'}>
								{teamRole.name.toLowerCase()}
							</Paragraph>
						</button>
					))}
				</View>
				<button
					type="button"
					onClick={props.onRemoveTeamMemberClick}
					className={classnames(style.TeamRoleSelect__item, {
						[`${style['TeamRoleSelect__item--disabled']}`]: props.isRemoveButtonDisabled,
					})}
					disabled={props.isRemoveButtonDisabled}
				>
					<Label size="medium" color={props.isRemoveButtonDisabled ? 'disabled' : 'negative'}>
						Remove
					</Label>
				</button>
			</View>
		</div>
	);
}
