import { useFeature } from '@optimizely/react-sdk';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './Diagnostics.module.scss';
import logoImg from './images/apto-logo.svg';
import iconSad from './images/icon-sad.svg';
import iconTada from './images/icon-tada.svg';

export default function DiagnosticsPage() {
	const [areFeatureFlagsWorking] = useFeature('INTERNAL_TEST_FEATURE_FLAGS');

	if (!areFeatureFlagsWorking) {
		return (
			<main className={style['Diagnostics']} data-testid="test-ff-negative">
				<View className={style['Diagnostics__background--negative']}></View>
				<View className={style['Diagnostics__container--negative']}>
					<View className={style['Diagnostics__content']}>
						<View className={style['Diagnostics__logo']}>
							<img src={logoImg} alt="" />
						</View>
						<View>
							<img src={iconSad} alt="" />
						</View>
						<Display size="large" className={style['Diagnostics__title']}>
							Oh no!
						</Display>
						<Paragraph size="large" className={style.Diagnostics__copy} textAlign="left">
							Feature flags are not working on your browser.
						</Paragraph>
						<Paragraph size="large" className={style.Diagnostics__copy} textAlign="left" margin="0 0 40px">
							Read the Slab post to learn more.
						</Paragraph>
						<Pill
							external
							size="large"
							level="primary"
							to="https://aptopayments.slab.com/posts/feature-flags-lxf5xi85"
							type="button"
						>
							Open Slab post
						</Pill>
					</View>
					<View className={style['Diagnostics__cards--negative']}></View>
				</View>
			</main>
		);
	}

	return (
		<main className={style['Diagnostics']} data-testid="test-ff-positive">
			<View className={style['Diagnostics__background--positive']}></View>
			<View className={style['Diagnostics__container']}>
				<View className={style['Diagnostics__content']}>
					<View className={style['Diagnostics__logo']}>
						<img src={logoImg} alt="" />
					</View>
					<View>
						<img src={iconTada} alt="" />
					</View>
					<Display size="large" className={style['Diagnostics__title']}>
						Congrats!
					</Display>
					<Paragraph size="large" className={style.Diagnostics__copy} textAlign="left">
						Feature flags are working correctly on your browser.
					</Paragraph>
					<Paragraph size="large" className={style.Diagnostics__copy} textAlign="left">
						You can test new features.
					</Paragraph>
				</View>
				<View className={style['Diagnostics__cards--positive']}></View>
			</View>
		</main>
	);
}
