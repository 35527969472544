import { useFeature } from '@optimizely/react-sdk';
import createActivityDetector, { ActivityDetectorOptions } from 'activity-detector';
import ModalIdle from 'components/ModalIdle/ModalIdle';
import React, { useEffect } from 'react';
import Modal from 'styleguide/Modal/Modal';
import useSession from './useSession';

const TIME_TO_IDLE = 30 * 60 * 1000; // 30 minutes

export default function useIdle(
	options: ActivityDetectorOptions = { timeToIdle: TIME_TO_IDLE, initialState: 'active', inactivityEvents: [] }
) {
	const { isLoggedIn } = useSession();
	const [isSkipIdleEnabled] = useFeature('SKIP_IDLE_TIMEOUT');

	useEffect(
		function _detectUserIdle() {
			if (isSkipIdleEnabled) {
				return;
			}

			function _showModal() {
				Modal.show(<ModalIdle />);
			}
			const activityDetector = createActivityDetector(options);
			activityDetector.on('idle', () => (isLoggedIn ? _showModal() : null));
			return () => activityDetector.stop();
		},
		[isLoggedIn, options, isSkipIdleEnabled]
	);
}
