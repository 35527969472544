import classnames from 'classnames';
import React from 'react';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import ICardNetwork from 'types/financial/ICardNetwork';
import style from './LastFour.module.scss';

export interface ILastFourProps {
	bold?: boolean;
	description?: string;
	designKey?: IAptoCardDesignKey;
	isWhite?: boolean;
	/**
	 * The network to be displayed on the last four component.
	 * When undefined no network will be shown.
	 */
	network?: ICardNetwork;
	iconSize?: 'small' | 'medium' | 'large';
	value?: string;

	/**
	 * To distinguish between Apto-issued cards from regular debit cards.
	 */
	typeOfCard?: 'apto' | 'external' | 'placeholder';
}

export default function LastFour(props: ILastFourProps) {
	return (
		<View className={style.LastFour} data-testid="LastFour">
			{props.typeOfCard !== undefined ? (
				<View
					className={classnames(
						style[
							`LastFour__card--${_getCardImageClassModifier(
								props.typeOfCard,
								props.network,
								props.designKey
							).toLowerCase()}`
						],
						style[`LastFour__card--${props.iconSize}`]
					)}
					data-testid="LastFourCardIcon"
				></View>
			) : null}
			<View className={style.LastFour__data}>
				{props.value ? (
					<Text
						className={classnames(style.LastFour__text, {
							[`${style['LastFour__text--white']}`]: props.isWhite,
						})}
						weight={props.bold ? 600 : 400}
						type="small"
					>
						•••• {props.value}
					</Text>
				) : null}
				{props.description ? (
					<Text className={style.LastFour__description} type="footnote" color="body">
						{props.description}
					</Text>
				) : null}
			</View>
		</View>
	);
}

function _getCardImageClassModifier(
	typeOfCard: 'apto' | 'external' | 'placeholder',
	network?: ICardNetwork,
	designKey?: IAptoCardDesignKey
): string {
	switch (typeOfCard) {
		case 'apto':
			return `${network}--${designKey || 'black'}`;
		case 'external':
			return network ? network : 'placeholder';
		case 'placeholder':
			return 'placeholder';
	}
}
