import React from 'react';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import ITeamRole, { ITeamRoleName } from 'types/team/ITeamRole';
import TeamRoleDropdown from './components/TeamRoleDropdown';
import style from './TeamRoleSelect.module.scss';
import useTeamRoleSelect from './useTeamRoleSelect';

export interface ITeamRoleSelectProps {
	teamRoles: ITeamRole[];
	selectedTeamRoleName: ITeamRoleName;
	onTeamRoleNameClick: (teamRole: ITeamRoleName) => void;
	onRemoveTeamMemberClick: () => void;
	isRemoveButtonDisabled: boolean;
}

export default function TeamRoleSelect(props: ITeamRoleSelectProps) {
	const { isExpanded, setExpanded, handleChangeTeamRole, handleRemoveTeamRole } = useTeamRoleSelect(props);

	function handleClickOutside() {
		setExpanded(false);
	}

	return (
		<View className={style.TeamRoleSelect}>
			<button
				type="button"
				className={style.TeamRoleSelect__toggle}
				onClick={() => setExpanded(!isExpanded)}
				aria-haspopup="listbox"
				aria-label="Change team member role:"
				id="TeamRoleSelect__toggle"
				aria-expanded={isExpanded}
			>
				<Label size="small" color="contentPrimary">
					{props.selectedTeamRoleName}
				</Label>
			</button>
			{isExpanded ? (
				<TeamRoleDropdown
					teamRoles={props.teamRoles}
					selectedTeamRoleName={props.selectedTeamRoleName}
					onRemoveTeamMemberClick={handleRemoveTeamRole}
					onTeamRoleNameClick={handleChangeTeamRole}
					onClickOutside={handleClickOutside}
					isRemoveButtonDisabled={props.isRemoveButtonDisabled}
				/>
			) : null}
		</View>
	);
}
