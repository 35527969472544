import transactionsParser from 'api-client/transactions/transactions.parser';
import apiFetch from 'api-client/utils/fetch';
import toCamelcase from 'api-client/utils/toCamelCase';
import { ICurrency } from 'constants/currencies';
import paginationService from 'services/pagination.service';
import sessionService from 'services/session.service';
import IPaginationConfig from 'types/IPaginationConfig';
import IEnvironment from 'types/session/IEnvironment';
import accountsParser from './accounts.parser';
import IAccountDetailsResponse from './types/IAccountDetailsResponse';
import IListDeveloperTransactionsResponse from './types/IListDeveloperTransactionsResponse';
import ILoadFundsResponse from './types/ILoadFundsResponse';
import IPushFundsResponse from './types/IPushFundsResponse';

const ACCOUNTS_ENDPOINT = '/developer/settings/accounts';
const LOAD_FUNDING_ACCOUNT_ENDPOINT = '/developer/settings/accounts/funding_account/load';
const PUSH_FUNDS_ENDPOINT = '/developer/settings/accounts/funding_account/push';
const LOAD_BILLING_ACCOUNT_ENDPOINT = '/developer/settings/accounts/billing_account/load';
const AUTO_REFILL_ENDPOINT = '/developer/settings/accounts/billing_account/auto_refill';
const TRANSACTIONS_ENDPOINT = '/developer/settings/transactions';

interface IGetArgs {
	environment: IEnvironment;
}

async function get(args: IGetArgs): Promise<IAccountDetailsResponse> {
	return apiFetch(`${ACCOUNTS_ENDPOINT}?environment=${args.environment}`, 'GET').then(toCamelcase);
}

interface ILoadFundsArgs {
	amount: number;
	currency: ICurrency;
	endpoint: string;
	environment: IEnvironment;
	paymentSourceId: string;
}

async function _loadFunds(args: ILoadFundsArgs): Promise<ILoadFundsResponse> {
	return apiFetch(`${args.endpoint}?environment=${args.environment}`, 'POST', {
		amount: {
			currency: args.currency,
			amount: args.amount,
		},
		payment_source_id: args.paymentSourceId,
	}).then(accountsParser.parseLoadFundsResponse);
}

interface ILoadFundingAccountArgs {
	amount: number;
	currency: ICurrency;
	environment: IEnvironment;
	paymentSourceId: string;
}

async function loadFundingAccount(args: ILoadFundingAccountArgs): Promise<ILoadFundsResponse> {
	return _loadFunds({ ...args, endpoint: LOAD_FUNDING_ACCOUNT_ENDPOINT });
}

interface ILoadBillingAccountArgs {
	amount: number;
	currency: ICurrency;
	environment: IEnvironment;
	paymentSourceId: string;
}
async function loadBillingAccount(args: ILoadBillingAccountArgs): Promise<ILoadFundsResponse> {
	return _loadFunds({ ...args, endpoint: LOAD_BILLING_ACCOUNT_ENDPOINT });
}

async function updateAutoRefill(
	currency: ICurrency,
	amount: number,
	paymentSourceId: string
): Promise<ILoadFundsResponse> {
	return apiFetch(`${AUTO_REFILL_ENDPOINT}?environment=${sessionService.getEnvironment()}`, 'PUT', {
		amount: {
			currency: currency,
			amount: amount,
		},
		payment_source_id: paymentSourceId,
	}).then(accountsParser.parseLoadFundsResponse);
}

interface IPushFundsArgs {
	/**
	 * The APTO enviroment where the push funds operation will be performed
	 */
	environment: IEnvironment;
	/**
	 * The amount of money to push
	 */
	amount: number;
	/**
	 * The currency in which the amount is expresed
	 */
	currency: ICurrency;
	/**
	 * The id of the cards where the funds will be pushed
	 */
	cardId: string;
}

async function pushFunds(args: IPushFundsArgs): Promise<IPushFundsResponse> {
	return apiFetch(`${PUSH_FUNDS_ENDPOINT}?environment=${args.environment}`, 'POST', {
		amount: {
			amount: args.amount,
			currency: args.currency,
		},
		card_id: args.cardId,
	}).then(accountsParser.parsePushFundsResponse);
}

export interface IListDeveloperTransactionsArgs {
	/**
	 * Sandbox or Production.
	 */
	environment: IEnvironment;
	/**
	 * Pagination start and end config.
	 */
	paginationConfig?: IPaginationConfig;
}

/**
 * Returns a paginated array of all transactions associated with a developer program.
 *
 * @param args IListDeveloperTransactionsArgs
 */
async function listDeveloperTransactions(
	args: IListDeveloperTransactionsArgs
): Promise<IListDeveloperTransactionsResponse> {
	const params = paginationService.getPaginationParams(args.paginationConfig);

	params.append('environment', args.environment);

	return apiFetch(`${TRANSACTIONS_ENDPOINT}?${params.toString()}`, 'GET').then(transactionsParser.parseTransactions);
}

export default {
	get,
	listDeveloperTransactions,
	loadBillingAccount,
	loadFundingAccount,
	pushFunds,
	updateAutoRefill,
};
