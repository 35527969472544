import { countryInfoHash, ICountryInfo } from './countryInfo';

function getFlagEmoji(countryCode: string): string {
  return countryInfoHash[countryCode] ? countryInfoHash[countryCode].flag : '';
}

function getCountryInfo(countryCode: string): ICountryInfo | null {
  return countryInfoHash[countryCode] ? countryInfoHash[countryCode] : null;
}

export default {
  getCountryInfo,
  getFlagEmoji,
};
