import mocksService from 'mocks/mocks.service';
import dummy_get_one_signed_payloads_response from 'mocks/serverResponses/api_keys/dummy_get_one_signed_payloads_response';
import { rest } from 'msw';

export default [
	rest.get('https://api.stg.aptopayments.com/v1/developer/api_keys', (req: any, res: any, ctx: any) => {
		return res(ctx.json(_getApiKey()));
	}),
	rest.get('https://api.stg.aptopayments.com/v1/developer/signed_payloads', (req: any, res: any, ctx: any) => {
		return res(ctx.json(dummy_get_one_signed_payloads_response));
	}),
];

function _createApiKey(id: number) {
	return {
		key_id: `key_9376871263${id}`,
		description: 'Test API key',
		api_client_id: `938194687234kjhksd${id}`,
		status: 'active',
		created: '2016-10-19T23:20:17.000Z',
		updated: '2016-10-19T23:20:17.000Z',
	};
}

function _getApiKey() {
	return {
		mobile_api_key: 'WGy/b9Rr8kYTkaaS2IkUIsnI4Tl3s0TJWgZB1+GD91fI1aHZ6stcJKquPbt2w00d',
		core_api_keys: {
			data: mocksService.createItems(_createApiKey, 0, 4),
		},
		page: 0,
		rows: 1,
		has_more: false,
		total_rows: 11,
	};
}
