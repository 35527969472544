import { useFeature } from '@optimizely/react-sdk';
import { ACTIVATE_ACCOUNT_BILLING_ROUTE, ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE } from 'constants/routes';
import useApplicationId from 'hooks/useApplicationId';
import { useConfirmLegalMutation } from 'queries/activateAccount';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useLegalStepWithApplicationId() {
	// SafeCast: This is rendered inside a WithApplicationId component so we know this is a valid string
	const applicationId = useApplicationId() as string;
	const navigate = useNavigate();
	const [isComplianceStepEnabled] = useFeature('APPLICATION_COMPLIANCE');

	const confirmLegalMutation = useConfirmLegalMutation();

	useEffect(() => {
		handleConfirmAllAgreementsAccepted();
	}, []); // eslint-disable-line

	function handleConfirmAllAgreementsAccepted() {
		return confirmLegalMutation.mutate(applicationId, {
			onSettled: () =>
				navigate(isComplianceStepEnabled ? ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE : ACTIVATE_ACCOUNT_BILLING_ROUTE),
		});
	}
}
