import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './AddPublicKeySection.module.scss';

interface IAddPublicKeySectionProps {
	onAdd: () => void;
}

export default function AddPublicKeySection(props: IAddPublicKeySectionProps) {
	return (
		<section>
			<View className={style.AddPublicKeySection__heading}>
				<Label size="medium" fontWeight="600" margin="0 20px 0 0">
					Signed Payloads
				</Label>
				<Pill data-testid="add-signed-payloads-btn" level="primary" size="small" onClick={props.onAdd}>
					Add
				</Pill>
			</View>
			<View className={style.AddPublicKeySection__body}>
				<Paragraph size="medium" color="contentPrimary" textAlign="center">
					You have no public key.
				</Paragraph>
			</View>
		</section>
	);
}
