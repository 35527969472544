import classNames from 'classnames';
import { LOAD_FUNDS_CONFIRM_ROUTE, LOAD_FUNDS_LOAD_ROUTE, LOAD_FUNDS_REVIEW_ROUTE } from 'constants/routes';
import { NavLink, useLocation } from 'react-router-dom';
import style from './LoadFundsSidebar.module.scss';

export default function LoadFundsSidebar({
	accountType,
}: {
	accountType: 'Developer Funding Account' | 'Billing Account';
}) {
	const location = useLocation();

	// Last step has no sidebar
	if (location.pathname === LOAD_FUNDS_CONFIRM_ROUTE) {
		return null;
	}

	return (
		<nav className={style.LoadFundsSidebar}>
			<NavLink className={_getClassName} to={LOAD_FUNDS_LOAD_ROUTE}>
				Load {accountType}
			</NavLink>
			<NavLink className={_getClassName} to={LOAD_FUNDS_REVIEW_ROUTE}>
				Review
			</NavLink>
		</nav>
	);
}

function _getClassName({ isActive }: { isActive: boolean }): string {
	return isActive
		? classNames(style.LoadFundsSidebar__link, style['LoadFundsSidebar__link--isActive'])
		: style.LoadFundsSidebar__link;
}
