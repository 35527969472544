import mocksService from '../mocks.service';

function get(req: any, res: any, ctx: any) {
	return res(
		ctx.json({
			account_owner: true,
			developer_accounts: mocksService.createItems(_createDeveloperAccount, 0, 3),
		})
	);
}

export default {
	get,
};

const dummyCompanies = ['ACME', 'Dark web debit cards', 'AirBnE – Vacation rentals for felons', 'Cardz 4 kidz'];
function _createDeveloperAccount(id: number) {
	return {
		id: `att_${id}`,
		company_name: dummyCompanies[id],
		default: id === 0,
	};
}
