import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import React from 'react';

interface ITransactionsHistoryErrorProps {
	serverError: string;
}

export default function TransactionsHistoryError(props: ITransactionsHistoryErrorProps) {
	return (
		<PaddingContainer>
			<Notification type="negative" margin="24px 0" title="Error" description={props.serverError} />
		</PaddingContainer>
	);
}
