import React from 'react';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import style from './Company.module.scss';

interface ICompanyProps {
	name: string;
	isTitle?: boolean;
}

export default function Company(props: ICompanyProps) {
	const intitial = props.name[0];

	return (
		<View className={style.Company}>
			<View className={style.Company__logo}>
				<span className={style.Company__char}>{intitial}</span>
			</View>
			{props.isTitle ? (
				<Heading size="large" tag="h1" className={style.Company__name}>
					{props.name}
				</Heading>
			) : (
				<Label size="large" fontWeight="600" className={style.Company__name}>
					{props.name}
				</Label>
			)}
		</View>
	);
}
