import modalAccountActivationService from 'components/ModalAccountActivation/modalAccountActivation.service';
import useSession from 'hooks/useSession';
import { QK_API_KEYS } from 'queries/apiKeys.queries';
import { useQueryClient } from 'react-query';
import Modal from 'styleguide/Modal/Modal';
import ModalAddApiKeys from './components/ModalAddApiKeys/ModalAddApiKeys';
import ModalDeleteApiKey from './components/ModalDeleteApiKey/ModalDeleteApiKey';

export default function useCredentialSectionSuccess() {
	const { accessLevel, environment, isProduction, lastApplicationId, lastApplicationStatus } = useSession();

	const queryClient = useQueryClient();

	async function handleApiKeyDeleteClicked(id: string) {
		Modal.show(<ModalDeleteApiKey apiKeyId={id} onApiKeyRemoved={Modal.hide} onCancelClick={Modal.hide} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'API keys',
		});
	}

	function showModalApiKeys() {
		Modal.show(<ModalAddApiKeys onCredentialGenerated={() => queryClient.invalidateQueries(QK_API_KEYS)} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'API keys',
		});
	}

	return {
		accessLevel,
		environment,
		handleApiKeyDeleteClicked,
		isProduction,
		lastApplicationStatus,
		showModalAccountActivation: () =>
			modalAccountActivationService.showModalAccountActivation(lastApplicationStatus, lastApplicationId),
		showModalApiKeys,
	};
}
