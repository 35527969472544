import classnames from 'classnames';
import { ICurrency } from 'constants/currencies';
import useSession from 'hooks/useSession';
import Grid from 'styleguide/Grid/Grid/Grid';
import Item from 'styleguide/Grid/Item/Item';
import Heading from 'styleguide/Type/Heading/Heading';
import Label from 'styleguide/Type/Label/Label';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './OverviewFundingAccountSuccess.module.scss';

export interface IFundingAccountProps {
	amount: number;
	currency: ICurrency;
}

export default function OverviewFundingAccountSuccess(props: IFundingAccountProps) {
	const { isProduction } = useSession();

	return (
		<Grid
			cols="2"
			className={classnames(
				style.OverviewFundingAccount,
				style[`OverviewFundingAccount--${isProduction ? 'production' : 'sandbox'}`]
			)}
		>
			<Item colWidth="1" className={style.OverviewFundingAccount__details}>
				<View className={style.OverviewFundingAccountHeading}>
					<Label
						size="small"
						fontWeight="600"
						margin="0 0 8px 0"
						className={style.OverviewFundingAccountHeading__label}
					>
						Developer Funding Account Balance
					</Label>
				</View>
				<Heading tag="h5" size="small">
					{new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: props.currency,
					}).format(props.amount)}
				</Heading>
			</Item>
			{!isProduction ? (
				<Item colWidth="1" className={style.OverviewFundingAccount__sandboxNote}>
					<Paragraph size="small" color="contentPrimary">
						We loaded your program balance with $20,000 worth of test funds to use in sandbox.
					</Paragraph>
				</Item>
			) : null}
		</Grid>
	);
}
