import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalLogout.module.scss';

export interface IModalLogoutProps {
	onLogout: () => void;
}

export default function ModalLogout(props: IModalLogoutProps) {
	return (
		<View className={style.ModalLogout} data-testid="modal-logout">
			<Heading size="small" color="contentPrimary" tag="h1" margin="0 0 12px 0">
				Logging out
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin="0 0 24px 0">
				Are you sure you want to log out?
			</Paragraph>

			<View className={style.ModalLogout__footer}>
				<Pill level="primary" size="medium" onClick={props.onLogout}>
					Log out
				</Pill>
			</View>
		</View>
	);
}
