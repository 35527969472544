import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, useState } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import style from './ExpirationDate.module.scss';
import { formatEventTarget } from './expirationDate.service';

export interface IExpirationDateProps extends ICommonInputProps {
  type: 'exp-date';
  value: string | number;
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function ExpirationDate(props: IExpirationDateProps) {
  let cnContent = '';
  let cnError = '';
  let cnDisabled = '';

  if (props.value) {
    cnContent += 'has-content';
  }
  if (props.error) {
    cnError += 'has-error';
  }
  if (props.disabled) {
    cnDisabled += 'is-disabled';
  }

  const [previousValue, setPreviousValue] = useState('');

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!props.onChange) {
      return;
    }
    const newValue = formatEventTarget(e.currentTarget.value, previousValue);
    setPreviousValue(newValue);
    e.currentTarget.value = newValue;
    props.onChange(e);
  }

  return (
    <div
      style={{ margin: props.margin }}
      className={classnames(style.ExpirationDate, style[cnContent], style[cnError], style[cnDisabled])}
    >
      <input
        className={style['ExpirationDate__input--text']}
        disabled={props.disabled}
        autoComplete={props.autocomplete}
        pattern={props.pattern}
        required={props.required}
        autoFocus={props.autofocus}
        id={props.id}
        type="text"
        name={props.name}
        value={props.value}
        onChange={handleChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      <label className={style.ExpirationDate__label} htmlFor={props.id}>
        {' '}
        {props.label}{' '}
      </label>
      {props.error ? <span className={style.ExpirationDate__error}>{props.error}</span> : null}
    </div>
  );
}
