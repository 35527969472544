import IWebhook from 'types/IWebhook';
import IEnvironment from 'types/session/IEnvironment';
import apiFetch from '../utils/fetch';
import toCamelCase from '../utils/toCamelCase';

const EVENT_RECIPIENT_ENDPOINT = '/developer/event_recipients';

interface IReadWebhooksArgs {
	environment: IEnvironment;
}
export interface IReadWebhooksResponse {
	eventRecipients: IWebhook[];
	hasMore: boolean;
	total: number;
}

function _parseDates(raw: any): IReadWebhooksResponse {
	return { ...raw, eventRecipients: raw.eventRecipients.map(_parseDatesInWebhook) };
}

function _parseDatesInWebhook(camelCaseWebhook: any): IWebhook {
	return {
		...camelCaseWebhook,
		createdAt: new Date(camelCaseWebhook.createdAt),
		updatedAt: new Date(camelCaseWebhook.updatedAt),
	};
}

function _unwrap(raw: any): IReadWebhooksResponse {
	return { ...raw, eventRecipients: raw.event_recipients.map(_unwrapRubyPayload) };
}

function _unwrapRubyPayload(wrapper: any): IWebhook {
	return wrapper.event_recipient;
}

async function read(args: IReadWebhooksArgs): Promise<IReadWebhooksResponse> {
	return apiFetch(`${EVENT_RECIPIENT_ENDPOINT}?environment=${args.environment}`, 'GET')
		.then(_unwrap)
		.then(toCamelCase)
		.then(_parseDates);
}

interface IRemoveWebhookArgs {
	id: string;
	environment: IEnvironment;
}

async function remove(args: IRemoveWebhookArgs) {
	return apiFetch(`${EVENT_RECIPIENT_ENDPOINT}/${args.id}?environment=${args.environment}`, 'DELETE');
}

export interface INewWebhook {
	description: string;
	url: string;
	authentication: {
		// TODO: We only support one type this at the moment, need to update login when more types added
		auth_type: string;
		data: {
			username: string;
			password: string;
		};
	};
	event_types: string[];
}

export interface ICreateWebhookArgs {
	webhook: INewWebhook;
	environment: IEnvironment;
}

async function create(args: ICreateWebhookArgs): Promise<IWebhook> {
	return apiFetch(`${EVENT_RECIPIENT_ENDPOINT}`, 'POST', {
		...args.webhook,
		environment: args.environment,
	})
		.then(toCamelCase)
		.then(_parseDatesInWebhook);
}

export default {
	create,
	read,
	remove,
};
