import ILoadFundsResponse from './types/ILoadFundsResponse';
import IPushFundsResponse from './types/IPushFundsResponse';

function parseLoadFundsResponse(res: any): ILoadFundsResponse {
	return {
		id: res.id,
		status: res.status,
		amount: {
			currency: res.amount.currency,
			amount: res.amount.amount,
		},
		source: {
			// Warning: The server is returning source instead a nested "payment_source"
			paymentSource: {
				cardNetwork: res.source.card_network,
				cardType: res.source.card_type,
				createdAt: new Date(res.source.created_at),
				description: res.source.description,
				id: res.source.id,
				lastFour: res.source.last_four,
				postalCode: res.source.postal_code,
				preferenceTypes: res.source.preference_types,
				type: res.source.type,
				updatedAt: new Date(res.source.updated_at),
			},
		},
		destinationId: res.destination_id,
		createdAt: new Date(res.created_at),
	};
}

function parsePushFundsResponse(res: any): IPushFundsResponse {
	return {
		id: res.id,
		status: res.status,
		amount: {
			currency: res.amount.currency,
			amount: res.amount.amount,
		},
		destination: {
			lastFour: String(res.destination.last_four),
			designKey: res.destination.design_key,
			network: res.destination.network,
		},
		createdAt: new Date(res.created_at),
	};
}

export default {
	parseLoadFundsResponse,
	parsePushFundsResponse,
};
