import apiClientService from 'api-client/apiclient.service';
import {
	IAddPaymentSourceArgs,
	IEditPaymentSourceArgs,
	IEditPaymentSourceResponse,
	IRemovePaymentSourceArgs,
} from 'api-client/paymentSources/paymentSources';
import useSession from 'hooks/useSession';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import IPaymentMethod, { IPaymentMethodCard } from 'types/IPaymentMethod';
import IError from 'types/network/IError';

export const QK_PAYMENT_SOURCES = 'payment_sources';

interface IUseListPaymentSourcesQueryArgs extends UseQueryOptions<IPaymentMethod[], IError> {}

export function useListPaymentSourcesQuery(args?: IUseListPaymentSourcesQueryArgs) {
	const { environment } = useSession();

	return useQuery<IPaymentMethod[], IError>({
		queryKey: [environment, QK_PAYMENT_SOURCES, 'list'],
		queryFn: () => apiClientService.paymentSources.read({ environment }).then((r) => r.paymentSources),
		...args,
	});
}

interface IUseUpdatePaymentSourceMutationArgs
	extends UseMutationOptions<IEditPaymentSourceResponse, IError, Omit<IEditPaymentSourceArgs, 'environment'>> {}

export function useUpdatePaymentSourceMutation(args?: IUseUpdatePaymentSourceMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<IEditPaymentSourceResponse, IError, Omit<IEditPaymentSourceArgs, 'environment'>>({
		mutationFn: (params) => {
			return apiClientService.paymentSources.put({ ...params, environment });
		},
		onSuccess: (data, variables, context) => {
			// The backend will update every payment method to mark only one as preferred
			queryClient.invalidateQueries([environment, QK_PAYMENT_SOURCES]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseAddPaymentMethodCardMutationArgs
	extends UseMutationOptions<IPaymentMethodCard, IError, Omit<IAddPaymentSourceArgs, 'environment'>> {}

export function useAddPaymentMethodCardMutation(args?: IUseAddPaymentMethodCardMutationArgs) {
	const queryClient = useQueryClient();
	const { environment } = useSession();

	return useMutation<IPaymentMethodCard, IError, Omit<IAddPaymentSourceArgs, 'environment'>>({
		mutationFn: (params) => apiClientService.paymentSources.addCard({ ...params, environment }),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_PAYMENT_SOURCES]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}

interface IUseDeletePaymentMethodMutationArgs
	extends UseMutationOptions<void, IError, Omit<IRemovePaymentSourceArgs, 'environment'>> {}

export function useRemovePaymentMethodMutation(args?: IUseDeletePaymentMethodMutationArgs) {
	const { environment } = useSession();
	const queryClient = useQueryClient();

	return useMutation<void, IError, Omit<IRemovePaymentSourceArgs, 'environment'>>({
		mutationFn: (params) =>
			apiClientService.paymentSources.remove({
				id: params.id,
				environment,
			}),
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries([environment, QK_PAYMENT_SOURCES]);
			args?.onSuccess?.(data, variables, context);
		},
		...args,
	});
}
