import React from 'react';
import { IColumn } from '../Table';
import TableCell from './TableCell';

export interface ITableRowProps {
  onClick?: (item: any) => void;
  columns: IColumn[];
  item: any;
}

export default function TableRow(props: ITableRowProps) {
  return (
    <tr
      style={{ cursor: props.onClick ? 'pointer' : 'initial' }}
      onClick={() => props.onClick && props.onClick(props.item)}
    >
      <td></td>
      {props.columns.map((column: IColumn) => (
        <TableCell key={column.key} renderer={column.renderer} value={props.item[column.key]} item={props.item} />
      ))}
      <td></td>
    </tr>
  );
}
