import toCamelCase from 'api-client/utils/toCamelCase';
import IInvitationDetails, { IInvitationStatus } from 'types/invitations/IInvitationDetails';
import { ITeamRoleName } from 'types/team/ITeamRole';
import apiFetch from '../utils/fetch';
import invitationsParser from './invitations.parser';

const INVITATIONS_ENDPOINT = '/developer/settings/invitations';
const ACCEPT_INVITATION_ENDPOINT = '/developer/invitations/accept';

async function invite(recipients: string[], role: ITeamRoleName) {
	return apiFetch(INVITATIONS_ENDPOINT, 'POST', {
		recipients,
		role,
	});
}

async function accept(
	invitationId: string,
	verificationId: string,
	secret: string
): Promise<IAcceptInvitationsResponse> {
	return apiFetch(ACCEPT_INVITATION_ENDPOINT, 'POST', {
		id: invitationId,
		verification_id: verificationId,
		secret,
	}).then(toCamelCase);
}

export interface IListInvitationsArgs {
	status?: IInvitationStatus;
}

async function list({ status }: IListInvitationsArgs): Promise<IListInvitationsResponse> {
	const endpoint = status ? `${INVITATIONS_ENDPOINT}?status=${status}` : INVITATIONS_ENDPOINT;
	return apiFetch(endpoint, 'GET').then(invitationsParser.parseInvitationsList);
}

export interface IListInvitationsResponse {
	invitations: IInvitationDetails[];
	hasMore: boolean;
	total: number;
}

export interface IAcceptInvitationsResponse {
	invitee: {
		exists: boolean;
		emailAddress: string;
	};
	developerAccount: {
		companyName: string;
	};
}

export default {
	accept,
	invite,
	list,
};
