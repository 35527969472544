import { IGetCardDesignResponse } from 'api-client/activateAccount/activateAccount';
import useApplicationId from 'hooks/useApplicationId';
import { useGetCardDesignQuery } from 'queries/activateAccount';

export default function useCardDesignWithApplicationId() {
	// SafeCast: this is rendered using WithApplicationId
	const applicationId = useApplicationId() as string;

	const cardDesignQuery = useGetCardDesignQuery({
		applicationId: applicationId,
		// Prevent background requests when the user focus the page
		refetchOnWindowFocus: false,
	});

	return {
		props: {
			//SafeCast: This data is only used when status is success
			storedValues: cardDesignQuery.data as IGetCardDesignResponse,
			applicationId: applicationId,
		},
		status: cardDesignQuery.status,
	};
}
