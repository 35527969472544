import React from 'react';
import Modal from 'styleguide/Modal/Modal';
import ModalDeactivateAccount from './ModalDeactivateAccount/ModalDeactivateAccount';

export default function useBusiness() {
	const bussinessInfo = {
		accountName: 'Acme',
		address: '32 Main St, San Francisco, CA 94102',
		email: 'lalexwu@acme-co.com',
		legalName: 'Acme.Co',
		website: 'www.acme-co.com',
	};

	function showModalDeactivateAccount() {
		Modal.show(<ModalDeactivateAccount onCancelClick={Modal.hide} />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Deactivate account',
		});
	}

	return {
		bussinessInfo,
		showModalDeactivateAccount,
	};
}
