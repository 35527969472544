import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import style from './Pill.module.scss';

export interface IPillProps {
  autofocus?: boolean;
  className?: string;
  disabled?: boolean;
  external?: boolean;
  icon?: 'arrow';
  id?: string;
  level: 'primary' | 'secondary' | 'tertiary' | 'cta-link' | 'inline-link';
  loading?: boolean;
  onClick?: (event: any) => void;
  /**
   * Outlined versions of the `secondary` and `tertiary` levels.
   */
  outlined?: boolean;

  /**
   * Replace the current history state instead of adding a new url into the stack.
   */
  replace?: boolean;
  size: 'large' | 'medium' | 'small';
  ['data-testid']?: string;
  to?: string;
  type?: 'submit' | 'button' | 'link';
}

export default function Pill(props: PropsWithChildren<IPillProps>) {
  const className = _getClassName(props);

  if (props.type === 'link' || props.external || props.to) {
    if (props.external) {
      return (
        <a
          className={classnames(className, props.className)}
          href={props.to}
          target="blank"
          rel="noopener noreferer"
          data-testid={props['data-testid']}
        >
          {props.children}
        </a>
      );
    }
    return (
      <Link
        {...props}
        className={classnames(className, props.className)}
        to={props.to as string}
        data-testid={props['data-testid']}
      />
    );
  }

  if (props.loading) {
    return (
      <button
        disabled
        data-testid={props['data-testid']}
        className={classnames(className, style.isLoading, props.className)}
        id={props.id}
      >
        <Loader level={props.level} />
        <span className={style.Pill__text}>{props.children}</span>
      </button>
    );
  }

  return (
    <button
      type={props.type}
      className={classnames(className, props.className)}
      id={props.id}
      onClick={props.onClick}
      autoFocus={props.autofocus}
      data-testid={props['data-testid']}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

function _getClassName(props: IPillProps) {
  let cnIcon = '';
  let cnLevel = '';
  let cnsize = '';

  if (props.icon) {
    cnIcon += _getIconClass(props.icon);
  }

  if (props.level) {
    cnLevel += _getLevelClass(props.level, props.outlined);
  }

  if (props.size) {
    cnsize += _getSizeClass(props.size);
  }

  return classnames(style.Pill, cnIcon, cnLevel, cnsize, {
    [`${style.isDisabled}`]: props.disabled,
  });
}

function _getLevelClass(level: string, outlined?: boolean) {
  switch (level) {
    case 'secondary':
      return outlined ? style['Pill--secondary-outlined'] : style['Pill--secondary'];
    case 'tertiary':
      return outlined ? style['Pill--tertiary-outlined'] : style['Pill--tertiary'];
    case 'cta-link':
      return style['Pill--ctaLink'];
    case 'inline-link':
      return style['Pill--inlineLink'];
    default:
      return '';
  }
}

function _getSizeClass(size: string) {
  switch (size) {
    case 'medium':
      return style['Pill--medium'];
    case 'small':
      return style['Pill--small'];
    default:
      return '';
  }
}

function _getIconClass(icon: string) {
  switch (icon) {
    case 'arrow':
      return style['Pill--hasArrow'];
    default:
      return '';
  }
}
