// https://apto-api-specs.netlify.app/private/stargateapi#operation/get_signed_payload_key_list
export default {
	public_keys: [
		{
			key_id: 'dummy_first_key_id',
			name: 'dummy_first_key_name',
			public_key:
				'-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzicO/CoKdlcqUZ/i86kH XXV6XBIxiadgVe96QcZcj7n3h3pA6aNIP7OvwL3l73eTPe2Oiz8Jd10rzmai+XEpFci2M1PGofvApb9Db0dWWV+Mx6 i7tNexZYvKDGao3p5Q9vbeoOJr1o+y8b4pxJIEAObCcWGjugKneya/k1KPqnpt5BIH1ddyieiGLDu4bIa/NuLkz3Jp semPERlaQY3yXmrtJyX2iMYnXbj/2BOh14FtRHf4ROf2QTXO+qiMVBO/UVu1rGsjRHIDWzWwhCPma03RlmV2gNffIQ zgrV9qIa+jA8ktr0rs5UW0eEBYLis+9yEZ+1wCynIGumbCxSDqHwIDAQAB-----END PUBLIC KEY-----',
			created: '2016-10-19T23:20:17.000Z',
		},
	],
};
