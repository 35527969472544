interface IBuildSearchParamsArgs {
	params: Record<string, string | number>;
	queryString?: string;
}

/**
 * Helper that builds an URLSearchParams preserving the existing values.
 */
function build(args: IBuildSearchParamsArgs): string {
	const params = args.queryString ? new URLSearchParams(args.queryString) : new URLSearchParams();

	for (const key in args.params) {
		const value = args.params[key];

		if (!value) {
			params.delete(key);
			break;
		}

		params.set(key, String(value));
	}

	return params.toString();
}

export default {
	build,
};
