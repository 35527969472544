import apiclientService from 'api-client/apiclient.service';
import { useEffect } from 'react';
import IDeveloperAccount from 'types/IDeveloperAccount';
import IServerRequestState from 'types/network/IServerRequest';
import usePureState from 'utils/usePureState';
import { ITeamsListProps } from './TeamsList';

interface IState extends IServerRequestState {
	teams: IDeveloperAccount[];
}

export default function useCompanyPanel(props: ITeamsListProps) {
	const { state, dispatch } = usePureState<IState>({
		teams: [],
		requestStatus: 'IDLE',
		serverError: '',
	});

	useEffect(
		function fetchTeams() {
			dispatch({ requestStatus: 'PENDING' });
			apiclientService.developerAccounts
				.list()
				.then((res) => {
					return res.developerAccounts.filter((team: IDeveloperAccount) => !team.default);
				})
				.then((teams: IDeveloperAccount[]) => {
					dispatch({ requestStatus: 'SUCCESS', teams });
				})
				.catch((err) => {
					dispatch({ requestStatus: 'FAILED', teams: [], serverError: err });
				});
		},
		[dispatch, props.companyName]
	);

	return {
		props: {
			switchTeam: props.handleSwitchCompany,
			teams: state.teams,
		},
		status: state.requestStatus,
	};
}
