import { LOGIN_ROUTE } from 'constants/routes';
import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import imgDone from './done.svg';
import style from './SuccessfulApplication.module.scss';
import useSuccessfulApplication from './useSuccessfulApplication';

const Confetti = lazy(() => import('confetti-react'));

const CONFETTI_OPTIONS = {
	opacity: 0.9,
	tweenDuration: 10000,
	numberOfPieces: 1000,
	initialVelocityY: 50,
	recycle: false,
	colors: [
		'#384E90',
		'#3F5C9A',
		'#42BBCE',
		'#58B290',
		'#59B146',
		'#7D2A79',
		'#7F488B',
		'#8EC240',
		'#987AA9',
		'#BB8EF3',
		'#C9D738',
		'#D1242A',
		'#D4167C',
		'#DB2C6F',
		'#F1AA49',
		'#FCEC26',
	],
};

export default function SuccessfulApplicationPage() {
	const { handleGoToProduction, isLoggedIn } = useSuccessfulApplication();

	if (!isLoggedIn) {
		// TODO: Save route and redirect to saved route once logged in
		return <Navigate to={LOGIN_ROUTE} />;
	}

	return (
		<>
			<Suspense fallback={null}>
				<Confetti {...CONFETTI_OPTIONS} />
			</Suspense>

			<main className={style.SuccessfulApplicationPage}>
				<img alt="" src={imgDone} width="80px" height="80px" />
				<Display size="x-small" tag="h1" margin="40px auto 12px" textAlign="center">
					You're all set!
				</Display>
				<Paragraph size="medium" color="contentPrimary" margin="12px auto 40px" textAlign="center">
					Your program has been configured! You are ready to generate production API keys and use our SDKs to start
					onboarding cardholders and issuing cards.
				</Paragraph>
				<Pill level="primary" size="medium" onClick={handleGoToProduction}>
					Go to production
				</Pill>
			</main>
		</>
	);
}
