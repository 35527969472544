import ModalRequestExampleApp from 'components/ModalRequestExampleApp/ModalRequestExampleApp';
import useSession from 'hooks/useSession';
import React from 'react';
import Modal from 'styleguide/Modal/Modal';

export default function useOverview() {
	const { isProduction } = useSession();

	function showModalRequestExampleApp() {
		Modal.show(<ModalRequestExampleApp />, {
			canBeClosedOnClickOut: false,
			ariaTitle: 'Download example app',
		});
	}

	return {
		isProduction,
		showModalRequestExampleApp,
	};
}
