import React from 'react';

export interface IEmojiProps {
  label?: string;
  symbol: string;
  fontSize?: React.CSSProperties['fontSize'];
}

const Emoji: React.FC<IEmojiProps> = (props) => {
  return (
    <span
      className="emoji"
      style={{ fontSize: props.fontSize }}
      role="img"
      aria-label={props.label ? props.label : ''}
      aria-hidden={props.label ? 'false' : 'true'}
    >
      {props.symbol}
    </span>
  );
};

export default Emoji;
