// https://private-api-specs.s3-us-west-1.amazonaws.com/private/StargateAPI.html#operation/get_agreements
export default {
	apto_privacy_policy: 'dummy_apto_privacy_policy',
	apto_terms_of_service: 'dummy_apto_terms_of_service',
	bank_privacy_policy: 'dummy_bank_privacy_policy',
	bank_terms_agreement: 'dummy_bank_terms_agreement',
	card_program_agreement: 'dummy_card_program_agreement',
	instant_issuance_pricing: 'dummy_instant_issuance_pricing',
};

export const AGREEMENTS_ENDPOINT_URL = 'https://api.stg.aptopayments.com/v1/developer/agreements';
