import apiclientService from 'api-client/apiclient.service';
import useSession from 'hooks/useSession';
import { QK_LIST_INVITATIONS } from 'queries/invitations';
import { useQueryClient } from 'react-query';
import validationService from 'services/validationService/validation.service';
import IServerRequestState from 'types/network/IServerRequest';
import useForm from 'utils/useForm';
import usePureState from 'utils/usePureState';

export interface IInviteMemberFields {
	email: string;
}

interface IState extends IServerRequestState {
	step: 'initial' | 'sent';
}
export default function useModalInviteMember() {
	const { companyName } = useSession();
	const queryClient = useQueryClient();
	const { state, dispatch } = usePureState<IState>({
		requestStatus: 'IDLE',
		serverError: '',
		step: 'initial',
	});

	const { bind, isSubmitting, isButtonEnabled, submitForm, values, resetForm } = useForm<IInviteMemberFields>({
		initialValues: { email: '' },
		onSubmit: onInviteSubmit,
		validate: validationService.createValidator({
			email: [validationService.email, validationService.required],
		}),
	});

	function onInviteSubmit(values: IInviteMemberFields) {
		dispatch({ requestStatus: 'PENDING', serverError: '' });
		return apiclientService.invitations
			.invite([values.email], 'admin')
			.then(() => {
				dispatch({ requestStatus: 'SUCCESS', step: 'sent' });
			})
			.catch((err) => {
				dispatch({ requestStatus: 'FAILED', serverError: err.message });
			})
			.finally(() => {
				queryClient.invalidateQueries(QK_LIST_INVITATIONS);
			});
	}

	function showModalInviteMember() {
		resetForm();
		dispatch({ requestStatus: 'IDLE', step: 'initial' });
	}

	return {
		bind,
		isSubmitting,
		isButtonDisabled: !isButtonEnabled,
		serverError: state.serverError,
		submitForm,
		step: state.step,
		companyName,
		email: values.email,
		showModalInviteMember,
	};
}
