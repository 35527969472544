import { IListDeveloperTransactionsArgs } from 'api-client/accounts/accounts';
import IAccountDetailsResponse from 'api-client/accounts/types/IAccountDetailsResponse';
import IListDeveloperTransactionsResponse from 'api-client/accounts/types/IListDeveloperTransactionsResponse';
import apiClientService from 'api-client/apiclient.service';
import { queryForItemsInThePreviousPage } from 'hooks/usePagination';
import useSession from 'hooks/useSession';
import { useQuery, UseQueryOptions } from 'react-query';
import IError from 'types/network/IError';

export const QK_DEV_TRANSACTIONS = 'DEV_TRANSACTION';
export const QK_DEV_ACCOUNTS = 'DEV_ACCOUNTS';

interface IUseListDeveloperTransactionsQueryArgs
	extends Omit<IListDeveloperTransactionsArgs, 'environment'>,
		UseQueryOptions<IListDeveloperTransactionsQueryResponse, IError> {}
interface IListDeveloperTransactionsQueryResponse extends IListDeveloperTransactionsResponse {
	hasMoreBefore: boolean;
}

export function useListDeveloperTransactionsQuery(args?: IUseListDeveloperTransactionsQueryArgs) {
	const { environment } = useSession();

	return useQuery<IListDeveloperTransactionsQueryResponse, IError>({
		queryKey: [environment, QK_DEV_TRANSACTIONS, 'list', args?.paginationConfig],
		queryFn: async () => {
			const res = await apiClientService.accounts.listDeveloperTransactions({
				paginationConfig: args?.paginationConfig,
				environment,
			});

			/**
			 * This is a temporal fix to know whether there's results in the "previous" page until our
			 * API provides that for us: ACQ-1488
			 */
			const hasMoreBefore = await queryForItemsInThePreviousPage(
				res.transactions[0],
				(args) =>
					apiClientService.accounts.listDeveloperTransactions({
						paginationConfig: args?.paginationConfig,
						environment,
					}),
				'transactions'
			);

			return {
				...res,
				hasMoreBefore,
			};
		},
		...args,
	});
}

interface IUseListAccountsQueryArgs extends UseQueryOptions<IAccountDetailsResponse, IError> {}

export function useListAccountsQuery(args?: IUseListAccountsQueryArgs) {
	const { environment } = useSession();

	return useQuery<IAccountDetailsResponse, IError>({
		queryKey: [environment, QK_DEV_ACCOUNTS, 'details'],
		queryFn: () => apiClientService.accounts.get({ environment }),
		...args,
	});
}
