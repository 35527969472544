//
export default {
	address: {
		street_one: 'dummy_street_one',
		street_two: 'dummy_street_two',
		locality: 'dummy_locality',
		region: 'dummy_region',
		postal_code: 'dummy_postal_code',
		country: 'dummy_country',
	},
	phone: {
		country_code: 1,
		phone_number: 123456789,
	},
	verification: {
		type: 'verification',
		verification_id: 'entity_830759bfd7543eb6',
		status: 'pending',
		verification_type: 'phone',
		verification_mechanism: 'phone',
	},
};
