import { useEffect } from 'react';
import IAptoCardDesignKey from 'types/aptoCards/IAptoCardDesignKey';
import IAptoCardNetwork from 'types/aptoCards/IAptoCardNetwork';
import IAptoCardType from 'types/aptoCards/IAptoCardType';
import preloadImage from 'utils/preloadImage';
import { IAptoCardProps } from './AptoCard';
import digitalBlackBack from './images/digital-black-back.svg';
import digitalBlackFront from './images/digital-black-front.svg';
import digitalBlueBack from './images/digital-blue-back.svg';
import digitalBlueFront from './images/digital-blue-front.svg';
import digitalCoralBack from './images/digital-coral-back.svg';
import digitalCoralFront from './images/digital-coral-front.svg';
import digitalGreenBack from './images/digital-green-back.svg';
import digitalGreenFront from './images/digital-green-front.svg';
import digitalGreyBack from './images/digital-grey-back.svg';
import digitalGreyFront from './images/digital-grey-front.svg';
import digitalIndigoBack from './images/digital-indigo-back.svg';
import digitalIndigoFront from './images/digital-indigo-front.svg';
import digitalWhiteBack from './images/digital-white-back.svg';
import digitalWhiteFront from './images/digital-white-front.svg';
import mastercardDarkLogo from './images/mastercard-dark-logo.svg';
import mastercardLightLogo from './images/mastercard-light-logo.svg';
import physicalBlackBack from './images/physical-black-back.svg';
import physicalBlackFront from './images/physical-black-front.svg';
import physicalBlueBack from './images/physical-blue-back.svg';
import physicalBlueFront from './images/physical-blue-front.svg';
import physicalCoralBack from './images/physical-coral-back.svg';
import physicalCoralFront from './images/physical-coral-front.svg';
import physicalGreenBack from './images/physical-green-back.svg';
import physicalGreenFront from './images/physical-green-front.svg';
import physicalGreyBack from './images/physical-grey-back.svg';
import physicalGreyFront from './images/physical-grey-front.svg';
import physicalIndigoBack from './images/physical-indigo-back.svg';
import physicalIndigoFront from './images/physical-indigo-front.svg';
import physicalWhiteBack from './images/physical-white-back.svg';
import physicalWhiteFront from './images/physical-white-front.svg';
import visaDarkLogo from './images/visa-dark-logo.svg';
import visaLightLogo from './images/visa-light-logo.svg';

export default function useAptoCard(props: IAptoCardProps) {
	useEffect(() => {
		_preloadImages();
	}, []);

	return {
		networkLogo: getCardNetworkLogo({
			network: props.network,
			designKey: props.designKey,
		}),
		imageSrc: getImageSrc(props.side, props.type, props.designKey),
	};
}

interface IgetCardNetworkLogoOpts {
	network?: IAptoCardNetwork;
	designKey?: IAptoCardDesignKey;
}

function getCardNetworkLogo(options: IgetCardNetworkLogoOpts) {
	switch (options.network) {
		case 'VISA':
			return options.designKey === 'white' ? visaDarkLogo : visaLightLogo;
		case 'MASTERCARD':
			return options.designKey === 'white' ? mastercardDarkLogo : mastercardLightLogo;
		default:
			return null;
	}
}

function getImageSrc(side: string, type: IAptoCardType = 'physical', color?: IAptoCardDesignKey) {
	return side === 'front' ? _getFrontSrc(type, color) : _getBackSrc(type, color);
}

function _getFrontSrc(type: IAptoCardType, color?: IAptoCardDesignKey) {
	return type === 'physical' ? _getPhysicalFrontSrc(color) : _getDigitalFrontSrc(color);
}

function _getBackSrc(type: IAptoCardType, color?: IAptoCardDesignKey) {
	return type === 'physical' ? _getPhysicalBackSrc(color) : _getDigitalBackSrc(color);
}

function _getDigitalFrontSrc(color?: IAptoCardDesignKey) {
	switch (color) {
		case 'blue':
			return digitalBlueFront;
		case 'coral':
			return digitalCoralFront;
		case 'green':
			return digitalGreenFront;
		case 'grey':
			return digitalGreyFront;
		case 'indigo':
			return digitalIndigoFront;
		case 'white':
			return digitalWhiteFront;
		case 'black':
		default:
			return digitalBlackFront;
	}
}

function _getPhysicalFrontSrc(color?: IAptoCardDesignKey) {
	switch (color) {
		case 'blue':
			return physicalBlueFront;
		case 'coral':
			return physicalCoralFront;
		case 'green':
			return physicalGreenFront;
		case 'grey':
			return physicalGreyFront;
		case 'indigo':
			return physicalIndigoFront;
		case 'white':
			return physicalWhiteFront;
		case 'black':
		default:
			return physicalBlackFront;
	}
}

function _getDigitalBackSrc(color?: IAptoCardDesignKey) {
	switch (color) {
		case 'blue':
			return digitalBlueBack;
		case 'coral':
			return digitalCoralBack;
		case 'green':
			return digitalGreenBack;
		case 'grey':
			return digitalGreyBack;
		case 'indigo':
			return digitalIndigoBack;
		case 'white':
			return digitalWhiteBack;
		case 'black':
		default:
			return digitalBlackBack;
	}
}

function _getPhysicalBackSrc(color?: IAptoCardDesignKey) {
	switch (color) {
		case 'blue':
			return physicalBlueBack;
		case 'coral':
			return physicalCoralBack;
		case 'green':
			return physicalGreenBack;
		case 'grey':
			return physicalGreyBack;
		case 'indigo':
			return physicalIndigoBack;
		case 'white':
			return physicalWhiteBack;
		case 'black':
		default:
			return physicalBlackBack;
	}
}

function _preloadImages() {
	[
		digitalBlackBack,
		digitalBlackFront,
		digitalBlueBack,
		digitalBlueFront,
		digitalCoralBack,
		digitalCoralFront,
		digitalGreenBack,
		digitalGreenFront,
		digitalGreyBack,
		digitalGreyFront,
		digitalIndigoBack,
		digitalIndigoFront,
		digitalWhiteBack,
		digitalWhiteFront,
		physicalBlackBack,
		physicalBlackFront,
		physicalBlueBack,
		physicalBlueFront,
		physicalCoralBack,
		physicalCoralFront,
		physicalGreenBack,
		physicalGreenFront,
		physicalGreyBack,
		physicalGreyFront,
		physicalIndigoBack,
		physicalIndigoFront,
		physicalWhiteBack,
		physicalWhiteFront,
		mastercardDarkLogo,
		mastercardLightLogo,
		visaDarkLogo,
		visaLightLogo,
	].forEach(preloadImage);
}
