import React, { ReactNode } from 'react';
import './Modal.scss';

const ANIMATION_DURATION = 300;
let singleton: Modal;

interface IModalState {
  isVisible: boolean;
  isFadingOut: boolean;
  canBeClosedOnClickOut: boolean;
  content?: React.ReactNode;
  ariaTitle: string;
}

interface IModalShowOptions {
  ariaTitle: string;
  canBeClosedOnClickOut?: boolean;
}

export default class Modal extends React.Component<Record<string, unknown>, IModalState> {
  static show(content?: ReactNode, options: IModalShowOptions = { ariaTitle: 'Modal' }) {
    if (options.canBeClosedOnClickOut === undefined) {
      options.canBeClosedOnClickOut = true;
    }
    singleton.setState({
      isFadingOut: false,
      isVisible: true,
      canBeClosedOnClickOut: options.canBeClosedOnClickOut,
      content,
      ariaTitle: options.ariaTitle,
    });
  }

  static hide() {
    singleton.setState({ isFadingOut: true });
    setTimeout(() => singleton.setState({ isVisible: false }), ANIMATION_DURATION);
  }

  constructor(props: Record<string, unknown>) {
    super(props);

    if (!singleton) {
      this.state = {
        isVisible: false,
        isFadingOut: false,
        canBeClosedOnClickOut: false,
        ariaTitle: '',
      };
      singleton = this;
    }

    return singleton;
  }

  render() {
    if (!this.state.isVisible) {
      return null;
    }
    const className = this.state.isFadingOut ? 'Modal Modal_isFadingOut' : 'Modal';

    return (
      <div className={className} onClick={(e) => this._onWrapperClick(e)}>
        <div className="Content" role="dialog" aria-modal="true" aria-label={this.state.ariaTitle}>
          <header>
            <button onClick={Modal.hide} title="Close" className="Modal_Close">
              <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3 0.710703C12.91 0.320703 12.28 0.320703 11.89 0.710703L6.99997 5.5907L2.10997 0.700703C1.71997 0.310703 1.08997 0.310703 0.699971 0.700703C0.309971 1.0907 0.309971 1.7207 0.699971 2.1107L5.58997 7.0007L0.699971 11.8907C0.309971 12.2807 0.309971 12.9107 0.699971 13.3007C1.08997 13.6907 1.71997 13.6907 2.10997 13.3007L6.99997 8.4107L11.89 13.3007C12.28 13.6907 12.91 13.6907 13.3 13.3007C13.69 12.9107 13.69 12.2807 13.3 11.8907L8.40997 7.0007L13.3 2.1107C13.68 1.7307 13.68 1.0907 13.3 0.710703Z"
                  fill="black"
                />
              </svg>
            </button>
          </header>
          {this.state.content || this.props.children}
        </div>
      </div>
    );
  }

  _onWrapperClick(event: React.MouseEvent) {
    // event.preventDefault(); // Commmented for now to make external links work in modals

    if (!this.state.canBeClosedOnClickOut) {
      return;
    }

    if (event.target === event.currentTarget) {
      Modal.hide();
    }
  }
}
