import classnames from 'classnames';
import { ChangeEvent, FocusEvent } from 'react';
import { ICommonInputProps } from 'styleguide/Input/Input';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import style from './Switch.module.scss';

export interface ISwitchProps extends ICommonInputProps {
  type: 'switch';
  className?: string;
  checked?: boolean;
  /** Callback called every time the value changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Function called when the element has the focus*/
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Function called when the element loses the focus*/
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default function Switch(props: ISwitchProps) {
  return (
    <div className={classnames(style.Switch, { [`${style['is-disabled']}`]: props.disabled }, props.className)}>
      <label htmlFor={props.id} className={style.Switch__label}>
        <input
          type="checkbox"
          className={style.Switch__input}
          disabled={props.disabled}
          autoFocus={props.autofocus}
          id={props.id}
          checked={props.checked}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
        <View className={style.Switch__toggle}>
          <Label size="x-small" fontWeight="600" className={style.Switch__on}>
            On
          </Label>
          <Label size="x-small" fontWeight="600" className={style.Switch__off}>
            Off
          </Label>
          <div className={style.Switch__button}></div>
        </View>
      </label>
    </div>
  );
}
