import { USERS_ROUTE } from 'constants/routes';
import usePaginationUtils from 'hooks/usePagination';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router';
import Modal from 'styleguide/Modal/Modal';
import ICardholder from 'types/cardholders/ICardholder';
import ModalAddUsers from './components/ModalAddUsers/ModalAddUsers';

interface IUseUsersPageSuccessArgs {
	cardholders: ICardholder[];
	hasMore: boolean;
	hasMoreBefore: boolean;
	total: number;
}

export default function useUsersPageSuccess(args: IUseUsersPageSuccessArgs) {
	const navigate = useNavigate();
	const { isProduction } = useSession();
	const { goToNextPage, goToPrevPage } = usePaginationUtils({ items: args.cardholders });

	return {
		goToNextPage,
		goToPrevPage,
		onRowClick: (item: ICardholder) => navigate(`${USERS_ROUTE}/${item.id}`),
		showModalAddUsers: () => Modal.show(<ModalAddUsers />, { canBeClosedOnClickOut: false, ariaTitle: 'Add users' }),
		isPaginationNextVisible: args.hasMore,
		isPaginationPrevVisible: args.hasMoreBefore,
		isProduction,
		total: args.total,
		users: args.cardholders,
	};
}
