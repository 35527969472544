import Initials from 'components/Initials/Initials';
import { MouseEvent } from 'react';
import { CSSTransition } from 'react-transition-group';
import SlideCard from 'styleguide/SlideCard/SlideCard';
import Label from 'styleguide/Type/Label/Label';
import View from 'styleguide/View/View';
import './Profile.scss';
import useProfile from './useProfile';

export interface IProfileProps {
	onLogout: () => Promise<any>;
	firstName: string;
	lastName: string;
	isHidden: boolean;
	onCloseClicked: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function Profile(props: IProfileProps) {
	const { showModalLogout, onOpenProfile } = useProfile(props);

	return (
		<CSSTransition in={!props.isHidden} classNames="Profile" timeout={400} unmountOnExit>
			<View className="Profile">
				<SlideCard isHidden={props.isHidden} onCloseClicked={props.onCloseClicked}>
					<View className="Profile__header">
						<Initials firstName={props.firstName} lastName={props.lastName} className="Profile__initials" />
						<Label size="large" color="contentPrimary" fontWeight="600" className="Profile__text">
							{props.firstName} {props.lastName}
						</Label>
					</View>
					<View className="Profile__body">
						<button onClick={onOpenProfile} className="Profile__button Profile__button--profile">
							My profile
						</button>
						<button onClick={showModalLogout} className="Profile__button Profile__button--logout">
							Log out
						</button>
					</View>
				</SlideCard>
			</View>
		</CSSTransition>
	);
}
