import { IGetCompanyAddressResponse } from 'api-client/activateAccount/companyAddress/companyAddress';
import Address from 'components/Address/Address';
import InputPhoneNumber from 'styleguide/InputPhoneNumber/InputPhoneNumber';
import Notification from 'styleguide/Notification/Notification';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import Display from 'styleguide/Type/Display/Display';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './CompanyAddressStepSuccess.module.scss';
import RegistrationAddressSection from './components/RegistrationAddressSection/RegistrationAddressSection';
import useCompanyAddressStepSuccess from './useCompanyAddressStepSuccess';

export interface ICompanyAddressStepSuccessProps {
	storedValues: IGetCompanyAddressResponse;
	applicationId: string;
}

export default function CompanyAddressStepSuccess(props: ICompanyAddressStepSuccessProps) {
	const {
		submitForm,
		isButtonDisabled,
		bind,
		handleAddRegistrationAddressClick,
		handleRemoveRegistrationAddressClick,
		isLoading,
		isRegistrationAddressEnabled,
		isRegistrationAddressVisible,
		serverError,
		registrationAddressIds,
		addressIds,
		addressLabels,
		registrationAddressLabels,
	} = useCompanyAddressStepSuccess({
		storedValues: props.storedValues,
		applicationId: props.applicationId,
	});

	return (
		<View className={style.CompanyAddress}>
			<Display size="x-small" tag="h1" margin="0 0 12px">
				How do we find you?
			</Display>
			<Paragraph size="medium" color="contentPrimary" margin="12px 0 24px">
				Enter the business address of your company.
			</Paragraph>
			<form className={style.CompanyAddress__form} onSubmit={submitForm}>
				<Notification isHidden={!serverError} type="negative" margin="32px 0" title="Error" description={serverError} />

				<View data-testid="business-address">
					<Address bind={bind} ids={addressIds} labels={addressLabels} />
				</View>

				<InputPhoneNumber bind={bind} selectOptions={['US']} margin="0 0 24px" />

				<Paragraph size="x-small" color="contentTertiary" margin="0 0 20px">
					<Text type="footnote" color="muted" weight={600} inline>
						Make sure this number can receive SMS.{' '}
					</Text>
					Once you click ‘Next’, we’ll send a 6-digit code for you to verify.
				</Paragraph>

				{isRegistrationAddressEnabled ? (
					<RegistrationAddressSection
						bind={bind}
						ids={registrationAddressIds}
						labels={registrationAddressLabels}
						isVisible={isRegistrationAddressVisible}
						onRemoveRegistrationAddressClick={handleRemoveRegistrationAddressClick}
						onAddRegistrationAddressClick={handleAddRegistrationAddressClick}
					/>
				) : null}

				<Pill
					type="submit"
					level="primary"
					size="medium"
					loading={isLoading}
					disabled={isButtonDisabled}
					data-testid="btn-next"
					className={style.CompanyAddress__cta}
				>
					Next
				</Pill>
			</form>
		</View>
	);
}
