import React, { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import View from 'styleguide/View/View';
import style from './SlideCard.module.scss';

export interface ISlideCardProps {
  isHidden: boolean;
  onCloseClicked?: (e: any) => void;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  slideDirection?: 'left' | 'right';
}

export default function SlideCard(props: PropsWithChildren<ISlideCardProps>) {
  return (
    <CSSTransition
      in={!props.isHidden}
      classNames={getTransitionClassNames(props.slideDirection)}
      timeout={400}
      unmountOnExit
    >
      <View className={style.SlideCard}>
        <View className={style.SlideCard__header}>
          <button onClick={props.onCloseClicked} title="Close" className={style.SlideCard__close}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.2997 0.710215C12.9097 0.320215 12.2797 0.320215 11.8897 0.710215L6.99973 5.59022L2.10973 0.700215C1.71973 0.310215 1.08973 0.310215 0.699727 0.700215C0.309727 1.09021 0.309727 1.72022 0.699727 2.11022L5.58973 7.00022L0.699727 11.8902C0.309727 12.2802 0.309727 12.9102 0.699727 13.3002C1.08973 13.6902 1.71973 13.6902 2.10973 13.3002L6.99973 8.41021L11.8897 13.3002C12.2797 13.6902 12.9097 13.6902 13.2997 13.3002C13.6897 12.9102 13.6897 12.2802 13.2997 11.8902L8.40973 7.00022L13.2997 2.11022C13.6797 1.73022 13.6797 1.09022 13.2997 0.710215Z"
                fill="black"
              />
            </svg>
          </button>
          {props.header ? props.header : null}
        </View>
        <View className={style.SlideCard__body}>{props.children}</View>
        {props.footer ? <View className={style.SlideCard__footer}>{props.footer}</View> : null}
      </View>
    </CSSTransition>
  );
}

function getTransitionClassNames(slideDirection = 'right') {
  return {
    enter: style[`SlideCard--${slideDirection}-enter`],
    enterActive: style[`SlideCard--${slideDirection}-enter-active`],
    exit: style[`SlideCard--${slideDirection}-exit`],
    exitActive: style[`SlideCard--${slideDirection}-exit-active`],
  };
}
