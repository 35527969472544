import creditCardService from 'services/creditCard.service';
import emailService from 'services/email.service';
import passwordService from 'services/password.service';
import phoneNumberService from 'services/phoneNumber.service';

const ALPHANUMERIC_REGEX = /^\w+$/;
const COMPANY_REGEX = /^[A-Za-z0-9 ]*$/; // alphanumeric and spaces only
const DATE_REGEX = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const EIN_REGEX = /^\d{9}$/;
const NAME_REGEX = /^[a-zA-Z-_ /. '']+$/; // alphabetic and dash only
const SSN_REGEX = /^\d{9}$/;
const US_ZIP_CODE_REGEX = /^\d{5}$/;
// https://gist.github.com/dperini/729294
const URL_REGEX =
	/^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export type ICreateValidatorOpts<Values> = {
	[K in keyof Values]?: IFieldValidator<Values>[];
};

export type Errors<Values> = {
	[K in keyof Values]?: string;
};

export type Touched<Values> = {
	[K in keyof Values]?: boolean;
};

export type IFieldValidator<Values> = (
	value: any,
	touched: boolean | undefined,
	context: { values: Values; touched: Touched<Values> }
) => string | undefined;

export type IValidateFunction<Values> = (values: Values, touched: Touched<Values>) => Errors<Values>;

function createValidator<Values>(createValidatorOpts: ICreateValidatorOpts<Values>): IValidateFunction<Values> {
	return function (values, touched) {
		const errors: Errors<Values> = {};

		for (const key in createValidatorOpts) {
			const validators = createValidatorOpts[key] as IFieldValidator<Values>[]; // Is safe to cast because we know the key exists on the object!
			validators.forEach((validator) => {
				const fieldValue = values[key];
				const fieldTouched = touched[key];
				const fieldError = validator(fieldValue, fieldTouched, {
					values,
					touched,
				});

				if (fieldError) {
					errors[key] = fieldError;
				}
			});
		}

		return errors;
	};
}

function required(value: string | number | boolean, touched: boolean | undefined): string | undefined {
	if (!touched) {
		return;
	}
	if (value === '' || value == null || value === undefined) {
		return 'Required';
	}
}

function length(size: number) {
	return function required(value: string, touched: boolean | undefined): string | undefined {
		if (!touched) {
			return;
		}
		if (value?.length !== size) {
			return `${size} characters expected`;
		}
	};
}

function maxLength(size: number) {
	return function required(value: string, touched: boolean | undefined): string | undefined {
		if (!touched) {
			return;
		}
		if (value?.length > size) {
			return `${size} characters max`;
		}
	};
}

function minLength(size: number) {
	return function required(value: string, touched: boolean | undefined): string | undefined {
		if (!touched) {
			return;
		}
		if (value?.length < size) {
			return `${size} characters min`;
		}
	};
}

function einUS(value: string, touched: boolean | undefined) {
	if (!touched) {
		return;
	}
	if (!EIN_REGEX.test(value)) {
		return 'EINs must be 9 digits';
	}
}

function zipCodeUS(value: string, touched: boolean | undefined): string | undefined {
	if (!touched) {
		return;
	}
	if (!US_ZIP_CODE_REGEX.test(value)) {
		return 'Invalid';
	}
}

function ssnUS(value: string, touched: boolean | undefined) {
	if (!touched) {
		return;
	}
	if (!SSN_REGEX.test(value)) {
		return 'Invalid';
	}
}

function date(value: string, touched: boolean | undefined) {
	if (!touched) {
		return;
	}
	if (!DATE_REGEX.test(value)) return 'Invalid date format';
}

function dateInTheFuture(value: string, touched: boolean | undefined) {
	if (touched && new Date(value).getTime() <= Date.now()) {
		return 'Expired';
	}
}

function dateInThePast(value: string, touched: boolean | undefined) {
	if (touched && new Date(value).getTime() > Date.now()) {
		return 'Invalid';
	}
}

function phoneNumber(_: any, touched: boolean | undefined, context: any) {
	if (touched && !phoneNumberService.isNumberValid(context.values.countryCode, context.values.phoneNumber)) {
		return `Please enter a valid ${context?.values.countryCode} number`;
	}
}

function email(value: string, touched: boolean | undefined) {
	if (touched && !emailService.isValidEmail(value)) {
		return 'Please enter a valid email address';
	}
}

function name(value: string, touched: boolean | undefined) {
	if (touched && !NAME_REGEX.test(value)) {
		return 'Invalid';
	}
}

function alphanumeric(value: string, touched: boolean | undefined) {
	if (!touched) {
		return;
	}
	if (!ALPHANUMERIC_REGEX.test(value)) {
		return 'Invalid';
	}
}

function url(value: string, touched: boolean | undefined) {
	if (!touched) {
		return;
	}
	if (!URL_REGEX.test(value)) return 'Invalid URL';
}

function cvv(value: string, touched: boolean | undefined) {
	return touched && !creditCardService.validateCVV(value) ? 'Invalid' : '';
}

function exp(value: string, touched: boolean | undefined) {
	return touched && !creditCardService.validateExp(value) ? 'Invalid' : '';
}

function pan(value: string, touched: boolean | undefined) {
	return touched && !creditCardService.validatePan(value) ? 'Invalid' : '';
}

function minimunValue(min: number) {
	return function miniminValueValidator(value: string, touched: boolean | undefined): string | undefined {
		if (!touched) {
			return;
		}

		if (parseFloat(value) < min) {
			return `${min} minimum`;
		}
	};
}

function maximumValue(max: number) {
	return function maximumValueValidator(value: string, touched: boolean | undefined): string | undefined {
		if (!touched) {
			return;
		}

		if (parseFloat(value) > max) {
			return `${max} maximum`;
		}
	};
}

function password(value: string, touched: boolean | undefined) {
	if (touched && !passwordService.isValidPassword(value)) {
		return 'Invalid password';
	}
}

function companyName(value: string, touched: boolean | undefined) {
	if (touched && !COMPANY_REGEX.test(value)) {
		return 'Alphanumeric characters only';
	}
}

export default {
	alphanumeric,
	companyName,
	createValidator,
	cvv,
	date,
	dateInTheFuture,
	dateInThePast,
	einUS,
	email,
	exp,
	length,
	maximumValue,
	maxLength,
	minimunValue,
	minimumValue: minimunValue,
	minLength,
	name,
	pan,
	password,
	phoneNumber,
	required,
	ssnUS,
	url,
	zipCodeUS,
};
