// https://apto-api-specs.netlify.app/private/stargateapi#operation/create_funding_account_push
export default {
	id: 'payment_98hnhu9sc7i9ay73375',
	status: 'processed',
	amount: {
		currency: 'USD',
		amount: 1457.87,
	},
	source: {
		balance: {
			id: 'bal_192837192y3kajhsdk',
			version: 'v2',
			type: 'coinbase_balance',
			name: 'My bitcoin wallet',
			state: 'VALID',
			currency: 'USD',
			total_amount: 12.34,
			spendable_amount: 15.34,
			held_amount: 20.34,
			native_id: '4054598e-q7ca-36c1-93d0-eafae0935vdl',
			native_currency: 'BTC',
			native_amount: 0.02,
			dda_number: '9990000267938',
			aba_routing_number: '121182810',
		},
	},
	destination: {
		id: 'crd_98hnhu9sc7i9ay73375',
		network: 'VISA',
		last_four: 1234,
		design_key: 'blue',
	},
	created_at: '2016-10-19T23:20:17.000Z',
};
