import WithQuery from 'utils/WithQuery';
import CredentialsSectionFailed from './components/CredentialsSectionFailed';
import CredentialsSectionSuccess from './components/CredentialsSectionSuccess/CredentialsSectionSuccess';
import useCredentials from './useCredentials';

export default WithQuery({
	Component: CredentialsSectionSuccess,
	Error: CredentialsSectionFailed,
	useHook: useCredentials,
});
