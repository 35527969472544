import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './FundingAccountTutorialModal.module.scss';

export interface ITutorialModalProps {
	hideTutorial: () => void;
}

export default function TutorialModal({ hideTutorial }: ITutorialModalProps) {
	return (
		<View className={style.TutorialModal}>
			<Heading size="small" tag="h1" margin=" 0 0 12px">
				Loading funds into your Developer Funding Account
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin=" 0 0 12px">
				For your cardholders' cards to work, each cardholder's account needs to have a "spendable balance". Each time we
				issue a card, we're also creating an underlying account at our bank partner to enable spending.
			</Paragraph>
			<Paragraph size="small" color="contentPrimary" margin=" 0 0 56px">
				You can load funds into your Developer Funding Account and individually allocate those funds to your
				cardholder's accounts. This means everyone will be able to buy stuff (online or at Apple Pay / Google Pay
				merchants) using your cards right away.
			</Paragraph>
			<View className={style.TutorialModal__close}>
				<Pill level="primary" size="medium" onClick={hideTutorial}>
					Done
				</Pill>
			</View>
		</View>
	);
}
