import ContentHeader from 'components/ContentHeader/ContentHeader';
import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import View from 'styleguide/View/View';

export default function UsersPageLoading() {
	return (
		<View data-testid="page-users">
			<ContentHeader title="Users" />
			<View
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Spinner width="128px" height="128px" />
			</View>
		</View>
	);
}
