import React, { ChangeEvent, FocusEvent } from 'react';
import Input from 'styleguide/Input/Input';
import { ICountryCode } from './ICountryCode';
import InputPhoneNumberService from './InputPhoneNumber.service';

export interface IInputPhoneNumberProps {
  bind: (id: 'countryCode' | 'phoneNumber') => {
    id: string;
    name: string;
    value: string | ICountryCode;
    error?: string;
    onChange: (
      event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>
    ) => void;
    onFocus: (
      event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>
    ) => void;
    onBlur: (
      event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>
    ) => void;
  };
  selectOptions: string[];
  margin?: React.CSSProperties['margin'];
  autocomplete?: boolean;
}

export default function InputPhoneNumber(props: IInputPhoneNumberProps) {
  return (
    <div style={{ position: 'relative', margin: props.margin }}>
      <Input
        {...props.bind('phoneNumber')}
        label="Phone number"
        type="tel"
        dialCode={InputPhoneNumberService.getDialCode(props.bind('countryCode').value as ICountryCode)}
        autocomplete={props.autocomplete ? 'tel' : undefined}
      />
      <Input {...props.bind('countryCode')} type="country" selectOptions={props.selectOptions} />
    </div>
  );
}
