import { useFeature } from '@optimizely/react-sdk';
import { IGetCompanyAddressResponse } from 'api-client/activateAccount/companyAddress/companyAddress';
import card from 'components/AptoCard/images/physical-white-front.svg';
import { ACTIVATE_ACCOUNT_VERIFY_PHONE_ROUTE } from 'constants/routes';
import { CountryCode } from 'libphonenumber-js/min';
import { useSetCompanyAddressMutation } from 'queries/activateAccount';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import validationService from 'services/validationService/validation.service';
import preloadImage from 'utils/preloadImage';
import useForm from 'utils/useForm';

export interface ICompanyAddressStepFields {
	country: string;
	locality: string;
	countryCode: CountryCode;
	phoneNumber: string;
	postalCode: string;
	region: string;
	streetOne: string;
	streetTwo: string;

	registrationCountry: string;
	registrationLocality: string;
	registrationPostalCode: string;
	registrationRegion: string;
	registrationStreetOne: string;
	registrationStreetTwo: string;
}

interface IUseCompanyAddressStepArgs {
	storedValues: IGetCompanyAddressResponse;
	applicationId: string;
}

export default function useCompanyAddressStepSuccess(args: IUseCompanyAddressStepArgs) {
	useEffect(() => preloadImage(card), []);
	const navigate = useNavigate();
	const setCompanyAddressMutation = useSetCompanyAddressMutation();
	const [isRegistrationAddressEnabled] = useFeature('APPLICATION_ADDRESS_REGISTRATION_ADDRESS');

	const addressIds = {
		streetOne: 'streetOne',
		streetTwo: 'streetTwo',
		locality: 'locality',
		region: 'region',
		postalCode: 'postalCode',
		country: 'country',
	} as const;

	const addressLabels = {
		country: 'Country of business',
		streetOne: 'Business address',
	};

	const registrationAddressIds = {
		streetOne: 'registrationStreetOne',
		streetTwo: 'registrationStreetTwo',
		locality: 'registrationLocality',
		region: 'registrationRegion',
		postalCode: 'registrationPostalCode',
		country: 'registrationCountry',
	} as const;

	const registrationAddressLabels = {
		country: 'Country of registration',
		streetOne: 'Registration address',
	};

	const [isRegistrationAddressVisible, setRegistrationAddressVisible] = useState(false);

	const { submitForm, bind, isButtonEnabled, isSubmitting, setValues, values } = useForm<ICompanyAddressStepFields>({
		optionalFields: [
			'streetTwo',
			'registrationCountry',
			'registrationLocality',
			'registrationPostalCode',
			'registrationRegion',
			'registrationStreetOne',
			'registrationStreetTwo',
		],
		initialValues: _getInitialValues(args.storedValues),
		onSubmit: async (values) => {
			const address = {
				country: values.country,
				locality: values.locality,
				postalCode: values.postalCode,
				region: values.region,
				streetOne: values.streetOne,
				streetTwo: values.streetTwo,
			};

			try {
				await setCompanyAddressMutation
					.mutateAsync({
						address,
						phoneNumber: values.phoneNumber,
						applicationId: args.applicationId,
					})
					.then(() => {
						sessionStorage.setItem('ADP_ACTIVATE_ACCOUNT_PHONE_NUMBER', values.phoneNumber);
						navigate(ACTIVATE_ACCOUNT_VERIFY_PHONE_ROUTE, { replace: true });
					});
			} catch (e) {
				console.error(e);
			}
		},
		validate: validationService.createValidator<ICompanyAddressStepFields>({
			country: [validationService.required],
			countryCode: [validationService.required],
			locality: [validationService.maxLength(40), validationService.required],
			phoneNumber: [validationService.required, validationService.phoneNumber],
			postalCode: [validationService.zipCodeUS, validationService.required],
			region: [validationService.required],
			streetOne: [validationService.maxLength(40), validationService.required],
			registrationCountry: [_validateRequiredRegistrationField],
			registrationLocality: [validationService.maxLength(40), _validateRequiredRegistrationField],
			registrationPostalCode: [validationService.zipCodeUS, _validateRequiredRegistrationField],
			registrationRegion: [_validateRequiredRegistrationField],
			registrationStreetOne: [validationService.maxLength(40), _validateRequiredRegistrationField],
		}),
	});

	function _validateRequiredRegistrationField(value: string, touched: boolean | undefined, context: any) {
		// These fields are required only if part of the registration address has been filled
		if (
			context.values.registrationCountry === '' &&
			context.values.registrationLocality === '' &&
			context.values.registrationPostalCode === '' &&
			context.values.registrationRegion === '' &&
			context.values.registrationStreetOne === '' &&
			context.values.registrationStreetTwo === ''
		) {
			return;
		}
		if (!value) {
			return 'Required';
		}
	}

	function handleRemoveRegistrationAddressClick() {
		setValues({
			...values,
			registrationCountry: '',
			registrationLocality: '',
			registrationPostalCode: '',
			registrationRegion: '',
			registrationStreetOne: '',
			registrationStreetTwo: '',
		});
		setRegistrationAddressVisible(!isRegistrationAddressVisible);
	}

	return {
		addressIds,
		addressLabels,
		bind,
		handleAddRegistrationAddressClick: () => setRegistrationAddressVisible(!isRegistrationAddressVisible),
		handleRemoveRegistrationAddressClick,
		isButtonDisabled: !isButtonEnabled,
		isLoading: isSubmitting,
		isRegistrationAddressEnabled,
		isRegistrationAddressVisible,
		registrationAddressIds,
		registrationAddressLabels,
		serverError: setCompanyAddressMutation.error?.message || '',
		submitForm,
	};
}

function _getInitialValues(response: IGetCompanyAddressResponse): ICompanyAddressStepFields {
	return {
		country: response.address?.country || '',
		locality: response.address?.locality || '',
		// TODO: We only allow US country codes
		countryCode: 'US',
		phoneNumber: response.phone?.phoneNumber ? String(response.phone.phoneNumber) : '',
		postalCode: response.address?.postalCode || '',
		region: response.address?.region || '',
		streetOne: response.address?.streetOne || '',
		streetTwo: response.address?.streetTwo || '',

		registrationCountry: '',
		registrationLocality: '',
		registrationPostalCode: '',
		registrationRegion: '',
		registrationStreetOne: '',
		registrationStreetTwo: '',
	};
}
