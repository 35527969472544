import React from 'react';
import Spinner from 'styleguide/Spinner/Spinner';
import View from 'styleguide/View/View';
import style from './UserPage.module.scss';

export default function UserPageLoading() {
	return (
		<View data-testid="page-user">
			<View className={style.UserPageLoading}>
				<Spinner width="128px" height="128px" />
			</View>
		</View>
	);
}
