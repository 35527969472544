import apiFetch from 'api-client/utils/fetch';
import toCamelCase from 'api-client/utils/toCamelCase';
import { IAddress } from 'components/Address/Address';
import phoneNumberService from 'services/phoneNumber.service';
import IApplicationStatus from 'types/session/IApplicationStatus';

const BASE_ENDPOINT = '/developer/get_started_application';

export interface IGetCompanyAddressArgs {
	applicationId: string;
}

export interface IGetCompanyAddressResponse {
	address: IAddress;
	phone: {
		countryCode: number;
		phoneNumber: number;
	};
	verification: {
		status: string; // TODO: This should be an enum
		type: string; // TODO: This should be an enum
		verificationId: string;
		verificationMechanism: string; // TODO: This should be an enum
		verificationType: string; // TODO: This should be an enum
	};
}

async function getCompanyAddress(args: IGetCompanyAddressArgs): Promise<IGetCompanyAddressResponse> {
	return apiFetch(`${BASE_ENDPOINT}/${args.applicationId}/contacts`, 'GET').then(toCamelCase);
}

export interface ISetCompanyAddressResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

export interface ISetCompanyAddressArgs {
	address: IAddress;
	phoneNumber: string;
	applicationId: string;
}

async function setCompanyAddress(args: ISetCompanyAddressArgs): Promise<ISetCompanyAddressResponse> {
	return apiFetch(`${BASE_ENDPOINT}/${args.applicationId}/contacts`, 'PUT', {
		address: {
			country: args.address.country,
			locality: args.address.locality,
			postal_code: args.address.postalCode,
			region: args.address.region,
			street_one: args.address.streetOne,
			street_two: args.address.streetTwo,
		},
		phone: {
			// TODO: We only support USA phones
			country_code: 1,
			phone_number: phoneNumberService.removeDialCode('US', args.phoneNumber),
		},
	}).then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

export interface IVerifyPhoneNumberResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

async function verifyPhoneNumber(code: string, applicationId: string): Promise<IVerifyPhoneNumberResponse> {
	return apiFetch(`${BASE_ENDPOINT}/${applicationId}/contacts/verify_phone`, 'POST', {
		code,
	}).then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

export interface IResendVerifyPhoneNumberResponse {
	id: string;
	status: IApplicationStatus;
	statusReason: string | null;
}

async function resendVerifyPhoneNumber(applicationId: string): Promise<IResendVerifyPhoneNumberResponse> {
	return apiFetch(`${BASE_ENDPOINT}/${applicationId}/contacts/restart_verification`, 'POST').then((res) => ({
		id: res.application.id,
		status: res.application.status,
		statusReason: res.application.status_reason,
	}));
}

export default {
	getCompanyAddress,
	resendVerifyPhoneNumber,
	setCompanyAddress,
	verifyPhoneNumber,
};
