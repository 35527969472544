import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import imgDone from './done.svg';
import style from './ModalInviteMemberSent.module.scss';

interface IModalInviteMemberSentProps {
	onDoneClick: () => void;
	onInviteMorePeopleClick: () => void;
	email: string;
	companyName: string;
}

export default function ModalInviteMemberSent(props: IModalInviteMemberSentProps) {
	return (
		<View className={style.ModalInviteMemberSent}>
			<View className={style.ModalInviteMemberSent__header}>
				<img alt="" src={imgDone} width="52" height="52" />
				<Heading size="small" tag="h1" margin="8px 0 24px">
					Invite sent
				</Heading>
			</View>
			<View className={style.ModalInviteMemberSent__body}>
				<Paragraph size="small" color="contentPrimary">
					We have sent an email to {props.email} that includes a link for an easy sign up as a member of{' '}
					{props.companyName}.
				</Paragraph>
			</View>
			<View className={style.ModalInviteMemberSent__footer}>
				<Pill level="secondary" size="medium" onClick={props.onInviteMorePeopleClick}>
					Invite more people
				</Pill>
				<Pill level="primary" size="medium" className={style.ModalInviteMemberSent__cta} onClick={props.onDoneClick}>
					Done
				</Pill>
			</View>
		</View>
	);
}
