import React from 'react';
import Dot from 'styleguide/Dot/Dot';
import ICardholderKYCStatus from 'types/cardholders/ICardholderKYCStatus';

export default function KYCFormatter({ value }: { value: ICardholderKYCStatus }) {
	switch (value) {
		case 'PASSED':
			return (
				<>
					{' '}
					<Dot type="positive" /> Approved{' '}
				</>
			);
		case 'REJECTED':
			return (
				<>
					{' '}
					<Dot type="negative" /> Denied{' '}
				</>
			);
		case 'RESUBMIT_DETAILS':
		case 'TEMPORARY_ERROR':
		case 'UNDER_REVIEW':
		case 'UPLOAD_FILE':
		default:
			return (
				<>
					{' '}
					<Dot /> Pending{' '}
				</>
			);
	}
}
