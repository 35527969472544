import apiClientService from 'api-client/apiclient.service';
import { SYNC_2FA_ROUTE, WAIT_FOR_EMAIL_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import validationService from 'services/validationService/validation.service';
import useForm from 'utils/useForm';

export default function useCreateAccount() {
	const navigate = useNavigate();
	const params = useQueryParams();
	const invitationId = params.get('invitation_id') || undefined;
	const verificationId = params.get('verification_id') || undefined;
	const secret = params.get('secret') || undefined;
	const email = params.get('email') || '';
	const company = params.get('company') || '';

	const [isPasswordValidatorVisible, setIsPasswordValidatorVisible] = useState(false);
	const [serverError, setServerError] = useState('');
	const { values, touched, setFieldError, handleBlur, submitForm, isSubmitting, handleFocus, isButtonEnabled, bind } =
		useForm({
			initialValues: {
				firstName: '',
				lastName: '',
				company: company,
				email: email,
				password: '',
			},
			async onSubmit(): Promise<any> {
				const payload = {
					firstName: values.firstName,
					lastName: values.lastName,
					companyName: values.company,
					email: values.email,
					password: values.password,
					invitationId,
					verificationId,
					secret,
				};
				return apiClientService.auth
					.createAccount(payload)
					.then((res) => {
						switch (res.attempt.status) {
							case 'setup_2fa': {
								const searchParams = new URLSearchParams({
									secret: res.totp.secret,
									attempt_id: res.attempt.id,
									email: res.totp.email,
								});
								return navigate({
									pathname: SYNC_2FA_ROUTE,
									search: `?${searchParams.toString()}`,
								});
							}
							case 'verify_email':
							default: {
								const searchParams = new URLSearchParams({
									attemptID: res.attempt.id,
									email: values.email,
								});
								return navigate({
									pathname: WAIT_FOR_EMAIL_ROUTE,
									search: `?${searchParams.toString()}`,
								});
							}
						}
					})
					.catch((err) => {
						if (err.code === 1000) {
							return setServerError('Invalid password');
						}

						if (err.message) {
							setServerError(err.message);
						}
					});
			},
			validate: validationService.createValidator({
				firstName: [validationService.required, validationService.name, validationService.maxLength(40)],
				lastName: [validationService.required, validationService.name, validationService.maxLength(40)],
				company: [validationService.required, validationService.maxLength(40), validationService.companyName],
				password: [validationService.required, validationService.password],
				email: [validationService.required, validationService.email],
			}),
		});

	return {
		createAccount: submitForm,
		bind,
		hidePasswordValidator: () => setIsPasswordValidatorVisible(false),
		isButtonDisabled: !isButtonEnabled,
		isCompanyDisabled: values.company && !touched.company ? true : false,
		isEmailDisabled: values.email && !touched.email ? true : false,
		isLoading: isSubmitting,
		isPasswordValidatorVisible,
		onPasswordBlur: (e: any) => {
			handleBlur(e);
			values.password !== '' ? setIsPasswordValidatorVisible(true) : setIsPasswordValidatorVisible(false);
		},
		onPasswordFocus: (e: any) => {
			handleFocus(e);
			setIsPasswordValidatorVisible(true);
		},
		password: values.password,
		serverError,
		setInvalidPassword: (err: string) => setFieldError('password', err),
	};
}
