import { useUpdatePaymentSourceMutation } from 'queries/paymentSources';
import paymentsService from 'services/payments.service';
import IPaymentMethod from 'types/IPaymentMethod';
import usePureState from 'utils/usePureState';
import modalEditPaymentSourcesService from './modalEditPaymentSources.service';

interface IUseModalEditPaymentSourcesSuccessArgs {
	paymentMethods: IPaymentMethod[];
	onPaymentMethodsEdited: () => void;
}

export default function useModalEditPaymentSourcesSuccess(args: IUseModalEditPaymentSourcesSuccessArgs) {
	const updatePaymentMethodMutation = useUpdatePaymentSourceMutation({ onSuccess: args.onPaymentMethodsEdited });

	const { state, dispatch } = usePureState({
		fundingSelected: paymentsService.getPreferredFundingMethod(args.paymentMethods),
		billingSelected: paymentsService.getPreferredBillingMethod(args.paymentMethods),
	});

	function handleFundingChange(id: string) {
		dispatch({ fundingSelected: args.paymentMethods.find((pm) => pm.id === id) as IPaymentMethod });
	}

	function handleBillingChange(id: string) {
		dispatch({ billingSelected: args.paymentMethods.find((pm) => pm.id === id) as IPaymentMethod });
	}

	async function handleConfirmClick() {
		return modalEditPaymentSourcesService.updatePaymentSources({
			billing: state.billingSelected,
			funding: state.fundingSelected,
			mutationFn: updatePaymentMethodMutation.mutate,
			onSuccess: args.onPaymentMethodsEdited,
		});
	}

	return {
		handleConfirmClick: handleConfirmClick,
		handleFundingChange: handleFundingChange,
		handleBillingChange: handleBillingChange,
		fundingSelected: state.fundingSelected,
		billingSelected: state.billingSelected,
		serverError: updatePaymentMethodMutation.error?.message || '',
		isButtonDisabled: !state.billingSelected && !state.fundingSelected,
		isLoading: updatePaymentMethodMutation.isLoading,
	};
}
