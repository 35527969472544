export default {
	data: [
		{
			user_id: 'dummy_user_id',
			name: {
				first_name: 'John',
				last_name: 'Doe',
			},
			email: 'john@acme.com',
			last_seen: '2020-06-08T14:54:36Z',
			role: 'admin',
		},
		{
			user_id: 'dummy_user_id_2',
			name: {
				first_name: 'Mike',
				last_name: 'Smith',
			},
			email: 'mike@acme.com',
			last_seen: '2020-06-08T14:54:36Z',
			role: 'owner',
		},
		{
			user_id: 'dummy_user_id_3',
			name: {
				first_name: 'Alice',
				last_name: 'Fowler',
			},
			email: 'alice@acme.com',
			last_seen: '2020-06-08T14:54:36Z',
			role: 'developer',
		},
		{
			user_id: 'dummy_user_id_4',
			name: {
				first_name: 'Carol',
				last_name: 'Johnson',
			},
			email: 'carol@acme.com',
			last_seen: '2020-06-08T14:54:36Z',
			role: 'viewer',
		},
	],
	has_more: false,
	total: 4,
};
