import IEnvironment from 'types/session/IEnvironment';
import apiFetch from '../utils/fetch';
import toCamelCase from '../utils/toCamelCase';

const EXAMPLE_APP_ENDPOINT = '/developer/example_app';
export interface IPhoneNumber {
	countryCode: number;
	number: number;
}

interface IRequestExampleAppArgs {
	phoneNumber: IPhoneNumber;
	environment: IEnvironment;
}

export interface IRequestExampleAppResponse {
	androidLink: string;
	iosLink: string;
}

function requestExampleApp(args: IRequestExampleAppArgs): Promise<IRequestExampleAppResponse> {
	return apiFetch(EXAMPLE_APP_ENDPOINT, 'POST', {
		environment: args.environment,
		phone_number: {
			country_code: args.phoneNumber.countryCode,
			phone_number: args.phoneNumber.number,
		},
	}).then(toCamelCase);
}

export default {
	requestExampleApp,
};
