import apiFetch from '../utils/fetch';

const CONTACT_US_ENDPOINT = '/developer/contact_us';

async function send(contactInfo: IContactInfo): Promise<IContactUsResponse> {
	return apiFetch(CONTACT_US_ENDPOINT, 'POST', {
		name: {
			first_name: contactInfo.name.firstName,
			last_name: contactInfo.name.lastName,
		},
		company: {
			name: contactInfo.company.name,
		},
		phone: {
			country_code: parseInt(contactInfo.phone.country_code, 10),
			phone_number: contactInfo.phone.phone_number,
		},
		email: contactInfo.email,
		website: contactInfo.website,
		description: contactInfo.description,
	});
}

export interface IContactUsResponse {
	code: number;
	message: string;
}

export interface IContactInfo {
	name: {
		firstName: string;
		lastName: string;
	};
	company: {
		name: string;
	};
	phone: {
		country_code: string;
		phone_number: string;
	};
	email: string;
	website: string;
	description: string;
}

export default {
	send,
};
