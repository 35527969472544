import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './LegalItem.module.scss';

export interface ILegalItemProps {
	text: string;
	url: string;
}

export default function LegalItem(props: ILegalItemProps) {
	return (
		<View className={style.LegalItem}>
			<Paragraph color="contentPrimary" size="small">
				{props.text}
			</Paragraph>

			<Pill type="link" size="small" level="tertiary" outlined external to={props.url}>
				View
			</Pill>
		</View>
	);
}
