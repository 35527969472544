import utils from 'utils';

const PRODUCTION_URL = 'https://vault.aptopayments.com/v1';
const STAGING_URL = 'https://vault.stg.aptopayments.com/v1';

export default function getVaultBaseUrl(): string {
	switch (utils.getEnvironment()) {
		case 'PRD':
			return PRODUCTION_URL;
		case 'STG':
		default:
			return STAGING_URL;
	}
}
