import Notification from 'components/Notification/Notification';
import PaddingContainer from 'components/PaddingContainer/PaddingContainer';
import { USER_CARDS_ROUTE, USER_DETAILS_ROUTE, USER_TRANSACTIONS_ROUTE } from 'constants/routes';
import { Navigate, Route, Routes } from 'react-router';
import TabItem from 'styleguide/Tabs/TabItem/TabItem';
import TabsList from 'styleguide/Tabs/TabsList/TabsList';
import View from 'styleguide/View/View';
import IAptoCard from 'types/aptoCards/IAptoCard';
import ISummaryCardholder from 'types/cardholders/ISummaryCardholder';
import ITransaction from 'types/transactions/ITransaction';
import HeaderSection from './components/HeaderSection/Header.section';
import CardsSection from './sections/cards/Cards.section';
import DetailsSection from './sections/details/Details.section';
import TransactionsSection from './sections/transactions/Transactions.section';
import style from './UserPage.module.scss';
import useUserPageSuccess from './useUserPageSuccess';

export interface ICardContentProps {
	cards: IAptoCard[];
	id: string;
	transactions: ITransaction[];
	user: ISummaryCardholder;
}

export default function UserPageSuccess(props: ICardContentProps) {
	const {
		onDeleteClicked,
		isDeleting,
		serverError,
		hasActivatedCards,
		isProduction,
		goToLoadFunds,
		onTransactionClicked,
		onCardClicked,
	} = useUserPageSuccess({
		cards: props.cards,
		id: props.id,
	});

	return (
		<View data-testid="page-user">
			<PaddingContainer>
				<Notification type="negative" isHidden={!serverError} title="Error" description={serverError} />

				<HeaderSection
					user={props.user}
					isCardholder={props.cards.length > 0}
					isLoadFundsDisabled={!hasActivatedCards}
					isProduction={isProduction}
					isDeleteLoading={isDeleting}
					onDeleteClicked={onDeleteClicked}
					onLoadFundsClicked={goToLoadFunds}
				/>

				<View className={style.UserPageSuccess__tabsNav}>
					<TabsList>
						<TabItem name="Details" to={USER_DETAILS_ROUTE.replace(':id', props.user.id)} />
						<TabItem name="Cards" to={USER_CARDS_ROUTE.replace(':id', props.user.id)} />
						<TabItem name="Transactions" to={USER_TRANSACTIONS_ROUTE.replace(':id', props.user.id)} />
					</TabsList>
				</View>
			</PaddingContainer>
			<Routes>
				<Route index element={<Navigate replace to={USER_DETAILS_ROUTE.replace(':id', props.user.id)} />} />
				<Route path="details" element={<DetailsSection user={props.user} />}></Route>
				<Route
					path="cards"
					element={<CardsSection cards={props.cards} isProduction={isProduction} onClick={onCardClicked} />}
				></Route>
				<Route
					path="transactions"
					element={
						<TransactionsSection
							transactions={props.transactions}
							isProduction={isProduction}
							onClick={onTransactionClicked}
						/>
					}
				></Route>
			</Routes>
		</View>
	);
}
