import React, { PropsWithChildren } from 'react';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import close from './close.svg';
import style from './DrawerModal.module.scss';

interface IDrawerModalProps {
  /**
   * Text that will be displayed below the title, as a paragraph
   */
  description: React.ReactNode;
  /**
   * Function for the close button
   */
  onClose: () => void;
  /**
   * Text that will be displayed as the title
   */
  title: string;
}

export default function DrawerModal(props: PropsWithChildren<IDrawerModalProps>) {
  return (
    <View className={style.DrawerModal}>
      <View className={style['DrawerModal__intro--desktop']}>
        <button title="Close" className={style['DrawerModal__close--desktop']} onClick={props.onClose}>
          <img src={close} alt="Close" />
        </button>
        <Text type="h1" margin={'28px 0 32px'}>
          {props.title}
        </Text>
        <Text type="medium" color="body" margin={'0 0 32px'}>
          {props.description}
        </Text>
      </View>
      <View className={style.DrawerModal__drawer}>
        <View className={style['DrawerModal__intro--mobile']}>
          <button title="Close" className={style['DrawerModal__close--mobile']} onClick={props.onClose}>
            <img src={close} alt="Close" />
          </button>
          <Text type="h1" margin={'36px 0 32px'}>
            {props.title}
          </Text>
          <Text type="medium" color="body" margin={'0 0 32px'}>
            {props.description}
          </Text>
        </View>
        <View className={style.DrawerModal__content}>{props.children}</View>
      </View>
    </View>
  );
}
