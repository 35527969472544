import classnames from 'classnames';
import style from './Loader.module.scss';

interface ILoaderProps {
  level: 'primary' | 'secondary' | 'tertiary' | 'cta-link' | 'inline-link';
}

export default function Loader(props: ILoaderProps) {
  const className = _getLevelClass(props.level);

  return (
    <>
      <span className={classnames(style[`Loader__dot--first`], className)}></span>
      <span className={classnames(style[`Loader__dot--second`], className)}></span>
      <span className={classnames(style[`Loader__dot--third`], className)}></span>
    </>
  );
}

function _getLevelClass(level: string) {
  switch (level) {
    case 'secondary':
      return style['Loader__dot--secondary'];
    case 'tertiary':
      return style['Loader__dot--tertiary'];
    case 'cta-link':
      return style['Loader__dot--ctaLink'];
    case 'inline-link':
      return style['Loader__dot--inlineLink'];
    default:
      return '';
  }
}
