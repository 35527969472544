import { useLocation, useNavigate } from 'react-router';
import queryParamsService from 'services/queryParams/queryParams.service';
import IPaginationConfig from 'types/IPaginationConfig';
import { useQueryParams } from './useQueryParams';

interface IPaginationOptions {
	items: ItemWithId[];
	startKey?: string;
	endKey?: string;
}

const defaultOptions = {
	startKey: 'starting_after',
	endKey: 'ending_before',
};

export default function usePagination(args: IPaginationOptions) {
	const { startKey, endKey, items } = { ...defaultOptions, ...args };
	const params = useQueryParams();
	const navigate = useNavigate();
	const location = useLocation();

	function goToNextPage() {
		const lastItem = items[items.length - 1];
		if (lastItem) {
			navigate({
				pathname: location.pathname,
				search: queryParamsService.build({
					queryString: location.search,
					params: {
						[startKey]: lastItem.id,
						[endKey]: '',
					},
				}),
			});
		}
	}

	function goToPrevPage() {
		const firstItem = items[0];
		if (firstItem) {
			navigate({
				pathname: location.pathname,
				search: queryParamsService.build({
					queryString: location.search,
					params: {
						[endKey]: firstItem.id,
						[startKey]: '',
					},
				}),
			});
		}
	}

	return {
		start: params.get(startKey) || undefined,
		end: params.get(endKey) || undefined,
		goToNextPage,
		goToPrevPage,
	};
}

type ItemWithId = { id: string };

type functionToFetchItems<K extends string> = ({
	paginationConfig,
}: {
	paginationConfig: IPaginationConfig;
}) => Promise<{ [value in K]: Array<any> }>;

/**
 * Generic function to know if a collection has items on the previous page.
 * Useful to know if the goPrev should be displayed.
 *
 * @param firstItem - The first item on the current page
 * @param fetchFunction - A function that can be executed with a pagination config. This function is used to get the previous page.
 * @param key - The name of the element in the response where the items of the previous page are stored.
 */
export async function queryForItemsInThePreviousPage<K extends string>(
	firstItem: ItemWithId,
	fetchFunction: functionToFetchItems<K>,
	key: K
): Promise<boolean> {
	if (!firstItem) {
		return false;
	}
	const response = await fetchFunction({
		paginationConfig: { end: firstItem.id },
	});

	return response[key].length > 0;
}
