import { IGetCompanyInfoResponse } from 'api-client/activateAccount/activateAccount';
import useApplicationId from 'hooks/useApplicationId';
import { useGetCompanyInfoQuery } from 'queries/activateAccount';

export default function useCompanyInfoStepWithApplicationId() {
	const applicationId = useApplicationId() as string;

	const companyInfoQuery = useGetCompanyInfoQuery({
		applicationId: applicationId,
		// Prevent background requests when the user focus the page
		refetchOnWindowFocus: false,
	});

	return {
		props: {
			// SafeCast: This data is only used when status is success
			storedValues: companyInfoQuery.data as IGetCompanyInfoResponse,
			applicationId,
		},
		status: companyInfoQuery.status,
	};
}
