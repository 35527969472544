import { IGetCompanyInfoResponse } from 'api-client/activateAccount/activateAccount';
import Notification from 'components/Notification/Notification';
import Tooltip from 'components/Tooltip/Tooltip';
import Input from 'styleguide/Input/Input';
import Pill from 'styleguide/Pill/Pill';
import Display from 'styleguide/Type/Display/Display';
import View from 'styleguide/View/View';
import style from './CompanyInfoSuccessStep.module.scss';
import Checkbox from './components/Checkbox/Checkbox';
import useCompanyInfoSuccessStep from './useCompanyInfoSuccessStep';

export interface ICompanyInfoSuccessStepProps {
	storedValues: IGetCompanyInfoResponse;
	applicationId: string;
}

export default function CompanyInfoSuccessStep(props: ICompanyInfoSuccessStepProps) {
	const {
		bind,
		checkboxValue,
		companyName,
		handleCheckboxChange,
		isButtonDisabled,
		isCompanyInfoV2Enabled,
		isLoading,
		serverError,
		submitForm,
	} = useCompanyInfoSuccessStep({
		storedValues: props.storedValues,
		applicationId: props.applicationId,
	});

	return (
		<View className={style.CompanyInfo}>
			<Display size="x-small" tag="h1" margin="0 0 24px">
				Tell us about {companyName || 'your company'}
			</Display>

			<form className={style.Form} onSubmit={submitForm}>
				<Notification isHidden={!serverError} type="negative" margin="32px 0" title="Error" description={serverError} />

				{/* These fields are full-width to be able to display the validation message correctly */}
				<Input type="text" {...bind('legalName')} label="Company legal name" margin="12px 0" />

				{isCompanyInfoV2Enabled ? (
					<>
						<Input type="text" {...bind('website')} label="Company website" margin="0" />

						<View className={style.CompanyInfo__programName}>
							<Input type="text" {...bind('brandName')} label="DBA/Program name" margin="12px 0" />
							<View className={style.CompanyInfo__info}>
								<Tooltip message="A registration address acts as the official address and point of contact for your company. If you use a registered agent for your company, they will provide you with a registration address.">
									<View className={style.CompanyInfo__infoIcon} />
								</Tooltip>
							</View>
						</View>

						<View className={style.CompanyInfo__programWebsite}>
							<Input type="text" {...bind('programWebsite')} label="DBA/Program website" readOnly={checkboxValue} />
							<Checkbox
								className={style.CompanyInfo__checkbox}
								id="websiteCheckbox"
								name="websiteCheckbox"
								label="Same as company website"
								checked={checkboxValue}
								onChange={handleCheckboxChange}
							/>
						</View>
					</>
				) : (
					<>
						<Input type="text" {...bind('brandName')} label="Company cardholder-facing name" margin="12px 0" />
						<Input type="text" {...bind('website')} label="Website" margin="12px 0" />
					</>
				)}

				<Input type="text" {...bind('taxId')} label="Company Tax ID (EIN)" margin="12px 0" />

				<Input
					type="textarea"
					rows={3}
					{...bind('description')}
					label="Describe your business in 300 characters or less"
					margin="12px 0 24px"
				></Input>

				<Pill
					type="submit"
					level="primary"
					size="medium"
					loading={isLoading}
					disabled={isButtonDisabled}
					data-testid="btn-next"
				>
					Next
				</Pill>
			</form>
		</View>
	);
}
