import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import View from 'styleguide/View/View';
import style from './LogoPreview.module.scss';

interface ILogoPreviewProps {
	handleRemoveLogoClick: () => void;
	logoBase64: string;
}

export default function LogoPreview(props: ILogoPreviewProps) {
	return (
		<View className={style.LogoPreview}>
			<View>
				<View className={style.LogoPreview__overlay}>
					<Pill
						level="tertiary"
						size="small"
						className={style.LogoPreview__button}
						onClick={props.handleRemoveLogoClick}
					>
						Remove
					</Pill>
				</View>
				<img src={props.logoBase64} alt="Logo preview" data-testid="logo-preview" />
			</View>
		</View>
	);
}
