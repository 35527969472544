import { IBillingAccount, IFundingAccount } from 'api-client/accounts/types/IAccountDetailsResponse';
import { ICurrency } from 'constants/currencies';
import { useListAccountsQuery } from 'queries/accounts';

export interface IFundingAccountFlatten {
	id: string;
	amount: number;
	currency: ICurrency;
	accountNumber: string;
	routingNumber: string;
}

export default function useAccounts() {
	const accountsQuery = useListAccountsQuery();

	return {
		status: accountsQuery.status,
		props: {
			// SafeCast: we're using <WithQuery />
			billingAccount: accountsQuery.data?.billingAccount as IBillingAccount,
			// SafeCast: we're using <WithQuery />
			fundingAccount: accountsQuery.data?.fundingAccount as IFundingAccount,
			serverError: accountsQuery.error?.message || '',
		},
	};
}
