import IEnvironment from 'types/session/IEnvironment';
import I2FAResponse from 'types/session/ITwoFAResponse';

const DEFAULT_ENVIRONMENT = 'sandbox';

export type ISession = I2FAResponse & { currentEnv: IEnvironment };

function getSession(): ISession | null {
	const data = sessionStorage.getItem('ADP_SESSION') || '';
	return data ? JSON.parse(atob(data)) : null;
}

function init(response: I2FAResponse): ISession {
	const session: ISession = { ...response, currentEnv: _getCurrentEnv(response) };
	setSession(session);
	return session;
}

function _getCurrentEnv(response: I2FAResponse): IEnvironment {
	return response.developerAccount.accessLevel === 'get_started' ? 'production' : DEFAULT_ENVIRONMENT;
}

function setSession(session: ISession | null): void {
	return sessionStorage.setItem('ADP_SESSION', btoa(JSON.stringify(session)));
}

function getUserToken(): string | undefined {
	return getSession()?.session.userToken;
}

function getEnvironment(): IEnvironment | undefined {
	return getSession()?.currentEnv;
}

export default {
	getEnvironment,
	getSession,
	getUserToken,
	init,
	setSession,
};
