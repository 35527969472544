import ILoginResponse from 'types/session/ILoginResponse';
import I2FAResponse from 'types/session/ITwoFAResponse';
import apiFetch from './utils/fetch';
import toCamelcase from './utils/toCamelCase';

const FORGOT_PASSWORD_ENDPOINT = '/developer/forgot_password';
const RESET_PASSWORD_ENDPOINT = '/developer/reset_password';
const SETUP_2FA_CODE = '/developer/setup_totp';
const SIGNIN_ENDPOINT = '/developer/signin';
const SIGNUP_ENDPOINT = '/developer/signup';
const VERIFY_2FA_CODE_ENDPOINT = '/developer/signin/2fa';
const VERIFY_EMAIL_ENDPOINT = '/developer/verify_email';
const RESEND_EMAIL_ENDPOINT = '/developer/resend_email';
const SESSION_ENDPOINT = '/developer/session';
const LOGOUT_ENDPOINT = '/developer/logout';

interface ILoginPayload {
	email: string;
	password: string;
	invitationId?: string;
	verificationId?: string;
	secret?: string;
}

export async function login(payload: ILoginPayload): Promise<ILoginResponse> {
	const { email, password, invitationId, verificationId, secret } = payload;
	return apiFetch(SIGNIN_ENDPOINT, 'POST', {
		email,
		password,
		..._addInviteIfNeeded(invitationId, verificationId, secret),
	}).then(toCamelcase);
}

interface ICreateAccountPayload {
	firstName: string;
	lastName: string;
	companyName: string;
	email: string;
	password: string;
	invitationId?: string;
	verificationId?: string;
	secret?: string;
}

export async function createAccount(payload: ICreateAccountPayload): Promise<ICreateAccountResponse> {
	const { firstName, lastName, companyName, email, password, invitationId, verificationId, secret } = payload;
	return apiFetch(SIGNUP_ENDPOINT, 'POST', {
		company: { name: companyName },
		email,
		name: { first_name: firstName, last_name: lastName },
		password,
		..._addInviteIfNeeded(invitationId, verificationId, secret),
	});
}

function _addInviteIfNeeded(invitationId?: string, verificationId?: string, secret?: string) {
	const shouldSendInvitation = invitationId && verificationId && secret;
	return shouldSendInvitation
		? { invitation: { id: invitationId, verification_id: verificationId, secret: secret } }
		: null;
}

export interface ICreateAccountResponse {
	attempt: {
		id: string;
		status: string;
	};
	username_verification: {
		code: string;
	};
	totp: {
		secret: string;
		email: string;
	};
}

export async function verifyEmail(code: string, attemptId: string): Promise<IVerifyEmailResponse> {
	return apiFetch(VERIFY_EMAIL_ENDPOINT, 'POST', {
		attempt_id: attemptId,
		code,
	});
}

export interface IVerifyEmailResponse {
	attempt: {
		id: string;
		status: string;
	};
	totp: {
		secret: string;
		email: string;
	};
}

export async function verify2FACode(attemptId: string, code: string): Promise<I2FAResponse> {
	return apiFetch(VERIFY_2FA_CODE_ENDPOINT, 'POST', {
		attempt_id: attemptId,
		code,
	}).then(toCamelcase);
}

export async function sync2FACode(firstCode: string, secondCode: string, attemptId: string): Promise<I2FAResponse> {
	return apiFetch(SETUP_2FA_CODE, 'POST', {
		attempt_id: attemptId,
		first_code: firstCode,
		second_code: secondCode,
	}).then(toCamelcase);
}

/**
 * This endpoint works both for autenticated and unautenticated users.
 */
export async function resetPassword(newPassword: string, attemptId?: string): Promise<IResetPasswordResponse> {
	if (attemptId) {
		return apiFetch(`${RESET_PASSWORD_ENDPOINT}/${attemptId}`, 'POST', {
			new_password: newPassword,
		});
	}
	return apiFetch(`${RESET_PASSWORD_ENDPOINT}`, 'POST', {
		new_password: newPassword,
	});
}

export async function forgotPassword(email: string): Promise<IResetPasswordResponse> {
	return apiFetch(FORGOT_PASSWORD_ENDPOINT, 'POST', { email }).then(toCamelcase);
}

export interface IResetPasswordResponse {
	attempt: {
		id: string;
		status: string;
	};
}

export async function resendEmail(attemptId: string): Promise<any> {
	return apiFetch(RESEND_EMAIL_ENDPOINT, 'POST', { attempt_id: attemptId });
}

export async function getSession(): Promise<I2FAResponse> {
	return apiFetch(SESSION_ENDPOINT, 'GET').then(toCamelcase);
}

export async function logout(): Promise<any> {
	return apiFetch(LOGOUT_ENDPOINT, 'POST');
}

export default {
	createAccount,
	forgotPassword,
	login,
	resendEmail,
	resetPassword,
	sync2FACode,
	verify2FACode,
	verifyEmail,
	getSession,
	logout,
};
