import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';

export default function Toast() {
	return (
		<ToastContainer
			autoClose={5000}
			closeOnClick
			hideProgressBar={true}
			limit={3}
			newestOnTop={false}
			pauseOnFocusLoss
			pauseOnHover
			position="bottom-right"
			role="alert"
			rtl={false}
			theme="colored"
			toastClassName="toast"
			transition={Slide}
		/>
	);
}
