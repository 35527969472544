// https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript

export default function toCamelCase(o: any): any {
	if (isObject(o)) {
		const n: any = {};
		Object.keys(o).forEach((k) => (n[toCamel(k)] = toCamelCase(o[k])));
		return n;
	}

	if (Array.isArray(o)) {
		return o.map((i) => toCamelCase(i));
	}

	return o;
}

const toCamel = (s: string): string => {
	return s.replace(/([-_][a-z])/gi, ($1) => {
		return $1.toUpperCase().replace('-', '').replace('_', '');
	});
};

function isObject(o: any) {
	return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}
