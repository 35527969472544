import { useFeature } from '@optimizely/react-sdk';
import {
	ACTIVATE_ACCOUNT_BILLING_ROUTE,
	ACTIVATE_ACCOUNT_CARD_ROUTE,
	ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE,
	ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE,
	ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE,
	ACTIVATE_ACCOUNT_LEGAL_ROUTE,
	ACTIVATE_ACCOUNT_OVERVIEW_ROUTE,
	ACTIVATE_ACCOUNT_OWNERS_ROUTE,
	ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE,
	ACTIVATE_ACCOUNT_ROUTE,
	ACTIVATE_ACCOUNT_VERIFY_PHONE_ROUTE,
} from 'constants/routes';
import useSession from 'hooks/useSession';
import { useLocation } from 'react-router-dom';

export default function useActivateAccountLayout() {
	const location = useLocation();

	const { isLoggedIn, accessLevel, lastApplicationStatus } = useSession();
	const [isSkipUrlCheckFFEnabled] = useFeature('SKIP_ACTIVATE_ACCOUNT_URL_CHECK');
	const [isComplianceStepEnabled] = useFeature('APPLICATION_COMPLIANCE');

	const TOTAL_STEPS = isComplianceStepEnabled ? '08' : '07';
	const currentStep = _getCurrentStepNumber(location.pathname, isComplianceStepEnabled);

	function hasAccessToProduction() {
		return !isSkipUrlCheckFFEnabled && accessLevel === 'get_started';
	}

	function hasAPendingApplication() {
		return !isSkipUrlCheckFFEnabled && lastApplicationStatus === 'pending_review';
	}

	return {
		currentStep,
		totalSteps: TOTAL_STEPS,
		isComplianceStepEnabled,
		isLoggedIn,
		hasAccessToProduction,
		hasAPendingApplication,
	};
}

function _getCurrentStepNumber(pathname: string, isComplianceStepEnabled: boolean) {
	if (isComplianceStepEnabled) {
		switch (pathname) {
			case ACTIVATE_ACCOUNT_ROUTE:
			case ACTIVATE_ACCOUNT_OVERVIEW_ROUTE:
				return '01';
			case ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE:
				return '02';
			case ACTIVATE_ACCOUNT_OWNERS_ROUTE:
				return '03';
			case ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE:
				return '04';
			case ACTIVATE_ACCOUNT_VERIFY_PHONE_ROUTE:
				return '04b'; // TODO Temporal solution, this should be a modal inside the company address step
			case ACTIVATE_ACCOUNT_CARD_ROUTE:
				return '05';
			case ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE:
			case ACTIVATE_ACCOUNT_LEGAL_ROUTE:
				return '06';
			case ACTIVATE_ACCOUNT_COMPLIANCE_ROUTE:
				return '07';
			case ACTIVATE_ACCOUNT_BILLING_ROUTE:
				return '08';
			default:
				console.error('Unrecognized step, fallback to 404', pathname);
				return '404';
		}
	}

	switch (pathname) {
		case ACTIVATE_ACCOUNT_ROUTE:
		case ACTIVATE_ACCOUNT_OVERVIEW_ROUTE:
			return '01';
		case ACTIVATE_ACCOUNT_COMPANY_INFO_ROUTE:
			return '02';
		case ACTIVATE_ACCOUNT_OWNERS_ROUTE:
			return '03';
		case ACTIVATE_ACCOUNT_COMPANY_ADDRESS_ROUTE:
			return '04';
		case ACTIVATE_ACCOUNT_VERIFY_PHONE_ROUTE:
			return '04b'; // TODO Temporal solution, this should be a modal inside the company address step
		case ACTIVATE_ACCOUNT_CARD_ROUTE:
			return '05';
		case ACTIVATE_ACCOUNT_PUBLIC_KEY_ROUTE:
		case ACTIVATE_ACCOUNT_LEGAL_ROUTE:
			return '06';
		case ACTIVATE_ACCOUNT_BILLING_ROUTE:
			return '07';
		default:
			console.error('Unrecognized step, fallback to 404', pathname);
			return '404';
	}
}
