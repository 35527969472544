import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import style from './Heading.module.scss';

export interface IHeadingProps {
  /**
   * A CSS classname to add additional styles
   */
  className?: string;

  /**
   * Color of the text.
   *
   * @default contentPrimary
   */
  color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary';

  /**
   * Add magin to the element.
   */
  margin?: React.CSSProperties['margin'];

  /**
   * HTML tag element to render as.
   */
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

  /**
   * Alignment of the text.
   *
   * @default left
   */
  textAlign?: 'left' | 'center' | 'right' | 'justified';

  /**
   * This will define the size of the font and the margin-bottom.
   */
  size: 'xx-large' | 'x-large' | 'large' | 'medium' | 'small' | 'x-small';
}

export default function Heading(props: PropsWithChildren<IHeadingProps>) {
  const cnColor = _getColorClass(props.color);
  const cnTextAlign = style[`Heading--${props.textAlign || 'left'}`];
  const cnSize = style[`Heading--${props.size}`];

  switch (props.tag) {
    case 'h1':
      return (
        <h1
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h4>
      );
    case 'h5':
      return (
        <h5
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h5>
      );
    case 'h6':
      return (
        <h6
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </h6>
      );
    case 'p':
    default:
      return (
        <p
          style={{ margin: props.margin }}
          className={classnames(style.Heading, cnColor, cnTextAlign, cnSize, props.className)}
        >
          {props.children}
        </p>
      );
  }
}

function _getColorClass(color?: 'contentPrimary' | 'contentSecondary' | 'contentTertiary') {
  switch (color) {
    case 'contentSecondary':
      return style['Heading--secondary'];
    case 'contentTertiary':
      return style['Heading--tertiary'];
    case 'contentPrimary':
    default:
      return style['Heading--primary'];
  }
}
