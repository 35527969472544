import React from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import Paragraph from 'styleguide/Type/Paragraph/Paragraph';
import View from 'styleguide/View/View';
import style from './ModalAddUsers.module.scss';
import useModalAddUsers from './useModalAddUsers';

export default function ModalAddUsers() {
	const { showModalRequestExampleApp } = useModalAddUsers();
	return (
		<View className={style.ModalAddUsers}>
			<Heading size="small" tag="h1" margin="0 0 12px 0">
				Add user
			</Heading>
			<Paragraph size="small" color="contentPrimary" margin="0 0 28px">
				For our Green Program, you must use the Mobile API to create test cardholders in one or more of the following
				ways: the Demo app, the Mobile SDK, or the Mobile API standalone. All calls should be made to the sandbox URL:
				api.sbx.aptopayments.com. Note: the Mobile API requires user verification for all methods, so before getting
				started&nbsp;
				<Pill
					type="link"
					level="inline-link"
					size="small"
					external
					to="https://docs.aptopayments.com/docs/overview/Getting%20Started/users"
				>
					learn how to do this in sandbox
				</Pill>
				.
			</Paragraph>
			<View className={style.ModalAddUsers__row}>
				<Paragraph size="small" color="contentPrimary">
					Demonstration app
				</Paragraph>
				<Pill level="secondary" size="small" onClick={showModalRequestExampleApp}>
					Get the link
				</Pill>
			</View>
			<View className={style.ModalAddUsers__row}>
				<Paragraph size="small" color="contentPrimary">
					Mobile SDK
				</Paragraph>
				<View className={style.ModalAddUsers__links}>
					<Pill
						type="link"
						level="inline-link"
						size="small"
						external
						to="https://docs.aptopayments.com/docs/sdks/iOS/mobile_sdk_ios#verify-a-user-credential-to-obtain-a-user-token"
					>
						iOS
					</Pill>

					<Pill
						type="link"
						level="inline-link"
						size="small"
						external
						to="https://docs.aptopayments.com/docs/sdks/Android/mobile_sdk_android#verify-a-user-credential-to-obtain-a-user-token"
					>
						Android
					</Pill>
				</View>
			</View>
			<View className={style.ModalAddUsers__row}>
				<Paragraph size="small" color="contentPrimary">
					Mobile API
				</Paragraph>
				<Pill
					type="link"
					level="inline-link"
					size="small"
					external
					to="https://docs.aptopayments.com/api/mobile-api#tag/Verifications"
				>
					Learn more in Docs
				</Pill>
			</View>
		</View>
	);
}
