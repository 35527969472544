import View from 'styleguide/View/View';
import IAptoCardType from 'types/aptoCards/IAptoCardType';
import CardSelector from './components/CardSelector/CardSelector';
import ICardSelectorOption from './components/CardSelector/ICardSelectorOption';

interface ICardTypeRadioGroupProps {
	selectCard: (value: ICardSelectorOption) => void;
	type: IAptoCardType;
	autoPrint?: boolean;
}

export default function CardTypeRadioGroup(props: ICardTypeRadioGroupProps) {
	return (
		<View role="radiogroup">
			<CardSelector
				onClick={props.selectCard}
				description="Digital + physical"
				option="physical"
				isActive={props.type === 'physical' && props.autoPrint === true}
			/>
			<CardSelector
				onClick={props.selectCard}
				option="mixed"
				description="Digital + optional physical"
				isActive={props.type === 'physical' && props.autoPrint === false}
			/>
			<CardSelector
				onClick={props.selectCard}
				option="digital"
				description="Digital only"
				isActive={props.type === 'digital'}
			/>
		</View>
	);
}
