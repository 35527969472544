import React, { PropsWithChildren } from 'react';
import Pill from 'styleguide/Pill/Pill';
import Heading from 'styleguide/Type/Heading/Heading';
import View from 'styleguide/View/View';
import style from './NotificationPopup.module.scss';

export interface INotificationPopupProps {
	title: string;
	handleClose: () => void;
}

export default function NotificationPopup(props: PropsWithChildren<INotificationPopupProps>) {
	return (
		<View className={style.NotificationPopup}>
			<Heading margin="0 0 12px" size="small" tag="h1">
				{props.title}
			</Heading>
			{props.children}
			<View className={style.NotificationPopup__footer}>
				<Pill level="primary" size="medium" onClick={props.handleClose}>
					Done
				</Pill>
			</View>
		</View>
	);
}
