import Notification from 'components/Notification/Notification';
import { ACTIVATE_ACCOUNT_OVERVIEW_ROUTE } from 'constants/routes';
import Pill from 'styleguide/Pill/Pill';
import Text from 'styleguide/Text/Text';
import View from 'styleguide/View/View';
import style from './MissingApplicationIdError.module.scss';

interface MissingApplicationIdErrorProps {
	stepName: string;
}

export default function MissingApplicationIdError(props: MissingApplicationIdErrorProps) {
	const { stepName } = props;

	return (
		<View className={style.MissingApplicationIdError}>
			<View className={style.MissingApplicationIdError__container}>
				<Text margin="24px 0 12px" type="h4">
					{stepName}
				</Text>

				<Notification
					type="negative"
					title="Error"
					description="There was an error retrieving the application id. Please try again by restarting the activation account process."
					margin="16px 0px 28px"
				/>

				<View>
					<Pill type="link" level="primary" size="medium" icon="arrow" to={ACTIVATE_ACCOUNT_OVERVIEW_ROUTE}>
						Start over
					</Pill>
				</View>
			</View>
		</View>
	);
}
