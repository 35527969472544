import ITransaction from 'types/transactions/ITransaction';
import { IReadTransactionsResponse } from './transactions';

function parseTransactions(raw: any): IReadTransactionsResponse {
	return {
		transactions: raw.transactions.map(parseTransaction),
		hasMore: raw.has_more,
		total: raw.total,
	};
}

function parseTransaction({ transaction }: { transaction: any }): ITransaction {
	return {
		billingAmount: transaction.billing_amount.amount,
		billingAmountCurrency: transaction.billing_amount.currency,
		cardDesignKey: transaction.card_design_key,
		cardLastFour: transaction.card_last_four,
		cardNetwork: transaction.card_network,
		cashbackAmount: transaction.cashback_amount.amount,
		cashbackAmountCurrency: transaction.cashback_amount.currency,
		createdAt: new Date(transaction.created_at),
		description: transaction.description,
		feeAmount: transaction.fee_amount.amount,
		feeAmountCurrency: transaction.fee_amount.currency,
		holdAmount: transaction.hold_amount.amount,
		holdAmountCurrency: transaction.hold_amount.currency,
		id: transaction.id,
		localAmount: transaction.local_amount.amount,
		localAmountCurrency: transaction.local_amount.currency,
		merchantName: transaction.merchant_name,
		state: transaction.state,
	};
}

export default {
	parseTransaction,
	parseTransactions,
};
