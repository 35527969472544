import { useRemoveSignedPayloadsKeyMutation } from 'queries/apiKeys.queries';

interface IUseModalDeleteSignedPayloadsKeyArgs {
	id: string;
	onSuccessfulDelete: () => void;
}

export default function useModalDeleteSignedPayloadsKey(args: IUseModalDeleteSignedPayloadsKeyArgs) {
	const removeSignedPayloadsMutation = useRemoveSignedPayloadsKeyMutation();

	function handleDelete() {
		removeSignedPayloadsMutation.mutate(
			{ id: args.id },
			{
				onSuccess: args.onSuccessfulDelete,
			}
		);
	}

	return {
		handleDelete,
		isLoading: removeSignedPayloadsMutation.isLoading,
		serverError: removeSignedPayloadsMutation.error?.message || '',
	};
}
