import { ReactNode } from 'react';
import View from 'styleguide/View/View';
import style from './Section.module.scss';

interface ISectionProps {
	children: ReactNode;
}

export default function Section(props: ISectionProps) {
	return <View className={style.Section}>{props.children}</View>;
}
