import React from 'react';

export default function TableHead(props: { headNames: string[] }) {
  return (
    <thead>
      <tr>
        <th></th>
        {props.headNames.map((name) => (
          <th key={name}>{name}</th>
        ))}
        <th></th>
      </tr>
    </thead>
  );
}
