import apiClientService from 'api-client/apiclient.service';
import { DASHBOARD_ROUTE } from 'constants/routes';
import { useQueryParams } from 'hooks/useQueryParams';
import useSession from 'hooks/useSession';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import sentryService from 'services/sentry.service';
import validationService from 'services/validationService/validation.service';
import IServerRequestState from 'types/network/IServerRequest';
import useForm from 'utils/useForm';

interface IUseSync2FAState extends IServerRequestState {
	isDataVisible: boolean;
}

export default function useSync2FA() {
	const { initSession } = useSession();
	const params = useQueryParams();
	const attemptID = params.get('attempt_id');
	const secret = params.get('secret');
	const email = params.get('email');
	const navigate = useNavigate();

	const [state, setState] = useState<IUseSync2FAState>({
		requestStatus: 'IDLE',
		serverError: '',
		isDataVisible: false,
	});

	const { bind, submitForm, values, errors, validateForm, resetForm } = useForm({
		initialValues: {
			firstCode: '',
			secondCode: '',
		},
		onSubmit: function (values) {
			if (!attemptID || !secret || !email) {
				return setState((s) => ({
					...s,
					serverError: 'Client and server are out of sync. Try to create an account again or contact us.',
				}));
			}

			setState((s) => ({ ...s, requestStatus: 'PENDING' }));

			return apiClientService.auth
				.sync2FACode(values.firstCode, values.secondCode, attemptID)
				.then((res) => {
					setState((s) => ({ ...s, requestStatus: 'SUCCESS' }));
					sentryService.identifyUser({
						email: res.session.userData.email,
						id: res.session.userId,
						username: `${res.session.userData.firstName} ${res.session.userData.lastName}`,
					});
					initSession(res);
					navigate(DASHBOARD_ROUTE);
				})
				.catch((error) => {
					resetForm();
					setState((s) => ({
						...s,
						serverError: error.message,
						requestStatus: 'FAILED',
					}));
				});
		},
		validate: validationService.createValidator({
			firstCode: [validationService.length(6), validationService.required],
			secondCode: [validationService.length(6), validationService.required],
		}),
	});

	useEffect(() => {
		validateForm().then((errors) => {
			const areNoErrors = Object.keys(errors).every((error) => !errors[error as keyof typeof errors]);
			const areValues = Object.keys(values).every((value) => values[value as keyof typeof values]);
			if (areNoErrors && areValues) {
				submitForm();
			}
		});
	}, [values, validateForm, submitForm]);

	return {
		bind,
		email,
		isDataVisible: state.isDataVisible,
		isSecondInputDisabled: !values.firstCode || !!errors.firstCode,
		requestStatus: state.requestStatus,
		secret,
		serverError: state.serverError,
		showUserData: () => setState((s) => ({ ...s, isDataVisible: true })),
		hideUserData: () => setState((s) => ({ ...s, isDataVisible: false })),
		submitForm,
	};
}
