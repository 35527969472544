import apiFetch from 'api-client/utils/fetch';
import toCamelCase from 'api-client/utils/toCamelCase';
import IGetAgreementsResponse from 'types/agreements/IAgreements';

const AGREEMENTS_ENDPOINT = '/developer/agreements';

async function read(): Promise<IGetAgreementsResponse> {
	return apiFetch(AGREEMENTS_ENDPOINT, 'GET').then(toCamelCase);
}

export default {
	read,
};
