import MissingApplicationIdError from 'components/MissingApplicationIdError/MissingApplicationIdError';
import useApplicationId from 'hooks/useApplicationId';
import React from 'react';

interface IWithApplicationIdProps<ComponentProps> {
	Component: React.ComponentType<ComponentProps>;
	stepName: string;
}

/**
 * High order component that returns an error when there is no application ID.
 */
export default function WithApplicationId<Props>({ Component, stepName }: IWithApplicationIdProps<Props>) {
	function WrappedComponent(originalProps: Props) {
		const applicationId = useApplicationId();

		if (!applicationId) {
			return <MissingApplicationIdError stepName={stepName} />;
		}

		return <Component {...(originalProps as any)} />;
	}

	WrappedComponent.displayName = `WithApplicationId${Component.displayName || Component.name}`;

	return WrappedComponent;
}
